import { Grid, useTheme } from '@mui/material';
import InputSelectV2 from 'components/FormElement/InputSelectV2';
import InputTextOutlinedV2 from 'components/FormElement/InputTextOutlinedV2';
import ModalFeedback from 'components/Generic/Modal/ModalFeedback';
import { ROLE_CUSTOMER, ROLE_SUPERCUSTOMER } from 'constants/users';
import _ from 'lodash';
import { UserFields, usersInitialState } from 'pages/userAccessControl/userFields';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import UsersService from 'services/UsersService';

const AddUsersModal = ({ openModal, setOpenModal, roles, clients, onHandleSaved, userSelected, setUserSelected }) => {
  const { fields, handleSave, errors, handleSubmit, validSubmit, resetForm, setValues, setInitialStateRequest } =
    UserFields(usersInitialState);
  const theme = useTheme();
  useEffect(() => {
    if (!openModal) {
      resetForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal]);

  useEffect(() => {
    (async () => {
      if (validSubmit) {
        if (!_.isEmpty(userSelected)) await UsersService.updateUser(userSelected.id, fields);
        else await UsersService.createNewUser(fields);

        toast.success('User saved succesfully');
        onHandleSaved();
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validSubmit]);

  useEffect(() => {
    if (fields.role !== '') {
      setValues((prev) => ({ ...prev, clientId: '' }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields.role]);

  useEffect(() => {
    if (!_.isEmpty(userSelected)) {
      const clientId = userSelected.Clients?.length > 0 ? userSelected.Clients[0].id : '';
      const { first_name, last_name, email, role, phone } = userSelected;
      setValues((prev) => ({
        ...prev,
        clientId,
        first_name,
        last_name,
        email,
        role,
        phone: phone || '',
      }));
      setInitialStateRequest((prev) => ({
        ...prev,
        clientId,
        first_name,
        last_name,
        email,
        role,
        phone: phone || '',
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSelected]);

  const handleCloseModal = async () => {
    Promise.all([setUserSelected({}), setOpenModal(false)]);
  };

  const addNewUser = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <InputTextOutlinedV2
            id="first_name"
            label="First Name"
            placeholder=""
            onChangeInput={handleSave}
            initialValue={fields?.first_name || ''}
            error={errors.first_name?.txt}
          />
        </Grid>
        <Grid item xs={12}>
          <InputTextOutlinedV2
            id="last_name"
            label="Last Name"
            placeholder=""
            onChangeInput={handleSave}
            initialValue={fields?.last_name || ''}
            error={errors?.last_name?.txt}
          />
        </Grid>
        <Grid item xs={12}>
          <InputTextOutlinedV2
            id="email"
            label="Email"
            type="email"
            onChangeInput={handleSave}
            error={errors.email?.txt}
            initialValue={fields?.email || ''}
            variant="standard"
          />
        </Grid>
        <Grid item xs={12}>
          <InputSelectV2
            id="role"
            label="Select Role"
            placeholder="Select Role"
            opts={roles}
            initialValue={fields.role}
            onChangeInput={handleSave}
            error={errors.role?.txt}
            defaultOption={false}
          />
        </Grid>

        {[ROLE_SUPERCUSTOMER, ROLE_CUSTOMER].includes(fields.role) && (
          <Grid item xs={12} sx={{}}>
            <InputSelectV2
              id="clientId"
              label="Select Client to Be Linked"
              placeholder="Select Client"
              opts={clients?.map((cl) => ({ _id: cl.id, label: cl.companyName }))}
              onChangeInput={handleSave}
              value={fields.clientId}
              initialValue={fields.clientId}
              error={errors.clientId?.txt}
              defaultOption={true}
            />
          </Grid>
        )}
      </Grid>
    );
  };

  return (
    <ModalFeedback
      variant="general"
      headerText={'Add New User'}
      headerBarColor={theme.palette.primary.main}
      open={openModal}
      onAccept={() => handleSubmit(fields)}
      onCancel={handleCloseModal}
      onClose={handleCloseModal}
      disableCloseOnAccept={true}
    >
      {addNewUser()}
    </ModalFeedback>
  );
};

export default AddUsersModal;
