import { Box, Grid, Typography } from '@mui/material';
import InputFileWithText from 'components/FormElement/InputFileWithText';
import InputSelectV2 from 'components/FormElement/InputSelectV2';
import InputTextOutlinedV2 from 'components/FormElement/InputTextOutlinedV2';
import RepeaterControl from 'components/FormElement/RepeaterControl';
import { holidaysOptions } from 'constants/project';
import { ROLE_SUPERTAS } from 'constants/users';
import _ from 'lodash';
import moment from 'moment';
import FieldsNewAdittionalSkills from 'pages/common/FieldsNewAdittionalSkills';
import { useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { currentTitle, hardwareOpts, seniority, timezoneOpts } from 'utils/staticData';
import { FormHook } from '../../../../hooks/form/Form';
import projectService from '../../../../services/projectService';
import { loginExpire } from '../../../../store/auth';
import { schemaManagerEmail, schemaManagerName, schemaProject } from '../../../../validations/projects/projectRegister';

const ProjectForm = ({ project, submitForm, onValidForm, reset: resetMainForm, auth }) => {
  const [skillsSearch, setSkillsSearch] = useState([]);
  const [workHours, setWorkHours] = useState([]);
  const [optsRecruiter, setOptsRecruiter] = useState([]);
  const [files, setFiles] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [reset, setReset] = useState(false);
  let elementRef = useRef();

  const {
    fields,
    handleSave,
    errors,
    handleSubmit,
    validSubmit,
    setValues,
    resetForm,
    setForm,
    setErrors,
    initialState,
    handleSaveRepeaterProp,
    setValidSubmit,
  } = FormHook(
    {
      description: '',
      position: '',
      seniority: '',
      teamsize: '',
      RecruiterId: '',
      managers: [{ name: '', email: '' }],
      timezone: '',
      customer_job_link: '',
      customer_job_description_link: '',
      notes: '',
      rate_assumption: '',
      job_description_link: '',
      codetest_link: '',
      maxrate: '',
      monthly_mxn: '',
      monthly_usd: '',
      hardware_needs: [],
      hardware_notes: '',
      interview_process: '',
      holidays: '',
      codetest_id: '',
      newSkills: [{ SkillId: '', skill_type: 1, yearExperience: '' }],
      adittionalSkills: [{ SkillId: '', skill_type: 2, yearExperience: '' }],
      working_hours_start: '',
      working_hours_end: '',
    },
    schemaProject
  );

  useMemo(() => {
    const handleDisable = () => auth.user.role !== ROLE_SUPERTAS;
    setDisabled(handleDisable);
  }, [auth.user.role]);

  useEffect(() => {
    if (resetMainForm === true) {
      resetForm();
      setReset(true);
      return;
    }
    setReset(false);
    // eslint-disable-next-line
  }, [resetMainForm]);

  useEffect(() => {
    if (submitForm === true) {
      Promise.all([setValidSubmit(null), handleSubmit(elementRef)]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitForm]);

  // initial hook
  useEffect(() => {
    (async () => {
      const { data: recruiters } = await projectService.getAllRecruiters();
      const { data: skillsDB } = await projectService.getAllSkills();
      const skillOpts = [];
      skillsDB.forEach((el) => skillOpts.push({ id: el.id, title: el.name }));

      const optsRecruiter = _.map(recruiters, (rec) => {
        return { _id: rec.id, label: rec.full_name };
      });

      const initialHour = '06:00';
      const arrHours = [];
      for (let i = 0; i <= 14; i++) {
        const time = moment(initialHour, 'hh').add(i, 'hours');
        arrHours.push({ _id: time.format('HH'), label: time.format('LT') });
      }
      setSkillsSearch(skillOpts);
      setWorkHours(arrHours);
      setOptsRecruiter(optsRecruiter);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (validSubmit === null) return;
      else if (validSubmit === false) {
        onValidForm(false);
        return;
      }

      try {
        const newData = { ...fields };
        if (!newData.adittionalSkills[0].SkillId || newData.adittionalSkills[0]?.SkillId === '')
          newData.adittionalSkills = [];
        if (files !== '') {
          const formData = new FormData();
          formData.append('id', 'job_description');
          formData.append('job_description', files, files.name);
          const {
            data: { file },
          } = await projectService.uploadProjectAttachments(formData, 'job_description');
          newData['customer_job_link'] = file;
        }
        setErrors({ ...initialState });
        onValidForm(newData);
      } catch (error) {
        if (error.response && error.response.data) {
          return toast.error(error.response.data.message);
        }
      }
    })();
  }, [validSubmit]); // eslint-disable-line

  useEffect(() => {
    (async () => {
      if (!!project && !_.isEmpty(project)) {
        const skill = await project.SkillsDB.filter((sk) => sk.skill_type === 1 || sk.skill_type === 3);
        let aditionalSkill = await project.SkillsDB.filter((sk) => sk.skill_type === 2);
        const newSkill = skill.map((sk) => {
          return { SkillId: sk.SkillId.toString(), skill_type: sk.skill_type, yearExperience: sk.year_experience };
        });
        let newAdittionalSkill = [{ SkillId: '', skill_type: 2, yearExperience: '' }];
        if (aditionalSkill.length > 0)
          newAdittionalSkill = aditionalSkill.map((sk) => {
            return { SkillId: sk.SkillId.toString(), skill_type: 2, yearExperience: sk.year_experience };
          });
        let hardware;
        if (typeof project.hardware_needs === 'object') hardware = Object.values(project.hardware_needs);
        else {
          /* eslint-disable */
          hardware = project.hardware_needs
            .replace(/[\{\}\"\ \']+/g, '')
            .split(',')
            .map((el) => parseInt(el));
          /* eslint-enable */
        }
        const hardware_label = hardwareOpts.filter((h) => hardware.includes(h._id)).map((obj) => obj._id);
        const newData = {
          description: project.description,
          position: project.position,
          seniority: project.seniority,
          teamsize: project.teamsize,
          RecruiterId: project.RecruiterId,
          managers: project.managers,
          timezone: project.timezone,
          // fee: data.fee,
          monthly_mxn: project.monthly_mxn,
          monthly_usd: project.monthly_usd,
          customer_job_link: project.customer_job_link || '',
          customer_job_description_link: project.customer_job_description_link || '',
          job_description_link: project.job_description_link || '',
          notes: project.notes || '',
          rate_assumption: project.rate_assumption || '',
          codetest_link: project.codetest_link || '',
          maxrate: project.maxrate,
          maxrate_pesos: project.maxrate_pesos,
          codetest_id: project.codetest_id,
          holidays: project.holidays,
          hardware_needs: hardware_label,
          hardware_notes: project.hardware_notes,
          interview_process: project.interview_process,
          newSkills: newSkill, //array single
          adittionalSkills: newAdittionalSkill || [], // array single
          working_hours_start: project.working_hours.monday.start,
          working_hours_end: project.working_hours.monday.end,
          working_hours: {
            monday: {
              active: project.working_hours.monday.active,
              start: project.working_hours.monday.start,
              end: project.working_hours.monday.end,
            },
          },
        };
        setForm({ ...initialState, ...newData });
      }
    })();
  }, [project]); // eslint-disable-line

  const handleFileChange = ({ target }) => setFiles(target.files[0]);
  const alignFields = () => ({ height: '100%', justifyContent: 'space-between' });

  return (
    <Grid container ref={(ref) => (elementRef = ref)} component="form" spacing={2} alignItems="flex-start">
      <Grid item xs={12} sm={6}>
        <InputSelectV2
          id="position"
          size={'small'}
          opts={currentTitle}
          error={errors.position?.txt}
          initialValue={fields.position}
          label="POSITION TITLE"
          placeholder={'Position Title'}
          onChangeInput={(e) => {
            handleSave(e);
          }}
          isDisabled={disabled}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <InputSelectV2
          id="seniority"
          label="SENIORITY"
          size={'normal'}
          opts={seniority}
          error={errors.seniority?.txt}
          initialValue={fields.seniority}
          placeholder={'Project Seniority'}
          onChangeInput={(e) => handleSave(e)}
          variant="standard"
          backgroundColor="nexxusGrayExtraLight.main"
          isDisabled={disabled}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <InputSelectV2
          id="RecruiterId"
          size={'normal'}
          opts={optsRecruiter}
          error={errors.RecruiterId && errors.RecruiterId.txt}
          initialValue={fields.RecruiterId}
          label="LEAD RECRUITER"
          placeholder={'Recruiter'}
          onChangeInput={(e) => {
            handleSave(e);
          }}
          variant="standard"
          backgroundColor="nexxusGrayExtraLight.main"
          isDisabled={disabled}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputTextOutlinedV2
          id={'description'}
          size={'normal'}
          label="PROJECT DESCRIPTION"
          multiline
          rowsMultiline={4}
          error={errors.description?.txt}
          initialValue={fields.description}
          onChangeInput={(e) => {
            handleSave(e);
          }}
          variant="standard"
          backgroundColor="nexxusGrayExtraLight.main"
          isDisabled={disabled}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputTextOutlinedV2
          id={'notes'}
          size={'normal'}
          label="PROJECT NOTES"
          multiline
          rowsMultiline={4}
          error={errors.notes?.txt}
          initialValue={fields.notes}
          onChangeInput={(e) => {
            handleSave(e);
          }}
          variant="standard"
          backgroundColor="nexxusGrayExtraLight.main"
          isDisabled={disabled}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputTextOutlinedV2
          id={'interview_process'}
          size={'normal'}
          label="INTERVIEW PROCESS AND ADDITIONAL REQUIREMENTS"
          multiline
          rowsMultiline={4}
          error={errors.interview_process?.txt}
          initialValue={fields.interview_process}
          onChangeInput={(e) => {
            handleSave(e);
          }}
          variant="standard"
          backgroundColor="nexxusGrayExtraLight.main"
          isDisabled={disabled}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <InputTextOutlinedV2
          id={'job_description_link'}
          error={errors.job_description_link?.txt}
          initialValue={fields.job_description_link}
          size={'normal'}
          label="Job Description Info"
          multiline
          rowsMultiline={4}
          onChangeInput={(e) => {
            handleSave(e);
          }}
          variant="standard"
          backgroundColor="nexxusGrayExtraLight.main"
          isDisabled={disabled}
          styleControl={alignFields()}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <InputTextOutlinedV2
          id={'customer_job_description_link'}
          error={errors.customer_job_description_link?.txt}
          initialValue={fields.customer_job_description_link}
          size={'normal'}
          label="JOB DESCRIPTION LINK"
          onChangeInput={(e) => {
            handleSave(e);
          }}
          variant="standard"
          backgroundColor="nexxusGrayExtraLight.main"
          isDisabled={disabled}
          styleControl={alignFields()}
        />
      </Grid>

      <Grid item xs={12} sm={3}>
        <InputFileWithText
          accept={['application/pdf']}
          id={'customer_job_link'}
          label={'Job Description File'}
          onFileChange={handleFileChange}
          reset={reset}
          defaultFileName={fields.customer_job_link}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <InputSelectV2
          id="hardware_needs"
          size={'normal'}
          label="HARDWARE NEEDS"
          initialValue={fields.hardware_needs}
          error={errors.hardware_needs?.txt}
          opts={hardwareOpts}
          onChangeInput={(e) => {
            handleSave(e);
          }}
          multiple={true}
        ></InputSelectV2>
      </Grid>
      <Grid item xs={12} sm={12} md={3}>
        <InputTextOutlinedV2
          id={'hardware_notes'}
          error={errors.hardware_notes?.txt}
          initialValue={fields.hardware_notes}
          size={'normal'}
          label="CUSTOM HARDWARE NOTES"
          onChangeInput={(e) => handleSave(e)}
          variant="standard"
          backgroundColor="nexxusGrayExtraLight.main"
          styleControl={alignFields()}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <Grid container spacing={2} alignItems="flex-start">
          <Grid item xs={12} sm={2}>
            <InputTextOutlinedV2
              id={'teamsize'}
              type="number"
              size={'normal'}
              label="HEADCOUNT"
              error={errors.teamsize?.txt}
              initialValue={fields.teamsize}
              onChangeInput={(e) => {
                handleSave(e);
              }}
              variant="standard"
              backgroundColor="nexxusGrayExtraLight.main"
              isDisabled={disabled}
              styleControl={alignFields()}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <InputSelectV2
              id="timezone"
              size={'normal'}
              opts={_.orderBy(timezoneOpts, [(timezone) => timezone._id.toLowerCase()], ['asc'])}
              error={errors.timezone?.txt}
              initialValue={fields.timezone}
              placeholder={'Timezone'}
              label="TIMEZONE"
              onChangeInput={(e) => {
                handleSave(e);
              }}
              isDisabled={disabled}
              sxContainer={alignFields()}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputTextOutlinedV2
              id={'codetest_link'}
              error={errors.codetest_link?.txt}
              initialValue={fields.codetest_link}
              size={'normal'}
              label="CODE TEST - INSERT LINK"
              onChangeInput={(e) => {
                handleSave(e);
              }}
              variant="standard"
              backgroundColor="nexxusGrayExtraLight.main"
              isDisabled={disabled}
              styleControl={alignFields()}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <InputSelectV2
              id={`working_hours_start`}
              opts={workHours}
              size={'normal'}
              initialValue={fields.working_hours_start}
              error={errors.working_hours_start?.txt}
              placeholder={'Start'}
              label="WORKING HOURS FROM"
              onChangeInput={(e) => {
                handleSave(e);
              }}
              isDisabled={disabled}
              sxContainer={alignFields()}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputSelectV2
              label="WORKING HOURS TO"
              id="working_hours_end"
              size={'normal'}
              opts={workHours}
              initialValue={fields.working_hours_end}
              error={errors.working_hours_end?.txt}
              placeholder={'End'}
              onChangeInput={(e) => {
                handleSave(e);
              }}
              isDisabled={disabled}
              sxContainer={alignFields()}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputSelectV2
              id="holidays"
              size={'small'}
              opts={holidaysOptions}
              error={errors.holidays?.txt}
              initialValue={fields.holidays}
              placeholder={''}
              label="HOLIDAYS"
              onChangeInput={(e) => {
                handleSave(e);
              }}
              isDisabled={disabled}
              sxContainer={alignFields()}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputTextOutlinedV2
              id={'maxrate'}
              type="number"
              placeholder="RATE USD"
              size={'normal'}
              label="MAX CUSTOMER RATE"
              error={errors.maxrate?.txt}
              initialValue={fields.maxrate}
              onChangeInput={(e) => {
                handleSave(e);
              }}
              variant="standard"
              backgroundColor="nexxusGrayExtraLight.main"
              isDisabled={disabled}
              styleControl={alignFields()}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputTextOutlinedV2
              id={'codetest_id'}
              type="number"
              placeholder=""
              size={'normal'}
              label="CODE TEST ID"
              error={errors.codetest_id?.txt}
              initialValue={fields.codetest_id}
              onChangeInput={(e) => {
                handleSave(e);
              }}
              variant="standard"
              backgroundColor="nexxusGrayExtraLight.main"
              isDisabled={disabled}
              styleControl={alignFields()}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} md={6}>
        <Box mt={4} sx={{ backgroundColor: '#fafafa' }}>
          <Grid container spacing={2} padding={2} alignItems="flex-start">
            <Grid item xs={12}>
              <Typography
                sx={{
                  marginTop: -1,
                  marginBotom: 10,
                  textAlign: 'center',
                  textTransform: 'uppercase',
                  color: '#666',
                  fontWeight: 'bold',
                  fontSize: 20,
                }}
              >
                Project Candidate Info
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Grid container spacing={2} alignItems="flex-start">
                <Grid item xs={12}>
                  <InputTextOutlinedV2
                    id={'monthly_mxn'}
                    type="number"
                    placeholder="MXN"
                    size={'normal'}
                    label="MO. RATE (MXN)"
                    error={errors.monthly_mxn?.txt}
                    initialValue={fields.monthly_mxn}
                    onChangeInput={(e) => {
                      handleSave(e);
                    }}
                    variant="standard"
                    backgroundColor="nexxusGrayExtraLight.main"
                    isDisabled={disabled}
                    styleControl={alignFields()}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputTextOutlinedV2
                    id={'monthly_usd'}
                    type="number"
                    placeholder="USD"
                    size={'normal'}
                    label="MO. RATE (USD)"
                    error={errors.monthly_usd?.txt}
                    initialValue={fields.monthly_usd}
                    onChangeInput={(e) => {
                      handleSave(e);
                    }}
                    variant="standard"
                    backgroundColor="nexxusGrayExtraLight.main"
                    isDisabled={disabled}
                    styleControl={alignFields()}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={8}>
              <InputTextOutlinedV2
                id={'rate_assumption'}
                error={errors.rate_assumption?.txt}
                initialValue={fields.rate_assumption}
                size={'normal'}
                label="Candidate Rate Assumptions"
                multiline
                rowsMultiline={5}
                onChangeInput={(e) => {
                  handleSave(e);
                }}
                variant="standard"
                backgroundColor="nexxusGrayExtraLight.main"
                isDisabled={disabled}
                styleControl={alignFields()}
              />
            </Grid>
          </Grid>
        </Box>
      </Grid>

      <Grid item container xs={12} md={6} alignItems="flex-start">
        <FieldsNewAdittionalSkills
          keyField="newSkills"
          fields={fields.newSkills}
          errors={errors.newSkills}
          skillsSearch={skillsSearch}
          reset={reset}
          setValues={setValues}
          handleSaveRepeaterProp={handleSaveRepeaterProp}
        />
      </Grid>

      <Grid item container xs={12} md={6} alignItems="flex-start">
        <FieldsNewAdittionalSkills
          keyField="adittionalSkills"
          fields={fields.adittionalSkills}
          errors={errors.adittionalSkills}
          skillsSearch={skillsSearch}
          reset={reset}
          setValues={setValues}
          handleSaveRepeaterProp={handleSaveRepeaterProp}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        {fields.managers &&
          fields.managers.length > 0 &&
          fields.managers.map((manager, index) => (
            <Grid container key={index} columnSpacing={2} alignItems={'flex-start'}>
              <Grid item xs={12} lg={6} sx={{ marginBottom: 2 }}>
                <InputTextOutlinedV2
                  id={`managers_${index}_name`}
                  size={'normal'}
                  label="Project POC Name"
                  error={errors.managers && errors.managers[index]?.name && errors.managers[index].name.txt}
                  initialValue={manager.name}
                  onChangeInput={(e) => {
                    handleSaveRepeaterProp(e, schemaManagerName);
                  }}
                  variant="standard"
                  backgroundColor="nexxusGrayExtraLight.main"
                  isDisabled={disabled}
                  styleControl={alignFields()}
                />
              </Grid>
              <Grid item xs={12} lg={6} sx={{ marginBottom: 2 }}>
                <InputTextOutlinedV2
                  id={`managers_${index}_email`}
                  size={'normal'}
                  label="Project POC Email"
                  error={errors.managers && errors.managers[index]?.email && errors.managers[index].email.txt}
                  initialValue={manager.email}
                  onChangeInput={(e) => {
                    handleSaveRepeaterProp(e, schemaManagerEmail);
                  }}
                  variant="standard"
                  backgroundColor="nexxusGrayExtraLight.main"
                  isDisabled={disabled}
                  styleControl={alignFields()}
                />
              </Grid>
            </Grid>
          ))}
        {auth.user.role === ROLE_SUPERTAS && (
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'end' }}>
            <RepeaterControl
              elements={fields.managers}
              maxElements={4}
              labelRemove={'REMOVE'}
              labelAdd={'ADD (UP TO 4)'}
              onHandleIncrement={() => {
                const newFields = { ...fields };
                newFields.managers.push({ name: '', email: '' });
                setValues(newFields);
              }}
              onHandleDecrement={() => {
                const newFields = { ...fields };
                setValues((prev) => ({ ...prev, managers: newFields.managers.slice(0, -1) }));
              }}
            />
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return {
    loginExpire: (user) => {
      dispatch(loginExpire(user));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ProjectForm);
