import { useEffect, useState } from 'react';

import httpService from '../../services/httpService';

import { objectToQueryString } from '../../utils/utils';
import useIsMounted from '../useIsMounted';

export default function useProjects(props) {
  const [state, setState] = useState({
    total: 0,
    rows: [],
  });
  const isMountedRef = useIsMounted();

  useEffect(() => {
    const url = process.env.REACT_APP_API_BACKEND + '/v1/projects?' + objectToQueryString(props.filters);

    const req = httpService.get(url);

    req.then(({ data }) => {
      // 👇️ only update state if component is mounted
      if (isMountedRef.current) {
        setState({
          total: data.length,
          rows: data,
        });
      }
    });
  }, [isMountedRef, props.filters]);

  return state;
}
