import { connect } from 'react-redux';

import { Box } from '@mui/material';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useEffect } from 'react';
import { loginExpire } from 'store/auth';
import MainLayout from '../../components/MainLayout/MainLayout';
import { useCustomers, useRecruiters } from '../../hooks/request';
import useSkills from '../../hooks/request/useSkills';
import useBreadcrumbs from '../../hooks/useBreadcrumbs';
import DashboardSummary from './dashboard/DashboardSummary';

const Dashboard = (props) => {
  const { setBreadcrumbs } = useBreadcrumbs();
  const recruiters = useRecruiters();
  const skills = useSkills();
  const { currentUser } = useCurrentUser();
  const { customers } = useCustomers();

  useEffect(() => {
    setBreadcrumbs([
      {
        name: 'Dashboard',
      },
    ]);
  }, [setBreadcrumbs]);

  return (
    <MainLayout {...props} current="dashboard">
      <Box pt={2} pb={2}>
        {currentUser && <DashboardSummary client={currentUser.Client} {...{ recruiters, skills, customers }} />}
      </Box>
    </MainLayout>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return {
    loginExpire: (user) => {
      dispatch(loginExpire(user));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
