import { FormHook } from '../../../hooks/form/Form';
import { schemaClient } from '../../../validations/clients/clientRegister';

export default function ClientFields() {
  const fields = {
    companyName: '',
    websiteUrl: '',
    logoUrl: '',
    timezone: '',
    status: '',
    notes: '',
    managers: [{ stakeholder: '', email: '' }],
  };

  return FormHook(fields, schemaClient);
}
