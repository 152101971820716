import http from 'services/httpService';

function uploadUserFile(formData) {
  return http.post(`${process.env.REACT_APP_API_BACKEND}/v1/users/me/attachments`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

/**
 *
 * @param {Object} body
 * @param {String} body.first_name
 * @param {String} body.last_name
 * @param {String} body.phone
 * @param {String} body.avatar
 *
 */
function updateMeInfo(body) {
  return http.patch(process.env.REACT_APP_API_BACKEND + `/v1/users/me`, body);
}

/**
 *
 * @param {Object} body
 * @param {String} body.email
 *
 */
function updateMeEmail(body) {
  return http.patch(process.env.REACT_APP_API_BACKEND + `/v1/users/me/email`, body);
}

/**
 *
 * @param {String} email
 *
 * */
function validateNewEmail(email) {
  return http.get(`${process.env.REACT_APP_API_BACKEND}/v1/users/valid-email`, { params: { email } });
}

/**
 *
 * @param {String} password
 *
 * */
function validateCurrentPassword(password) {
  return http.get(`${process.env.REACT_APP_API_BACKEND}/v1/users/valid-password`, { params: { password } });
}

/**
 *
 * @param {Object} body
 * @param {String} body.old_password
 * @param {String} body.password
 *
 */
function updateMePassword(body) {
  return http.patch(process.env.REACT_APP_API_BACKEND + `/v1/users/me/password`, body);
}

// eslint-disable-next-line import/no-anonymous-default-export
export default { uploadUserFile, updateMeInfo, validateNewEmail, updateMeEmail, validateCurrentPassword, updateMePassword };
