import { Box, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import MainContainer from './MainContainer';
import React, { useEffect, useContext } from 'react';
import { connect } from 'react-redux';
import { logout } from '../../store/auth';
import { SideBar, TopBar, ThemeContext } from '@nexxus/components';

const drawerWidth = 119;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  width: '100%',
  flexGrow: 1,
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(0, 2, 3, 2),
  },
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(0, 3, 3, 3),
  },
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

const MainLayout = (props) => {
  const { current } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const { auth } = props;
  const lastLogin = auth?.lastLogin;
  const { setThemeName } = useContext(ThemeContext);

  useEffect(() => {
    setThemeName('new');
  }, []);

  return (
    <Box sx={{ display: 'flex', background: '#ECECEC' }}>
      <Box component="nav" sx={{ width: { lg1: drawerWidth }, flexShrink: { md: 0 } }} aria-label="mailbox folders">
        <SideBar
          {...props}
          mobileOpen={mobileOpen}
          handleSidebarOpen={(val) => setMobileOpen(val)}
          current={current}
          drawerWidth={drawerWidth}
          auth={auth}
        />
      </Box>
      <Stack width="100%">
        <TopBar
          handleSidebarOpen={() => setMobileOpen(!mobileOpen)}
          lastLogin={lastLogin ?? ''}
          avatar={auth?.user.avatar ?? ''}
        />
        <Main open={mobileOpen} sx={{ minHeight: 'calc(100vh - 66px)' }}>
          <MainContainer>{props.children}</MainContainer>
        </Main>
      </Stack>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => {
      dispatch(logout());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MainLayout);
