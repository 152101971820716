import React, { Fragment } from 'react';
import InterviewUploads from '../../../components/InterviewUploads';

const TabUploads = ({ candidateId, isDisabled }) => {
  return (
    <Fragment>
      <InterviewUploads candidateId={candidateId} isDisabled={isDisabled} />
    </Fragment>
  );
};

export default TabUploads;
