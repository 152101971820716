import _ from 'lodash';
import projectService from 'services/projectService';
import { PROJECT_STATUS } from 'utils/staticData';

const getActiveProjsAndCustomers = (clientID, recruiter) => {
  const client = clientID ?? '';
  return new Promise(async (resolve) => {
    const { data: activeProjects } = await projectService.getAllProjects({
      // recruiters: recruiter ? [recruiter] : [],
      excludeStatus: [PROJECT_STATUS.ARCHIVED, PROJECT_STATUS.ON_HOLD],
      client,
    });

    const customers = _.uniqBy(
      activeProjects.map((pj) => pj.Client),
      'id'
    );

    resolve({ activeProjects, customers });
  });
};

export { getActiveProjsAndCustomers };
