import { connect } from 'react-redux';

import { Box, Grid, Typography } from '@mui/material';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useEffect, useMemo, useState } from 'react';
import { loginExpire } from 'store/auth';
import MainLayout from './../../components/MainLayoutRedesign/MainLayout';
import useBreadcrumbs from '../../hooks/useBreadcrumbs';
import { ApplicantsWidget, CardsWidget, PositionsWidget, NCSscoreWidget } from '@nexxus/components';
import customerService from 'services/customerService';
import projectService from 'services/projectService';
import { capitalizeString } from 'utils/utils';
import { PROJECT_STATUS } from 'utils/staticData';
import { excludeForTotalCounters } from 'constants/customer';

const Dashboard = (props) => {
  const { setBreadcrumbs } = useBreadcrumbs();
  const { currentUser } = useCurrentUser();
  const [counters, setCounters] = useState(null);
  const [testNcs] = useState(Math.floor(Math.random() * (100 - 80 + 1) + 80));
  const [projects, setProjects] = useState([]);
  const [applicants, setApplicants] = useState([]);
  const [latestProjects, setLatestProjects] = useState([]);

  const excludeForLatestApplicants = [...excludeForTotalCounters];

  useEffect(() => {
    setBreadcrumbs([
      {
        name: 'Dashboard',
      },
    ]);
  }, [setBreadcrumbs]);

  useMemo(() => {
    (async () => {
      if (currentUser) {
        const { data } = await customerService.getCustomerCounters(currentUser?.Client?.id, null, {
          excludeForTotal: excludeForTotalCounters,
        });
        const { data: projects } = await projectService.getProjectsByClient(currentUser?.Client?.id, {
          excludeStatus: [PROJECT_STATUS.ARCHIVED],
        });
        const projs = projects.map((pj) => ({
          _id: pj.id,
          label: `${capitalizeString(pj.position)} - ${capitalizeString(pj.seniority)}`,
        }));

        const { data: latestCandidates } = await customerService.getCustomerLatestCandidates(currentUser.Client.id, {
          excludeForApplicants: excludeForLatestApplicants,
        });

        const { data: latestProjects } = await customerService.getCustomerLatestProjects(currentUser.Client.id, {
          excludeForProjects: PROJECT_STATUS.ARCHIVED,
        });

        Promise.all([
          setCounters(data),
          setProjects(projs),
          setApplicants(latestCandidates),
          setLatestProjects(latestProjects),
        ]);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  const handleCounter = async (ProjectId) => {
    const project = ProjectId === 0 ? null : ProjectId;
    const { data } = await customerService.getCustomerCounters(currentUser.Client.id, project, {
      excludeForTotal: excludeForTotalCounters,
    });
    setCounters(data);
  };

  return (
    <MainLayout {...props} current="dashboard">
      <Box>
        <Typography my="20px" variant="h1" color="primary">
          Dashboard
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={7}>
            <CardsWidget counters={counters} projects={projects} onHandleCounter={handleCounter} />
          </Grid>
          <Grid item xs={5}>
            <ApplicantsWidget applicants={applicants} />
          </Grid>
          <Grid item xs={7}>
            <PositionsWidget title="Latest Positions" positions={latestProjects} />
          </Grid>
          <Grid item xs={5}>
            <NCSscoreWidget random={testNcs} />
          </Grid>
        </Grid>
      </Box>
    </MainLayout>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return {
    loginExpire: (user) => {
      dispatch(loginExpire(user));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
