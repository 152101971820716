import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useRef, useState } from 'react';
import { usePrevious } from 'utils/utils';

const InputSelectV2 = ({
  id,
  children,
  label,
  tooltip,
  size,
  opts,
  initialValue,
  renderValue,
  multiple = false,
  error,
  placeholder,
  onChangeInput,
  isDisabled,
  styleLabel,
  sx,
  sxContainer,
  fullWidth,
  defaultOption = false,
  styleError,
  inputProps = {},
}) => {
  const [value, setValue] = useState('');
  const [full, setFull] = useState(null);

  let inputRef = useRef();
  const prevValue = usePrevious(value);

  useEffect(() => {
    if (value !== initialValue) setValue(initialValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue]);

  useEffect(() => {
    if (typeof prevValue !== 'undefined') {
      let obj = { target: { name: id, value: inputRef.current.value } };
      // select simple - prevent re render on capture initial value from request
      if (initialValue === value) return;
      // for multiple select
      if (defaultOption && defaultOption === true && multiple && multiple === true) {
        if (inputRef.current.value.includes('')) obj = { target: { name: id, value: [] } };
      }
      return onChangeInput(obj);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (typeof fullWidth !== 'undefined' && fullWidth === false) {
      setFull(false);
    } else {
      setFull(true);
    }
  }, [fullWidth]);

  let options = [];
  options = opts && opts.length > 0 && [...opts];
  if (opts && defaultOption === true) {
    const noSelectionOption = { _id: '', label: placeholder ?? 'No Selection' };
    options = [noSelectionOption, ...opts];
  }

  const renderPlaceholdValue = useCallback(
    (val) => <Typography variant="placeholder">{placeholder}</Typography>,
    [placeholder]
  );

  return (
    <>
      <FormControl
        fullWidth={full}
        sx={{ textAlign: 'left', justifyContent: 'space-between', ...sxContainer }}
        disabled={isDisabled}
      >
        {label && label !== '' && (
          <FormLabel error={!!error && error !== ''} htmlFor={id} sx={{ ...styleLabel, marginTop: 'auto' }}>
            {label} {tooltip}
          </FormLabel>
        )}
        <Select
          inputRef={inputRef}
          name={id}
          id={id}
          fullWidth={true}
          onChange={(e) => {
            setValue(e.target.value);
          }}
          multiple={multiple}
          displayEmpty
          error={!!error}
          value={value || (multiple ? [] : '')}
          sx={sx}
          inputProps={{ ...inputProps, 'aria-label': 'Without label', size }}
          renderValue={(!value || value?.length === 0) && placeholder ? renderPlaceholdValue : renderValue}
        >
          {children ||
            (options &&
              options.map((option, index) => {
                return (
                  <MenuItem key={index} value={option._id}>
                    {option.label}
                  </MenuItem>
                );
              }))}
        </Select>
        {error && (
          <FormHelperText error id={id} sx={{ styleError }}>
            {error}
          </FormHelperText>
        )}
      </FormControl>
    </>
  );
};

InputSelectV2.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.arrayOf(PropTypes.element),
  label: PropTypes.string,
  tooltip: PropTypes.element,
  size: PropTypes.string,
  sx: PropTypes.object,
  sxContainer: PropTypes.object,
  styleLabel: PropTypes.object,
  inputProps: PropTypes.object,
  isDisabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  opts: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.any.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
  renderValue: PropTypes.func,
  error: PropTypes.any,
  placeholder: PropTypes.string,
  onChangeInput: PropTypes.func,
  styleError: PropTypes.oneOfType([PropTypes.object, PropTypes.any]),
};

export default InputSelectV2;
