export const HOLIDAYS = {
  MX: 'mx',
  US: 'us',
};

export const holidaysOptions = [
  {
    _id: HOLIDAYS.MX,
    label: 'MX',
  },
  {
    _id: HOLIDAYS.US,
    label: 'US',
  },
];
