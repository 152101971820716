import { HOLIDAYS } from 'constants/project';
import Joi from 'joi';
import { setValidationMessages } from '../../utils/validationMessages';

export const schemaManagerName = {
  name: Joi.string()
    .required()
    .messages(setValidationMessages(['string', 'required'])),
};

export const schemaManagerEmail = {
  email: Joi.string()
    .required()
    .email({ minDomainSegments: 2, tlds: { allow: false } })
    .messages(setValidationMessages(['string', 'required'])),
};

export const schemaRepeaterSkill = {
  SkillId: Joi.number()
    .integer()
    .required()
    .messages(setValidationMessages(['number', 'required'])),
};

export const schemaRepeaterSkillYear = {
  yearExperience: Joi.number()
    .integer()
    .required()
    .messages(setValidationMessages(['number', 'required'])),
};

export const schemaRepeaterSkillOptional = {
  SkillId: Joi.number()
    .integer()
    .optional()
    .allow('', ' ')
    .messages(setValidationMessages(['number'])),
};

export const schemaRepeaterSkillYearOptional = {
  yearExperience: Joi.number()
    .integer()
    .optional()
    .allow('')
    .messages(setValidationMessages(['number'])),
};

export const schemaProjectHeader = {
  ClientId: Joi.number()
    .integer()
    .required()
    .messages(setValidationMessages(['number', 'required'])),
  status: Joi.string()
    .required()
    .messages(setValidationMessages(['string', 'required'])),
};

export const schemaProject = {
  description: Joi.string()
    .required()
    .messages(setValidationMessages(['string', 'required'])),
  teamsize: Joi.number()
    .required()
    .messages(setValidationMessages(['number', 'required'])),
  RecruiterId: Joi.number()
    .integer()
    .required()
    .messages(setValidationMessages(['number', 'required'])),
  position: Joi.string()
    .required()
    .messages(setValidationMessages(['string', 'required'])),
  seniority: Joi.string()
    .required()
    .messages(setValidationMessages(['string', 'required'])),
  managers: Joi.array().items(
    Joi.object().keys({
      name: Joi.string()
        .required()
        .messages(setValidationMessages(['string', 'required'])),
      email: Joi.string()
        .required()
        .email({ minDomainSegments: 2, tlds: { allow: false } })
        .messages(setValidationMessages(['string', 'required'])),
    })
  ),
  newSkills: Joi.array().items(
    Joi.object().keys({
      SkillId: Joi.number()
        .integer()
        .required()
        .messages(setValidationMessages(['number', 'required'])),
      yearExperience: Joi.number()
        .integer()
        .required()
        .messages(setValidationMessages(['number', 'required'])),
    })
  ),
  adittionalSkills: Joi.array()
    .items(
      Joi.object().keys({
        SkillId: Joi.number()
          .integer()
          .optional()
          .allow('', ' ')
          .messages(setValidationMessages(['number'])),
        yearExperience: Joi.number()
          .integer()
          .optional()
          .allow('')
          .messages(setValidationMessages(['number'])),
      })
    )
    .min(0),
  timezone: Joi.string()
    .required()
    .messages(setValidationMessages(['string', 'required'])),
  customer_job_link: Joi.string()
    .optional()
    .allow('')
    .messages(setValidationMessages(['string'])),
  customer_job_description_link: Joi.string()
    .uri()
    .optional()
    .allow('')
    .messages(setValidationMessages(['string', 'required', 'uri'])),
  rate_assumption: Joi.string()
    .optional()
    .allow('')
    .messages(setValidationMessages(['string'])),
  codetest_link: Joi.string()
    .optional()
    .allow('')
    .messages(setValidationMessages(['string'])),
  notes: Joi.string()
    .optional()
    .allow('')
    .messages(setValidationMessages(['string'])),
  job_description_link: Joi.string()
    .allow('')
    .messages(setValidationMessages(['string'])),
  maxrate: Joi.number()
    .required()
    .messages(setValidationMessages(['number', 'required'])),
  codetest_id: Joi.string()
    .alphanum()
    .min(3)
    .max(4)
    .allow('')
    .messages(setValidationMessages(['string'])),
  monthly_mxn: Joi.number()
    .required()
    .messages(setValidationMessages(['number', 'required'])),
  monthly_usd: Joi.number()
    .required()
    .messages(setValidationMessages(['number', 'required'])),
  working_hours_start: Joi.string()
    .required()
    .messages(setValidationMessages(['string', 'required'])),
  working_hours_end: Joi.string()
    .required()
    .messages(setValidationMessages(['string', 'required'])),
  hardware_needs: Joi.array()
    .items(Joi.number().integer())
    .min(0)
    .messages(setValidationMessages(['array'])),
  hardware_notes: Joi.string()
    .optional()
    .allow('', ' ')
    .messages(setValidationMessages(['string'])),
  holidays: Joi.string()
    .optional()
    .valid(HOLIDAYS.MX, HOLIDAYS.US, '', ' ')
    .messages(setValidationMessages(['any', 'string'])),
  interview_process: Joi.string()
    .required()
    .messages(setValidationMessages(['string', 'required'])),
};
