import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Button, CircularProgress, FormControl, Grid, Typography, useTheme } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import SvgButton from 'components/Candidates/InterviewCandidateTab/Elements/SvgButton';
import InputFileWithText from 'components/FormElement/InputFileWithText';
import InputSelectV2 from 'components/FormElement/InputSelectV2';
import ModalFeedback from 'components/Generic/Modal/ModalFeedback';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import candidateService from 'services/candidateService';

const MoreUploadsTab = ({ isDisabled }) => {
  const [attachments, setAttachments] = useState([]);
  const [attachment, setAttachment] = useState({});
  const [title, setTitle] = useState('');
  const [files, setFiles] = useState('');
  const [saving, setSaving] = useState(false);
  const [reset, setReset] = useState(false);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const handleFileChange = ({ target }) => {
    setReset(false);
    setFiles(target.files[0]);
  };
  const theme = useTheme();
  let { CandidateId } = useParams();

  const columns = [
    {
      field: 'title',
      headerName: 'TITLE',
      editable: false,
      sortable: false,
      flex: 1,
    },
    {
      field: 'url',
      headerName: 'Actions',
      editable: false,
      sortable: false,
      width: 140,
      renderCell: ({ row }) => {
        return (
          <>
            <SvgButton
              fontSize="28px"
              color="nexxusBlack"
              iconName="pdf-icon"
              label=""
              onClick={() => downloadDataUrl(row.url)}
            />
            <SvgButton
              LinkComponent="a"
              disableRipple
              target="_blank"
              isDisabled={isDisabled}
              href={row.url}
              fontSize="28px"
              iconName="remove-icon"
              label=""
              onClick={() => {
                setAttachment(row);
                setOpenDeleteModal(true);
              }}
            />
          </>
        );
      },
    },
  ];

  const downloadDataUrl = (dataUrl) => {
    const link = document.createElement('a');
    link.target = '_blank';
    link.href = dataUrl;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    (async () => {
      const {
        data: { attachments },
      } = await candidateService.getCandidateAttachments(CandidateId);
      if (attachments) setAttachments(attachments);
    })();
  }, []); // eslint-disable-line

  useEffect(() => {
    (async () => {
      if (saving === true) {
        try {
          const formData = new FormData();
          formData.append('id', 'attachment');
          formData.append('dest', 'candidate-attachments');
          formData.append('title', title);
          formData.append('attachment', files, files.name);
          const { data } = await candidateService.uploadCandidateFile(CandidateId, formData);
          await candidateService.updateCandidateActivity(CandidateId);

          setSaving(false);
          setFiles('');
          setTitle('');
          setReset(true);
          setAttachments(data.meta_uploads.attachments);
        } catch (e) {
          toast.error('Error uploading file');
          setSaving(false);
          setFiles('');
          setTitle('');
          setReset(true);
        }
      }
    })();
  }, [saving]); // eslint-disable-line

  const onCloseModal = (event, reason) => {
    event?.preventDefault();
    setOpenDeleteModal(false);
  };

  const onAcceptDelete = async () => {
    const newAt = [...attachments].filter((at) => at !== attachment);
    Promise.all([
      await candidateService.updateCandidateAttachment(CandidateId, { attachments: newAt }),
      await candidateService.updateCandidateActivity(CandidateId),
      setAttachments(newAt),
    ]);
  };

  return (
    <Grid container spacing={2}>
      <ModalFeedback
        variant="warning"
        headerText="Delete Test Result"
        titleText="Are you sure you want to delete this test result?"
        open={openDeleteModal}
        onCancel={onCloseModal}
        onClose={onCloseModal}
        onAccept={onAcceptDelete}
      />
      <Grid item sm={12} md={6} lg={4}>
        <InputSelectV2
          id="title_upload"
          size={'small'}
          isDisabled={isDisabled}
          opts={[
            { _id: 'economical offer', label: 'Economical Offer' },
            { _id: 'phone screen', label: 'Phone Screen' },
            { _id: 'onboarding format', label: 'Onboarding Format' },
            { _id: 'portfolio', label: 'Portfolio' },
            { _id: 'other', label: 'Other' },
          ]}
          error={''}
          initialValue={title}
          placeholder={'Attachment Title'}
          label="Attachment TITLE"
          onChangeInput={(e) => {
            setReset(false);
            setTitle(e.target.value);
          }}
        ></InputSelectV2>
      </Grid>
      <Grid item sm={12} md={6} lg={4}>
        <InputFileWithText
          accept={[
            'application/pdf',
            'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          ]}
          id={'uploadFile'}
          label={'File (pdf, word)'}
          isDisabled={isDisabled}
          onFileChange={handleFileChange}
          reset={reset}
        ></InputFileWithText>
      </Grid>
      <Grid item md={12} lg={4}>
        <FormControl sx={{ width: '100%', mt: '22px', [theme.breakpoints.down('md')]: { mt: 0 } }}>
          <Button
            variant="contained"
            size="large"
            fullWidth
            type="button"
            startIcon={
              saving ? (
                <CircularProgress style={{ color: 'white', marginRight: 16 }} size="0.8rem" thickness={8} />
              ) : (
                <CheckCircleIcon />
              )
            }
            sx={{ width: '100%' }}
            disabled={title === '' || files === ''}
            onClick={() => (!saving ? setSaving(true) : {})}
          >
            Add New Upload
          </Button>
        </FormControl>
      </Grid>
      <Grid container item xs={12}>
        <Typography variant="subtitle" fontSize={18} align="left" marginBottom={0} sx={{ py: 1 }}>
          CANDIDATE ATTACHMENTS
        </Typography>
      </Grid>
      <Grid container item xs={12}>
        {attachments.length > 0 && (
          <DataGrid
            sx={{ mt: 1 }}
            headerHeight={50}
            rows={attachments || []}
            columns={columns}
            autoHeight
            rowsPerPageOptions={[5, 10, 20, 50, 100]}
            disableSelectionOnClick
            disableColumnMenu
            pageSize={10}
            // onPageSizeChange={(newPage) => setPageSize(newPage)}
            pagination
            getRowId={(row) => row.title + row.url}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default MoreUploadsTab;
