import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import { CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';
import ModalFeedback from 'components/Generic/Modal/ModalFeedback';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const ButtonsBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const ButtonBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    marginBottom: '.5em',
    '& .MuiButton-root': {
      height: 'unset',
      padding: '10px',
    },
  },
}));

const IntakeActionButtons = ({
  projects,
  onHandleProjects,
  onHandleAddProjects,
  openModal,
  callbackSave,
  isEdit,
  setTrySubmit,
  isValidating,
  setValidating,
}) => {
  const { ProjectId } = useParams();
  const [validate, setValidate] = useState(false);
  const [open, setOpen] = useState(false);
  const [openAddProject, setOpenAddProject] = useState(false);
  const [openDeleteProject, setOpenDeleteProject] = useState(false);
  const theme = useTheme();

  const onCloseModal = (event, reason) => {
    event?.preventDefault();
    setOpen(false);
    setOpenAddProject(false);
    setOpenDeleteProject(false);
  };
  const onAccept = (e, reason) => {
    setOpen(false);
    callbackSave();
  };
  const onAcceptAddProject = () => {
    onHandleAddProjects([...projects, false]);
  };
  const onAcceptDeleteProject = () => {
    const projs = [...projects];
    projs.splice(-1);
    onHandleProjects(projs);
  };
  useEffect(() => {
    if (openModal) {
      setOpen(true);
    }
  }, [openModal]);

  useEffect(() => {
    if (validate) {
      setTimeout(() => {
        setTrySubmit(true);
      }, 100);
    }
  }, [validate, setTrySubmit]);

  useEffect(() => {
    if (isValidating !== validate) setValidate(isValidating);
  }, [isValidating, validate]);

  return (
    <>
      <ModalFeedback
        variant="info"
        headerText="Project"
        titleText={isEdit ? 'Do you want to apply the changes on the project?' : 'Do you want to save a new project?'}
        open={open}
        onCancel={onCloseModal}
        onClose={onCloseModal}
        onAccept={onAccept}
      />
      <ModalFeedback
        variant="info"
        headerText="Project"
        titleText="Do you want to add a new project?"
        open={openAddProject}
        onCancel={onCloseModal}
        onClose={onCloseModal}
        onAccept={onAcceptAddProject}
      />
      <ModalFeedback
        variant="warning"
        headerText="Project"
        titleText="Do you want to delete a project?"
        open={openDeleteProject}
        onCancel={onCloseModal}
        onClose={onCloseModal}
        onAccept={onAcceptDeleteProject}
      />
      <ButtonsBox
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: 1,
          width: '100%',
        }}
      >
        <ButtonBox
          sx={{
            [theme.breakpoints.down('md')]: {
              order: '-2',
            },
            flex: 1,
          }}
        >
          {projects.length <= 5 && !ProjectId && (
            <Button
              variant="contained"
              sx={{ height: '30px', borderRadius: 0, paddingX: 1, [theme.breakpoints.down('md')]: { width: '100%' } }}
              color="nexxusGreen"
              type="button"
              startIcon={<AddCircleIcon sx={{ color: 'white', marginLeft: 2, fontSize: 4 }} />}
              disabled={false}
              onClick={(e) => {
                setOpenAddProject(true);
              }}
            >
              <Typography
                sx={{
                  fontSize: '12px',
                  fontWeight: 'bold',
                  paddingRight: 1,
                  color: 'white',
                }}
              >
                Add project
              </Typography>
            </Button>
          )}
        </ButtonBox>
        <ButtonBox
          sx={{
            [theme.breakpoints.down('md')]: {
              flexDirection: 'column-reverse',
              gap: '.4em',
            },
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography
            sx={{
              fontSize: '9px',
              fontWeight: 'bold',
              textAlign: 'left',
              color: 'nexxusGray.main',
              marginRight: 2,
            }}
          >
            DON'T FORGET TO SAVE YOUR CHANGES
          </Typography>
          <Button
            variant="contained"
            sx={{
              minWidth: 150,
              height: '30px',
              borderRadius: 0,
              paddingX: 1,
              [theme.breakpoints.down('md')]: { width: '100%' },
            }}
            color="nexxusBlue"
            type="button"
            startIcon={
              validate ? (
                <CircularProgress style={{ color: 'white', marginLeft: 2 }} size="0.8rem" thickness={8} />
              ) : (
                <SaveIcon sx={{ color: 'white', marginLeft: 2, fontSize: 4 }} />
              )
            }
            disabled={false}
            onClick={async (e) => {
              if (!validate) Promise.all([setValidate(true), setValidating(true)]);
            }}
          >
            <Typography
              sx={{
                fontSize: '12px',
                fontWeight: 'bold',
                paddingRight: 1,
                color: 'white',
                marginTop: '4px',
              }}
            >
              {validate ? 'Validating' : 'save changes '}
            </Typography>
          </Button>
        </ButtonBox>
        <ButtonBox
          sx={{
            [theme.breakpoints.down('md')]: {
              order: '-2',
            },
            flex: 1,
            textAlign: 'right',
          }}
        >
          {projects.length > 1 && (
            <Button
              variant="contained"
              sx={{ height: '30px', borderRadius: 0, paddingX: 1, [theme.breakpoints.down('md')]: { width: '100%' } }}
              color="nexxusRed"
              type="button"
              startIcon={<DeleteIcon sx={{ color: 'white', marginLeft: 2, fontSize: 4 }} />}
              disabled={false}
              onClick={(e) => {
                setOpenDeleteProject(true);
              }}
            >
              <Typography
                sx={{
                  fontSize: '12px',
                  fontWeight: 'bold',
                  paddingRight: 1,
                  color: 'white',
                  marginTop: '4px',
                }}
              >
                Remove project
              </Typography>
            </Button>
          )}
        </ButtonBox>
      </ButtonsBox>
    </>
  );
};

export default IntakeActionButtons;
