// @ts-ignore
import { TabsCandidatesV2 } from '@nexxus/components';
import CandidateHeader from 'pages/customer/candidateOverview/candidateHeader';
import MainLayout from './../../components/MainLayoutRedesign/MainLayout';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import customerService from 'services/customerService';
import { PROJECT_STATUS } from 'utils/staticData';
import { capitalizeString } from 'utils/utils';
import projectService from '../../services/projectService';
import { loginExpire } from '../../store/auth';
// @ts-ignore
import useCustomerProjectCandidates from 'hooks/customer/useCustomerProjectCandidates';
import { CANDIDATEPROJECT_STATUS, GROUP_PROJECT_STATUS_REJECTED } from 'constants/candidate_project';
import { Stack, Typography } from '@mui/material';

const Candidates = (props) => {
  const [searchParams] = useSearchParams();
  // @ts-ignore
  const [projects, setProjects] = useState([]);
  const [candidateSearch, setCandidateSearch] = useState([]);
  const navigate = useNavigate();

  const pjId = searchParams.get('ProjectId');
  // eslint-disable-next-line no-unused-vars
  // @ts-ignore
  const tabSelected = searchParams.get('Tab');
  const { currentUser } = useCurrentUser();
  const {
    // @ts-ignore
    candidates,
    projectId,
    candidatesCountStatus,
    customer,
    // @ts-ignore
    statusSelected,
    candidateSelected,
    // @ts-ignore
    loading,
    setStatusSelected,
    setProjectId,
    setCustomer,
    // @ts-ignore
    setCandidateSelected,
    setLoading,
  } = useCustomerProjectCandidates();
  //init Hook
  useEffect(() => {
    (async () => {
      if (currentUser) {
        const { data } = await projectService.getProjectsByClient(currentUser.Client.id, {
          excludeStatus: [PROJECT_STATUS.ARCHIVED, PROJECT_STATUS.ON_HOLD],
        });
        const projs = data.map((pj) => ({
          ...pj,
          _id: pj.id,
          label: `${capitalizeString(pj.position)} - ${capitalizeString(pj.seniority)}`,
        }));

        if (pjId) if (!projs.find((pj) => pj._id === parseInt(pjId))) return navigate('/404');

        await Promise.all([setCustomer(currentUser.Client), setProjects(projs), setProjectId(pjId ?? '')]);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, navigate, searchParams, pjId]);

  useEffect(() => {
    (async () => {
      if (currentUser && projectId !== '') {
        // populate candidate search
        const { data: allCandidates } = await customerService.getCustomerCandidates(currentUser.Client.id, {
          // @ts-ignore
          ProjectId: projectId !== '' && projectId !== 0 ? projectId : null,
          includes: false,
          status: [
            CANDIDATEPROJECT_STATUS.PANEL_INTERVIEW,
            CANDIDATEPROJECT_STATUS.SKILLS_ASSESSMENT,
            CANDIDATEPROJECT_STATUS.SUBMITTED_CUSTOMER,
            CANDIDATEPROJECT_STATUS.CUSTOMER_INTERVIEW,
            CANDIDATEPROJECT_STATUS.HIRED,
            CANDIDATEPROJECT_STATUS.PANEL_INTERVIEW_REJECTED,
            CANDIDATEPROJECT_STATUS.SKILLS_ASSESSMENT_REJECTED,
            CANDIDATEPROJECT_STATUS.CUSTOMER_INTERVIEW_REJECTED,
            CANDIDATEPROJECT_STATUS.SUBMITTED_CUSTOMER_REJECTED,
            CANDIDATEPROJECT_STATUS.OFFER_REJECTION,
            CANDIDATEPROJECT_STATUS.DROPPED_OUT,
          ],
        });
        setCandidateSearch(allCandidates);
      }
    })();
  }, [currentUser, projectId, setCandidateSearch]);

  const getStatusSelected = useCallback(
    (val) => {
      const selected = Object.keys(candidatesCountStatus)
        .filter((key) => candidatesCountStatus[key]['orderTab'] === val)
        .reduce((cur, key) => {
          return Object.assign(cur, { ...candidatesCountStatus[key] });
        }, {});
      return selected?.key || '';
    },
    [candidatesCountStatus]
  );

  useEffect(() => {
    if (projectId !== '') setStatusSelected(getStatusSelected(1));
  }, [projectId, setStatusSelected, getStatusSelected]);

  useEffect(() => {
    (async () => {
      if (candidateSelected !== '') {
        const candidate = candidateSearch.find((cand) => cand.id === candidateSelected);
        const keyStatus = Object.entries(CANDIDATEPROJECT_STATUS).find(
          ([key, _value]) => CANDIDATEPROJECT_STATUS[key] === candidate.Projects[0].Project_Candidate['status']
        );
        setStatusSelected(GROUP_PROJECT_STATUS_REJECTED.includes(keyStatus[1]) ? 'REJECTED' : keyStatus[0]);
      }
    })();
  }, [candidateSelected, candidateSearch, setStatusSelected]);

  // @ts-ignore
  const handlePosition = (PjId) => {
    Promise.all([setStatusSelected(''), setProjectId(PjId)]);
  };

  return (
    <>
      <MainLayout {...props} subtitle="Candidate" subtitleFocus="Overview" current="projects">
        <Stack spacing={2}>
          <Typography
            color="#0242D1"
            textTransform="capitalize"
            fontSize="24px"
            sx={{ lineHeight: '28px', fontWeight: '500' }}
          >
            Candidate Overview
          </Typography>
          <CandidateHeader
            logoUrl={customer?.logoUrl || ''}
            candidatesNames={candidateSearch.map((cand, index) => ({
              key: cand.id,
              label: `${cand.full_name}`,
            }))}
            positions={projects}
            onHandlePosition={handlePosition}
            lifetimeTotals={candidatesCountStatus}
            handleCandidateSelected={(value) => {
              if (value === '') setCandidateSelected(value);
              else Promise.all([setStatusSelected(''), setCandidateSelected(value)]);
            }}
          />
          <TabsCandidatesV2
            project={projectId}
            countersCandidate={candidatesCountStatus}
            allCandidates={candidates}
            setStatusSelected={setStatusSelected}
            statusSelected={statusSelected}
            loading={loading}
            setLoading={setLoading}
          />
        </Stack>
      </MainLayout>
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return {
    loginExpire: (user) => {
      dispatch(loginExpire(user));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Candidates);
