import http from './httpService';

/**
 * @param {number} ClientId
 */
function getCustomerLatestProjects(ClientId, filters) {
  return http.get(`${process.env.REACT_APP_API_BACKEND}/v1/clients/${ClientId}/projects-latest`, {
    params: {
      filters,
    },
  });
}

/**
 * @param {number} ClientId
 */
function getCustomerLatestCandidates(ClientId, filters) {
  return http.get(`${process.env.REACT_APP_API_BACKEND}/v1/clients/${ClientId}/candidates-latest`, {
    params: {
      filters,
    },
  });
}

/**
 * @param {number} ProjectId
 */
function getCustomerCounters(ClientId, ProjectId, filters) {
  return http.get(`${process.env.REACT_APP_API_BACKEND}/v1/clients/${ClientId}/projects/candidates-counts`, {
    params: {
      ProjectId,
      filters,
    },
  });
}

/**
 * @param {object} params
 * @param {(number|null)} params.ClientId
 */
function getCustomerCandidates(ClientId, params) {
  return http.get(`${process.env.REACT_APP_API_BACKEND}/v1/clients/${ClientId}/candidates`, {
    params: params,
  });
}

/**
 * @param {number} ProjectId
 * @param {number} CandidateId
 */
function getCustomerFeedback(ProjectId, CandidateId) {
  return http.get(process.env.REACT_APP_API_BACKEND + '/v1/clients/candidate_feedback', {
    params: {
      projectId: ProjectId,
      candidateId: CandidateId,
    },
  });
}

/**
 * @param {Object} formData
 * @param {string} formData.statusFeed - status for save feed
 * @param {string} formData.feedback - note from the customer
 * @param {number} formData.candidateId
 * @param {number} formData.projectId
 */
function saveCustomerFeedback(formData) {
  return http.post(process.env.REACT_APP_API_BACKEND + '/v1/clients/candidate_feedback', formData);
}

/**
 * @param {string} companyName
 */
function getCompanyNameValid(companyName) {
  return http.get(process.env.REACT_APP_API_BACKEND + `/v1/clients/valid-companyname/${companyName}`);
}

const customerService = {
  getCustomerFeedback,
  saveCustomerFeedback,
  getCompanyNameValid,
  getCustomerCounters,
  getCustomerCandidates,
  getCustomerLatestCandidates,
  getCustomerLatestProjects,
};

export default customerService;
