const CANDIDATE_STATUS = {
  ARCHIVED: 5,
  ACTIVE: 10,
};

const CANDIDATE_EMPLOYMENT = {
  FTE: 'fte',
  CONT: 'contractor',
  OTHER: 'other',
  VENDOR: 'vender',
};

const CANDIDATE_LOCATION = {
  MEXICO: 'mex',
  BAHAMAS: 'bhs',
  BARBADOS: 'brb',
  BELIZE: 'blz',
  BOLIVIA: 'bol',
  BRAZIL: 'bra',
  COLOMBIA: 'col',
  CUBA: 'cub',
  CHILE: 'chl',
  ECUADOR: 'ecu',
  SALVADOR: 'slv',
  GRENADA: 'grd',
  GUATEMALA: 'gtm',
  GUYANA: 'guy',
  HAITI: 'hti',
  HONDURAS: 'hnd',
  JAMAICA: 'jam',
  NICARAGUA: 'nic',
  PANAMA: 'pan',
  PARAGUAY: 'pry',
  TRINIDAD_TOBAGO: 'tto',
  VENEZUELA: 'ven',
  URUGUAY: 'ury',
  ARGENTINA: 'arg',
  DOMINICAN_REPUBLIC: 'dom',
  COSTA_RICA: 'cri',
  PERU: 'per',
};

const CANDIDATE_TIMEZONES = {
  PST: 'pst',
  MST: 'mst',
  CST: 'cst',
  EST: 'est',
  BRT: 'brt',
  VET: 'vet',
  ART: 'art',
  BOT: 'bot',
  ACT: 'act',
  AMT: 'amt',
  FNT: 'fnt',
  CLT: 'clt',
  EASST: 'easst',
  ECT: 'ect',
  GALT: 'galt',
  GYT: 'gyt',
  PYST: 'pyst',
  PET: 'pet',
  UYT: 'uyt',
  AST: 'ast',
};

module.exports = {
  CANDIDATE_STATUS,
  CANDIDATE_EMPLOYMENT,
  CANDIDATE_LOCATION,
  CANDIDATE_TIMEZONES,
};
