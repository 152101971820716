import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Button, ClickAwayListener, Popover, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/system';
import { useEffect, useState } from 'react';
import { mapRangeValue } from '../../utils/utils';
import ScoreGauge from './ScoreGauge';
import InfoIcon from '@mui/icons-material/Info';

const NCSButton = styled(Button)({
  padding: '2px 8px',
  lineHeight: '1',
  fontSize: '10px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minWidth: 0,
  '& .MuiButton-endIcon': {
    marginRight: 0,
    marginLeft: '4px',
    fontSize: '1.4em',
  },
});

export default function NcsPopover({ candidate, ncs, openModal, setOpenModal }) {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(Boolean);
  const [ncsValue, setNcsValue] = useState(0);

  useEffect(() => {
    setNcsValue(candidate?.candidate_ncs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [candidate]);

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (e) => {
    setOpen(true);
  };

  useEffect(() => {
    setOpen(openModal);
  }, [openModal]);

  const handleCloseModal = () => {
    setOpen(false);
    setOpenModal(false);
  };

  // const open = Boolean(anchorEl );
  const id = open ? 'simple-popover' : undefined;

  const tas_evaluation =
    Math.floor(mapRangeValue(candidate.CandidateGrade?.professionalism || 1, 1, 5, 0, 8)) +
    Math.floor(mapRangeValue(candidate.CandidateGrade?.english_proficiency || 1, 1, 5, 0, 5)) +
    Math.floor(mapRangeValue(candidate.CandidateGrade?.articulation || 1, 1, 5, 0, 5)) +
    Math.floor(mapRangeValue(candidate.CandidateGrade?.cultural_match || 1, 1, 5, 0, 2));

  const desired_seniority = candidate.Projects[0].seniority === candidate.developer_seniority ? 25 : 0;
  const must_have_skills = mapRangeValue(candidate.Projects[0].Project_Candidate?.meta.must_have_skills || 1, 1, 5, 0, 20);
  const nice_to_have_skills = mapRangeValue(
    candidate.Projects[0].Project_Candidate?.meta.nice_to_have_skills || 1,
    1,
    5,
    0,
    2
  );
  const engineering_interview = mapRangeValue(candidate.CandidateGrade?.engineering_interview || 1, 1, 5, 0, 33);

  return (
    <Box sx={{ display: 'inline' }}>
      <NCSButton variant="contained" color="primary" onClick={handleClick} endIcon={<InfoOutlinedIcon />}>
        {(ncs && `NCS`) || `SCORE`}
      </NCSButton>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        PaperProps={{
          elevation: 3,
          sx: {
            borderRadius: 0,
            border: 1,
            borderColor: theme.palette.grey.light,
          },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
      >
        <ClickAwayListener onClickAway={handleCloseModal}>
          <Stack spacing={2} sx={{ width: 620, height: 500, padding: '30px 40px' }}>
            <Box>
              <Stack direction="row" justifyContent="space-between">
                <Box>
                  <Typography variant="h6" sx={{ fontWeight: 900, textTransform: 'uppercase' }}>
                    Nexxus Compatibility Score (NCS)
                  </Typography>
                  <Typography variant="h4" sx={{ fontWeight: 300 }}>
                    How We Calculate
                  </Typography>
                  <Typography variant="body1" sx={{ mt: 2 }}>
                    Maximum Score is 100.
                  </Typography>
                  <Typography variant="body1">Matching Impact &amp; Score = Best Possible Match</Typography>
                </Box>
                {Number(ncsValue) !== Number(-1) ? (
                  <Box>
                    <ScoreGauge value={candidate?.candidate_ncs} size={150} />
                  </Box>
                ) : (
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Stack width={160} direction="column" sx={{ alignItems: 'center' }}>
                      <InfoIcon color="nexxusYellow" sx={{ fontSize: '2.2rem' }} />
                      <Typography textAlign="center" fontSize=".75em" sx={{ color: '#C2901C', paddingTop: '.5em' }}>
                        NCS cannot be displayed due to missing variables required to calculate the score.
                      </Typography>
                    </Stack>
                  </Box>
                )}
              </Stack>

              <table className="table-ncs-breakdown" cellSpacing={0}>
                <thead>
                  <tr>
                    <th>Criteria</th>
                    <th>Impact</th>
                    <th>Candidate Score</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Desired Seniority & Experience</td>
                    <td>25%</td>
                    <td>{desired_seniority}%</td>
                  </tr>
                  <tr>
                    <td>Skills (Must Have)</td>
                    <td>20%</td>
                    <td>{must_have_skills}%</td>
                  </tr>
                  <tr>
                    <td>Skills (Secondary)</td>
                    <td>2%</td>
                    <td>{nice_to_have_skills}%</td>
                  </tr>
                  <tr>
                    <td>Engineering Evaluation</td>
                    <td>33%</td>
                    <td>{engineering_interview}%</td>
                  </tr>
                  <tr>
                    <td>TAS Evaluation</td>
                    <td>20%</td>
                    <td>{tas_evaluation}%</td>
                  </tr>
                </tbody>
              </table>
            </Box>
          </Stack>
        </ClickAwayListener>
      </Popover>
    </Box>
  );
}
