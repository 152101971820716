import { Route } from 'react-router-dom';
import Login from '../pages/auth/Login';
import ResetPassword from '../pages/auth/ResetPassword';
import ForgotPassword from '../pages/auth/ForgotPassword';

const AuthRoutes = [
  <Route key="auth01" path="/login" element={<Login />} />,
  <Route key="auth02" path="/forgot-password" element={<ForgotPassword />} />,
  <Route key="auth03" path="/reset-password" element={<ResetPassword />} />,
];

export default AuthRoutes;
