import { Box, CircularProgress, Stack, Typography, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';

export const useExternalScript = (url) => {
  let [state, setState] = useState(url ? 'loading' : 'idle');

  useEffect(() => {
    if (!url) {
      setState('idle');
      return;
    }
    let script = document.querySelector(`script[src="${url}"]`);

    const handleScript = (e) => {
      setState(e.type === 'load' ? 'ready' : 'error');
    };

    if (!script) {
      script = document.createElement('script');
      // @ts-ignore
      script.type = 'application/javascript';
      // @ts-ignore
      script.src = url;
      // @ts-ignore
      script.async = true;
      document.body.appendChild(script);
      script.addEventListener('load', handleScript);
      script.addEventListener('error', handleScript);
    }

    script.addEventListener('load', handleScript);
    script.addEventListener('error', handleScript);

    return () => {
      script.removeEventListener('load', handleScript);
      script.removeEventListener('error', handleScript);
      script.parentNode.removeChild(script);
    };
  }, [url]);

  return state;
};

const HubspotContactForm = () => {
  const theme = useTheme();
  const state = useExternalScript('https://js.hsforms.net/forms/v2.js');
  const [ready, setReady] = useState(false);
  useEffect(() => {
    if (state === 'ready') {
      // @ts-ignore
      if (window.hbspt) {
        // @ts-ignore
        window.hbspt.forms.create({
          target: '#hubspotForm',
          portalId: '6267217',
          formId: '1d2cc817-f801-4f2b-a6d7-34f0d1e0f9a9',
        });
      }
      window.addEventListener('message', (event) => {
        if (event.origin !== process.env.REACT_APP_URL) return;
        if (event.data.type === 'hsFormCallback' && event.data.eventName === 'onFormReady') {
          setReady(true);
        }
      });
    }
  }, [state]);

  return (
    <div>
      {!ready && (
        <Stack direction="row" justifyContent="center" alignItems="center" spacing={2} sx={{ minHeight: 400 }}>
          <Box sx={{ flex: 1, textAlign: 'right', pr: 2 }}>
            <CircularProgress size={40} thickness={8} sx={{ color: theme.palette.primary.lighter }} />
          </Box>
          <Box sx={{ flex: 1, textAlign: 'right', pr: 8 }}>
            <Typography
              variant="subtitle1"
              sx={{
                flex: 1,
                color: theme.palette.grey.light,
                fontWeight: '400',
                textAlign: 'left',
                overflowWrap: 'break-word',
                fontSize: 40,
              }}
            >
              {'Loading...'}
            </Typography>
          </Box>
        </Stack>
      )}
      {state === 'ready' && (
        <Stack direction="row" justifyContent="center" alignItems="center" id="hubspotForm" sx={{ minHeight: 200 }} />
      )}
    </div>
  );
};

export default HubspotContactForm;
