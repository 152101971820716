import { Button, Chip, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import InputAutocomplete from 'components/FormElement/InputAutoComplete';
import ModalFeedback from 'components/Generic/Modal/ModalFeedback';
import { useEffect, useState } from 'react';
import { capitalizeString } from 'utils/utils';

const ModalTags = (props) => {
  const theme = useTheme();
  const [tagModalOpen, setTagModalOpen] = useState(false);
  const [value, setValue] = useState({ static: [], state: [] });

  useEffect(() => {
    if (props.initialValue && props.initialValue.length > 0) {
      const arrIdTags = [];
      props.initialValue.forEach((tag) => arrIdTags.push({ id: tag.id, title: tag.name }));
      setValue((prev) => ({ ...prev, static: arrIdTags, state: arrIdTags }));
    }
  }, [props.initialValue]);

  useEffect(() => {
    if (props.reset === true) setValue((prev) => ({ ...prev, state: prev.static }));
  }, [props.reset]);

  return (
    <Stack alignItems="flex-end">
      <Stack direction="row" spacing={2}>
        {value.state &&
          value.state.map((tag, index) => (
            <Chip key={index} label={capitalizeString(tag.title)} variant="tag" color="success" />
          ))}
      </Stack>
      {!props.editing && (
        <Button variant="text" onClick={() => setTagModalOpen(true)}>
          Manage Tags
        </Button>
      )}
      <Typography variant="caption" color="error">
        {props?.error && props.error.txt}
      </Typography>
      <ModalFeedback
        variant="general"
        headerText="Manage Candidate Tags"
        headerBarColor={theme.palette.primary.main}
        open={tagModalOpen}
        btnCancelText="Reset"
        btnConfirmText="Done"
        modalWidth={640}
        onAccept={() => {
          setTagModalOpen(false);
        }}
        onClose={() => setTagModalOpen(false)}
        onCancel={() => {
          setTagModalOpen(false);
          setValue((prev) => ({ ...prev, state: prev.static }));
        }}
      >
        <InputAutocomplete
          id="tags"
          labelField="title"
          onHandleChange={(items) => {
            props.setResetTags(false);
            setValue((prev) => ({ ...prev, state: items }));
            props.onHandleTags(items);
          }}
          isMultiple={true}
          isCreatable={false}
          handleFreeSolo={false}
          label="TAGS"
          defaultOpts={props.tags}
          reset={false}
          value={value.state.map((a) => a.id)}
          disabled={props.editing}
          minReqOpts={1}
          getOptionDisabled={(option) => (value.state.length >= 5 || value.state.includes(option) ? true : false)}
        />
      </ModalFeedback>
    </Stack>
  );
};

export default ModalTags;
