import { ROLE_CUSTOMER, ROLE_SUPERCUSTOMER } from 'constants/users';
import CandidateOverview from 'pages/customer/CandidateOverview';
import ProjectOverview from 'pages/customer/ProjectOverview';
import Reports from 'pages/customer/Reports';
import WrapperCustomer from 'pages/customer/WrapperCustomer';
import { Navigate, Route } from 'react-router-dom';
import Dashboard from '../pages/customer/Dashboard';
import PrivateRoute from './privateRouter';

const customerRoutes = [
  {
    name: 'Dashboard Customer',
    path: '/customer/dashboard',
    element: <Dashboard />,
    authorization: [ROLE_CUSTOMER, ROLE_SUPERCUSTOMER],
  },
  {
    name: 'Customer Overview',
    path: '/customer/projects/overview',
    element: <CandidateOverview />,
    authorization: [ROLE_CUSTOMER, ROLE_SUPERCUSTOMER],
  },
  {
    name: 'Project Overview',
    path: '/customer/projects/intake',
    element: <ProjectOverview key="view:createRole" />,
    authorization: [ROLE_SUPERCUSTOMER],
  },
  {
    name: 'Project Overview Edit',
    path: '/customer/projects/intake/:ProjectId/edit',
    element: <ProjectOverview key="view:editRole" />,
    authorization: [ROLE_SUPERCUSTOMER],
  },
  {
    name: 'Edit Project View',
    path: '/customer/projects/:ProjectId/view',
    element: <CandidateOverview />,
    authorization: [ROLE_SUPERCUSTOMER],
  },
  {
    name: 'Reports',
    path: '/customer/reports',
    element: <Reports />,
    authorization: [ROLE_SUPERCUSTOMER],
  },
];

export const CustomerRoutes = ({ user }) => {
  return customerRoutes.map((route, index) => {
    return (
      <Route
        key={`customer-${index}`}
        path={route.path}
        element={
          <PrivateRoute>
            {route.authorization.includes(user?.role) ? (
              <WrapperCustomer>{route.element}</WrapperCustomer>
            ) : (
              <Navigate to="/403" replace />
            )}
          </PrivateRoute>
        }
      />
    );
  });
};
