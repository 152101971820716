import { ROLE_ACCOUNT_MANAGER, ROLE_CANDIDATE, ROLE_SUPERTAS, ROLE_TAS } from 'constants/users';
import { connect } from 'react-redux';
import { BrowserRouter, Navigate, Route, Routes, useLocation } from 'react-router-dom';
import Layout from '../components/Layout';
import Page403 from '../pages/403';
import Page404 from '../pages/404';
import Sandbox from '../sandbox/Sandbox';
import { loginExpire, logout } from '../store/auth';
import { AccountManagagerRoutes } from './accountManager';
import AuthRoutes from './authRouter';
import { CandidateRoutes } from './candidateRouter';
import { CustomerRoutes } from './customerRouter';
import { TasRoutes } from './tasRouter';

import { useCallback, useEffect } from 'react';
import { AdminRoutes } from 'routers/adminRouter';
import { ImportRoutes } from 'routers/importRouter';
import { MeRoutes } from 'routers/meRouter';
import PrivateRoute from 'routers/privateRouter';
import ScrollToTop from 'utils/ScrollToTop';

let showMessage;

const useBeforeUnloadComponent = ({ auth }) => {
  const location = useLocation();

  const addBeforeUnloadEvent = useCallback((event) => {
    if (showMessage) {
      event.returnValue = 'Are you sure you want to leave from Nexxus App?';
      return 'Are you sure you want to leave from Nexxus App?';
    }
  }, []);

  useEffect(() => {
    window.addEventListener('beforeunload', addBeforeUnloadEvent);
    //window.addEventListener('click', hideUnloadMessageOnAnchorClick);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    showMessage = true;
  }, [location]);
};

const AppRouter = (props) => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <RouterApp auth={props.auth} />
    </BrowserRouter>
  );
};

const RouterApp = (props) => {
  useBeforeUnloadComponent(props);
  return (
    <Routes>
      {AuthRoutes}
      {AdminRoutes(props.auth)}
      {ImportRoutes(props.auth)}
      {typeof props.auth.user !== 'undefined' &&
        [ROLE_TAS, ROLE_SUPERTAS].includes(props.auth.user.role) &&
        TasRoutes(props.auth)}
      {CustomerRoutes(props.auth)}
      {typeof props.auth.user !== 'undefined' &&
        props.auth.user.role === ROLE_ACCOUNT_MANAGER &&
        AccountManagagerRoutes(props.auth)}
      {typeof props.auth.user !== 'undefined' && props.auth.user.role === ROLE_CANDIDATE && CandidateRoutes(props.auth)}
      {props.auth.user && MeRoutes(props.auth)}
      <Route path="/403" element={<Page403 />} />
      <Route path="/sandbox" element={<Sandbox />} />
      <Route path="/main" element={<Layout />} />
      <Route
        path="/404"
        element={
          <PrivateRoute>
            <Page404 />
          </PrivateRoute>
        }
      />
      <Route path="*" element={<Navigate replace to="/404" />} />
    </Routes>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return {
    loginExpire: (user) => {
      dispatch(loginExpire(user));
    },
    logout: () => {
      dispatch(logout());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppRouter);
