import FieldSkills from 'pages/common/customerIntakeForm/intake/fieldSkills';
import { SKILL_TYPE } from 'utils/staticData';
import {
  schemaRepeaterSkill,
  schemaRepeaterSkillOptional,
  schemaRepeaterSkillYear,
  schemaRepeaterSkillYearOptional,
} from 'validations/projects/projectRegister';

const FieldsNewAdittionalSkills = ({ keyField, fields, errors, skillsSearch, reset, setValues, handleSaveRepeaterProp }) => {
  return (
    <FieldSkills
      skills={fields}
      errors={errors}
      skillsSearch={skillsSearch}
      reset={reset}
      keyField={keyField}
      addMoreSkill={() => {
        const newFields = [...fields];
        newFields.push({ SkillId: '', skill_type: SKILL_TYPE.MUST_HAVE, yearExperience: '' });
        setValues((prev) => ({ ...prev, [keyField]: newFields }));
      }}
      removeMoreSkill={() => {
        const newFields = [...fields];
        setValues((prev) => ({ ...prev, [keyField]: newFields.slice(0, -1) }));
      }}
      onUpdateSkill={(e, schema) => {
        handleSaveRepeaterProp(e, schema);
      }}
      schemas={{
        newSkill: { skill: schemaRepeaterSkill, year: schemaRepeaterSkillYear },
        adittionalSkill: { skill: schemaRepeaterSkillOptional, year: schemaRepeaterSkillYearOptional },
      }}
    />
  );
};

export default FieldsNewAdittionalSkills;
