import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import NoteAltOutlinedIcon from '@mui/icons-material/NoteAltOutlined';
import { Box, Button, Paper, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/system';
import InputSelectV2 from 'components/FormElement/InputSelectV2';
import ModalFeedback from 'components/Generic/Modal/ModalFeedback';
import TabsCandidateOverview from 'components/TabsCandidateOverview/TabsCandidateOverview';
import useCandidateProjectStatus from 'hooks/useCandidateProjectStatus';
import parse from 'html-react-parser';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import authService from 'services/authService';
import { capitalizeString } from 'utils/utils';
import MainLayout from '../../components/MainLayout/MainLayout';
import projectService from '../../services/projectService';
import { loginExpire } from '../../store/auth';

const Reports = (props) => {
  const [searchParams] = useSearchParams();
  const [projects, setProjects] = useState([]);
  const theme = useTheme();
  const [client, setClient] = useState('');
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const {
    initialCounters,
    candidates,
    projectId,
    setStatusSelected,
    setProjectId,
    statusSelected,
    totalCandidates,
    candidatesCountStatus,
    dataSource,
  } = useCandidateProjectStatus('report');
  const pjId = searchParams.get('ProjectId');
  const tabSelected = searchParams.get('Tab');

  //init Hook
  useEffect(() => {
    (async () => {
      const { data: user } = await authService.getCurrentUser();
      const { data } = await projectService.getProjectsByClient(user.Client.id, {
        hasReports: true,
      });
      let projs = [];
      data.forEach((pj) => {
        if (parseInt(pj.CounterReports) > 0)
          projs.push({
            ...pj,
            _id: pj.id,
            label: `${pj.Client.companyName} - ${capitalizeString(pj.position)} - ${capitalizeString(pj.seniority)}`,
          });
      });

      if (pjId) if (!projs.find((pj) => pj._id === parseInt(pjId))) return navigate('/404');
      if (projs.length === 0) projs.push({ _id: '', label: '0 Reports Found' });
      await Promise.all([setProjects(projs), setClient(projs[0]?.Client?.companyName), setProjectId(pjId ?? '')]);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, searchParams, pjId]);

  useEffect(() => {
    (async () => {
      if (projectId !== '') {
        let status = 'PANEL_INTERVIEW';
        if (tabSelected) status = pjId === projectId ? tabSelected : 'PANEL_INTERVIEW';
        setStatusSelected(status);
      }
    })();
  }, [projectId, pjId, tabSelected, setStatusSelected]);

  const ExclamationIcon = styled(ErrorOutlineIcon)(({ theme }) => ({
    transform: 'rotate(180deg)',
    mt: 0.5,
  }));

  const StyledNotesBox = styled(Box)(({ theme }) => ({
    fontSize: 14,
    textAlign: 'left',
    '& > ol': { marginLeft: '35px' },
  }));

  const onCloseModal = (event) => {
    event?.preventDefault();
    setOpen(false);
  };

  const onAccept = (event) => {
    event?.preventDefault();
    setOpen(false);
  };

  return (
    <>
      <MainLayout {...props} subtitle="Candidate" subtitleFocus="Reports" current="reports">
        {dataSource.notes && (
          <ModalFeedback
            variant="general"
            headerText="Talent Acquisition Notes"
            headerBarColor={theme.palette.primary.main}
            open={open}
            onClose={onCloseModal}
            onAccept={onAccept}
            disableBtnCancel={true}
            btnConfirmText={'Close'}
          >
            <StyledNotesBox>{parse(_.unescape(_.unescape(dataSource.notes)))}</StyledNotesBox>
          </ModalFeedback>
        )}

        <Box sx={{ paddingX: 0 }}>
          <Box
            sx={{
              height: '180px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              background: theme.palette.gradients.dark.lr,
              color: 'white',
            }}
          >
            <div style={{}}>
              <Typography variant="h5" align="center" pt={1} pb={1} sx={{ textTransform: 'uppercase' }}>
                <strong>{client}</strong>
              </Typography>

              <Typography variant="h4" align="center" pt={0} pb={1} sx={{ textTransform: 'uppercase' }}>
                <strong style={{ letterSpacing: '.2em' }}>Weekly</strong> <span style={{ fontWeight: '300' }}>Reports</span>
              </Typography>
            </div>
          </Box>
          <Stack
            direction="row"
            spacing={1}
            sx={{
              mt: 4,
              mx: 2,
              [theme.breakpoints.down('md')]: {
                flexWrap: 'wrap',
              },
            }}
          >
            <Box
              sx={(theme) => ({
                width: '100%',
              })}
            >
              <Box
                sx={{
                  textAlign: 'left',
                  marginTop: 1,
                  marginRight: 2,
                  marginBottom: 2,
                }}
              >
                <Typography
                  sx={{
                    color: 'nexxusGrayMid.main',
                    fontSize: 12,
                    textAlign: 'left',
                    marginTop: 3,
                    fontWeight: 'bold',
                  }}
                >
                  SELECT A REPORT TO VIEW THE STATUS OF ALL ASSIGNED CANDIDATES
                </Typography>
                <InputSelectV2
                  id={'projectId'}
                  size={'normal'}
                  opts={projects}
                  initialValue={projectId}
                  onChangeInput={(e) => {
                    setProjectId(e.target.value);
                  }}
                />
              </Box>
            </Box>
            <Box
              sx={{
                flexShrink: 1,
                width: '57%',
                [theme.breakpoints.down('md')]: {
                  width: '100%',
                },
              }}
            >
              <Stack direction="row" spacing={1} sx={{ mt: 4 }}>
                <Box>
                  <ExclamationIcon />
                </Box>
                <Box>
                  <Typography variant="h6" sx={{ fontWeight: 900, fontSize: 14 }}>
                    WHAT'S NCS ?
                  </Typography>
                  <Typography variant="body1" sx={{ mt: 0, fontSize: 10 }}>
                    The Nexxus Compatibility Score (NCS) formula is based off of data collected during our interview and code
                    testing process. This evaluation combined with the project specifics calculates the NCS score out of 100.
                  </Typography>
                </Box>
              </Stack>
            </Box>
          </Stack>
          <Paper elevation={0}></Paper>
          <Typography variant="h5" sx={{ fontWeight: 700, color: 'grey.main', textAlign: 'center', my: 2 }}>
            TOTAL CANDIDATES: {totalCandidates}
          </Typography>
          <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2} sx={{ mt: 3 }}>
            {dataSource.notes && (
              <Button
                variant="contained"
                type="button"
                startIcon={<NoteAltOutlinedIcon />}
                disabled={false}
                onClick={() => setOpen(true)}
                size="small"
                sx={{
                  fontSize: 12,
                  [theme.breakpoints.down('md')]: {
                    width: '100%',
                  },
                }}
              >
                View Notes
              </Button>
            )}
          </Stack>
          <TabsCandidateOverview
            initialCounters={initialCounters}
            candidatesCountStatus={candidatesCountStatus}
            candidates={candidates}
            projectId={projectId}
            setStatusSelected={setStatusSelected}
            statusSelected={statusSelected}
            isFeedbackActive={true}
            selectedProject={projectId}
            type={'report'}
          />
        </Box>
      </MainLayout>
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return {
    loginExpire: (user) => {
      dispatch(loginExpire(user));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Reports);
