import { FormHook } from '../../../../hooks/form/Form';
import { CLIENTSTATUS } from '../../../../utils/staticData';
import { schemaClient as schemaCustomer } from '../../../../validations/clients/clientRegister';

export default function CustomerFields() {
  const fields = {
    companyName: '',
    websiteUrl: '',
    logoUrl: '',
    timezone: '',
    status: CLIENTSTATUS.ACTIVE.toString(),
    notes: '',
    managers: [{ stakeholder: '', email: '' }],
  };

  return FormHook(fields, schemaCustomer);
}
