import { Edit as EditIcon, Visibility as VisibilityIcon } from '@mui/icons-material';
import { Grid, IconButton, Pagination, Stack, CircularProgress, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import { DataGrid } from '@mui/x-data-grid';
import { PdfIcon } from 'assets/icons';
import NoDataOverlay from 'components/NoDataOverlay';
import { publish, subscribe, unsubscribe } from 'events.js';
import useCandidateFilter from 'hooks/useCandidateFilter';
import { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import CandidateCard from 'components/Candidates/CardidateCard/CandidateCard';

const CandidateResultTable = ({ auth, only_blacklisted, only_staff }) => {
  const theme = useTheme();
  const [pageSize, setPageSize] = useState(12);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState(true);

  const { handleFilterCandidates, handleFilterPagination, featuredCandidates, candidates, candidatesIsLoading } =
    useCandidateFilter(only_blacklisted, only_staff);

  useEffect(() => {
    subscribe('CandidateSearchFilterUpdate', (event) => handleFilterCandidates(event?.detail));

    return () => {
      unsubscribe('CandidateSearchFilterUpdate');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    publish('CandidateSearchFeaturedCandidates', featuredCandidates);
  }, [featuredCandidates]);

  useEffect(() => {
    handleFilterPagination({
      page,
      limit: pageSize,
      orderBy: ['updatedAt'],
      order: order ? 'DESC' : 'ASC',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, pageSize, order]);

  const pageCount = Math.ceil(parseInt(candidates[0]?.total_count || 0) / pageSize);

  const columns = [
    {
      field: 'full_name',
      headerName: 'Name',
      editable: false,
      flex: 3,
      sortable: false,
    },
    {
      field: 'developer_title',
      headerName: 'Position',
      editable: false,
      flex: 2,
      sortable: false,
    },
    /*{
      field: 'developer_seniority',
      headerName: 'Seniority',
      editable: false,
      flex: 1,
      sortable: false,
    },*/
    {
      field: 'candidateScore',
      headerName: 'Score',
      editable: false,
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      sortable: false,
      valueGetter: ({ row }) => Math.round(row.candidateScore),
    },
    /*{
      field: 'CandidateSkills',
      headerName: 'Skills',
      headerAlign: 'center',
      align: 'center',
      editable: false,
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        return <CandidatePopover label="VIEW ALL" candidate={params.row} />;
      },
    },
    {
      field: 'location',
      headerName: 'Location',
      editable: false,
      flex: 1,
      sortable: false,
    },
    {
      field: 'timezone',
      headerName: 'Time Zone',
      headerAlign: 'center',
      align: 'center',
      editable: false,
      flex: 1,
      sortable: false,
    },*/
    {
      field: 'createdAt',
      headerName: 'Created',
      headerAlign: 'center',
      align: 'center',
      editable: false,
      flex: 1,
      valueGetter: ({ row }) => moment(row.createdAt).format('MM/DD/YYYY'),
    },
    {
      field: 'updatedAt',
      headerName: 'Last Activity',
      headerAlign: 'center',
      align: 'center',
      editable: false,
      flex: 1,
      valueGetter: ({ row }) => moment(row.updatedAt).format('MM/DD/YYYY'),
    },
    {
      field: 'edit',
      headerName: 'Actions',
      headerAlign: 'center',
      align: 'center',
      editable: false,
      sortable: false,
      width: 140,

      renderCell: (params) => {
        return (
          <Stack direction="row" spacing={1}>
            <IconButton component={Link} to={`#`}>
              <PdfIcon color={'nexxusBlack'} />
            </IconButton>
            {auth.user.role === 'superTas' ? (
              <>
                <IconButton component={Link} to={`/tas/candidates/${params.row.id}/edit`}>
                  <EditIcon color={'nexxusBlack'} />
                </IconButton>
                <IconButton component={Link} to={`/tas/candidates/${params.row.id}`}>
                  <VisibilityIcon color={'nexxusBlack'} />
                </IconButton>
              </>
            ) : (
              <IconButton component={Link} to={`/tas/candidates/${params.row.id}`}>
                <VisibilityIcon color={'nexxusBlack'} />
              </IconButton>
            )}
          </Stack>
        );
      },
    },
  ];

  const handleSortModelChange = useCallback((sortModel) => {
    setOrder((prev) => !prev);
  }, []);

  return (
    <>
      {!only_staff && (
        <DataGrid
          sx={{ mt: 4 }}
          headerHeight={50}
          autoHeight
          paginationMode="server"
          rowCount={parseInt(candidates[0]?.total_count) || 0}
          rows={candidates || []}
          columns={columns}
          rowsPerPageOptions={[5, 12, 20, 50, 100]}
          disableSelectionOnClick
          disableColumnMenu
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => {
            if (newPageSize !== pageSize) {
              setPageSize(newPageSize);
              setPage(0);
            }
          }}
          page={page}
          pagination
          loading={candidatesIsLoading}
          components={{
            ...theme.components.MuiDataGrid.defaultProps.components,
            ...{
              NoRowsOverlay: () => <NoDataOverlay title="No Data" />,
              NoResultsOverlay: () => <NoDataOverlay title="No Data" />,
            },
          }}
          onPageChange={(newPage) => {
            if (newPage !== page) setPage(newPage);
          }}
          sortingMode="server"
          onSortModelChange={handleSortModelChange}
        />
      )}

      {only_staff && (
        <>
          {candidatesIsLoading && (
            <Stack mt={6} direction="row" justifyContent="center">
              <CircularProgress />
            </Stack>
          )}
          {!candidatesIsLoading && (
            <Typography variant="subtitle" mt={4} mb={6} textAlign="center">
              Matching Results {candidates.length === 0 ? '0' : `(${candidates[0]?.total_count})`}
            </Typography>
          )}
          <Grid container spacing={2} rowSpacing={6}>
            {candidates.map((candidate) => (
              <Grid item xs={12} smd={6} lg3={4} xl2={3} key={candidate.id}>
                <CandidateCard candidate={candidate} />
              </Grid>
            ))}
          </Grid>
          <Stack mt={6} direction="row" justifyContent="center">
            {!candidatesIsLoading && (
              <Pagination
                count={pageCount}
                page={page + 1}
                onChange={(event, newPage) => {
                  setPage(newPage - 1);
                }}
              />
            )}
          </Stack>
        </>
      )}
    </>
  );
};

export default CandidateResultTable;
