import { FormHook } from 'hooks/form/Form';
import PropTypes from 'prop-types';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { schemaCandidateGrade } from 'validations/candidateGrade';

//-----------------------------------------------------------------------------

const TabInterviewContext = createContext({
  currentCandidateId: '',
  setCurrentCandidateId: (candidate) => {},
  currentIsDisabled: false,
  setCurrentIsDisabled: (disabled) => {},
  globalDataUpdateDone: false,
  setGlobalDataUpdateDone: (value) => {},
  projectDataUpdateDone: false,
  setProjectDataUpdateDone: (value) => {},
  gradeOptions: [],
  percentValues: [],
  fields: {
    professionalism: '',
    english_proficiency: '',
    articulation: '',
    experience: '',
    cultural_match: '',
    engineering_interview: '',
  },
  setValues: (value) => {},
  handleSave: (event) => {},
  errors: {},
  handleSubmit: (e, schemaCandidateGrade) => {},
  validSubmit: false,
  updateDone: false,
});

//-----------------------------------------------------------------------------

const TabInterviewProvider = ({ children }) => {
  const [currentCandidateId, setCurrentCandidateId] = useState('');
  const [currentIsDisabled, setCurrentIsDisabled] = useState(false);
  const [globalDataUpdateDone, setGlobalDataUpdateDone] = useState(false);
  const [projectDataUpdateDone, setProjectDataUpdateDone] = useState(false);
  const [updateDone, setUpdateDone] = useState(false);
  const scoreLenght = 100;
  const getStatusValues = (scoreLenght) => {
    return Array.from({ length: scoreLenght }, (_, i) => ({
      _id: i + 1,
      label: `${i + 1}%`,
    }));
  };
  const percentValues = useMemo(() => getStatusValues(scoreLenght), [scoreLenght]);
  const gradeOptions = [
    { _id: 1, label: '1 - Bad' },
    { _id: 2, label: '2' },
    { _id: 3, label: '3 - Good' },
    { _id: 4, label: '4' },
    { _id: 5, label: '5 - Excellent' },
  ];
  const { fields, setValues, handleSave, errors, handleSubmit, validSubmit } = FormHook(
    {
      professionalism: '',
      english_proficiency: '',
      articulation: '',
      experience: '',
      cultural_match: '',
      engineering_interview: '',
    },
    schemaCandidateGrade
  );

  useEffect(() => {
    (() => {
      if (globalDataUpdateDone && projectDataUpdateDone) {
        setGlobalDataUpdateDone(false);
        setProjectDataUpdateDone(false);
        setUpdateDone(true);
      } else {
        setUpdateDone(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalDataUpdateDone, projectDataUpdateDone]);

  return (
    <TabInterviewContext.Provider
      value={{
        currentCandidateId,
        setCurrentCandidateId,
        currentIsDisabled,
        setCurrentIsDisabled,
        globalDataUpdateDone,
        setGlobalDataUpdateDone,
        projectDataUpdateDone,
        setProjectDataUpdateDone,
        gradeOptions,
        percentValues,
        fields,
        setValues,
        handleSave,
        errors,
        handleSubmit,
        validSubmit,
        updateDone,
      }}
    >
      {children}
    </TabInterviewContext.Provider>
  );
};

TabInterviewProvider.propTypes = {
  children: PropTypes.node,
};

const useTabInterviewState = () => useContext(TabInterviewContext);

export { useTabInterviewState, TabInterviewProvider };
