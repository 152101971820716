import {
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  Stack,
  styled,
  Typography,
  useTheme,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import InputSelectV2 from 'components/FormElement/InputSelectV2';
import InputTextOutlinedV2 from 'components/FormElement/InputTextOutlinedV2';
import MainLayout from 'components/MainLayout/MainLayout';
import { ROLE_ADMIN, ROLE_CANDIDATE, ROLE_LABELS, ROLE_SUPERTAS } from 'constants/users';
import useBreadcrumbs from 'hooks/useBreadcrumbs';
import { useCurrentUser } from 'hooks/useCurrentUser';
import useUsersFilter from 'hooks/useUsersFilter';
import AddUsersModal from 'pages/userAccessControl/AddUsersModal';
import RenderActions from 'pages/userAccessControl/RenderActions';
import { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import ClientService from 'services/clientService';
import UsersService from 'services/UsersService';
import { loginExpire } from 'store/auth';

const FilterButton = styled(Button)({
  fontSize: 18,
  fontWeight: 'bold',
});

const getClients = () => {
  return [
    { _id: 1, label: 'client 1' },
    { _id: 2, label: 'client 2' },
  ];
};

const NoDataOverlay = () => {
  return (
    <Stack height="100%" alignItems="center" justifyContent="center">
      <Typography variant="h3" color="nexxusGrayLight.main" sx={{ fontWeight: 'bold' }}>
        NO DATA
      </Typography>
      <Typography variant="h6" color="nexxusGrayLight.main">
        Please Select Candidate Fields
      </Typography>
    </Stack>
  );
};

const UserAccessControl = (props) => {
  const [pageSize, setPageSize] = useState(20);
  const [reportsLoading] = useState(false);
  const theme = useTheme();
  const maxWidth = props.maxWidth || 'xl';
  const { setBreadcrumbs } = useBreadcrumbs();
  const { currentUser } = useCurrentUser();
  const initialFilters = {
    first_name: '',
    last_name: '',
    email: '',
    role: '',
  };
  const [filters, setFilters] = useState(initialFilters);
  const [openModal, setOpenModal] = useState(false);
  const [clients, setClients] = useState([]);
  const [userSelected, setUserSelected] = useState({});
  const [showingResults, setShowingResults] = useState(0);
  const [showFilters, setShowFilters] = useState(false);

  const { handleFilterUsers, users, refetch } = useUsersFilter();

  useEffect(() => {
    (async () => {
      const { data } = await ClientService.getAllClients();
      setClients(data);
    })();
  }, []); // eslint-disable-line

  useMemo(() => {
    if (users.length <= 0) return;
    setShowingResults(users.length);
  }, [users]);

  useEffect(() => {
    if (filters.email === '' && filters.first_name === '' && filters.last_name === '' && filters.role === '') {
      setShowFilters(false);
      return;
    }
    setShowFilters(true);
  }, [filters.email, filters.first_name, filters.last_name, filters.role]);

  useEffect(() => {
    setBreadcrumbs([
      {
        name: 'Dashboard',
        url: '/tas/dashboard',
      },
      {
        name: 'Users',
      },
    ]);
  }, [setBreadcrumbs]);

  const handleDeleteUser = async (user) => {
    await UsersService.softDeleteUser(user.id);
    toast.success('User deleted from Nexxus');
    refetch();
  };

  const getRoles = () => {
    const except = [ROLE_CANDIDATE];
    if (props.auth.user.role === ROLE_SUPERTAS) except.push(ROLE_ADMIN);
    const roles = Object.keys(ROLE_LABELS)
      .filter((role) => !except.includes(role))
      .map((role, index) => ({ _id: role, label: ROLE_LABELS[role] }));
    return roles;
  };

  const columns = [
    {
      field: 'first_name',
      headerName: 'First Name',
      editable: false,
      flex: 1,
    },
    {
      field: 'last_name',
      headerName: 'Last Name',
      editable: false,
      flex: 1,
    },
    {
      field: 'email',
      headerName: 'Email',
      editable: false,
      flex: 2,
    },
    {
      field: 'role',
      headerName: 'Role',
      editable: false,
      flex: 2,
    },

    {
      field: 'actions',
      headerName: 'Actions',
      headerAlign: 'center',
      align: 'center',
      editable: false,
      sortable: false,
      width: 140,

      renderCell: ({ row }) => {
        return (
          <RenderActions
            onHandleDeleteUser={() => handleDeleteUser(row)}
            roles={getRoles()}
            clients={getClients()}
            setOpenModalUser={() => setOpenModal(true)}
            row={row}
            users={users}
            setUserSelected={setUserSelected}
          />
        );
      },
    },
  ];

  const handleClearFilter = () => {
    setFilters(initialFilters);
    handleFilterUsers(initialFilters);
  };

  const handleSaved = () => {
    setOpenModal(false);
    refetch();
  };

  const handleOpenNew = () => {
    Promise.all([setUserSelected({}), setOpenModal(true)]);
  };

  return (
    <>
      <AddUsersModal
        roles={getRoles()}
        clients={clients}
        openModal={openModal}
        setOpenModal={setOpenModal}
        onHandleSaved={handleSaved}
        userSelected={userSelected}
        setUserSelected={setUserSelected}
      />

      <MainLayout {...props} current="users">
        <Box>
          <Container maxWidth={maxWidth}>
            <Grid container>
              <Grid item xs={6} lg={6}>
                <Typography variant="h4" align="left" pt={1} pb={1} sx={{ textTransform: 'uppercase' }}>
                  Welcome <strong>{currentUser?.first_name}!</strong>
                </Typography>
              </Grid>
              <Grid item xs={12} lg={'auto'} sx={{ marginLeft: 'auto' }}>
                <FormControl sx={{ width: '100%' }}>
                  <FilterButton variant="contained" color="info" size="large" onClick={handleOpenNew}>
                    + Add User
                  </FilterButton>
                </FormControl>
              </Grid>
            </Grid>

            <Divider sx={{ mt: 4, mb: 4, borderBottomWidth: 2 }} />

            <Grid container columns={20} spacing={3} mb={3}>
              <Grid item xs={20} lg={4}>
                <InputTextOutlinedV2
                  id="first_name"
                  label="First Name"
                  placeholder="Filter by first name"
                  onChangeInput={(e) => setFilters((prev) => ({ ...prev, first_name: e.target.value }))}
                  initialValue={filters.first_name}
                />
              </Grid>
              <Grid item xs={20} lg={4}>
                <InputTextOutlinedV2
                  id="last_name"
                  label="Last Name"
                  placeholder="Filter by last name"
                  onChangeInput={(e) => setFilters((prev) => ({ ...prev, last_name: e.target.value }))}
                  initialValue={filters.last_name}
                />
              </Grid>
              <Grid item xs={20} lg={4}>
                <InputTextOutlinedV2
                  id="email"
                  label="Email"
                  placeholder="Filter by email"
                  onChangeInput={(e) => setFilters((prev) => ({ ...prev, email: e.target.value }))}
                  initialValue={filters.email}
                />
              </Grid>

              <Grid item xs={20} lg={4}>
                <InputSelectV2
                  id="role"
                  label="Role"
                  placeholder="No Selection"
                  opts={getRoles()}
                  onChangeInput={(e) => setFilters((prev) => ({ ...prev, role: e.target.value }))}
                  initialValue={filters.role}
                  defaultOption={true}
                />
              </Grid>
              <Grid item xs={20} lg={4}>
                <FormControl sx={{ width: '100%' }}>
                  <FormLabel>&nbsp;</FormLabel>
                  <FilterButton
                    variant="contained"
                    color="secondary"
                    size="large"
                    onClick={() => handleFilterUsers(filters)}
                  >
                    FILTER RESULTS
                  </FilterButton>
                </FormControl>
              </Grid>
            </Grid>

            <Grid container sx={{ justifyContent: 'center!important' }}>
              <Grid item xs={'auto'}>
                <Button
                  size="small"
                  sx={{
                    color: 'rgba(0,0,0,0.6)',
                    fontWeight: '700',
                    textTransform: 'lowercase',
                    cursor: 'default',
                    '&:hover': {
                      background: 'transparent',
                    },
                  }}
                >
                  showing {showingResults} results
                </Button>
              </Grid>
              {showFilters && (
                <>
                  <Typography
                    sx={{
                      fontSize: '0.8125rem',
                      textTransform: 'uppercase',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      paddingBottom: '.2em',
                    }}
                  >
                    |
                  </Typography>
                  <Grid item xs={'auto'}>
                    <Button
                      size="small"
                      sx={{ color: 'rgba(0,0,0,0.6)', fontWeight: '700', textTransform: 'lowercase' }}
                      onClick={handleClearFilter}
                    >
                      CLEAR FILTERS
                    </Button>
                  </Grid>
                </>
              )}
            </Grid>

            {reportsLoading && (
              <Stack alignItems="center">
                <CircularProgress />
              </Stack>
            )}
            <DataGrid
              sx={{ mt: 4 }}
              headerHeight={50}
              rows={users || []}
              columns={columns}
              autoHeight
              rowsPerPageOptions={[5, 10, 20, 50, 100]}
              disableSelectionOnClick
              disableColumnMenu
              pageSize={pageSize}
              onPageSizeChange={(newPage) => setPageSize(newPage)}
              pagination
              loading={reportsLoading}
              components={{
                ...theme.components.MuiDataGrid.defaultProps.components,
                ...{
                  NoRowsOverlay: () => <NoDataOverlay />,
                  NoResultsOverlay: () => <NoDataOverlay />,
                },
              }}
            />
          </Container>
        </Box>
      </MainLayout>
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return {
    loginExpire: (user) => {
      dispatch(loginExpire(user));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserAccessControl);
