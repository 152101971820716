import { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { loginExpire } from '../../../store/auth';

import ContainerView from '../../../components/ContainerView';
import MainLayout from '../../../components/MainLayout/MainLayout';

import useBreadcrumbs from '../../../hooks/useBreadcrumbs';
import clientService from '../../../services/clientService';
import { Box, Skeleton, Typography } from '@mui/material';
import ManageClientsHeader from './manage/ManageClientHeader';
import ManageCustomerTable from './manage/ManageCustomerTable';
import ManageRenderCompany from './manage/ManageRenderCompany';

const ManageClients = (props) => {
  const [clients, setClients] = useState([]);
  const [hideArchiveCustomers, setHideArchiveCustomers] = useState(false);
  // get all clients
  useEffect(() => {
    clientService.getAllClients().then((res) => {
      setClients(res.data);
    });
  }, []);

  const { setBreadcrumbs } = useBreadcrumbs();

  useEffect(() => {
    setBreadcrumbs([
      {
        name: 'Dashboard',
        url: '/tas/dashboard',
      },
      {
        name: 'Customers',
      },
    ]);
  }, [setBreadcrumbs]);

  return (
    <MainLayout {...props} current="customers" subtitle="Manage Customers">
      <ContainerView>
        <>
          <Box>
            <Box sx={{ display: 'flex', marginBottom: 1 }}>
              <Typography
                variant="h4"
                sx={{
                  textTransform: 'uppercase',
                }}
              >
                MANAGE <strong>CUSTOMERS</strong>
              </Typography>
            </Box>
            <ManageClientsHeader
              role={props.auth.user.role}
              hideArchiveCustomers={hideArchiveCustomers}
              setHideArchiveCustomers={setHideArchiveCustomers}
            />
            <Box sx={{ borderColor: 'nexxusGrayLight.main', borderWidth: 1, borderStyle: 'solid', px: 4, py: 2 }}>
              {clients.length > 0 ? (
                <ManageCustomerTable user={props.auth.user}>
                  {clients.map((client) => {
                    return (
                      <ManageRenderCompany
                        key={`render-company-${client.id}`}
                        client={client}
                        user={props.auth.user}
                        hideArchive={hideArchiveCustomers}
                      />
                    );
                  })}
                </ManageCustomerTable>
              ) : (
                [...new Array(10)].map((_e, index) => (
                  <Box key={`skeleton-${index}`} sx={{ mb: 2 }}>
                    <Skeleton variant="rectangular" animation="wave" sx={{ width: '100%' }} height={100} />
                  </Box>
                ))
              )}
            </Box>
          </Box>
          {/* <ArchiveClientDialog {...archiveClientDialog} /> */}
        </>
      </ContainerView>
    </MainLayout>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return {
    loginExpire: (user) => {
      dispatch(loginExpire(user));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageClients);
