import { connect } from 'react-redux';
import { loginExpire } from '../../store/auth';

import MainLayout from '../../components/MainLayout/MainLayout';
import CustomerIntakeForm from '../common/customerIntakeForm/CustomerIntakeForm';

const CustomerIntake = (props) => {
  return (
    <MainLayout {...props} current="customers">
      <CustomerIntakeForm></CustomerIntakeForm>
    </MainLayout>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return {
    loginExpire: (user) => {
      dispatch(loginExpire(user));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerIntake);
