import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { Avatar, Box, Divider, Grid, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/system';
import _ from 'lodash';
import moment from 'moment';
import { useEffect } from 'react';
import { useState } from 'react';
import NcsPopover from '../NcsPopover';
import ScoreGauge from '../ScoreGauge';
import InfoIcon from '@mui/icons-material/Info';

const NameTypography = styled(Typography)({
  '&': {
    fontSize: 16,
    lineHeight: 1.2,
    fontWeight: 600,
    textTransform: 'uppercase',
    overflow: 'hidden',
  },
});

const NameDivider = styled(Divider)({
  '&': {
    margin: '10px auto',
    width: 50,
  },
});

const getCompactName = (name = '') => {
  const nameParts = name.split(' ');
  if (nameParts.length > 3) {
    return `${nameParts[0]} ${nameParts[1].substring(0, 1)}. ${nameParts[2]} ${nameParts[3].substring(0, 1)}.`;
  } else if (nameParts.length > 2) {
    return `${nameParts[0]} ${nameParts[1].substring(0, 1)}. ${nameParts[2]}`;
  }

  return name;
};

export default function CandidateCardInfo({ candidate, ncs = false, delay = 0 }) {
  const theme = useTheme();
  const [ncsValue, setNcsValue] = useState(0);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    const candidateScore = ncs ? candidate?.candidate_ncs : candidate?.candidateScore;
    setNcsValue(candidateScore);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [candidate]);

  const handleClick = (e) => {
    setOpenModal(true);
  };

  const DateAdded = () => {
    return (
      <Stack justifyContent="center" alignItems="center" spacing={0.2}>
        <IconButton
          LinkComponent="a"
          href={candidate?.linkedin}
          target="_blank"
          sx={{ ml: -0.5, mt: -0.5, p: 0.5 }}
          disableRipple
        >
          <LinkedInIcon sx={{ color: theme.palette.primary.main, fontSize: '2.2rem' }} />
        </IconButton>
        <Typography sx={{ fontSize: 10, textTransform: 'uppercase', color: theme.palette.grey.main, fontWeight: 'bold' }}>
          Date Added:
        </Typography>
        <Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>
          {moment(new Date(candidate.createdAt)).format('L')}
        </Typography>
      </Stack>
    );
  };

  const CandidateAvatar = () => {
    const avatarHeight = 115;
    const bgColor = 'nexxusGrayLight.main';
    return (
      <Box>
        <Avatar
          {..._.merge({
            src: candidate?.avatar_url,
          })}
          sx={{
            bgcolor: bgColor,
            margin: '0 auto',
            width: avatarHeight,
            height: avatarHeight,
            fontSize: '1.85rem',
            fontWeight: 'bold',
            textTransform: 'uppercase',
            color: 'rgba(255, 255, 255, .6)',
            border: '1px solid rgba(200, 200, 200, 1)',
          }}
        />
      </Box>
    );
  };

  const SkillsMatch = () => {
    return (
      <Stack justifyContent="center" alignItems="center" spacing={0.2} sx={{ gap: '.5em', mb: '3px' }}>
        {Number(ncsValue).toString() !== Number(-1).toString() ? (
          <ScoreGauge delay={delay} value={ncsValue} sx={{ marginBottom: ncs ? '' : '1.2em' }} />
        ) : (
          <Tooltip title="NCS cannot be displayed due to missing variables required to calculate the score. Click the icon for more information.">
            <IconButton onClick={handleClick} color="nexxusYellow" sx={{ padding: '7px' }}>
              <InfoIcon sx={{ fontSize: '2.2rem' }} />
            </IconButton>
          </Tooltip>
        )}
        {ncs && <NcsPopover openModal={openModal} setOpenModal={setOpenModal} candidate={candidate} ncs={ncs} />}
      </Stack>
    );
  };

  const CandidateName = () => {
    return (
      <Stack direction="row" spacing={1} justifyContent="center" alignItems="center" sx={{ mt: 2 }}>
        <NameTypography align="center">{getCompactName(candidate?.full_name)} </NameTypography>
      </Stack>
    );
  };

  return (
    <Box>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="flex-end"
        columns={3}
        sx={{ position: 'relative', mt: -3.5 }}
      >
        <Grid item xs>
          <DateAdded />
        </Grid>
        <Grid item xs>
          <CandidateAvatar />
        </Grid>
        <Grid item xs>
          <SkillsMatch />
        </Grid>
      </Grid>

      <CandidateName />
      <NameDivider />
    </Box>
  );
}
