import { ROLE_ACCOUNT_MANAGER } from 'constants/users';
import CandidateOverview from 'pages/accountManager/CandidateOverview';
import Projects from 'pages/accountManager/Projects';
import ViewProject from 'pages/accountManager/projects/ViewProject';
import { Navigate, Route } from 'react-router-dom';
import CustomerIntake from '../pages/accountManager/CustomerIntake';
import Dashboard from '../pages/accountManager/Dashboard';
import PrivateRoute from './privateRouter';

const accountManagagerRoutes = [
  {
    name: 'Dashboard Account Manager',
    path: '/accountmanager/dashboard',
    element: <Dashboard />,
    authorization: [ROLE_ACCOUNT_MANAGER],
  },
  {
    name: 'Customer Intake Account Manager',
    path: '/accountmanager/customer/intake',
    element: <CustomerIntake />,
    authorization: [ROLE_ACCOUNT_MANAGER],
  },
  {
    name: 'View/Edit Customer',
    path: '/accountmanager/customer/intake/:ProjectId',
    element: <CustomerIntake />,
    authorization: [ROLE_ACCOUNT_MANAGER],
  },
  {
    name: 'View Projects',
    path: '/accountmanager/projects',
    element: <Projects />,
    authorization: [ROLE_ACCOUNT_MANAGER],
  },
  {
    name: 'View Project',
    path: '/accountmanager/projects/:ProjectId/view',
    element: <ViewProject />,
    authorization: [ROLE_ACCOUNT_MANAGER],
  },
  {
    name: 'Candidate Overview',
    path: '/accountmanager/projects/overview',
    element: <CandidateOverview />,
    authorization: [ROLE_ACCOUNT_MANAGER],
  },
];

export const AccountManagagerRoutes = ({ user }) => {
  return accountManagagerRoutes.map((route, index) => {
    return (
      <Route
        key={`customer-${index}`}
        path={route.path}
        element={
          <PrivateRoute>
            {route.authorization.includes(user?.role) ? route.element : <Navigate to="/403" replace />}
          </PrivateRoute>
        }
      />
    );
  });
};
