import { configureStore } from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import loggingMiddleware from './logginMiddleware';
import reducer from './reducer';

const loggerMiddleware = createLogger();

// eslint-disable-next-line import/no-anonymous-default-export
export default function () {
  return configureStore({
    reducer,
    middleware: [thunk, loggerMiddleware, loggingMiddleware],
  });
}
