import { useCallback, useEffect, useState } from 'react';
import http from 'services/httpService';
import { CANDIDATE_NOTE_SCOPE } from 'utils/staticData';

export default function useFetchCandidateNotes({ params, runAtStart = false, runOnChange = false }) {
  const [candidateNotes, setCandidateNotes] = useState([]);
  const [error, setError] = useState(false);
  const [hasFetched, setHasFetched] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const fetchCandidateNotes = useCallback(async () => {
    /*eslint no-fallthrough: ["error", { "commentPattern": "break[\\s\\w]*omitted" }]*/
    try {
      const candidateId = params.candidateId;
      const projectId = params.projectId;
      const isGlobalScope = params.scope === CANDIDATE_NOTE_SCOPE.GLOBAL;
      const isProjectScope = params.scope === CANDIDATE_NOTE_SCOPE.PROJECT;

      if (!!!candidateId) return;

      if (isGlobalScope || (isProjectScope && !!projectId)) {
        setIsLoading(true);
        const { data } = await http.get(`${process.env.REACT_APP_API_BACKEND}/v1/candidates/${params.candidateId}/notes`, {
          params,
        });
        setCandidateNotes(data);
        setIsLoading(false);
        setHasFetched(true);
      }
    } catch (error) {
      setError(true);
      setIsLoading(false);
    }
  }, [params]);

  const refetch = useCallback(() => {
    setCandidateNotes([]);
    fetchCandidateNotes();
  }, [fetchCandidateNotes]);

  useEffect(() => {
    if ((runAtStart && !hasFetched) || runOnChange) {
      fetchCandidateNotes();
      setHasFetched(true);
    }
  }, [fetchCandidateNotes, hasFetched, runAtStart, runOnChange]);

  return { candidateNotes, isLoading, error, refetch };
}
