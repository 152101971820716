import { Button, FormControlLabel, Grid, Paper, Radio, RadioGroup, Stack, Typography } from '@mui/material';
import { isEmpty, isEqual, uniqWith } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import http from 'services/httpService';

const enabledFields = {
  first_name: String,
  last_name: String,
  email: String,
  location: String,
  linkedin: String,
  timezone: String,
  developer_title: String,
  developer_seniority: String,
  availability: String,
  availability_interview: String,
  employment_type: String,
  english_proficiency: String,
  github_profile: String,
  info_additional: String,
  portfolio_link: String,
  salary_currency: String,
  salary_expectation: String,
  Skills: Array,
};

const DuplicateMergeForm = ({ candidatesToMerge = [], onClose }) => {
  const formRef = useRef();
  const [fieldsToMerge, setFieldsToMerge] = useState([]);
  const [mergedIds, setMergedIds] = useState('');

  useEffect(() => {
    const fields = [];
    setMergedIds(() => candidatesToMerge.map((candidate) => candidate.id).join(','));
    Object.keys(enabledFields).forEach((field) => {
      let fieldObject = {};
      fieldObject.name = field;
      fieldObject.label = field.replace('_', ' ');
      fieldObject.type = enabledFields[field];

      let fieldOptions = [];

      candidatesToMerge.forEach((candidate) => {
        if (!candidate[field]) return;

        fieldOptions.push(candidate[field]);
      });

      fieldObject.options = uniqWith(fieldOptions, isEqual);
      fields.push(fieldObject);
    });

    setFieldsToMerge(fields);
  }, [candidatesToMerge]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(formRef.current);

    let json = {};
    formData.forEach((value, key) => (json[key] = value));

    http
      .post(process.env.REACT_APP_API_BACKEND + '/v1/import/candidates/merge', json)
      .then((res) => {
        onClose();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <Grid container spacing={2} component="form" ref={formRef} name="myform">
        {!isEmpty(fieldsToMerge) && <input type="hidden" name="merged_ids" value={mergedIds} />}
        {fieldsToMerge.map((field) => {
          // hide options that are empty on all candidates
          if (field.options.length === 0) return null;

          return (
            <Grid key={field.name} item xs={4}>
              <Paper sx={{ p: 1, borderRadius: 0.5, height: '100%' }}>
                <Typography fontWeight="bold" textTransform="capitalize">
                  {field.label}
                </Typography>
                <RadioGroup aria-labelledby={field.name} name={field.name}>
                  {field.options.map((option, index, self) => {
                    if (field.type === Array) {
                      const skillsNames = option.map((x) => x.name).join(', ');
                      const skillsIds = option.map((x) => x.id).toString();

                      return (
                        <FormControlLabel
                          key={skillsIds}
                          value={skillsIds}
                          control={option.length === 1 ? <Radio checked /> : <Radio />}
                          label={skillsNames}
                        />
                      );
                    }

                    return (
                      <FormControlLabel
                        key={option}
                        value={option}
                        control={field.options.length === 1 ? <Radio checked /> : <Radio />}
                        label={option}
                      />
                    );
                  })}
                </RadioGroup>
              </Paper>
            </Grid>
          );
        })}
      </Grid>
      <Stack flexDirection="row" mt={3} gap={2} justifyContent="center">
        <Button sx={{ width: '180px' }} variant="contained" onClick={handleSubmit}>
          Merge Data
        </Button>
        <Button sx={{ width: '180px' }} variant="outlined" onClick={onClose}>
          Cancel
        </Button>
      </Stack>
    </>
  );
};

export default DuplicateMergeForm;
