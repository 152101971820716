/*
 *
 * Tabs for Candidate Overview
 * Sections in Use
 * Tas - Candidate Overview
 * Account Manager - Candidate Overview
 * Customer - Candidate Overview
 *
 */
import { CalendarMonthOutlined as CalendarIcon } from '@mui/icons-material';
import RateReviewIcon from '@mui/icons-material/RateReview';
import { Box, Button, Divider, Grid, Stack, Tab, Tabs, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/system';
import CandidateCard from 'components/Candidates/CardidateCard/CandidateCard';
import InputSelectV2 from 'components/FormElement/InputSelectV2';
import { useCurrentUser } from 'hooks/useCurrentUser';
import _ from 'lodash';
import { CANDIDATEPROJECT_STATUS } from 'constants/candidate_project';
import ModalOverview from 'pages/customer/candidateOverview/ModalOverview';
import { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import SwipeableViews from 'react-swipeable-views';
import { loginExpire } from 'store/auth';
import { a11yProps, TabPanel } from 'utils/tabPanels';

const CustomTabs = styled(Tabs)(({ theme }) => ({
  backgroundColor: 'transparent',
  '& .MuiTabs-indicator': {
    backgroundColor: 'transparent',
    height: 3,
    position: 'relative',
  },
  '& .MuiTab-root.Mui-selected': {
    backgroundColor: 'transparent',
    color: theme.palette.grey.dark,
    opacity: '1',
    textDecoration: 'underline',
    textUnderlineOffset: '.5em',
  },
  '& .MuiTab-root': {
    fontSize: '13px',
    [theme.breakpoints.between('smd', 'md')]: {
      maxWidth: 'fit-content',
      minWidth: 'fit-content',
    },
    transition: '0.3s',
    margin: '0',
    borderRadius: '0px',
    borderBottomStyle: 'solid',
    color: theme.palette.grey.main,
    borderWidth: '0px',
    borderColor: 'transparent',
    backgroundColor: 'transparent',
    opacity: '1',
    flexDirection: 'row',
    minHeight: '50px',
    padding: '6px 12px',
    lineHeight: '2em',
    fontWeight: '800',
    letterSpacing: '.07em',
    // borderLeft: `1px solid ${theme.palette.nexxusGrayLight.main}`,
    '&:not(:nth-last-of-type(2)):not(:last-child):hover': {
      background: theme.palette.grey.main,
      color: 'white',
    },
    '& > svg': {
      marginRight: '10px',
    },
    '& :not(.Mui-selected)': {
      borderRightStyle: 'none!important',
    },
    '&:first-of-type': {
      marginLeft: '0',
      borderLeft: 'none',
    },
    '&:last-child': {
      marginRight: '0',
    },
    '&:not(:first-of-type):not(:last-child):not(:nth-last-of-type(2))::before': {
      content: '""',
      position: 'absolute',
      zIndex: '1',
      left: '0',
      bottom: '0',
      height: '70%',
      top: '15%',
      border: '1px solid',
      borderWidth: '0 0 0 1px',
      borderColor: theme.palette.nexxusGrayLight.main,
    },
    '&:not(:nth-last-of-type(2)):not(:last-child):hover::before': {
      border: 'none!important',
    },
  },
  '& .MuiTabs-flexContainer': {
    [theme.breakpoints.between('sm', 'md')]: {
      flexWrap: 'wrap',
      justifyContent: 'center',
      gap: '1em',
    },
    gap: '0',
  },
  '& .MuiTabScrollButton-root': {
    width: '20px',
  },
}));

const CustomTabPanel = styled(TabPanel)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    '& .MuiBox-root': { paddingTop: 0 },
  },
  overflowX: 'hidden',
}));

const CustomTab = styled(Tab)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    textUnderlineOffset: '.3em',
    minHeight: 0,
  },
}));

const UrgentButton = styled(Button)(({ theme }) => ({
  width: '85%',
  animation: 'glow .7s ease-in-out infinite alternate',
  '@-webkit-keyframes glow': {
    from: {
      background: 'transparent',
    },
    to: {
      background: 'rgba(239, 68, 68, 0.85)',
      color: 'white',
    },
  },
}));

const TabsCandidateOverview = ({
  candidates,
  projectId,
  setStatusSelected,
  statusSelected = 'PANEL_INTERVIEW',
  candidatesCountStatus,
  initialCounters,
  selectedProject,
  isFeedbackActive = false,
  type = 'live',
}) => {
  const theme = useTheme();
  const isMobileViewPort = useMediaQuery(theme.breakpoints.down('smd'));

  const [value, setValue] = useState('PANEL_INTERVIEW');
  const [panelView, setPanelView] = useState(0);
  const [candSelected, setCandSelected] = useState('');
  const app_url = process.env.REACT_APP_URL;
  const [open, setOpen] = useState(false);
  const [allCandidates, setAllCandidates] = useState([]);
  const user = useCurrentUser();

  useEffect(() => {
    (async () => {
      candidates.map((cand, index) => {
        const actualStatus = setActualStatus(statusSelected, cand);
        return setReportUrgent(cand, actualStatus);
      });
      setAllCandidates(candidates);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [candidates]);

  useEffect(() => {
    (async () => {
      if (statusSelected !== value && statusSelected !== '') {
        const tabSel = _.find(initialCounters, (v) => statusSelected === v.key);
        setValue(tabSel ? tabSel.key : 'PANEL_INTERVIEW');
        setPanelView(tabSel ? tabSel.orderTab : 0);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusSelected]);

  useEffect(() => {
    if (candSelected !== '') setOpen(true);
  }, [candSelected]);

  const handleChange = (event, newValue) => {
    const tabNameSelected = event.target.textContent;
    const key = tabNameSelected.replace(/\s\((\d+)\)/, '').replace(' ', '_');
    const tabSel = _.find(initialCounters, (v) => key === v.key);

    if (projectId !== '') {
      setValue(key);
      setPanelView(tabSel.orderTab);
      setStatusSelected(key);
    }
  };
  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const NoResultsComponent = (
    <Box sx={{ minHeight: { xs: 'calc(12 * 8px + 1em)', md: 'calc(35 * 8px + 1em)' }, width: '100%' }}>
      <Typography
        sx={{
          color: 'nexxusGrayMid.main',
          fontSize: 22,
          fontWeight: 'bold',
          textTransform: 'none',
          textAlign: 'center',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}
      >
        NO DATA - PLEASE SELECT OPTIONS
      </Typography>
    </Box>
  );

  const candidateBoxStyle = () =>
    candidates.length > 0 ? { display: 'grid', gridTemplateColumns: 'repeat(auto-fill,minmax(270px,1fr))', gap: '1em' } : {};

  const getCustomStyle = (candidateLabel) => {
    if (candidateLabel === 'HIRED') {
      return {
        [theme.breakpoints.between('smd', 'md')]: {
          marginLeft: '2em!important',
        },
        color: `${theme.palette.nexxusGrayLight.main}!important`,
        background: `${theme.palette.success.main}!important`,
        opacity: '.75!important',
        marginRight: '.75em!important',
        // marginLeft: '.75em!important',
        '&.Mui-selected': {
          opacity: '1!important',
          color: 'white!important',
        },
      };
    } else {
      if (candidateLabel === 'REJECTED') {
        return {
          color: `${theme.palette.nexxusGrayLight.main}!important`,
          background: `${theme.palette.error.main}!important`,
          opacity: '.75!important',
          '&.Mui-selected': {
            opacity: '1!important',
            color: 'white!important',
          },
        };
      } else {
        return {};
      }
    }
  };

  const setActualStatus = (status, candidate) => {
    if (status === 'CUSTOMER_STAGE' && candidate) {
      const keyStatus = Object.keys(CANDIDATEPROJECT_STATUS).filter(
        (key) => CANDIDATEPROJECT_STATUS[key] === candidate.Projects[0].Project_Candidate['status']
      );
      return keyStatus;
    }

    return status;
  };

  const setReportUrgent = (candidate, actualStatus) => {
    if (actualStatus[0] === 'SUBMITTED_CUSTOMER') {
      if (
        !_.get(candidate.Project_Candidates[0], `customer_feedback`) ||
        (_.get(candidate.Project_Candidates[0], `customer_feedback.${actualStatus[0]}`) &&
          candidate.Project_Candidates[0].customer_feedback[actualStatus[0]].feedback === '') ||
        !candidate.Project_Candidates[0].customer_feedback[actualStatus[0]].feedback
      ) {
        candidate.isUrgentReport = true;
      } else {
        candidate.isUrgentReport = false;
      }
    } else {
      candidate.isUrgentReport = null;
    }
    return candidate;
  };

  return (
    <>
      <ModalOverview
        open={open}
        handleCloseModal={() => {
          setOpen(false);
          setCandSelected('');
        }}
        onCloseModalFeedUpdated={(CandidateId) => {
          const newArr = [...allCandidates].map((cand) => {
            let candidate = { ...cand };
            if (cand.id === CandidateId) candidate = { ...candidate, isUrgentReport: false };
            return candidate;
          });
          Promise.all([setOpen(false), setCandSelected(''), setAllCandidates(newArr)]);
        }}
        candidate={candSelected}
        project={projectId}
        statusFeed={statusSelected}
      />
      <Box sx={{ width: '100%', marginTop: 2.5 }}>
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ width: '100%' }}>
            {isMobileViewPort ? (
              <DropDownTabpanelSelector
                candidatesCountStatus={candidatesCountStatus}
                setStatusSelected={setStatusSelected}
                projectId={projectId}
              />
            ) : (
              <CustomTabs
                onChange={handleChange}
                variant={isMobileViewPort ? 'scrollable' : 'fullWidth'}
                scrollButtons={isMobileViewPort ? true : false}
                allowScrollButtonsMobile={isMobileViewPort ? true : false}
                value={value}
                TabIndicatorProps={{ sx: { top: 0 } }}
              >
                {Object.keys(candidatesCountStatus).map((key, index) => {
                  const candidateLabel = candidatesCountStatus[key].key;
                  const customStyle = getCustomStyle(candidateLabel);
                  return (
                    <CustomTab
                      key={key}
                      value={candidatesCountStatus[key].key}
                      label={`${key.replaceAll('_', ' ')} (${candidatesCountStatus[key]?.count})`}
                      {...a11yProps(index)}
                      sx={customStyle}
                    />
                  );
                })}
              </CustomTabs>
            )}
            <Divider variant="middle" sx={{ mt: 2.5, ml: 0, mr: 0 }} />
            <SwipeableViews
              style={isMobileViewPort ? { border: 'none', display: 'flex', justifyContent: 'center' } : { border: 'none' }}
              axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
              index={panelView}
              disabled={true}
              onChangeIndex={handleChangeIndex}
            >
              {Object.keys(candidatesCountStatus).map((key, index) => {
                return (
                  <Fragment key={key}>
                    <CustomTabPanel value={panelView} index={index} sx={{ overflowX: 'hidden' }}>
                      <Grid container sx={candidateBoxStyle}>
                        {allCandidates.length > 0 ? (
                          <>
                            {allCandidates.map((cand, index) => {
                              return (
                                <Fragment key={cand.id}>
                                  <Grid
                                    item
                                    xs={3}
                                    sx={{
                                      [theme.breakpoints.between('smd', 'md')]: {
                                        maxWidth: '100%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                      },
                                      mt: 8,
                                      maxWidth: '100%!important',
                                      flexDirection: 'column',
                                      alignItems: 'center',
                                    }}
                                  >
                                    <CandidateCard candidate={cand} ncs={true} />
                                    {user.currentUser.role === 'superCustomer' &&
                                      isFeedbackActive &&
                                      ['CUSTOMER_STAGE'].includes(statusSelected) && (
                                        <Stack
                                          direction="row"
                                          spacing={1}
                                          justifyContent="center"
                                          sx={{ mt: 2, mx: 'auto' }}
                                        >
                                          <Fragment>
                                            {cand.isUrgentReport}
                                            {cand.isUrgentReport === true && (
                                              <UrgentButton
                                                variant="action-icon"
                                                color="warning"
                                                onClick={() => {
                                                  setCandSelected(cand);
                                                }}
                                              >
                                                <span style={{ paddingRight: '1em' }}>Urgent Feedback</span>
                                                <RateReviewIcon />
                                              </UrgentButton>
                                            )}
                                            {cand.isUrgentReport !== null && !cand.isUrgentReport && cand.email && (
                                              <Button
                                                variant="action"
                                                color="primary"
                                                ignoreLinkBehavior={true}
                                                startIcon={<CalendarIcon />}
                                                target="_blank"
                                                href={` mailto:${selectedProject?.Recruiter?.email}?subject=Schedule%20Interview&body=Dear%20${selectedProject?.Recruiter?.full_name}%0D%0A%0D%0AI%20would%20like%20to%20schedule%20an%20interview%20with%20${cand.full_name}%20${app_url}/tas/candidates/${cand.id}%20regarding%20the%20position%20below%3A%0D%0A%0D%0A${selectedProject?.Client?.companyName}%0D%0A${selectedProject?.name}%0D%0A${app_url}/tas/projects/${selectedProject?.id}/view%0D%0A%0D%0A%0D%0ABest%2C%0D%0A`}
                                              >
                                                Schedule Interview
                                              </Button>
                                            )}
                                            {cand.isUrgentReport !== null && !cand.isUrgentReport && (
                                              <Button
                                                variant="action-icon"
                                                color="warning"
                                                onClick={() => {
                                                  setCandSelected(cand);
                                                }}
                                              >
                                                <RateReviewIcon />
                                              </Button>
                                            )}
                                          </Fragment>
                                        </Stack>
                                      )}
                                  </Grid>
                                </Fragment>
                              );
                            })}
                          </>
                        ) : (
                          NoResultsComponent
                        )}
                      </Grid>
                    </CustomTabPanel>
                  </Fragment>
                );
              })}
            </SwipeableViews>
          </Box>
        </Box>
      </Box>
    </>
  );
};

const DropDownTabpanelSelector = ({ setStatusSelected, candidatesCountStatus, projectId }) => {
  const theme = useTheme();
  const [projectCandidatesStatus, setProjectCandidatesStatus] = useState([]);
  const [projectCandidateStatusId, setProjectCandidateStatusId] = useState('');
  const [dropdownColor, setDropdownColor] = useState(theme.palette.nexxusBlack.main);

  useEffect(() => {
    const getBackgroundColor = (key) => {
      if (key === 'HIRED') {
        return theme.palette.success.main + '!important';
      }
      if (key === 'REJECTED') {
        return theme.palette.error.main + '!important';
      }
      return 'white';
    };

    const projectCandidatesStatus = Object.keys(candidatesCountStatus).map((key) => ({
      _id: key,
      label: key.replace(/_/g, ' ') + ` (${candidatesCountStatus[key]?.count})`,
      style: {
        '&.Mui-selected': {
          background: key !== 'HIRED' && key !== 'REJECTED' && 'rgb(133 134 137 / 8%)',
          opacity: '1',
          color: (key === 'HIRED' || key === 'REJECTED') && 'white',
        },
        color:
          key !== 'HIRED' && key !== 'REJECTED'
            ? theme.palette.grey.main + '!important'
            : theme.palette.nexxusGrayLight.main,
        marginTop: '.5em',
        background: getBackgroundColor(key),
        opacity: key !== 'HIRED' && key !== 'REJECTED' ? '1' : '.6',
      },
    }));
    setProjectCandidatesStatus(projectCandidatesStatus);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [candidatesCountStatus]);

  useEffect(() => {
    const firstElementInDropdown = Object.keys(candidatesCountStatus)[0];
    setProjectCandidateStatusId(firstElementInDropdown);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  const handleChange = (e) => {
    setProjectCandidateStatusId(e.target.value);
    setStatusSelected(e.target.value);
    const valueSelected = e.target.value;
    if (valueSelected === 'HIRED') {
      setDropdownColor(theme.palette.success.main);
    }
    if (valueSelected === 'REJECTED') {
      setDropdownColor(theme.palette.error.main);
    } else {
      setDropdownColor(theme.palette.grey.dark);
    }
  };

  return (
    <Grid item xs={12} lg={7}>
      <Box
        sx={{
          minWidth: 120,
          textAlign: 'left',
          marginTop: 1,
          marginRight: 2,
          marginBottom: 2,
        }}
      >
        <Typography
          sx={{
            color: 'nexxusGrayMid.main',
            fontSize: 12,
            textAlign: 'left',
            marginTop: 3,
            fontWeight: 'bold',
          }}
        >
          SELECT A PROJECT CANDIDATE'S STATUS
        </Typography>
        <InputSelectV2
          id={'candidateStatusID'}
          size={'normal'}
          opts={projectCandidatesStatus}
          initialValue={projectCandidateStatusId}
          onChangeInput={handleChange}
          sx={{ '& .MuiSelect-select': { color: dropdownColor } }}
        />
      </Box>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return {
    loginExpire: (user) => {
      dispatch(loginExpire(user));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TabsCandidateOverview);
