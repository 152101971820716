import { getTheme as getNewTheme} from "@nexxus/new-theme";
import {getTheme as getOldTheme} from "@nexxus/old-theme"


  export function getThemeByName(theme){
    return themeMap[theme];
  }
  
  const themeMap= {
    "new":getNewTheme(),
    "old":getOldTheme(),
  };
