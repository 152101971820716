import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { useDebounce } from 'use-debounce';
import { usePrevious } from 'utils/utils';

const InputTextOutlinedV2 = ({
  id,
  type,
  label,
  tooltip,
  placeholder,
  error,
  initialValue,
  onChangeInput,
  size,
  multiline,
  rowsMultiline,
  endAdornment,
  startAdornment,
  styleControl,
  styleLabel,
  styleInput,
  styleError,
  inputProps,
  isDisabled,
  removeBlankSpaces = false,
}) => {
  const [value, setValue] = useState('');
  const [valueDebouce] = useDebounce(value, 300);
  let inputRef = useRef();
  const prevValue = usePrevious(valueDebouce);

  useEffect(() => {
    if (initialValue) setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    if (typeof prevValue !== 'undefined') {
      const obj = { target: { name: inputRef.current.name, value: inputRef.current.value } };
      if (initialValue === value && prevValue === '' && value !== '') {
        // select simple - prevent re render on capture initial value from request
        return;
      }
      return onChangeInput(obj);
    }
  }, [valueDebouce]); // eslint-disable-line

  return (
    <>
      <FormControl fullWidth sx={{ textAlign: 'left', ...styleControl }} disabled={isDisabled}>
        {label && label !== '' && (
          <FormLabel error={!!error && error !== ''} htmlFor={id} sx={{ marginTop: 'auto', ...styleLabel }}>
            {label} {tooltip}
          </FormLabel>
        )}
        <OutlinedInput
          inputRef={inputRef}
          fullWidth
          name={id}
          id={id}
          value={value}
          onChange={(e) => {
            if (removeBlankSpaces) {
              const fixedText = e.target.value.replaceAll(' ', '');
              setValue(fixedText);
            } else {
              setValue(e.target.value);
            }
          }}
          size={size}
          type={type || 'text'}
          multiline={multiline || false}
          rows={rowsMultiline || 1}
          error={!!error}
          placeholder={placeholder}
          disabled={isDisabled || false}
          startAdornment={startAdornment}
          endAdornment={endAdornment}
          sx={{ ...styleInput }}
          inputProps={inputProps}
        />
        {error && (
          <FormHelperText error id={id} sx={{ styleError }}>
            {error}
          </FormHelperText>
        )}
      </FormControl>
    </>
  );
};

InputTextOutlinedV2.propTypes = {
  id: PropTypes.string.isRequired, //id for input
  type: PropTypes.string, // type of text field
  label: PropTypes.string, // form label
  tooltip: PropTypes.element,
  placeholder: PropTypes.string, // placeholder of the field
  error: PropTypes.string, // error message
  initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // value of the field
  onChangeInput: PropTypes.func, // func callback
  size: PropTypes.string, // size of input
  multiline: PropTypes.bool, //If true, a TextareaAutosize element is rendered.
  rowsMultiline: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), //Number of rows to display when multiline option is set to true.
  startAdornment: PropTypes.element, // Adornment for the input at the beginning of field
  endAdornment: PropTypes.element, // Adornment for the input at the end of field
  styleLabel: PropTypes.object, // props to overwrite styles for label control
  styleInput: PropTypes.object, // props to overwrite styles for input control
  styleError: PropTypes.object, // props to overwrite styles for error message
  isDisabled: PropTypes.bool,
  styleControl: PropTypes.object,
  inputProps: PropTypes.object,
};

export default InputTextOutlinedV2;
