import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Element, scroller } from 'react-scroll';
import { loginExpire } from '../../../store/auth';

import Stack from '@mui/material/Stack';
import ModalFeedback from 'components/Generic/Modal/ModalFeedback';
import ProjectSection from './intake/ProjectSection';

import { Autocomplete, TextField, useTheme } from '@mui/material';
import _ from 'lodash';
import CustomerSection from 'pages/common/customerIntakeForm/intake/CustomerSection';
import IntakeActionButtons from 'pages/common/customerIntakeForm/intake/intakeActionButtons';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { default as clientService } from 'services/clientService';
import projectService from 'services/projectService';
import { CLIENTSTATUS, PROJECT_STATUS } from 'utils/staticData';
import { getPathDashboard } from 'utils/users';
import { useCustomers } from '../../../hooks/request';

const CustomerIntake = (props) => {
  const maxWidth = props.maxWidth || 'xl';
  const [customerSelected, setCustomerSelected] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);
  const [customerForm, setCustomerForm] = useState(false);
  const [projects, setProjects] = useState([false]);
  const [trySubmit, setTrySubmit] = useState(false);
  const [reset, setReset] = useState(false);
  const [success, setSuccess] = useState(false);
  const [open, setOpen] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openProjInfModal, setOpenProjInfModal] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [modalProjectsOpen, setModalProjectsOpen] = useState(false);
  const [validating, setValidating] = useState(false);
  const theme = useTheme();

  const { ProjectId } = useParams();
  const history = useNavigate();
  const counts = useRef(0);
  const firstValid = useRef(false);

  const handleOpenProjInfModal = (e) => {
    setOpenProjInfModal(true);
  };
  const onCloseModal = (event, reason) => {
    event?.preventDefault();
    setOpen(false);
    setOpenDeleteModal(false);
    setOpenProjInfModal(false);
    setSubmitting(false);
  };
  const onCloseProjIndModal = (event, reason) => {
    setOpenProjInfModal(false);
    setModalProjectsOpen(false);
    setSubmitting(false);
  };

  const onAccept = (e, reason) => {
    setProjects((prev) => [...prev, false]);
    setOpen(false);
  };

  const onAcceptDeleteModal = (e, reason) => {
    const projs = [...projects];
    projs.splice(-1);
    setProjects(projs);
  };

  const onAcceptProjInfModal = async (e, reason) => {
    let clientId;
    try {
      if (customerSelected === '') {
        const { data } = await clientService.createClient(customerForm);
        clientId = data.id;
      } else {
        clientId = customerSelected.id;
      }

      if (!!ProjectId) {
        projectService.patchProject(
          { ...projects[0], ClientId: clientId, status: PROJECT_STATUS.ACTIVE.toString() },
          ProjectId
        );
      } else {
        await Promise.all(
          projects.map(async (project) => {
            await projectService.registerProject({ ...project, ClientId: clientId });
          })
        );
      }

      setReset(true);
      setProjects([false]);
      setSuccess(true);
      toast.success('Customer intake saved!');
      scroller.scrollTo('anchorElement', { duration: 500, delay: 50, smooth: true, offset: 0 });
    } catch (error) {
      if (error.response && error.response.data) {
        return toast.error(error.response.data.message);
      }
    }
  };

  const { customers } = useCustomers({ excludeStatus: [CLIENTSTATUS.ARCHIVED] });

  useEffect(() => {
    (async () => {
      const pjs = projects.filter((pj) => pj === false || pj === null);
      const customer = customerSelected !== '' ? customerSelected : customerForm;

      if (reset === false && customer !== false && pjs.length === 0 && submitting) {
        handleOpenProjInfModal();
      } else {
        setReset(false);
      }
    })();
  }, [customerForm]); // eslint-disable-line

  useEffect(() => {
    const customer = customerSelected !== '' ? customerSelected : customerForm;
    if (projects.length > 0 && firstValid.current === true && customer !== false) {
      counts.current += 1;
      const pjs = projects.filter((pj) => pj === false || pj === null);
      firstValid.current = true;
      if (reset === false && pjs.length === 0) {
        return handleOpenProjInfModal();
      }
      if (counts.current === projects.length) {
        counts.current = 0;
        setTrySubmit(false);
        setValidating(false);
      }
    }
    firstValid.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projects, customerForm]);

  useEffect(() => {
    (async () => {
      if (customerSelected) {
        setIsDisabled(true);
        setReset(false);
      } else {
        setIsDisabled(false);
        setReset(true);
      }
    })();
  }, [customerSelected]); // eslint-disable-line

  useEffect(() => {
    (async () => {
      if (!!ProjectId && !_.isEmpty(props.project)) {
        const { project: projectDB } = props;
        setCustomerSelected(projectDB.Client);
      }
    })();
  }, [props.project]); // eslint-disable-line

  return (
    <>
      <ModalFeedback
        variant="info"
        headerText="Project"
        titleText="Do you want to add a new project?"
        open={open}
        onCancel={onCloseModal}
        onClose={onCloseModal}
        onAccept={onAccept}
      />
      <ModalFeedback
        variant="warning"
        headerText="Project"
        titleText="Do you want to delete a project?"
        open={openDeleteModal}
        onCancel={onCloseModal}
        onClose={onCloseModal}
        onAccept={onAcceptDeleteModal}
      />
      <ModalFeedback
        variant="info"
        headerText="Project Information"
        titleText="Do you want to save the info?"
        open={openProjInfModal}
        onCancel={onCloseModal}
        onClose={onCloseProjIndModal}
        onAccept={onAcceptProjInfModal}
      />
      {success === false && (
        <Container maxWidth={maxWidth}>
          <Element name="anchorElement"></Element>

          <Grid container spacing={2}>
            <Grid item xs={12} sx={{ pb: 2 }}>
              <Typography variant="h4" align="left" pt={1} sx={{ textTransform: 'uppercase' }}>
                CUSTOMER INTAKE FORM
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ fontWeight: 900, fontSize: 18 }}>SELECT CUSTOMER</Typography>
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                id="customer"
                options={customers}
                value={customerSelected}
                getOptionLabel={(option) => {
                  return option?.companyName || '';
                }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => {
                  return <TextField {...params} />;
                }}
                onChange={(ev, value) => {
                  setCustomerSelected(value);
                }}
                disabled={isDisabled}
              />
            </Grid>
          </Grid>
          <CustomerSection
            customer={customerSelected}
            trySubmit={trySubmit}
            onHandleError={() => {
              setTrySubmit(false);
              setReset(false);
              setSubmitting(false);
            }}
            onHandleValidation={(fields) => {
              setCustomerForm(fields);
            }}
            reset={reset}
            isDisabled={isDisabled}
          />
          <Divider sx={{ my: 4 }}></Divider>
          <ProjectSection
            trySubmit={trySubmit}
            projects={projects}
            projectDB={props.project}
            onHandleError={() => {
              setTrySubmit(false);
              setReset(false);
              setSubmitting(false);
            }}
            onHandleValidation={(numberProject, fields) => {
              const pjs = [...projects];
              pjs[numberProject - 1] = fields;
              Promise.all([setTrySubmit(false), setProjects(pjs)]);
            }}
            reset={reset}
          />
          <Grid item xs={12} sx={{ [theme.breakpoints.down('md')]: { pt: '0!important', mt: 0 }, mt: 4 }}>
            <IntakeActionButtons
              projects={projects}
              openModal={modalProjectsOpen}
              isEdit={true}
              callbackSave={null}
              onHandleAddProjects={(projs) => {
                counts.current += 1;
                setProjects(projs);
              }}
              onHandleProjects={(projs) => {
                counts.current -= 1;
                setProjects(projs);
              }}
              setTrySubmit={setTrySubmit}
              setValidating={setValidating}
              isValidating={validating}
            />
          </Grid>
        </Container>
      )}

      {success === true && (
        <Stack direction="column" justifyContent="space-between" sx={{ my: 8, textAlign: 'center', py: 6, px: 12 }}>
          <Typography variant="h1" sx={{ fontWeight: 900, fontSize: '3em', color: 'nexxusGreen.main' }}>
            SUBMISSION RECEIVED
          </Typography>
          <Box sx={{ textAlign: 'center', py: 4 }}>
            <CheckCircleOutlineIcon sx={{ color: 'nexxusGreen.main', fontSize: '6em' }}></CheckCircleOutlineIcon>
          </Box>

          <Button
            variant="text"
            sx={{ mt: 4, '&:hover': { background: 'none', textDecoration: 'underline' } }}
            onClick={() => {
              history(getPathDashboard(props.auth.user));
            }}
          >
            <Typography display="flex" sx={{ fontWeight: 'bold', fontSize: '2em', color: '#009eff' }}>
              GO TO DASHBOARD
            </Typography>
          </Button>
        </Stack>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return {
    loginExpire: (user) => {
      dispatch(loginExpire(user));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerIntake);
