import { Box, Button, Divider, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import { useCallback, useEffect, useState, Fragment } from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';

const StatusCardWrapper = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  padding: '0px',
  textTransform: 'uppercase',
  paddingLeft: '10px',
  minWidth: 'fit-content',
  flex: '1',
  cursor: 'unset',
  paddingRight: '10px',
  borderRadius: '2px',
  '@media(max-width:135px)': {
    width: '100%!important',
  },
  '@media(min-width:500px)': {
    maxWidth: '190px',
  },
}));

const CardLabel = styled(Box)({
  fontWeight: 700,
  fontSize: 13,
  color: 'black',
  width: '100%',
  height: '40px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
});

const ButtonToolTip = styled(Button)(({ theme }) => ({
  minWidth: '0',
  fontSize: 'calc(1em + 2px)!important',
  paddingBottom: '1px',
  paddingTop: '0px',
  color: 'black',
  paddingLeft: '0px',
  paddingRight: '0px',
  fontWeight: '700',
  '&:hover': {
    background: 'unset',
    textDecoration: 'underline',
    textUnderlineOffset: '5px',
    color: theme.palette.primary.lighter,
  },
}));

const DividerStyled = styled(Divider)(({ theme }) => ({
  [theme.breakpoints.between(1181, 1417)]: {
    visibility: 'hidden',
  },
  [theme.breakpoints.between(1067, 1181)]: {
    visibility: 'unset',
  },
  [theme.breakpoints.down(1067)]: {
    visibility: 'hidden',
  },
  [theme.breakpoints.down(800)]: {
    display: 'none',
  },
}));

const StatusCard = ({ className, sx, sxLabel, sxLabelCount, candidates, label, counter = 0 }) => {
  const [open, setOpen] = useState(false);

  const handleTooltipClose = useCallback(() => {
    setOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTooltipOpen = useCallback(() => {
    setOpen(!open);
  }, [open]);

  return (
    <StatusCardWrapper
      className={className}
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      sx={{
        ...sx,
        '& .MuiTooltip-tooltip': { maxWidth: 'fit-content' },
        '&.coloredCard': {
          opacity: open ? '1!important' : '.75!important',
          '& .MuiButtonBase-root': {
            '&:hover': {
              color: '#e1e1e1!important',
            },
          },
        },
      }}
    >
      {candidates?.length > 0 ? (
        <CardLabel sx={sxLabel}>
          {label} &nbsp;
          <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={handleTooltipClose}>
            <Box>
              <Tooltip
                PopperProps={{
                  disablePortal: true,
                }}
                onClose={handleTooltipClose}
                open={open}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title={
                  <Box style={{ padding: '12px 16px', maxWidth: 'fit-content' }}>
                    {candidates.length > 0 &&
                      candidates?.map((cand, index) => (
                        <Fragment key={cand.id}>
                          <Typography textAlign="left" color="inherit" fontSize={'1.2em'}>
                            {cand.full_name}
                          </Typography>
                          {index + 1 !== candidates.length && <Divider sx={{ marginTop: '2px', marginBottom: '2px' }} />}
                        </Fragment>
                      ))}
                  </Box>
                }
              >
                <ButtonToolTip variant="text" sx={sxLabelCount} onClick={handleTooltipOpen}>
                  ({counter ?? 0})
                </ButtonToolTip>
              </Tooltip>
            </Box>
          </ClickAwayListener>
        </CardLabel>
      ) : (
        <CardLabel sx={sxLabel}>
          {label} <span style={{ fontSize: 'calc(1em + 2px)', paddingBottom: '1px' }}>&nbsp;({counter ?? 0})</span>
        </CardLabel>
      )}
    </StatusCardWrapper>
  );
};

const StatusCardsReport = ({ candidatesByStatus, candidatesCountStatus }) => {
  const [customerStage, setCustomerStage] = useState([]);
  const [customerRejected, setCustomerRejected] = useState([]);

  useEffect(() => {
    if (candidatesByStatus && candidatesByStatus.length > 0) {
      if (candidatesByStatus.OFFER_EXTENDED?.length >= 0) {
        setCustomerStage((arr) => [
          ...(candidatesByStatus?.SUBMITTED_CUSTOMER ?? null),
          ...(candidatesByStatus?.CUSTOMER_INTERVIEW ?? null),
        ]);
      }
      const candidates = [];
      candidatesByStatus?.PANEL_INTERVIEW_REJECTED && candidates.push(...candidatesByStatus.PANEL_INTERVIEW_REJECTED);
      candidatesByStatus?.SKILLS_ASSESSMENT_REJECTED && candidates.push(...candidatesByStatus.SKILLS_ASSESSMENT_REJECTED);
      candidatesByStatus?.SUBMITTED_CUSTOMER_REJECTED && candidates.push(...candidatesByStatus.SUBMITTED_CUSTOMER_REJECTED);
      candidatesByStatus?.CUSTOMER_INTERVIEW_REJECTED && candidates.push(...candidatesByStatus.CUSTOMER_INTERVIEW_REJECTED);
      // candidatesByStatus?.OFFER_REJECTION && candidates.push(...candidatesByStatus.OFFER_REJECTION);
      candidatesByStatus?.DROPPED_OUT && candidates.push(...candidatesByStatus.DROPPED_OUT);

      setCustomerRejected((arr) => [...candidates]);
    }
  }, [candidatesByStatus]);

  return (
    <Box
      sx={{
        width: '100%',
        justifyContent: 'center',
        display: 'flex',
        flexWrap: 'wrap',
        columnGap: '.25em',
        rowGap: '.5em',
        '@media(max-width:500px)': {
          flexWrap: 'nowrap',
          flexDirection: 'column',
        },
      }}
    >
      <StatusCard
        label="Panel Interview" //panel interview
        candidates={candidatesByStatus?.PANEL_INTERVIEW}
        counter={candidatesCountStatus?.PANEL_INTERVIEW}
        sx={{
          background: 'transparent',
          color: 'black!important',
        }}
        sxLabel={{ '@media(min-width:500px)': { justifyContent: 'flex-start' } }}
      />
      <DividerStyled orientation="vertical" flexItem />
      <StatusCard
        label="Skills Assessment" //panel interview
        candidates={candidatesByStatus?.SKILLS_ASSESSMENT}
        counter={candidatesCountStatus?.SKILLS_ASSESSMENT}
        sx={{
          background: 'transparent',
          color: 'black!important',
        }}
        sxLabel={{ minWidth: '176px' }}
      />
      <DividerStyled orientation="vertical" flexItem />
      <StatusCard
        candidates={customerStage}
        counter={candidatesCountStatus?.SUBMITTED_CUSTOMER + candidatesCountStatus?.CUSTOMER_INTERVIEW}
        label="Customer Stage"
        sx={{
          background: 'transparent',
          color: 'black!important',
        }}
      />
      <DividerStyled orientation="vertical" flexItem />
      <StatusCard
        candidates={candidatesByStatus?.OFFER_EXTENDED}
        counter={candidatesCountStatus?.OFFER_EXTENDED}
        label="Offer Extended"
        sx={{
          background: 'transparent',
          color: 'black!important',
        }}
      />
      <DividerStyled orientation="vertical" flexItem />
      <StatusCard
        candidates={candidatesByStatus?.OFFER_ACCEPTED}
        counter={candidatesCountStatus?.OFFER_ACCEPTED}
        label="Offer Accepted"
        sx={{
          background: 'transparent',
          color: 'black!important',
        }}
      />
      <StatusCard
        className="coloredCard"
        candidates={candidatesByStatus?.HIRED}
        counter={candidatesCountStatus?.HIRED}
        label="Hired"
        sxLabelCount={{ color: 'white' }}
        sx={{
          background: '#43B92D!important',
          opacity: '.75!important',
          minWidth: '130px',
        }}
        sxLabel={{ color: 'white!important' }}
      />
      <StatusCard
        className="coloredCard"
        candidates={customerRejected}
        counter={
          candidatesCountStatus?.PANEL_INTERVIEW_REJECTED +
          candidatesCountStatus?.SKILLS_ASSESSMENT_REJECTED +
          candidatesCountStatus?.SUBMITTED_CUSTOMER_REJECTED +
          candidatesCountStatus?.CUSTOMER_INTERVIEW_REJECTED +
          // candidatesCountStatus?.OFFER_REJECTION +
          candidatesCountStatus?.DROPPED_OUT
        }
        label="Rejected"
        sxLabelCount={{ color: 'white' }}
        sx={{
          background: '#d32f2f!important',
          opacity: '.75!important',
          marginLeft: '0px!important',
          minWidth: '130px',
        }}
        sxLabel={{ color: 'white!important' }}
      />
    </Box>
  );
};

export default StatusCardsReport;
