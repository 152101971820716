import { CANDIDATEPROJECT_COUNTERS, CANDIDATEPROJECT_STATUS } from 'constants/candidate_project';
import _ from 'lodash';
import customerService from 'services/customerService';
import projectService from 'services/projectService';
import reportService from 'services/reportService';

const { useEffect, useState } = require('react');
const { default: candidateService } = require('services/candidateService');

const useCandidateProjectStatus = (type) => {
  const [projectId, setProjectId] = useState('');
  const [statusSelected, setStatusSelected] = useState('');
  const [candidates, setCandidates] = useState([]);
  const [totalCandidates, setTotalCandidates] = useState(0);
  const initialCounters = {
    PANEL_INTERVIEW: { count: 0, key: 'PANEL_INTERVIEW', projectStatus: 'PANEL_INTERVIEW', orderTab: 0 },
    SKILLS_ASSESSMENT: { count: 0, key: 'SKILLS_ASSESSMENT', projectStatus: 'SKILLS_ASSESSMENT', orderTab: 1 },
    CUSTOMER_STAGE: { count: 0, key: 'CUSTOMER_STAGE', projectStatus: 'CUSTOMER_STAGE', orderTab: 2 },
    OFFER_EXTENDED: { count: 0, key: 'OFFER_EXTENDED', projectStatus: 'OFFER_EXTENDED', orderTab: 3 },
    OFFER_ACCEPTED: { count: 0, key: 'OFFER_ACCEPTED', projectStatus: 'OFFER_ACCEPTED', orderTab: 4 },
    HIRED: { count: 0, key: 'HIRED', projectStatus: 'HIRED', orderTab: 5 },
    REJECTED: { count: 0, key: 'REJECTED', projectStatus: 'REJECTED', orderTab: 6 },
  };
  const source = Object.assign({}, ...Object.entries({ ...CANDIDATEPROJECT_COUNTERS }).map(([k, v]) => ({ [k]: [] })));

  const setCountersReport = (candidates) => {
    let newCounters = { ...initialCounters };
    let total = 0;
    if (candidates)
      Object.keys(candidates)
        .filter((status) => status !== 'ARCHIVED')
        .forEach((key) => {
          if (
            [
              'PANEL_INTERVIEW_REJECTED',
              'SKILLS_ASSESSMENT_REJECTED',
              'SUBMITTED_CUSTOMER_REJECTED',
              'CUSTOMER_INTERVIEW_REJECTED',
              'OFFER_REJECTION',
              'DROPPED_OUT',
            ].includes(key)
          )
            newCounters.REJECTED.count += candidates[key].length;
          else if (['CUSTOMER_INTERVIEW', 'SUBMITTED_CUSTOMER'].includes(key))
            newCounters.CUSTOMER_STAGE.count += candidates[key].length;
          else newCounters[key].count += candidates[key].length;
          total += candidates[key].length;
        });
    return { counters: newCounters, total: total };
  };

  const getStatusArr = () => {
    let status = [];
    let statusProject;
    switch (statusSelected) {
      case 'REJECTED':
        status.push(
          CANDIDATEPROJECT_STATUS['PANEL_INTERVIEW_REJECTED'],
          CANDIDATEPROJECT_STATUS['SKILLS_ASSESSMENT_REJECTED'],
          CANDIDATEPROJECT_STATUS['SUBMITTED_CUSTOMER_REJECTED'],
          CANDIDATEPROJECT_STATUS['CUSTOMER_INTERVIEW_REJECTED'],
          CANDIDATEPROJECT_STATUS['OFFER_REJECTION'],
          CANDIDATEPROJECT_STATUS['DROPPED_OUT']
        );
        statusProject = 'REJECTED';
        break;
      case 'CUSTOMER_STAGE':
        status.push(CANDIDATEPROJECT_STATUS['SUBMITTED_CUSTOMER'], CANDIDATEPROJECT_STATUS['CUSTOMER_INTERVIEW']);
        statusProject = 'CUSTOMER_STAGE';
        break;
      default:
        statusProject = statusSelected;
        break;
    }

    if (!_.includes(['REJECTED', 'CUSTOMER_STAGE'], statusSelected)) status.push(CANDIDATEPROJECT_STATUS[statusProject]);
    return { statusArr: status, statusTab: statusProject };
  };
  const [candidatesCountStatus, setCandidatesCountStatus] = useState(initialCounters);
  const [dataSource, setDataSource] = useState({ candidates: source });
  const sortBy = 'id';

  useEffect(() => {
    (async () => {
      if (projectId !== '' && statusSelected !== '') {
        // candidates
        let src = [];
        const tab = getStatusArr();
        if (!type) {
          const { data } = await candidateService.getCandidatesByProjectStatus(projectId, { status: tab.statusArr, sortBy });
          src = data;
        } else if (type && type === 'report' && dataSource.candidates) {
          const source = _.cloneDeep(dataSource.candidates);
          let candidates = Object.keys({ ...source })
            .filter((key) => key === tab.statusTab)
            .reduce((obj, key) => {
              obj[key] = dataSource.candidates[key];
              return obj;
            }, {});
          let arrCandidates = [];
          if (tab.statusTab === 'REJECTED') {
            arrCandidates = _.concat(
              ...source['PANEL_INTERVIEW_REJECTED'],
              ...source['SKILLS_ASSESSMENT_REJECTED'],
              ...source['SUBMITTED_CUSTOMER_REJECTED'],
              ...source['CUSTOMER_INTERVIEW_REJECTED'],
              ...source['OFFER_REJECTION'],
              ...source['DROPPED_OUT']
            );
          } else if (tab.statusTab === 'CUSTOMER_STAGE') {
            arrCandidates = _.concat(...source['SUBMITTED_CUSTOMER'], ...source['CUSTOMER_INTERVIEW']);
          } else {
            arrCandidates = [...candidates[tab.statusTab]];
          }

          const newArr = await Promise.all(
            arrCandidates.map(async (cand) => {
              const { data } = await customerService.getCustomerFeedback(parseInt(projectId), cand.id);
              return { ...cand, Project_Candidates: [data.Project_Candidates[0]] };
            })
          );

          src = newArr;
        }
        Promise.all([setCandidates(src)]);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusSelected, dataSource]);

  useEffect(() => {
    (async () => {
      if (projectId !== '') {
        // counters
        let countsStatus;
        if (!type) {
          const { data: counters } = await projectService.getProjectCandidatesCount(projectId);
          countsStatus = { ...counters };
          Promise.all([
            setStatusSelected(''),
            setCandidatesCountStatus((prev) => ({
              ...prev,
              PANEL_INTERVIEW: { ...prev['PANEL_INTERVIEW'], count: countsStatus.PANEL_INTERVIEW },
              SKILLS_ASSESSMENT: { ...prev['SKILLS_ASSESSMENT'], count: countsStatus.SKILLS_ASSESSMENT },
              CUSTOMER_STAGE: {
                ...prev['CUSTOMER_STAGE'],
                count: countsStatus.SUBMITTED_CUSTOMER + countsStatus.CUSTOMER_INTERVIEW,
              },
              OFFER_EXTENDED: { ...prev['OFFER_EXTENDED'], count: countsStatus.OFFER_EXTENDED },
              OFFER_ACCEPTED: { ...prev['OFFER_ACCEPTED'], count: countsStatus.OFFER_ACCEPTED },
              HIRED: { ...prev['HIRED'], count: countsStatus.HIRED },
              REJECTED: {
                ...prev['REJECTED'],
                count:
                  countsStatus.PANEL_INTERVIEW_REJECTED +
                  countsStatus.SKILLS_ASSESSMENT_REJECTED +
                  countsStatus.CUSTOMER_INTERVIEW_REJECTED +
                  countsStatus.SUBMITTED_CUSTOMER_REJECTED +
                  countsStatus.OFFER_REJECTION +
                  countsStatus.DROPPED_OUT,
              },
            })),
          ]);
        } else if (type && type === 'report') {
          const { data } = await reportService.getReportOfWeek(projectId);
          const { counters, total } = setCountersReport(data?.candidates || []);
          setDataSource(data ?? source);
          setTotalCandidates(total);
          setCandidatesCountStatus(counters);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  useEffect(() => {
    (async () => {
      const sum = Object.values(candidatesCountStatus).reduce((accumulator, value) => {
        return accumulator + value.count;
      }, 0);
      setTotalCandidates(sum);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [candidatesCountStatus]);

  return {
    initialCounters,
    candidates,
    projectId,
    candidatesCountStatus,
    totalCandidates,
    statusSelected,
    dataSource,
    setStatusSelected,
    setProjectId,
    setCandidates,
  };
};

export default useCandidateProjectStatus;
