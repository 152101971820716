import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import InputTextOutlinedV2 from 'components/FormElement/InputTextOutlinedV2';
import RepeaterControl from 'components/FormElement/RepeaterControl';
import { schemaManagerEmail, schemaManagerName } from '../../../../validations/projects/projectRegister';

const ManagerInput = ({ managers, errors, addMoreManager, removeManager, onHandleChange }) => {
  return (
    <>
      {managers &&
        managers.length > 0 &&
        managers.map((manager, index) => (
          <Grid container key={index} sx={{ marginBottom: 2 }} columnSpacing={2}>
            <Grid item xs={12} md={6}>
              <InputTextOutlinedV2
                id={`managers_${index}_name`}
                size={'normal'}
                label="Project POC Name"
                error={errors.managers && errors.managers[index]?.name && errors.managers[index].name.txt}
                initialValue={manager.name}
                onChangeInput={(e) => onHandleChange(e, schemaManagerName)}
                variant="standard"
                backgroundColor="nexxusGrayExtraLight.main"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputTextOutlinedV2
                id={`managers_${index}_email`}
                size={'normal'}
                label="Project POC Email"
                error={errors.managers && errors.managers[index]?.email && errors.managers[index].email.txt}
                initialValue={manager.email}
                onChangeInput={(e) => onHandleChange(e, schemaManagerEmail)}
                variant="standard"
                backgroundColor="nexxusGrayExtraLight.main"
              />
            </Grid>
          </Grid>
        ))}

      <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'end' }}>
        <RepeaterControl
          elements={managers}
          maxElements={3}
          labelRemove={'REMOVE'}
          labelAdd={'ADD (UP TO 3-)'}
          onHandleIncrement={addMoreManager}
          onHandleDecrement={removeManager}
        />
      </Box>
    </>
  );
};

export default ManagerInput;
