import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Box, Button, Divider, Grid, Stack, Typography, useTheme } from '@mui/material';
import IntakeActionButtons from 'pages/common/customerIntakeForm/intake/intakeActionButtons';
import ProjectSection from 'pages/common/customerIntakeForm/intake/ProjectSection';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { animateScroll as scroller, Element } from 'react-scroll';
import { toast } from 'react-toastify';
import projectService from 'services/projectService';

const OverviewSummary = ({ clientId, projectDB, user }) => {
  const { ProjectId } = useParams();
  const theme = useTheme();
  const [trySubmit, setTrySubmit] = useState(false);
  const [projects, setProjects] = useState([false]);
  const [reset, setReset] = useState(false);
  const [success, setSuccess] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [isRole, setIsRole] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [greetingsMsg, setGreetingsMsg] = useState('');
  const [toastText, setToastText] = useState('');
  const [validating, setValidating] = useState(false);
  const location = useLocation();
  const counts = useRef(0);
  const firstValid = useRef(false);

  const history = useNavigate();

  //init Hook
  useEffect(() => {
    (async () => {})();
  }, []);

  useMemo(() => {
    const pathname = location.pathname.toLowerCase();
    if (pathname.includes('edit')) {
      setIsEditMode(true);
    } else {
      setIsEditMode(false);
    }
  }, [location]);

  useMemo(() => {
    const isRoleAndEdit = () => {
      setGreetingsMsg('Project Modified!');
      setToastText('Project Info Modified!');
    };
    const isRoleAndNoEdit = () => {
      setGreetingsMsg('Project Created!');
      setToastText('Project Info Submitted!');
    };
    const isNotRoleAndEdit = () => {
      setGreetingsMsg('Project Modified!');
      setToastText('Project Info Modified!');
    };
    const isNotRoleAndNoEdit = () => {
      setGreetingsMsg('Project Created!');
      setToastText('Project Info Submitted!');
    };

    isRole && isEditMode && isRoleAndEdit();
    isRole && !isEditMode && isRoleAndNoEdit();
    !isRole && isEditMode && isNotRoleAndEdit();
    !isRole && !isEditMode && isNotRoleAndNoEdit();
  }, [isRole, isEditMode]);

  /* eslint-disable */
  useEffect(() => {
    const role = user.role;
    if (role === 'superCustomer') {
      setIsRole(true);
    } else {
      setIsRole(false);
    }
  });
  /* eslint-enable */

  useEffect(() => {
    if (projects.length > 0 && firstValid.current === true) {
      counts.current += 1;
      const pjs = projects.filter((pj) => pj === false || pj === null);
      firstValid.current = true;

      if (reset === false && pjs.length === 0) {
        return setOpenModal(true);
      }
      if (counts.current === projects.length) {
        counts.current = 0;
        setTrySubmit(false);
        setValidating(false);
      }
    }
    firstValid.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projects]);

  const onAcceptProjInfModal = async (e, reason) => {
    if (projects.length <= 0) {
      return;
    }
    const pjs = projects.filter((pj) => pj === false);

    if (reset !== false || pjs.length !== 0) {
      setReset(false);
      return;
    }
    try {
      if (!!ProjectId) {
        await projectService.patchProject(projects[0], ProjectId);
      } else {
        await Promise.all(
          projects.map(async (project) => {
            await projectService.registerProject({ ...project, ClientId: clientId });
          })
        );
      }

      setReset(true);
      setProjects([false]);
      setSuccess(true);
      toast.success(toastText);
      scroller.scrollTo('anchorElement', { duration: 500, delay: 50, smooth: true, offset: 0 });
    } catch (error) {
      if (error.response && error.response.data) {
        return toast.error(error.response.data.message);
      }
    }
  };

  return (
    <>
      {success === false && (
        <Grid container spacing={2}>
          <Element name="anchorElement"></Element>
          <TitlePage isRole={isRole} isEdit={isEditMode}></TitlePage>
          <Divider sx={{ my: 4 }}></Divider>
          <ProjectSection
            trySubmit={trySubmit}
            projects={projects}
            projectDB={projectDB}
            onHandleError={() => {
              setTrySubmit(false);
              setReset(false);
            }}
            onHandleValidation={(numberProject, fields) => {
              const pjs = [...projects];
              pjs[numberProject - 1] = fields;
              Promise.all([setTrySubmit(false), setProjects(pjs)]);
            }}
            reset={reset}
          />

          <Grid item xs={12} sx={{ [theme.breakpoints.down('md')]: { pt: '0!important', mt: 0 }, mt: 4 }}>
            <IntakeActionButtons
              projects={projects}
              openModal={openModal}
              isEdit={isEditMode}
              callbackSave={onAcceptProjInfModal}
              // setValid={setValid}
              onHandleAddProjects={(projs) => {
                counts.current += 1;
                setProjects(projs);
              }}
              onHandleProjects={(projs) => {
                counts.current -= 1;
                setProjects(projs);
              }}
              setTrySubmit={setTrySubmit}
              setValidating={setValidating}
              isValidating={validating}
            />
          </Grid>
        </Grid>
      )}

      {success === true && (
        <Stack direction="column" justifyContent="space-between" sx={{ my: 8, textAlign: 'center', py: 6, px: 12 }}>
          {/* <Typography variant="h1" sx={{ fontWeight: 900, fontSize: '3em', color: '#b7b7b7' }}>
            Form saved success!
          </Typography> */}
          <Box sx={{ textAlign: 'center', py: 4 }}>
            <CheckCircleOutlineIcon sx={{ color: 'nexxusGreen.main', fontSize: '6em' }}></CheckCircleOutlineIcon>
          </Box>
          <Typography variant="h1" sx={{ fontWeight: 900, fontSize: '3em', color: 'nexxusGreen.main' }}>
            {greetingsMsg}
          </Typography>

          <Button
            variant="text"
            sx={{ mt: 4, '&:hover': { background: 'none', textDecoration: 'underline' } }}
            onClick={() => {
              history('/customer/dashboard');
            }}
          >
            <Typography display="flex" sx={{ fontWeight: 'bold', fontSize: '1.75em', color: '#009eff' }}>
              RETURN TO DASHBOARD
            </Typography>
          </Button>
        </Stack>
      )}
    </>
  );
};

const TitlePage = ({ isRole, isEdit }) => {
  const [titleName, setTitleName] = useState('');

  useMemo(() => {
    if (isEdit) {
      setTitleName('EDIT PROJECT');
    } else {
      setTitleName('CREATE A PROJECT');
    }
  }, [isEdit]);

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
        <Typography
          sx={{
            fontSize: '22pt',
            fontWeight: 'bold',
            textAlign: 'left',
            marginBottom: 1,
          }}
        >
          {titleName}
        </Typography>
      </Box>
    </>
  );
};

export default OverviewSummary;
