import { useCallback, useEffect, useState } from 'react';

import candidateService from '../../services/candidateService';

export default function useCandidates({
  filters,
  pagination = {},
  runAtStart = false,
  runOnChange = false,
  only_blacklisted,
  only_staff,
}) {
  const [candidates, setCandidates] = useState([]);
  const [error, setError] = useState(false);
  const [hasFetched, setHasFetched] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const fetchCandidates = useCallback(async () => {
    try {
      setIsLoading(true);
      const { data } = await candidateService.getCandidates({ ...filters, ...pagination }, only_blacklisted, only_staff);
      setCandidates(data);
      setIsLoading(false);
    } catch (error) {
      setError(true);
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, pagination]);

  const refetch = useCallback(() => {
    setCandidates([]);
    fetchCandidates();
  }, [fetchCandidates]);

  useEffect(() => {
    if ((runAtStart && !hasFetched) || runOnChange) {
      fetchCandidates();
      setHasFetched(true);
    }
  }, [fetchCandidates, hasFetched, runAtStart, runOnChange]);

  return { candidates, isLoading, error, refetch };
}
