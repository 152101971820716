import { createSlice } from '@reduxjs/toolkit';

let user = JSON.parse(localStorage.getItem('currentUser'));
let tokens = JSON.parse(localStorage.getItem('tokens'));
let lastLogin = localStorage.getItem('lastLogin');

const initialState = user ? { loggedIn: true, user, tokens, lastLogin } : {};

const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    loginRequest: (state, action) => {
      return {
        loggingIn: true,
        user: action.payload.user,
      };
    },
    loginSuccess: (state, action) => {
      return {
        ...state,
        loggingIn: true,
        user: action.payload.user,
        tokens: action.payload.tokens,
        lastLogin: action.payload.lastLogin,
      };
    },
    loginExpire: (state, action) => {
      return state;
    },
    loginFailure: () => {
      return {};
    },
    logout: () => {
      return {};
    },
  },
});

export const { loginRequest, loginSuccess, loginFailure, loginExpire, logout } = authSlice.actions;
export default authSlice.reducer;
