import { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { loginExpire, logout } from '../store/auth';
import { useConstructor } from '../utils/useConstructor';

const PrivateRoute = ({ children: ChildrenComponent, ...props }) => {
  const [navigateTo, setNavigateTo] = useState('');
  useConstructor(() => {});
  props.loginExpire();
  const currentUser = useSelector((state) => state.auth.user);
  const location = useLocation();

  useEffect(() => {
    (async () => {})();
  }, []);

  useEffect(() => {
    if (['/403', '/404'].includes(location.pathname)) {
      setNavigateTo('/login');
    } else {
      const uriWord = `${location.pathname}${location.search}`;
      const encodedUriWord = encodeURIComponent(uriWord);
      setNavigateTo(`/login?returnUrl=${encodedUriWord}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return currentUser ? (
    ChildrenComponent
  ) : (
    <>
      <Navigate to={navigateTo} state={{ from: location }} />
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return {
    loginExpire: (user) => {
      dispatch(loginExpire(user));
    },
    logout: () => {
      dispatch(logout());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
