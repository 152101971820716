import { Typography } from '@mui/material';
import CandidateResultTable from 'pages/tas/candidateSearch/CandidateResultTable';
import FeaturedCandidates from 'pages/tas/candidateSearch/FeaturedCandidaters';
import PropTypes from 'prop-types';
import CandidateTableFilters from './CandidateTableFilters';

const CandidateResultContent = (props) => {
  return (
    <>
      <CandidateTableFilters showIncludeBox={props.showIncludeBox} />
      {!props.only_staff && (
        <Typography
          variant="subtitle"
          align="center"
          sx={{
            mt: 2,
            mb: 8,
          }}
        >
          Featured Candidates - Based on Your Search Criteria
        </Typography>
      )}
      {!props.only_staff && (
        <FeaturedCandidates auth={props.auth} only_blacklisted={props.only_blacklisted} only_staff={props.only_staff} />
      )}

      <CandidateResultTable auth={props.auth} only_blacklisted={props.only_blacklisted} only_staff={props.only_staff} />
    </>
  );
};

CandidateResultContent.propTypes = {
  showIncludeBox: PropTypes.bool,
  only_blacklisted: PropTypes.bool,
};

export default CandidateResultContent;
