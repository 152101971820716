import Joi from 'joi';
import { setValidationMessages } from 'utils/validationMessages';
import { FormHook } from '../../../../hooks/form/Form';
import { schemaProject } from '../../../../validations/projects/projectRegister';

export const projectInitialState = {
  status: '',
  name: '',
  description: '',
  position: '',
  seniority: '',
  teamsize: '',
  RecruiterId: null,
  managers: [{ name: '', email: '' }],
  timezone: '',
  // fee: '',
  customer_job_link: '',
  codetest_link: '',
  maxrate: '',
  monthly_mxn: '0',
  monthly_usd: 0,
  holidays: '',
  hardware_needs: [],
  hardware_notes: '',
  interview_process: '',
  newSkills: [{ SkillId: '', skill_type: 1, yearExperience: '' }],
  adittionalSkills: [{ SkillId: '', skill_type: 2, yearExperience: '' }],
  working_hours_start: '',
  working_hours_end: '',
  working_hours: {
    monday: {
      active: '',
      start: '',
      end: '',
    },
  },
};

export default function ProjectFields(data = projectInitialState) {
  return FormHook(data, {
    ...schemaProject,
    RecruiterId: Joi.optional().allow(''),
    hardware_notes: Joi.optional().allow(''),
    description: Joi.optional().allow(''),
    job_description_link: Joi.string()
      .required()
      .messages(setValidationMessages(['string', 'required'])),
  });
}
