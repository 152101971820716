import { connect } from 'react-redux';
import { loginExpire } from '../../store/auth';

import CustomerIntakeForm from 'pages/common/customerIntakeForm/CustomerIntakeForm';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import projectService from 'services/projectService';
import MainLayout from '../../components/MainLayout/MainLayout';

const CustomerIntake = (props) => {
  const { ProjectId } = useParams();
  const history = useNavigate();
  const [project, setProject] = useState({});

  useEffect(() => {
    (async () => {
      if (!!ProjectId) {
        try {
          const { data } = await projectService.getProject(ProjectId);
          setProject(data);
        } catch (e) {
          history('/404');
        }
      }
    })();
  }, [ProjectId]); // eslint-disable-line

  return (
    <MainLayout {...props} current="customers">
      <CustomerIntakeForm project={project}></CustomerIntakeForm>
    </MainLayout>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return {
    loginExpire: (user) => {
      dispatch(loginExpire(user));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerIntake);
