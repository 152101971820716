import React, { useState } from 'react';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PersonIcon from '@mui/icons-material/Person';
import {
  Box,
  Button,
  Container,
  FormControl,
  FormHelperText,
  FormLabel,
  InputAdornment,
  Link,
  OutlinedInput,
  Stack,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import Joi from 'joi';
import { Link as RouterLink } from 'react-router-dom';
import envelope from '../../assets/img/envelope.png';
import AuthSkeleton from '../../components/Auth/AuthSkeleton';
import authService from '../../services/authService';
import { setValidationMessages } from '../../utils/validationMessages';

const ForgotPassword = () => {
  const ButtonStyle = styled(Button)(({ theme }) => ({
    textTransform: 'none',
    fontSize: 16,
    fontWeight: 'bold',
    color: '#fff',
    backgroundColor: theme.palette.primary.main,
  }));

  const [email, setEmail] = React.useState('');
  const [errors, setErrors] = React.useState({});
  const [sent, setSent] = useState(false);
  const [sending, setSending] = useState(false);

  const schema = Joi.object({
    email: Joi.string()
      .required()
      .email({ minDomainSegments: 2, tlds: { allow: ['com', 'net'] } })
      .messages(setValidationMessages(['string', 'required'])),
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSending(true);
    try {
      Joi.assert({ email }, schema, { abortEarly: false, allowUnknown: true });
      await authService.forgotPassword(email);
      setSent(true);
      setSending(false);
      setEmail('');
      setErrors({});
    } catch (error) {
      if (error.details) {
        setSending(false);
        return setErrors({ email: error.details[0].message });
      }
      setErrors({ email: error.response.data.message });
      setSending(false);
    }
  };

  const emailSentComponent = (
    <Box
      component={motion.div}
      animate={{ scale: [0, 1], opacity: [0, 1] }}
      transition={{
        scale: { type: 'spring', stiffness: 100, duration: 0.5 },
        opacity: { type: 'spring', stiffness: 100, duration: 1 },
      }}
    >
      <Box display="flex" justifyContent="center" my={4}>
        <img src={envelope} alt="" className="" width={100} />
      </Box>
      <Typography sx={{ fontSize: 24, fontWeight: 'bold', marginTop: 1, marginBottom: 2, color: 'nexxusGray.main' }}>
        CHECK YOUR EMAIL
      </Typography>
      <Typography sx={{ fontSize: 16, color: 'nexxusGray.main', textAlign: 'center', paddingX: 8, marginBottom: 2 }}>
        We have sent password recovery instructions to your email.
      </Typography>
      <Button component={RouterLink} to="/login" variant="contained" color="primary">
        Go to Login
      </Button>
      <Typography variant="body2" mt={3}>
        Did not receive the email? Check your spam filter, or&nbsp;
        <Link
          component="button"
          variant="body2"
          fontWeight="bold"
          onClick={(e) => {
            e.preventDefault();
            setSent(false);
          }}
        >
          try another email address
        </Link>
      </Typography>
    </Box>
  );

  const Spinner = () => <div className="loader"></div>;

  const emailFormComponent = (
    <>
      <Container component="form" onSubmit={handleSubmit}>
        <Box sx={{ marginTop: 1, marginLeft: 2 }}>
          <RouterLink
            to="/login"
            color="black"
            style={{ textDecoration: 'none', display: 'flex', alignItems: 'center', color: 'black' }}
          >
            <ArrowBackIcon sx={{ marginRight: 1 }} />
            BACK
          </RouterLink>
        </Box>
        <Typography variant="h6" my={3}>
          RESET PASSWORD
        </Typography>
        <Typography variant="body1" my={2}>
          Enter the email associated with your account and we'll send an email with instructions to reset your password.
        </Typography>
        <Stack spacing={2} mt={5} maxWidth={300} mx="auto">
          <FormControl>
            <FormLabel>Email Address</FormLabel>
            <OutlinedInput
              value={email}
              onChange={(e) => setEmail(e.currentTarget.value)}
              size="small"
              error={!!errors.email}
              startAdornment={
                <InputAdornment position="start">
                  <PersonIcon color={classNames(!errors.email ? 'nexxusBlue' : 'warning')} fontSize="small" />
                </InputAdornment>
              }
            />
            {errors.email && (
              <FormHelperText error id="accountId-error">
                {errors.email}
              </FormHelperText>
            )}
          </FormControl>
          <ButtonStyle variant="contained" type={sending ? 'button' : 'submit'} fullWidth>
            {sending ? 'Sending' : 'Send'}
            {sending && <Spinner />}
          </ButtonStyle>
        </Stack>
      </Container>
    </>
  );

  return <AuthSkeleton>{sent === false ? emailFormComponent : emailSentComponent}</AuthSkeleton>;
};

export default ForgotPassword;
