import { useCustomers, useProjects } from 'hooks/request';
import useBreadcrumbs from 'hooks/useBreadcrumbs';
import { useEffect, useState } from 'react';
import { CLIENTSTATUS, PROJECT_STATUS } from 'utils/staticData';

const useManageProjects = (filters) => {
  const [filterArchived, setFilterArchived] = useState({
    client: '',
    recruiters: [],
    position: '',
    technology: '',
    status: PROJECT_STATUS.ARCHIVED,
    excludeStatus: '',
    page: '',
    limit: '',
  });

  const [client, setClient] = useState({});
  const [countProjects, setCountProjects] = useState({ URGENT: 0, ACTIVE: 0, ARCHIVED: 0 });
  const projects = useProjects({ filters: filters });
  const projectsArchived = useProjects({ filters: filterArchived });

  useEffect(() => {
    (async () => {
      if (filters.client === '') setClient({});
      else {
        const clientSelected = customers.find((c) => c.id === filters.client);
        setClient(clientSelected);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.client]);

  useEffect(() => {
    (async () => {
      const counts = { URGENT: 0, ACTIVE: 0, ARCHIVED: 0 };
      if (projects.total !== 0) {
        projects.rows.forEach((pj) => {
          if (pj.status === PROJECT_STATUS.ACTIVE) counts.ACTIVE++;
          if (pj.status === PROJECT_STATUS.URGENT) counts.URGENT++;
        });
      }
      setCountProjects((prev) => ({ ...prev, ACTIVE: counts.ACTIVE, URGENT: counts.URGENT }));
    })();
  }, [projects]);

  useEffect(() => {
    (async () => {
      setCountProjects((prev) => ({ ...prev, ARCHIVED: projectsArchived.rows.length }));
    })();
  }, [projectsArchived]);

  const totalSize = 100;
  const { customers } = useCustomers({ excludeStatus: [CLIENTSTATUS.ARCHIVED] });

  const { setBreadcrumbs } = useBreadcrumbs();

  useEffect(() => {
    setBreadcrumbs([
      {
        name: 'Dashboard',
        url: '/tas/dashboard',
      },
      {
        name: 'Projects',
      },
    ]);
  }, [setBreadcrumbs]);

  return {
    client,
    customers,
    totalSize,
    countProjects,
    projects,
    projectsArchived,
    setFilterArchived,
  };
};

export default useManageProjects;
