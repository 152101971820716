import { useCallback, useEffect, useState } from 'react';
import candidateService from 'services/candidateService';
import { capitalizeString } from 'utils/utils';

export default function useCustomerFeedback(candidateId) {
  const [customerFeedback, setCustomerFeedback] = useState([]);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const fetchCustomerFeedback = useCallback(async () => {
    try {
      setIsLoading(true);
      const { data } = await candidateService.getCandidateFeedback(candidateId);

      const feeds = [];
      data[0]?.Project_Candidates.forEach((pj) => {
        Object.keys(pj.customer_feedback).forEach((key) => {
          if (pj.customer_feedback[key].feedback && pj.customer_feedback[key].feedback !== '') {
            feeds.push({
              customer: pj.Project?.Client?.companyName || '',
              feedback: pj.customer_feedback[key].feedback,
              date: pj.customer_feedback[key].dateStatus,
              project: capitalizeString(pj.Project.position),
            });
          }
        });
      });
      setCustomerFeedback(feeds);

      setIsLoading(false);
    } catch (error) {
      setError(true);
      setIsLoading(false);
    }
  }, [candidateId]);

  const refetch = useCallback(() => {
    setCustomerFeedback([]);
    fetchCustomerFeedback();
  }, [fetchCustomerFeedback]);

  useEffect(() => {
    const asyncFetch = async () => {
      await fetchCustomerFeedback();
    };
    asyncFetch();
  }, [fetchCustomerFeedback]);

  return { customerFeedback, isLoading, error, refetch };
}
