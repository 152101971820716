import { Box, Typography } from '@mui/material';
import MainLayout from 'components/MainLayout/MainLayout';
import DuplicateCandidatesGrid from 'pages/import/DuplicateCandidates/DuplicateCandidatesGrid';

const DuplicateCandidates = (props) => {
  return (
    <MainLayout {...props} current="dashboard">
      <Box pt={2} pb={2}>
        <Typography variant="h4" textTransform="uppercase">
          Duplicate <strong>Candidates</strong>
        </Typography>

        <DuplicateCandidatesGrid />
      </Box>
    </MainLayout>
  );
};

export default DuplicateCandidates;
