import { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box, Grid, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Stack from '@mui/material/Stack';
import SvgIcon from '@mui/material/SvgIcon';

import InputProfileImage from '../../../components/FormElement/InputProfileImage';
import { CLIENTSTATUS, clientStatusOptions, timezoneOpts } from '../../../utils/staticData';

import CreateClientFields from './ClientFields';
import FormCounters from './form/FormCounters';

import { schemaClient, schemaCustomerManager, schemaCustomerStakeholder } from '../../../validations/clients/clientRegister';

import clientService from '../../../services/clientService';

import { ReactComponent as NotifyIcon } from '../../../assets/icons/notify-icon.svg';
import { ReactComponent as EditIcon } from '../../../assets/icons/project-edit-icon.svg';

import InputSelectV2 from 'components/FormElement/InputSelectV2';
import InputTextOutlinedV2 from 'components/FormElement/InputTextOutlinedV2';
import RepeaterControl from 'components/FormElement/RepeaterControl';
import ModalFeedback from 'components/Generic/Modal/ModalFeedback';
import { ROLE_SUPERTAS } from 'constants/users';
import { useCustomers } from 'hooks/request';

const ClientForm = ({ CustomerId, isDisabled, mode, onValidForm, onChangeMode, onUpdateCounter, auth: { user } }) => {
  const [submitting, setSubmitting] = useState(false);
  const [logoUrl, setLogoUrl] = useState('');
  const [logoUrlPreview, setLogoUrlPreview] = useState('');
  const [files, setFiles] = useState([]);
  const { customers } = useCustomers();

  const [initialManagers, setInitialManagers] = useState([]);
  const [counters, setCounters] = useState({
    candidatesCountDenied: 0,
    candidatesCountHired: 0,
    candidatesCountOffered: 0,
  });
  const [open, setOpen] = useState(false);

  const {
    fields,
    initialStateRequest,
    setInitialStateRequest,
    handleSave,
    setValues,
    handleSubmit,
    errors,
    validSubmit,
    resetForm,
    setErrors,
    setForm,
    initialState,
    handleSaveRepeaterProp,
  } = CreateClientFields();
  const [headerText, setHeaderText] = useState('');
  const [titleText, setTitleText] = useState('');

  useMemo(() => {
    setHeaderText('New Customer');
    setTitleText('Do you want to create a new customer?');

    if (mode === 'edit') {
      setHeaderText('Edit Customer');
      setTitleText('Do you want to edit this customer?');
    }
  }, [mode]);

  const setDataCustomer = (rows) => {
    return {
      companyName: rows.companyName,
      // address: rows.address,
      // phone: rows.phone,
      logoUrl: rows.logoUrl,
      websiteUrl: rows.websiteUrl,
      timezone: rows.timezone,
      first_name: rows.first_name,
      last_name: rows.last_name,
      status: rows.status,
      notes: rows.notes,
      managers: rows.managers,
    };
  };

  // init Hook
  useEffect(() => {
    (async () => {
      if (CustomerId) {
        const { data } = await clientService.getClient(CustomerId);
        const newData = setDataCustomer(data);

        onUpdateCounter({ ...data.counters });

        await Promise.all([
          setCounters({ ...data.counters }),
          setInitialManagers([...data.managers]),
          setInitialStateRequest({ ...initialState, ...newData }),
          setLogoUrl(data.logoUrl),
          setValues({ ...initialState, ...newData }),
        ]);
      } else {
        await Promise.all([setLogoUrl(''), setLogoUrlPreview(''), setForm({ ...initialState })]);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [CustomerId]);

  useEffect(() => {
    const uploadFiles = async (newData) => {
      if (files.length <= 0) return;
      for (const [, value] of Object.entries(files)) {
        const formData = new FormData();
        if (value.file) {
          formData.append('id', 'avatar_url');
          formData.append('avatar_url', value.file, value.file.name);
          const {
            data: { file },
          } = await clientService.uploadClientLogoImage(formData, 'avatar_url');
          newData['logoUrl'] = file;
        }
      }
    };
    (async () => {
      if (!(submitting && validSubmit)) return;
      setErrors({});
      const newData = { ...fields };
      try {
        await uploadFiles(newData);
        if (!!!CustomerId) {
          const { data } = await clientService.createClient(newData);
          const clientID = data.id;
          onValidForm(clientID);
          return;
        }

        await clientService.updateClient(CustomerId, newData);
        toast.success('Customer updated!');

        await Promise.all([setSubmitting(false), setInitialStateRequest({ ...initialState, ...newData }), setFiles([])]);
      } catch (error) {
        setSubmitting(false);
        if (error.response?.data) {
          const repeatedCustomer = customers.find(
            (customer) => customer.companyName.toLowerCase() === fields.companyName.toLowerCase()
          );
          if (repeatedCustomer) return toast.error('Repeated Customer Company Name');
          return toast.error(error.response.data.message);
        }
      }
    })();
  }, [submitting]); // eslint-disable-line

  useEffect(() => {
    validSubmit ? setOpen(true) : setOpen(false);
  }, [validSubmit]);

  const onCloseModal = (event, reason) => {
    event?.preventDefault();
    setOpen(false);
  };

  const onAccept = (e, reason) => {
    setSubmitting(true);
    setOpen(false);
  };

  const handleDrop = (files) => {
    if (files.length > 1 || !files[0].name) return;
    setFiles([{ file: files[0], name: [files[0].name] }]);
    setLogoUrlPreview(URL.createObjectURL(files[0]));
  };

  const renderViewStatus = () => {
    let statusText;
    if (parseInt(fields.status) === CLIENTSTATUS.ACTIVE) {
      statusText = 'ACTIVE';
    } else {
      statusText = 'ARCHIVED';
    }

    return (
      <Typography
        sx={{
          fontSize: '16px',
          fontWeight: 'bold',
          color: parseInt(fields.status) === CLIENTSTATUS.ACTIVE ? 'nexxusGreenMid.main' : 'nexxusRed.main',
          marginRight: 2,
        }}
      >
        STATUS: {statusText}
      </Typography>
    );
  };

  const discardChanges = async () => {
    const initial = { ...initialStateRequest };
    initial.managers = initialManagers;
    await Promise.all([
      setLogoUrl(initial['logoUrl']),
      setLogoUrlPreview(initial['logoUrl']),
      setFiles([]),
      setValues(!!CustomerId ? initial : initialState),
    ]);
  };

  const setAvatar = () => {
    return logoUrlPreview || logoUrl;
  };

  return (
    <>
      <Grid container>
        <ModalFeedback
          variant="info"
          headerText={headerText}
          titleText={titleText}
          open={open}
          onCancel={onCloseModal}
          onClose={onCloseModal}
          onAccept={onAccept}
        />
        <Grid item xs={4}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', height: '100%' }}>
            <InputProfileImage
              onHandleDrop={handleDrop}
              avatar={setAvatar()}
              isCompany
              onHandleDelete={(e) => {
                setLogoUrl('');
                setLogoUrlPreview('');
                setValues({ ...fields, logoUrl: '' });
              }}
              isDisabled={isDisabled}
            />
            {typeof mode !== 'undefined' && (
              <Box sx={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column', marginLeft: 4 }}>
                <Typography
                  sx={{
                    fontSize: '16px',
                    fontWeight: 'bold',
                    textAlign: 'left',
                  }}
                >
                  {fields.companyName}
                </Typography>
                {/* <Typography
                  sx={{
                    fontSize: '14px',
                    textAlign: 'left',
                  }}
                >
                  {fields.address}
                </Typography>
                <Typography
                  sx={{
                    fontSize: '14px',
                    textAlign: 'left',
                  }}
                >
                  {fields.phone}
                </Typography> */}
                {fields.websiteUrl && fields.websiteUrl !== '' && (
                  <Button
                    LinkComponent="a"
                    href={fields.websiteUrl}
                    variant="text"
                    target="_blank"
                    sx={{ px: 0, '&:hover': { backgroundColor: 'white' } }}
                  >
                    <Typography
                      display="flex"
                      sx={{
                        color: 'nexxusBlueExtraLight.main',
                        fontSize: 14,
                        fontWeight: 'bold',
                        textAlign: 'left',
                        textDecoration: 'none',
                      }}
                    >
                      VISIT WEBSITE
                    </Typography>
                  </Button>
                )}
              </Box>
            )}
          </Box>
        </Grid>
        <Grid item xs={8}>
          <Grid container alignItems="center" justifyContent="flex-end" sx={{ pb: 2 }}>
            {/* customer viewmode */}
            {CustomerId !== 'undefined' && mode === 'view' && (
              <Grid item xs={12}>
                <Box
                  sx={{ minHeight: '55px', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', padding: 0 }}
                >
                  {renderViewStatus()}
                  {[ROLE_SUPERTAS].includes(user.role) && (
                    <>
                      <Button
                        variant="outlined"
                        size="small"
                        color="error"
                        onClick={onChangeMode}
                        sx={{ borderRadius: 0 }}
                        startIcon={
                          <SvgIcon component={EditIcon} inheritViewBox sx={{ color: 'white', fontSize: 14 }}></SvgIcon>
                        }
                      >
                        <Typography
                          sx={{
                            fontSize: '14px',
                            fontWeight: 'bold',
                          }}
                        >
                          edit customer
                        </Typography>
                      </Button>
                      <Button
                        variant="outlined"
                        size="small"
                        color="error"
                        sx={{ borderRadius: 0, marginLeft: 1 }}
                        startIcon={
                          <SvgIcon component={NotifyIcon} inheritViewBox sx={{ color: 'white', fontSize: 14 }}></SvgIcon>
                        }
                      >
                        <Typography
                          sx={{
                            fontSize: '14px',
                            fontWeight: 'bold',
                          }}
                        >
                          email customer
                        </Typography>
                      </Button>
                    </>
                  )}
                </Box>
              </Grid>
            )}

            {/* customer create/edit mode */}
            {(typeof CustomerId === 'undefined' || CustomerId) && (typeof mode === 'undefined' || mode === 'edit') && (
              <Grid item xs={12}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                  {mode === 'edit' && (
                    <Typography
                      sx={{
                        fontWeight: 'bold',
                        color: 'nexxusRed.main',
                        fontSize: '10px',
                        pr: 2,
                      }}
                    >
                      YOU ARE NOW EDITING {fields.companyName}
                    </Typography>
                  )}
                  <Typography
                    sx={{
                      fontWeight: 'bold',
                      color: 'nexxusGrayMid.main',
                      fontSize: '14px',
                      pr: 2,
                    }}
                  >
                    CUSTOMER STATUS
                  </Typography>
                  <InputSelectV2
                    id="status"
                    size={'small'}
                    error={errors.status?.txt}
                    initialValue={fields.status}
                    opts={clientStatusOptions}
                    onChangeInput={(e) => handleSave(e)}
                    fullWidth={false}
                    sx={{ minWidth: '200px' }}
                  />
                </Box>
              </Grid>
            )}
          </Grid>
          <Stack direction="row" spacing={2} justifyContent="flex-end" alignItems="center">
            {/* Counter Projects */}
            <FormCounters counters={counters} mode={mode} />
          </Stack>
        </Grid>
      </Grid>
      <Divider sx={{ borderColor: 'nexxusGrayMid.main', marginY: 3 }}></Divider>
      <Box>
        <Grid
          container
          spacing={2}
          component="form"
          id={CustomerId}
          onSubmit={(e) => handleSubmit(e, schemaClient)}
          sx={{ mb: 4 }}
        >
          <Grid item xs={12} sm={6} md={4}>
            <InputTextOutlinedV2
              id={'companyName'}
              size={'normal'}
              label="COMPANY NAME"
              error={errors.companyName?.txt}
              initialValue={fields.companyName}
              onChangeInput={(e) => handleSave(e, initialStateRequest?.companyName)}
              variant="standard"
              backgroundColor="nexxusGrayExtraLight.main"
              isDisabled={isDisabled}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <InputTextOutlinedV2
              id={'websiteUrl'}
              size={'normal'}
              label="COMPANY WEBSITE"
              error={errors.websiteUrl?.txt}
              initialValue={fields.websiteUrl}
              onChangeInput={(e) => handleSave(e)}
              variant="standard"
              backgroundColor="nexxusGrayExtraLight.main"
              isDisabled={isDisabled}
              type="url"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <InputSelectV2
              id={'timezone'}
              size={'normal'}
              label="TIMEZONE"
              opts={timezoneOpts}
              error={errors.timezone?.txt}
              initialValue={fields.timezone}
              onChangeInput={(e) => handleSave(e)}
              variant="standard"
              backgroundColor="nexxusGrayExtraLight.main"
              isDisabled={isDisabled}
            />
          </Grid>
          <Grid item xs={12} sm={8}>
            <Grid container spacing={2} columnSpacing={2}>
              <Grid item xs={6}>
                <FormControl fullWidth sx={{ textAlign: 'left' }}>
                  <FormLabel>EXECUTIVE STAKEHOLDERS</FormLabel>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth sx={{ textAlign: 'left' }}>
                  <FormLabel>EXECUTIVE STAKEHOLDERS EMAILs</FormLabel>
                </FormControl>
              </Grid>
            </Grid>
            {fields.managers.length > 0 &&
              fields.managers.map((manager, index) => (
                <Grid container spacing={0} sx={{ marginBottom: 1 }} key={index} columnSpacing={2}>
                  <Grid item xs={6} sx={{ pt: 0 }}>
                    <InputTextOutlinedV2
                      id={`managers_${index}_stakeholder`}
                      size={'normal'}
                      error={
                        errors.managers && errors.managers[index]?.stakeholder && errors.managers[index].stakeholder.txt
                      }
                      initialValue={manager.stakeholder}
                      onChangeInput={(e) => handleSaveRepeaterProp(e, schemaCustomerStakeholder)}
                      variant="standard"
                      backgroundColor="nexxusGrayExtraLight.main"
                      isDisabled={isDisabled}
                      styleControl={{ pt: 0 }}
                    />
                  </Grid>
                  <Grid item xs={6} sx={{ pt: 0 }}>
                    <InputTextOutlinedV2
                      id={`managers_${index}_email`}
                      size={'normal'}
                      error={errors.managers && errors.managers[index]?.email && errors.managers[index].email.txt}
                      initialValue={manager.email}
                      onChangeInput={(e) => handleSaveRepeaterProp(e, schemaCustomerManager)}
                      removeBlankSpaces={true}
                      variant="standard"
                      backgroundColor="nexxusGrayExtraLight.main"
                      isDisabled={isDisabled}
                    />
                  </Grid>
                </Grid>
              ))}

            {mode !== 'view' && (
              <Box sx={{ mt: 2 }}>
                <RepeaterControl
                  elements={fields.managers}
                  maxElements={4}
                  labelRemove={'REMOVE'}
                  labelAdd={'ADD ANOTHER'}
                  onHandleIncrement={() => {
                    const newFields = { ...fields };
                    newFields.managers.push({ stakeholder: '', email: '' });
                    setValues((prev) => ({ ...prev, managers: newFields.managers }));
                  }}
                  onHandleDecrement={() => {
                    const newFields = { ...fields };
                    setValues((prev) => ({ ...prev, managers: newFields.managers.slice(0, -1) }));
                  }}
                />
              </Box>
            )}
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputTextOutlinedV2
              id={'notes'}
              size={'normal'}
              label="ADDITIONAL NOTES OR COMMENTS"
              multiline
              rowsMultiline={7}
              error={errors.notes?.txt}
              initialValue={fields.notes}
              onChangeInput={(e) => handleSave(e)}
              variant="standard"
              backgroundColor="nexxusGrayExtraLight.main"
              styleControl={{
                '& fieldset': {
                  maxHeight: '190px',
                },
                '& .MuiInputBase-root': {
                  padding: '11.5px 14px!important',
                },
              }}
              isDisabled={isDisabled}
            />
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: 1,
              }}
            >
              <Typography
                sx={{
                  fontSize: '9px',
                  fontWeight: 'bold',
                  textAlign: 'left',
                  color: 'nexxusGray.main',
                  marginRight: 2,
                }}
              >
                DON'T FORGET TO SAVE YOUR CHANGES
              </Typography>
              <Button
                variant="contained"
                sx={{ height: '30px', borderRadius: 0, paddingX: 1 }}
                color="nexxusBlue"
                type="submit"
                startIcon={<CheckCircleIcon sx={{ color: 'white', marginLeft: 2, fontSize: 4 }} />}
                disabled={isDisabled === true}
                onClick={(e) => {}}
              >
                <Typography
                  sx={{
                    fontSize: '12px',
                    fontWeight: 'bold',
                    paddingRight: 1,
                    color: 'white',
                    marginTop: '4px',
                  }}
                >
                  save changes
                </Typography>
              </Button>
              <Typography
                sx={{
                  fontSize: '9px',
                  fontWeight: 'bold',
                  textAlign: 'left',
                  color: 'nexxusGray.main',
                  mx: 2,
                }}
              >
                OR
              </Typography>
              <Button
                variant="action"
                color="warning"
                disabled={isDisabled === true}
                onClick={async () => {
                  setErrors({});
                  if (!CustomerId) {
                    resetForm();
                  } else {
                    discardChanges();
                  }
                }}
              >
                <Typography display="flex" sx={{ fontWeight: 'bold', fontSize: 12 }}>
                  DISCARD CHANGES
                </Typography>
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientForm);
