import EditIcon from '@mui/icons-material/Edit';
import { Box, Button, Stack, SvgIcon, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ROLE_SUPERTAS, ROLE_TAS } from 'constants/users';
import useIsInViewport from 'hooks/useIsInViewport';
import { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { loginExpire } from 'store/auth';
import linkedin from '../../../assets/img/linkedin-logo.png';

const CandidateHeaderForm = ({
  editing,
  first_name,
  last_name,
  auth,
  onSetEditing,
  children,
  onSetEditMode,
  showEditBottomBtn,
}) => {
  const { CandidateId } = useParams();
  const theme = useTheme();
  const ref1 = useRef(null);
  const editBtnObserver = useIsInViewport(ref1);

  useEffect(() => {
    showEditBottomBtn(editBtnObserver);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editBtnObserver]);

  return (
    <Stack direction="row" spacing={3}>
      {/* INPUT PROFILE IMAGE */}
      {children}
      <Box
        sx={{
          '@media(max-width:475px)': {
            marginLeft: '48px!important',
          },
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        {typeof CandidateId === 'undefined' && (
          <>
            <Typography variant="h6">CANDIDATE HOME</Typography>
            <Typography
              sx={{
                fontSize: '30px',
                fontWeight: 'bold',
                textAlign: 'left',
                textTransform: 'uppercase',
              }}
            >
              CREATE CANDIDATE
            </Typography>
          </>
        )}
        {typeof CandidateId !== 'undefined' && (
          <Stack direction="row" sx={{ gap: '1em' }}>
            <Typography variant="h6">
              {typeof CandidateId !== 'undefined' && !editing && 'EDIT '}
              {typeof CandidateId !== 'undefined' && editing && 'VIEW '}
              CANDIDATE
            </Typography>
            {CandidateId && !editing && (
              <Typography variant="h6" sx={{ color: theme.palette.error.main, textTransform: 'uppercase' }}>
                {'now editing'}
              </Typography>
            )}
          </Stack>
        )}
        {typeof CandidateId !== 'undefined' && (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography
              sx={{
                fontSize: '30px',
                fontWeight: 'bold',
                textAlign: 'left',
                textTransform: 'uppercase',
              }}
            >
              {`${first_name} ${last_name}`}
            </Typography>
            <Box sx={{ marginLeft: 3, marginRight: 6 }}>
              <img src={linkedin} width={25} height={25} alt="" />
            </Box>
            {[ROLE_TAS, ROLE_SUPERTAS].includes(auth.user.role) && editing ? (
              <Button
                variant="contained"
                color="error"
                ref={ref1}
                startIcon={<SvgIcon style={{ color: '#fff' }} component={EditIcon} inheritViewBox />}
                onClick={() => {
                  onSetEditMode(true);
                  onSetEditing(false);
                }}
              >
                edit candidate
              </Button>
            ) : null}
          </Box>
        )}
      </Box>
    </Stack>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return {
    loginExpire: (user) => {
      dispatch(loginExpire(user));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CandidateHeaderForm);
