import { CANDIDATEPROJECT_COUNTERS } from 'constants/candidate_project';
import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { loginExpire } from 'store/auth';
import { Grid, Box, Typography } from '@mui/material';
import submitLogo from 'assets/icons/submitted-icon.gif';
import cancelLogo from 'assets/icons/cancel-icon.gif';
import likeLogo from 'assets/icons/like-icon.gif';
import projectService from 'services/projectService';

const ProjectPipelineCounters = ({ ProjectId, type = 'full' }) => {
  const [candidatesCountStatus, setCandidatesCountStatus] = useState({
    ...CANDIDATEPROJECT_COUNTERS,
  });

  // project hook
  useEffect(() => {
    (async () => {
      if (!!ProjectId) {
        const { data: countsStatus } = await projectService.getProjectCandidatesCount(ProjectId);
        setCandidatesCountStatus(countsStatus);
      }
    })();
  }, [ProjectId]); // eslint-disable-line

  const {
    PANEL_INTERVIEW,
    SKILLS_ASSESSMENT,
    SUBMITTED_CUSTOMER,
    CUSTOMER_INTERVIEW,
    OFFER_EXTENDED,
    OFFER_ACCEPTED,
    PANEL_INTERVIEW_REJECTED,
    SKILLS_ASSESSMENT_REJECTED,
    SUBMITTED_CUSTOMER_REJECTED,
    CUSTOMER_INTERVIEW_REJECTED,
    OFFER_REJECTION,
    DROPPED_OUT,
  } = candidatesCountStatus;

  const attrType = (attrTrue, attrFalse) => (type && type !== 'full' ? attrTrue : attrFalse);

  return (
    <Grid
      sx={{ width: attrType('600px!important', 'inherit') }}
      container
      columnSpacing={2}
      justifyContent={attrType('600px!important', 'inherit')}
    >
      <Grid item xs={12} sm={4}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            padding: 1,
            justifyContent: 'space-between',
          }}
          className="blue-card"
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '50%',
              color: 'common.white',
              border: 4,
              width: attrType('45px', '70px'),
              height: attrType('45px', '70px'),
              marginRight: attrType(5, 'inherit'),
            }}
          >
            <Typography
              sx={{
                fontSize: attrType('26px', '40px'),
                fontWeight: 'bold',
                textAlign: 'left',
                color: 'white',
              }}
            >
              {PANEL_INTERVIEW +
                SKILLS_ASSESSMENT +
                SUBMITTED_CUSTOMER +
                CUSTOMER_INTERVIEW +
                OFFER_EXTENDED +
                OFFER_ACCEPTED}
            </Typography>
          </Box>
          <Box>
            <img src={submitLogo} alt="" className="" height={attrType(24, 48)} style={{ marginLeft: 'auto' }} />
            <Typography
              sx={{
                fontSize: attrType('14px', '20px'),
                fontWeight: 'bold',
                textAlign: 'right',
                color: 'white',
                lineHeight: 1,
                marginY: 1,
              }}
            >
              CANDIDATES
            </Typography>
            <Typography
              sx={{
                fontSize: attrType('14px', '20px'),
                textAlign: 'right',
                color: 'white',
                lineHeight: 1,
              }}
            >
              IN PIPELINE
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            padding: 1,
            justifyContent: 'space-between',
          }}
          className="green-card"
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '50%',
              color: 'common.white',
              border: 4,
              width: attrType('45px', '70px'),
              height: attrType('45px', '70px'),
              marginRight: attrType(5, 'inherit'),
            }}
          >
            <Typography
              sx={{
                fontSize: attrType('26px', '40px'),
                fontWeight: 'bold',
                textAlign: 'left',
                color: 'white',
              }}
            >
              {candidatesCountStatus.HIRED}
            </Typography>
          </Box>
          <Box sx={{ textAlign: 'right' }}>
            <img src={likeLogo} alt="" className="" height={attrType(24, 48)} style={{ marginLeft: 'auto' }} />
            <Typography
              sx={{
                fontSize: attrType('14px', '20px'),
                fontWeight: 'bold',
                textAlign: 'right',
                color: 'white',
                lineHeight: 1,
                marginY: 1,
              }}
            >
              CANDIDATES
            </Typography>
            <Typography
              sx={{
                fontSize: attrType('14px', '20px'),
                textAlign: 'right',
                color: 'white',
                lineHeight: 1,
              }}
            >
              HIRED
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: '#FF5050',
            padding: 1,
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              borderRadius: '50%',
              color: 'common.white',
              border: 4,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: attrType('45px', '70px'),
              height: attrType('45px', '70px'),
              marginRight: attrType(5, 'inherit'),
            }}
          >
            <Typography
              sx={{
                fontSize: attrType('26px', '40px'),
                fontWeight: 'bold',
                textAlign: 'left',
                color: 'white',
              }}
            >
              {PANEL_INTERVIEW_REJECTED +
                SKILLS_ASSESSMENT_REJECTED +
                SUBMITTED_CUSTOMER_REJECTED +
                CUSTOMER_INTERVIEW_REJECTED +
                OFFER_REJECTION +
                DROPPED_OUT}
            </Typography>
          </Box>
          <Box>
            <img src={cancelLogo} alt="" className="" height={attrType(24, 48)} style={{ marginLeft: 'auto' }} />
            <Typography
              sx={{
                fontSize: attrType('14px', '20px'),
                fontWeight: 'bold',
                textAlign: 'right',
                color: 'white',
                lineHeight: 1,
                marginY: 1,
              }}
            >
              CANDIDATES
            </Typography>
            <Typography
              sx={{
                fontSize: attrType('14px', '20px'),
                textAlign: 'right',
                color: 'white',
                lineHeight: 1,
              }}
            >
              REJECTED
            </Typography>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return {
    loginExpire: (user) => {
      dispatch(loginExpire(user));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectPipelineCounters);
