import ContactSupportOutlinedIcon from '@mui/icons-material/ContactSupportOutlined';
import { Fab, useMediaQuery, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import ModalFeedback from 'components/Generic/Modal/ModalFeedback';
import HubspotContactForm from 'components/HubspotContactForm/HubspotContactForm';
import { useState } from 'react';
import { connect } from 'react-redux';
import { loginExpire } from 'store/auth';

const WrapperCustomer = (props) => {
  const theme = useTheme();
  const [modalHubspot, setModalHubspot] = useState(false);
  const isMobileViewPort = useMediaQuery(theme.breakpoints.down('sm'));

  const FloatingIconButton = styled(Fab)(({ theme }) => ({
    position: 'fixed',
    bottom: theme.spacing(5),
    right: theme.spacing(2),
  }));
  return (
    <>
      <ModalFeedback
        variant="general"
        headerText="Contact Form Support"
        disableBtnCancel={true}
        disableBtnConfirm={true}
        headerBarColor={theme.palette.primary.main}
        modalWidth={isMobileViewPort ? 0 : 800}
        open={modalHubspot}
        onClose={() => setModalHubspot(false)}
        onCancel={() => setModalHubspot(false)}
      >
        <HubspotContactForm />
      </ModalFeedback>
      <FloatingIconButton color="primary" aria-label="support" size="medium" onClick={() => setModalHubspot(true)}>
        <ContactSupportOutlinedIcon sx={{ width: '1.3em', height: '1.3em' }} />
      </FloatingIconButton>
      {props.children}
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return {
    loginExpire: (user) => {
      dispatch(loginExpire(user));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WrapperCustomer);
