import { Table, TableBody, TableCell as MuiTableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import RenderActiveProjects from './RenderActiveProjects';

const TableCell = withStyles({
  root: {
    borderBottom: 'none',
  },
})(MuiTableCell);

const ActiveProjectList = ({ projects = [], activeProjectCount }) => {
  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell align="left">
              <Typography sx={{ fontWeight: 'bold', fontSize: '10px', color: 'nexxusGrayMid.main' }}>PRIORITY</Typography>
            </TableCell>
            <TableCell align="left">
              <Typography sx={{ fontWeight: 'bold', fontSize: '10px', color: 'nexxusGrayMid.main' }}>POSITION</Typography>
            </TableCell>
            <TableCell align="center">
              <Typography sx={{ fontWeight: 'bold', fontSize: '10px', color: 'nexxusGrayMid.main' }}>TECHNOLOGY</Typography>
            </TableCell>
            <TableCell align="left">
              <Typography sx={{ fontWeight: 'bold', fontSize: '10px', color: 'nexxusGrayMid.main' }}>SENIORITY</Typography>
            </TableCell>
            <TableCell align="center">
              <Typography sx={{ fontWeight: 'bold', fontSize: '10px', color: 'nexxusGrayMid.main' }}>SKILLS</Typography>
            </TableCell>
            <TableCell align="center">
              <Typography sx={{ fontWeight: 'bold', fontSize: '10px', color: 'nexxusGrayMid.main' }}>HEADCOUNT</Typography>
            </TableCell>
            <TableCell align="center">
              <Typography sx={{ fontWeight: 'bold', fontSize: '10px', color: 'nexxusGrayMid.main' }}>RECRUITER</Typography>
            </TableCell>
            <TableCell align="center">
              <Typography sx={{ fontWeight: 'bold', fontSize: '10px', color: 'nexxusGrayMid.main' }}>ACTIONS</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <RenderActiveProjects projects={projects} activeProjectCount />
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ActiveProjectList;
