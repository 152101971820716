import ApartmentRoundedIcon from '@mui/icons-material/ApartmentRounded';
import { Avatar, Box, Divider, Grid, Link, MenuItem, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { Link as LinkRouterDom } from 'react-router-dom';

import DashboardCard from '../../../../components/Dashboard/DashboardCard';
import ProjectList from './ProjectList';

import { DirectionsRun, Inventory, PriorityHigh } from '@mui/icons-material';

import useManageProjects from 'hooks/useManageProjects';
import { connect } from 'react-redux';
import MainLayout from '../../../../components/MainLayout/MainLayout';
import { loginExpire } from '../../../../store/auth';
import { PROJECT_STATUS } from '../../../../utils/staticData';
import InputSelectV2 from 'components/FormElement/InputSelectV2';

const ManageProjects = (props) => {
  const [filters, setFilters] = useState({
    client: '',
    recruiters: [],
    position: '',
    technology: '',
    status: '',
    excludeStatus: [PROJECT_STATUS.ARCHIVED],
    page: '',
    limit: '',
  });
  const { client, customers, totalSize, countProjects, projects, projectsArchived, setFilterArchived } =
    useManageProjects(filters);

  return (
    <MainLayout {...props} current="projects">
      <Box>
        <Stack direction="row" spacing={2} justifyContent="flex-end" marginBottom={2}>
          &nbsp;
          <Typography variant="body2">
            <strong>{client.companyName}</strong>
          </Typography>
          <Typography variant="body2">
            {client.address} {client.phone}
          </Typography>
          {client?.websiteUrl && (
            <Link component="a" href={client.websiteUrl} target="_blank">
              <Typography variant="body2"> VISIT WEBSITE</Typography>
            </Link>
          )}
        </Stack>
        <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
          <Typography sx={{ fontWeight: 'bold', color: 'nexxusGrayMid.main', fontSize: '14px' }}>SELECT CUSTOMER</Typography>
          {/* Client Selection */}
          <Box sx={{ width: '200px' }}>
            <InputSelectV2
              id="client"
              initialValue={filters.client}
              opts={customers.map((customer) => ({ _id: customer.id, label: customer.companyName }))}
              placeholder="CUSTOMER"
              noSelection
              onChangeInput={(ev) => {
                setFilters((prev) => ({ ...prev, client: ev.target.value }));
                setFilterArchived((prev) => ({ ...prev, client: ev.target.value }));
              }}
              size="small"
            >
              {(
                <MenuItem key={0} value={''}>
                  No Selection
                </MenuItem>
              ) &&
                customers.forEach((customer, index) => {
                  return (
                    <MenuItem key={`customer-${customer.id}-${index}`} value={customer.id}>
                      {customer.companyName}
                    </MenuItem>
                  );
                })}
            </InputSelectV2>
          </Box>
          {/* Client Logo */}
          <Box>
            <Avatar
              sx={{
                width: totalSize,
                height: totalSize,
              }}
              src={client?.logoUrl}
            >
              <ApartmentRoundedIcon sx={{ fontSize: 56 }} />
            </Avatar>
          </Box>
          {/* Counter Projects */}
          <Stack direction="row" spacing={2} justifyContent="space-between">
            <DashboardCard bgcolor="red-card" totalSize={50} width={200} total={countProjects.URGENT}>
              <Typography>
                <PriorityHigh />
              </Typography>
              <Typography sx={{ fontWeight: 'bold', fontSize: 15 }}>URGENT</Typography>
              <Typography sx={{ fontSize: 15 }}>PROJECTS</Typography>
            </DashboardCard>
            <DashboardCard bgcolor="green-card" totalSize={50} width={200} total={countProjects.ACTIVE}>
              <Typography>
                <DirectionsRun />
              </Typography>
              <Typography sx={{ fontWeight: 'bold', fontSize: 15 }}>ACTIVE</Typography>
              <Typography sx={{ fontSize: 15 }}>PROJECTS</Typography>
            </DashboardCard>
            <DashboardCard bgcolor="blue-card" totalSize={60} width={200} total={countProjects.ARCHIVED}>
              <Typography>
                <Inventory />
              </Typography>
              <Typography sx={{ fontWeight: 'bold', fontSize: 15 }}>ARCHIVED</Typography>
              <Typography sx={{ fontSize: 15 }}>PROJECTS</Typography>
            </DashboardCard>
          </Stack>
        </Stack>

        <Divider sx={{ mt: 3, mb: 3, borderBottomWidth: 2 }} />
        <Grid container>
          <Grid item xs={4}>
            {props.auth.user.role !== 'tas' && (
              <LinkRouterDom to="/tas/projects/create">
                <Typography display="flex" sx={{ fontWeight: 'bold', fontSize: '16px', color: 'green' }}>
                  + ADD A NEW PROJECT
                </Typography>
              </LinkRouterDom>
            )}
          </Grid>

          <Grid item xs={4}>
            <Typography sx={{ fontWeight: 'bold', fontSize: '16px', color: 'nexxusGrayMid.main', textAlign: 'center' }}>
              ACTIVE PROJECTS
            </Typography>
          </Grid>
        </Grid>
        <ProjectList {...{ projects }} initialOrder={'status'} />

        <Grid container sx={{ mt: 6 }}>
          <Grid item xs={4}></Grid>
          <Grid item xs={4}>
            <Typography sx={{ fontWeight: 'bold', fontSize: '16px', color: 'nexxusGrayMid.main', textAlign: 'center' }}>
              ARCHIVED PROJECTS
            </Typography>
          </Grid>
        </Grid>
        <ProjectList projects={projectsArchived} />
      </Box>
    </MainLayout>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return {
    loginExpire: (user) => {
      dispatch(loginExpire(user));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageProjects);
