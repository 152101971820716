import { useEffect, useMemo, useState } from 'react';

import { FiberManualRecord } from '@mui/icons-material';
import {
  Autocomplete,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  ListItemText,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useCustomers, useRecruiters, useSkills } from 'hooks/request';
import InputSelectV2 from 'components/FormElement/InputSelectV2';
import { CLIENTSTATUS, currentTitle } from 'utils/staticData';

export default function RefineProjectResults(props) {
  const hideArchived = useMemo(() => props.projectStatus.hide_archived, [props.projectStatus.hide_archived]);
  const useCustomersParams = hideArchived ? { status: CLIENTSTATUS.ACTIVE } : {};
  const { customers, refetch } = useCustomers(useCustomersParams);
  const recruiters = useRecruiters();
  const skills = useSkills();
  const [skillValue, setSkillValue] = useState(null);

  useEffect(() => {
    props.setFilters((prev) => ({
      ...prev,
      hide_archived: hideArchived,
      client: '',
    }));
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hideArchived]);

  return (
    <>
      <Grid container>
        <Grid xs={10} item>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Typography sx={{ fontSize: 20, whiteSpace: 'nowrap' }}>Refine Results</Typography>
            <Typography sx={{ color: 'lightgray', fontWeight: 'bold' }} alignItems="center">
              <FiberManualRecord color="onhold" sx={{ mb: -0.7 }} />
              ON HOLD
            </Typography>
            <Typography sx={{ color: 'lightgray', fontWeight: 'bold' }} alignItems="center">
              <FiberManualRecord color="archived" sx={{ mb: -0.7 }} />
              ARCHIVED
            </Typography>
            <Typography sx={{ color: 'lightgray', fontWeight: 'bold' }} alignItems="center">
              <FiberManualRecord color="active" sx={{ mb: -0.7 }} />
              ACTIVE
            </Typography>
            <Typography sx={{ color: 'lightgray', fontWeight: 'bold' }} alignItems="center">
              <FiberManualRecord color="urgent" sx={{ mb: -0.7 }} />
              URGENT
            </Typography>

            <FormGroup>
              <FormControlLabel
                sx={{ mt: -0.4 }}
                control={<Checkbox checked={props.projectStatus.urgent_only} onChange={props.toggleUrgentOnly} />}
                label={<Typography sx={{ fontWeight: 'bold', mb: -0.4 }}>SHOW URGENT (ONLY)</Typography>}
              />
            </FormGroup>
            <FormGroup>
              <FormControlLabel
                sx={{ mt: -0.4 }}
                control={<Checkbox checked={props.projectStatus.hide_archived} onChange={props.toggleHideArchived} />}
                label={<Typography sx={{ fontWeight: 'bold', mb: -0.4 }}>HIDE ARCHIVED</Typography>}
              />
            </FormGroup>
          </Stack>
        </Grid>
        <Grid item sx={{ marginLeft: 'auto' }}>
          <Button
            variant="contained"
            color={'warning'}
            disabled={false}
            onClick={() => {
              setSkillValue(null);
              props.setProjectStatus(() => ({
                urgent_only: false,
                hide_archived: false,
              }));
              const initialValues = {
                client: '',
                recruiters: [],
                position: '',
                technology: '',
                status: '',
                excludeStatus: [],
                page: '',
                limit: '',
              };

              props.setFilters((prev) => ({
                ...prev,
                ...initialValues,
              }));
              props.handleReset(initialValues);
            }}
          >
            Clear Filters
          </Button>
        </Grid>
      </Grid>
      <Stack direction="row" spacing={2} sx={{ mt: 3 }}>
        <InputSelectV2
          id="client"
          label="Customer"
          initialValue={props.filters.client}
          opts={customers.map((customer) => ({ _id: customer.id, label: customer.companyName }))}
          placeholder="No Selection"
          noSelection
          error=""
          onChangeInput={(ev) => {
            props.setFilters((prev) => ({ ...prev, client: ev.target.value }));
          }}
        >
          {(
            <MenuItem key={0} value={''}>
              No Selection
            </MenuItem>
          ) &&
            customers.forEach((customer, index) => {
              return (
                <MenuItem key={`customer-${customer.id}-${index}`} value={customer.id}>
                  {customer.companyName}
                </MenuItem>
              );
            })}
        </InputSelectV2>
        <InputSelectV2
          id="recruiters"
          label="Recruiter"
          initialValue={props.filters.recruiters}
          placeholder="No Selection"
          multiple
          renderValue={() => {
            if (props.filters.recruiters?.length === 1) {
              const selectedRecruiter = recruiters.rows.find((recruiter) => recruiter.id === props.filters.recruiters[0]);
              return selectedRecruiter?.full_name;
            }

            return `${props.filters.recruiters.length} Selected`;
          }}
          onChangeInput={(ev) => {
            props.setFilters((prev) => ({ ...prev, recruiters: ev.target.value }));
          }}
        >
          {recruiters.rows.map((recruiter, index) => {
            return (
              <MenuItem key={`recruiter-${recruiter.id}-${index}`} value={recruiter.id}>
                <Checkbox checked={props.filters.recruiters.includes(recruiter.id)} />
                <ListItemText primary={recruiter.full_name} />
              </MenuItem>
            );
          })}
        </InputSelectV2>
        <InputSelectV2
          id="position"
          label="Title"
          opts={currentTitle}
          placeholder="No Selection"
          initialValue={props.filters.position}
          onChangeInput={(ev) => {
            props.setFilters((prev) => ({ ...prev, position: ev.target.value }));
          }}
        />
        <FormControl fullWidth>
          <FormLabel>Main Technology</FormLabel>
          <Autocomplete
            id="skill"
            name="skill"
            sx={{ '& .MuiTextField-root > div': { paddingLeft: '14px' } }}
            placeholder="Search Skills"
            onChange={(ev, item) => {
              setSkillValue(item);
              props.setFilters((prev) => ({ ...prev, technology: item?.id || '' }));
            }}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            options={skills.rows}
            renderInput={(params) => <TextField {...params} placeholder="No Selection" />}
            value={skillValue}
          />
        </FormControl>

        <FormControl sx={{ width: '100%' }}>
          <FormLabel>&nbsp;</FormLabel>
          <Button
            variant="contained"
            color="secondary"
            onClick={props.handleFilterProjects}
            sx={{
              height: '54px',
              width: '100%',
              fontWeight: 'bold',
              fontSize: 17,
              pt: 1,
              borderRadius: 0,
            }}
          >
            FILTER RESULTS
          </Button>
        </FormControl>
      </Stack>
    </>
  );
}
