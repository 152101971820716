import { ROLE_SUPERCUSTOMER, ROLE_SUPERTAS } from 'constants/users';

const getPathDashboard = (user, state) => {
  let pathname = user.role;
  if (pathname === ROLE_SUPERTAS) pathname = 'tas';
  if (pathname === ROLE_SUPERCUSTOMER) pathname = 'customer';
  return `/${pathname.toLowerCase()}/dashboard`;
};

export { getPathDashboard };
