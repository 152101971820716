import  { useMemo, useState } from 'react';
import {ThemeProvider as MUIThemeProvider} from '@mui/material/styles'
import { getThemeByName } from './base';
import { ThemeContext } from '@nexxus/components';


const ThemeProvider = (props) => {
    const [themeName, setThemeName] = useState('old');
    
    const theme = useMemo(() => {
        return getThemeByName(themeName);
      }, [themeName]);

    const contextValue = useMemo(() => {
    return { themeName, setThemeName };
    }, [themeName, setThemeName]);

    return (
        <ThemeContext.Provider value={contextValue}>
            <MUIThemeProvider theme={theme}>{props.children}</MUIThemeProvider>
        </ThemeContext.Provider>
    );
}

export  {ThemeProvider};
