import { FiberManualRecord } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import AddchartIcon from '@mui/icons-material/Addchart';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Box, CircularProgress, Container, Divider, Stack, Typography, useTheme } from '@mui/material';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import InputSelectV2 from 'components/FormElement/InputSelectV2';
import MainLayout from 'components/MainLayout/MainLayout';
import useBreadcrumbs from 'hooks/useBreadcrumbs';
import { useCurrentUser } from 'hooks/useCurrentUser';
import moment from 'moment';
import { getActiveProjsAndCustomers } from 'pages/tas/reports/utilFunctions';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import reportService from 'services/reportService';
import { loginExpire } from 'store/auth';
import { currentTitle, seniority } from 'utils/staticData';

const NoDataOverlay = () => {
  return (
    <Stack height="100%" alignItems="center" justifyContent="center">
      <Typography variant="h3" color="nexxusGrayLight.main" sx={{ fontWeight: 'bold' }}>
        NO DATA
      </Typography>
      <Typography variant="h6" color="nexxusGrayLight.main">
        Please Select Candidate Fields
      </Typography>
    </Stack>
  );
};

const ListReports = (props) => {
  const [customer, setCustomer] = useState(0);
  const [customers, setCustomers] = useState([]);
  const [reports, setReports] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [reportsLoading] = useState(false);
  const theme = useTheme();
  const maxWidth = props.maxWidth || 'xl';

  const { setBreadcrumbs } = useBreadcrumbs();
  const { currentUser } = useCurrentUser();

  const navigate = useNavigate();

  const handleViewReport = (e, row) => {
    e.preventDefault();
    navigate(`/tas/reports/${row.ReportId}/view`);
  };

  const handleEditReport = (e, row) => {
    e.preventDefault();
    navigate(`/tas/reports/create/${row.id}`);
  };

  const columns = [
    {
      field: 'reportIsCreated',
      headerName: '',
      width: 50,
      sortable: false,
      renderCell: ({ row }) => {
        if (row.reportIsCreated === true && row.status !== 'draft')
          return <FiberManualRecord color="active" sx={{ mb: -0.7 }} />;
        else if (row.status === 'draft') return <FiberManualRecord color="draft" sx={{ mb: -0.7 }} />;
        else return <FiberManualRecord color="urgent" sx={{ mb: -0.7 }} />;
      },
    },
    {
      field: 'companyName',
      headerName: 'Customer',
      editable: false,
      flex: 1,
      valueGetter: ({ row }) => row.Client?.companyName || '',
    },
    {
      field: 'position',
      headerName: 'position',
      editable: false,
      flex: 1,
      valueGetter: ({ row }) => currentTitle.find((pos) => pos._id === row.position)['label'] || '',
      // valueGetter: ({ row }) => _.unescape(_.unescape(row.reportNotes)).replace(/(<([^>]+)>)/gi, '') || '',
    },
    {
      field: 'seniority',
      headerName: 'seniority',
      editable: false,
      flex: 1,
      valueGetter: ({ row }) => seniority.find((se) => se._id === row.seniority)['shortLabel'] || '',
      // valueGetter: ({ row }) => _.unescape(_.unescape(row.reportNotes)).replace(/(<([^>]+)>)/gi, '') || '',
    },
    {
      field: 'generatedAt',
      headerName: 'Sent On',
      editable: false,
      flex: 1,
      valueGetter: ({ row }) => (row.reportGeneratedAt ? moment(row.reportGeneratedAt).format('MMMM Do, YYYY') : ''),
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      getActions: ({ row }) => {
        let actions = [];
        if (row.reportAction && row.status !== 'draft') {
          actions.push(
            <GridActionsCellItem
              key={1}
              icon={<VisibilityIcon />}
              onClick={(e) => handleViewReport(e, row)}
              label="View Sent Report"
            />
          );
        }

        if (row.reportAction && row.status === 'draft') {
          actions.push(
            <GridActionsCellItem
              key={2}
              icon={<EditIcon />}
              onClick={(e) => handleViewReport(e, row)}
              label="Review Draft Report"
            />
          );
        }

        if (!row.reportIsCreated) {
          actions.push(
            <GridActionsCellItem
              key={3}
              icon={<AddchartIcon />}
              onClick={(e) => handleEditReport(e, row)}
              label="Create Report"
            />
          );
        }

        return actions;
      },
    },
  ];

  useEffect(() => {
    (async () => {
      const { reports, customers } = await fetchReports();
      setCustomers(customers);
      setReports(reports);
    })();
  }, []); // eslint-disable-line

  useEffect(() => {
    (async () => {
      if (customer !== 0) {
        const { reports } = await fetchReports(customer);
        setReports(reports);
      }
    })();
  }, [customer]); // eslint-disable-line

  const fetchReports = async (client = '') => {
    const currentRecruiterId = props.auth.user.id;

    const { activeProjects, customers } = await getActiveProjsAndCustomers(client, currentRecruiterId);

    const { data: reportFromCustomers } = await reportService.getAllReports({ customers: customers.map((c) => c.id) });
    const reports = [];
    activeProjects.forEach((pj) => {
      const reportCreated = reportFromCustomers.find((r) => r.ClientId === pj.ClientId && r.ProjectId === pj.id);
      reports.push({
        ...pj,
        ReportId: reportCreated ? reportCreated.id : null,
        reportIsCreated: reportCreated ? true : false,
        reportNotes: reportCreated?.notes || '',
        reportAction: reportCreated ? true : false,
        reportGeneratedAt: reportCreated ? reportCreated.generatedAt : '',
        status: reportCreated ? reportCreated.status : '',
      });
    });
    return { customers, reports };
  };

  useEffect(() => {
    setBreadcrumbs([
      {
        name: 'Dashboard',
        url: '/tas/dashboard',
      },
      {
        name: 'Reports',
      },
    ]);
  }, [setBreadcrumbs]);

  return (
    <>
      <MainLayout {...props} current="reports">
        <Box>
          <Container maxWidth={maxWidth}>
            <Typography variant="h4" align="left" pt={1} pb={1} sx={{ textTransform: 'uppercase' }}>
              Welcome <strong>{currentUser?.first_name}!</strong>
            </Typography>
            <Divider sx={{ mt: 4, mb: 4, borderBottomWidth: 2 }} />
            <Stack direction="row" alignItems="center" spacing={2} justifyContent="space-between">
              <Stack direction="row" alignItems="center" spacing={2}>
                <Typography sx={{ fontSize: 20, whiteSpace: 'nowrap' }}>Report:</Typography>
                <Typography sx={{ color: 'lightgray', fontWeight: 'bold' }} alignItems="center">
                  <FiberManualRecord color="active" sx={{ mb: -0.7 }} />
                  Sent
                </Typography>
                <Typography sx={{ color: 'lightgray', fontWeight: 'bold' }} alignItems="center">
                  <FiberManualRecord color="draft" sx={{ mb: -0.7 }} />
                  Draft
                </Typography>
                <Typography sx={{ color: 'lightgray', fontWeight: 'bold' }} alignItems="center">
                  <FiberManualRecord color="urgent" sx={{ mb: -0.7 }} />
                  Pending
                </Typography>
              </Stack>
              <Stack direction="row" spacing={2} justifyContent="flex-end" alignItems="center">
                <Typography sx={{ fontWeight: 'bold', color: 'nexxusGrayMid.main', fontSize: '14px' }}>
                  SELECT CUSTOMER
                </Typography>
                {/* Client Selection */}
                <Box sx={{ width: '200px' }}>
                  <InputSelectV2
                    id="client"
                    initialValue={customer}
                    opts={customers.map((customer) => ({ _id: customer.id, label: customer.companyName }))}
                    placeholder="No Selection"
                    onChangeInput={(ev) => setCustomer(ev.target.value)}
                    defaultOption={true}
                  ></InputSelectV2>
                </Box>
              </Stack>
            </Stack>
            {reportsLoading && (
              <Stack alignItems="center">
                <CircularProgress />
              </Stack>
            )}
            <DataGrid
              sx={{ mt: 4 }}
              headerHeight={50}
              rows={reports || []}
              columns={columns}
              autoHeight
              rowsPerPageOptions={[5, 10, 20, 50, 100]}
              disableSelectionOnClick
              disableColumnMenu
              pageSize={pageSize}
              onPageSizeChange={(newPage) => setPageSize(newPage)}
              pagination
              loading={reportsLoading}
              components={{
                ...theme.components.MuiDataGrid.defaultProps.components,
                ...{
                  NoRowsOverlay: () => <NoDataOverlay />,
                  NoResultsOverlay: () => <NoDataOverlay />,
                },
              }}
            />
          </Container>
        </Box>
      </MainLayout>
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return {
    loginExpire: (user) => {
      dispatch(loginExpire(user));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ListReports);
