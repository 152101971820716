import { useEffect, useState } from 'react';
import authService from '../services/authService';
import useIsMounted from './useIsMounted';

const useCurrentUser = () => {
  const [currentUser, setCurrentUser] = useState(null);
  const isMountedRef = useIsMounted();

  useEffect(() => {
    const getCurrentUser = async () => {
      const { data } = await authService.getCurrentUser();
      // 👇️ only update state if component is mounted
      if (isMountedRef.current) {
        setCurrentUser(data);
      }
    };
    getCurrentUser();
  }, [isMountedRef]);
  return { currentUser };
};

export { useCurrentUser };
