import _ from 'lodash';
import { useEffect, useState } from 'react';

import httpService from '../../services/httpService';
import useIsMounted from '../useIsMounted';

export default function useRecruiters() {
  const [state, setState] = useState({
    rows: [],
    total: 0,
    options: [],
  });
  const isMountedRef = useIsMounted();

  useEffect(() => {
    const req = httpService.get(process.env.REACT_APP_API_BACKEND + '/v1/projects/recruiters');
    req.then(({ data }) => {
      const optsRecruiter = _.map(data, (rec) => {
        return { _id: rec.id, label: rec.full_name };
      });
      // 👇️ only update state if component is mounted
      if (isMountedRef.current) {
        setState({
          rows: data,
          total: data.length,
          options: optsRecruiter,
        });
      }
    });
  }, [isMountedRef]);

  return state;
}
