import { useEffect, useState } from 'react';
import projectService from 'services/projectService';
import useIsMounted from './useIsMounted';

const useCandidateActiveProjects = (candidateId) => {
  const [candidateActiveProjects, setCandidateActiveProjects] = useState([]);
  const isMountedRef = useIsMounted();
  // TODO implmenent error handling
  // const [error, setError] = useState(false);

  useEffect(() => {
    const fetchProjects = async () => {
      projectService
        .getActiveProjectsByCandidate(candidateId)
        .then(({ data }) => {
          // 👇️ only update state if component is mounted
          if (isMountedRef.current) {
            setCandidateActiveProjects(data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };
    fetchProjects();
  }, [candidateId, isMountedRef]);

  return { candidateActiveProjects };
};

export default useCandidateActiveProjects;
