import { ROLE_CANDIDATE } from 'constants/users';
import { Navigate, Route } from 'react-router-dom';
import Dashboard from '../pages/customer/Dashboard';
import PrivateRoute from './privateRouter';

const candidateRoutes = [
  {
    name: 'Dashboard Customer',
    path: '/candidate/dashboard',
    element: <Dashboard />,
    authorization: [ROLE_CANDIDATE],
  },
];

export const CandidateRoutes = ({ user }) => {
  return candidateRoutes.map((route, index) => {
    return (
      <Route
        key={`customer-${index}`}
        path={route.path}
        element={
          <PrivateRoute>
            {route.authorization.includes(user?.role) ? route.element : <Navigate to="/403" replace />}
          </PrivateRoute>
        }
      />
    );
  });
};
