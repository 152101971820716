import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import clientService from '../../../services/clientService';

import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Divider, Typography } from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTheme } from '@mui/system';
import { ROLE_TAS } from 'constants/users';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { CLIENTSTATUS } from 'utils/staticData';
import ActiveProjectList from './ActiveProjectList';

export default function ActiveProjects({ clientId, activeProjectCount = 0, client, sx }) {
  const [expanded, setExpanded] = useState(false);
  const [projects, setProjects] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [clientStatus, setClientStatus] = useState('');
  const [colorStatus, setColorStatus] = useState('');
  const currentUser = useSelector((state) => state.auth.user);
  const theme = useTheme();

  useEffect(() => {
    const getProjects = async () => {
      const { data } = await clientService.getClientProjects(clientId, 1);
      setProjects(data);
      setLoaded(true);
    };
    if (clientId && !loaded && expanded) {
      getProjects();
    }
  }, [clientId, expanded, loaded]);

  useEffect(() => {
    const clientStatus = client.status;
    const statusDB = Object.keys(CLIENTSTATUS).find((key) => Number(CLIENTSTATUS[key]) === Number(clientStatus));
    setClientStatus(statusDB);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
      if (clientStatus) {
        const stat = Object.keys(CLIENTSTATUS)
          .find((key) => key === clientStatus)
          .replace(/_/g, ' ');
        if (stat === 'ARCHIVED') {
          setColorStatus(theme.palette.archived.main);
        }
        if (stat === 'ACTIVE') {
          setColorStatus(theme.palette.active.main);
        }
        if (stat === 'DRAFT') {
          setColorStatus(theme.palette.draft.main);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientStatus]);

  return (
    <Box sx={sx}>
      <Accordion
        elevation={0}
        onChange={(e, expanded) => {
          setExpanded(expanded);
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            id="panel1a-header"
            sx={{
              border: 'none',
              padding: '2px 20px',
              margin: '0px',
              marginY: 0,
              minHeight: 30,
              '& .MuiAccordionSummary-content': {
                my: 1,
              },
            }}
          >
            <Box display="flex" sx={{ fontWeight: 'bold', fontSize: '12px', color: 'green' }}>
              {activeProjectCount}
              <Typography sx={{ fontWeight: 'bold', fontSize: '12px', color: 'black', marginLeft: 1 }}>
                &nbsp;ACTIVE PROJECTS
              </Typography>
            </Box>
          </AccordionSummary>
          {![ROLE_TAS].includes(currentUser.role) && (
            <Button
              component={Link}
              to={`/tas/projects/create/based_on_customer/${clientId}`}
              variant="text"
              sx={{ marginLeft: 5 }}
            >
              <Typography display="flex" sx={{ fontWeight: 'bold', fontSize: '12px', color: 'green' }}>
                + ADD A NEW PROJECT
              </Typography>
            </Button>
          )}

          <Typography
            display="flex"
            sx={{ fontWeight: 'bold', fontSize: '9px', color: 'nexxusGrayMid.main', marginLeft: 'auto', marginRight: 2 }}
          >
            DATE CREATED: {moment(new Date(client.createdAt)).format('MMMM Do, YYYY')} | LAST UPDATED:{' '}
            {moment(new Date(client.updatedAt)).format('MMMM Do, YYYY')}
          </Typography>
          <Typography display="flex" sx={{ fontWeight: 'bold', fontSize: '14px', color: 'nexxusGray' }}>
            STATUS: <span style={{ marginLeft: '.3em', color: colorStatus }}>{clientStatus}</span>
          </Typography>
        </Box>
        <Divider sx={{ flexGrow: 1, borderBottomWidth: '1px' }} />
        <AccordionDetails
          sx={{
            padding: '2px',
            margin: '4px 0',
            background: 'white',
          }}
        >
          <ActiveProjectList projects={projects} activeProjectCount />
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
