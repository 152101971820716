import { Box, Button, Popover, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useFetchCandidateNotes } from 'hooks/request';
import moment from 'moment';
import { useState } from 'react';
import { CANDIDATE_NOTE_SCOPE } from 'utils/staticData';

const Note = ({ note, index }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{ bgcolor: index % 2 ? theme.palette.nexxusGrayLight.main : theme.palette.nexxusGrayExtraLight.main, p: 1, mb: 1 }}
    >
      <Typography variant="body2" color="textSecondary" sx={{ fontWeight: 'bold' }}>
        {note.note}
      </Typography>
      <Typography variant="caption" color="textSecondary" sx={{ fontWeight: 'bold' }}>
        {note.NoteTaker.full_name} | {moment(note.createdAt).format('MM/DD/YYYY | h:mm a')} PST
      </Typography>
    </Box>
  );
};

export default function CandidateProjectNotesPopover({ candidateId, projectId, label }) {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const { candidateNotes } = useFetchCandidateNotes({
    params: { candidateId, scope: CANDIDATE_NOTE_SCOPE.PROJECT, projectId },
    runAtStart: true,
  });

  return (
    <div>
      <Button aria-describedby={id} variant="text" onClick={handleClick} sx={{ color: theme.palette.primary.lighter }}>
        {label}
      </Button>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        PaperProps={{
          elevation: 1,
          sx: {
            borderRadius: 0,
            p: 0.75,
            minWidth: 100,
            minHeight: 40,
            width: candidateNotes.length ? 480 : 'auto',
            height: candidateNotes.length ? 240 : 'auto',
            overflowY: 'auto',
          },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Stack spacing={0.5}>
          {candidateNotes.map((note, index) => (
            <Note key={note.id} index={index} note={note} />
          ))}

          {candidateNotes.length === 0 && (
            <Typography variant="caption" align="center" textTransform="uppercase" fontWeight="bold">
              No Notes
            </Typography>
          )}
        </Stack>
      </Popover>
    </div>
  );
}
