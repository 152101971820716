import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, Avatar, Box, Container, IconButton, Stack, Toolbar, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import ModalFeedback from 'components/Generic/Modal/ModalFeedback';
import { ROLE_LABELS } from 'constants/users';
import { useCurrentUser } from 'hooks/useCurrentUser';
import _ from 'lodash';
import { useMemo, useState } from 'react';

const UserAvatar = styled(Avatar)(({ theme }) => ({
  width: '60px',
  height: '60px',
  fontSize: '24px',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  color: 'rgba(255, 255, 255, .6)',
  border: '1px solid rgba(0, 0, 0, .2)',
  background: theme.palette.nexxusGrayLight.main,
}));

const Topbar = (props) => {
  const [customerLogoUrl, setCustomerLogoUrl] = useState('');
  const { currentUser } = useCurrentUser();
  const {
    drawerWidth,
    handleSidebarOpen,
    auth: { user },
    onHandleLogout,
  } = props;

  const [open, setOpen] = useState(false);

  const onCloseModal = (event, reason) => {
    event?.preventDefault();
    setOpen(false);
  };

  const onAccept = (e, reason) => {
    onHandleLogout();
    setOpen(false);
  };

  useMemo(() => {
    if (currentUser && customerLogoUrl === '') {
      if (user.role === 'superCustomer' || user.role === 'customer') {
        setCustomerLogoUrl(currentUser?.Client?.['logoUrl'] ?? '');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  return (
    <>
      <ModalFeedback
        variant="warning"
        headerText="LOG OUT"
        titleText="Do you want to logout?"
        open={open}
        onCancel={onCloseModal}
        onClose={onCloseModal}
        onAccept={onAccept}
      />{' '}
      <AppBar
        position="fixed"
        elevation={0}
        sx={{
          width: { md: `calc(100% - ${drawerWidth}px)` },
          backgroundColor: 'white',
          boxShadow: 1,
        }}
      >
        <Container maxWidth="xl" sx={{ px: '12px !important' }}>
          <Toolbar sx={{ marginX: 0, height: '80px' }} disableGutters>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={() => handleSidebarOpen()}
              sx={{ mr: 2, display: { lg1: 'none' }, color: 'black' }}
            >
              <MenuIcon />
            </IconButton>
            <Box sx={{ flexGrow: 1 }}></Box>

            <Stack direction="row-reverse" spacing={2}>
              <UserAvatar
                {..._.merge({
                  src: user.role === 'superCustomer' || user.role === 'customer' ? customerLogoUrl : props.auth.user.avatar,
                })}
              />
              <Stack justifyContent="center" sx={{ textAlign: 'right' }}>
                <Typography variant="body" color="text.primary" sx={{ lineHeight: '1.2em', fontWeight: 'bold' }}>
                  {user.first_name} {user.last_name}
                </Typography>
                <Typography variant="caption" color="text.primary" sx={{ lineHeight: '1em' }}>
                  {ROLE_LABELS[user.role]}
                </Typography>
                <Typography
                  color="nexxusRed.main"
                  onClick={() => setOpen(true)}
                  sx={{
                    mt: 0.7,
                    textTransform: 'uppercase',
                    fontSize: 10,
                    cursor: 'pointer',
                    '&:hover': {
                      textDecoration: 'underline',
                    },
                  }}
                >
                  Sign Out
                </Typography>
              </Stack>
            </Stack>
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
};

export default Topbar;
