import { Box, Stack, Typography, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import _ from 'lodash';
import moment from 'moment';
import useCustomerFeedback from 'hooks/useCustomerFeedback';
import FeedbackForm from './FeedbackForm';
import DOMPurify from 'dompurify';

const NoDataOverlay = () => {
  return (
    <Stack height="100%" alignItems="center" justifyContent="center">
      <Typography variant="h3" color="nexxusGrayLight.main" sx={{ fontWeight: 'bold' }}>
        NO DATA
      </Typography>
      <Typography variant="h6" color="nexxusGrayLight.main">
        There is no feedback from customers
      </Typography>
    </Stack>
  );
};

const TabFeedback = ({ candidateId }) => {
  const { customerFeedback, refetch } = useCustomerFeedback(candidateId);

  const handleAddFeedback = () => {
    refetch();
  };

  const CustomContainer = styled(Box)(({ theme }) => ({
    borderRadius: theme.variables.inputs.borderRadius,
    borderColor: theme.variables.inputs.borderColor,
    borderWidth: theme.variables.inputs.borderWidth,
    borderStyle: 'solid',
    padding: '15px',
    overflowY: 'auto',
    height: '503px',
  }));
  const Entry = styled(Typography)(({ theme }) => ({
    margin: '5px 0',
    padding: '2px 5px',
  }));

  const formattedDate = (date) => moment(date).format('MM/DD/YYYY');

  const cleanHTML = (dirtyHTML) =>
    DOMPurify.sanitize(dirtyHTML, {
      USE_PROFILES: { html: true },
    });

  return (
    <Grid container spacing={3}>
      <Grid item sm={12} md={6}>
        <Box sx={{}}>
          <Typography variant="label">CUSTOMER FEEDBACK</Typography>
          <CustomContainer>
            {customerFeedback.length === 0 && <NoDataOverlay />}
            {customerFeedback.length > 0 &&
              customerFeedback.map((feed, index) => {
                return (
                  <Box key={`${feed.date}-${feed.customer}-${feed.project}`}>
                    <Entry>
                      <em>{formattedDate(feed.date)}</em>: <b>{feed.customer}</b> - <i>{feed.project}</i>
                    </Entry>
                    <Box dangerouslySetInnerHTML={{ __html: cleanHTML(_.unescape(feed.feedback)) }} />
                  </Box>
                );
              })}
          </CustomContainer>
        </Box>
      </Grid>
      <Grid item sm={12} md={6}>
        {candidateId && <FeedbackForm candidateId={candidateId} onSuccess={handleAddFeedback} />}
      </Grid>
    </Grid>
  );
};

export default TabFeedback;
