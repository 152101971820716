import { ROLE_LABELS } from 'constants/users';
import Profile from 'pages/Profile';
import { Navigate, Route } from 'react-router-dom';
import PrivateRoute from './privateRouter';

const meRoutes = [
  {
    name: 'Profile',
    path: '/profile',
    element: <Profile />,
    authorization: Object.keys(ROLE_LABELS),
  },
];

export const MeRoutes = ({ user }) => {
  return meRoutes.map((route, index) => {
    return (
      <Route
        key={`me-${index}`}
        path={route.path}
        element={
          <PrivateRoute>
            {route.authorization.includes(user?.role) ? route.element : <Navigate to="/403" replace />}
          </PrivateRoute>
        }
      />
    );
  });
};
