import ClearIcon from '@mui/icons-material/Clear';
import { Autocomplete, CircularProgress, createFilterOptions, Typography } from '@mui/material';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';
import { capitalizeString } from '../../utils/utils';

const filter = createFilterOptions();
const onlyContainSpaces = (word) => /^\s*$/.test(word);
const removeOuterBlank = (word) => word?.trimStart()?.trimEnd();

const InputAutocomplete = (props) => {
  const {
    id,
    isMultiple,
    disabled,
    label,
    labelField,
    InputLabelProps,
    value,
    error,
    onHandleChange,
    onSearch,
    sx,
    defaultOpts,
    reset,
    isCreatable,
    styleLabel,
    styleErrorControl,
    renderInputMultiline,
    placeholder,
    minReqOpts = 0,
    handleFreeSolo = true,
    handleClearOnBlur,
    isSkillsInput,
    parentSx,
    getOptionDisabled = () => false,
  } = props;

  const [values, setValues] = useState([]);
  const [opts, setOpts] = useState([]);
  const [searchInputValue, setSearchInputValue] = useState('');
  const [searchOpen, setSearchOpen] = useState(false);
  const [searchInputValueDebouce] = useDebounce(searchInputValue, 500);
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    if (!isMultiple || isMultiple === false) {
      let active = true;

      (async () => {
        setLoading(true);
        const options = await onSearch(searchInputValueDebouce);
        if (active) setOpts(options);
        setLoading(false);
      })();

      return () => {
        setLoading(false);
        active = false;
      };
    }
  }, [searchInputValueDebouce, searchOpen]); // eslint-disable-line

  useEffect(() => {
    if (searchInputValue !== '' && searchInputValue !== undefined) {
      setInputValue(searchInputValue);
    } else {
      if (values.length > 0) {
        setInputValue(values[0].title);
      } else {
        setInputValue('');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchInputValue]);

  useEffect(() => {
    (async () => {
      setOpts(defaultOpts);
      if (defaultOpts && defaultOpts.length > 0 && isMultiple === true && typeof value !== 'undefined') {
        const newVals = await defaultOpts.filter((opt) => value.includes(opt.id));
        return setValues(newVals);
      }
      if (isMultiple !== true) {
        const newVals = await defaultOpts.filter((opt) => opt.id === parseInt(value));
        if (newVals.length > 0) {
          setValues(newVals);
          setSearchInputValue(newVals[0].title);
          return;
        }
      }
    })();
  }, [defaultOpts]); // eslint-disable-line

  useEffect(() => {
    (async () => {
      if (isMultiple && isMultiple === true) {
        if (typeof value !== 'undefined' && value.length !== values.length) {
          const newVals = await value.map((val) => {
            const option = defaultOpts.find((opt) => opt.id === val);
            return option ? option : { id: val, title: capitalizeString(val) };
          });
          return setValues(newVals);
        }
      }
    })();
  }, [value]); // eslint-disable-line

  useEffect(() => {
    if (reset === true) {
      setValues([]);
      setSearchInputValue('');
    }
  }, [reset]);

  const handleNoInputVal = (singleValue, newVals, newValue, valueTitle) => {
    if (!singleValue.hasOwnProperty('inputValue')) {
      newValue.forEach((val) => {
        const id = val.id ?? removeOuterBlank(val);
        const title = capitalizeString(val.title ?? removeOuterBlank(val));
        newVals.push({ id, title });
      });
      const optInArr = newValue.find((val) => val.id === removeOuterBlank(singleValue));
      if (optInArr) {
        // removing created on the fly
        newVals.splice(newVals.indexOf(optInArr), 1);
        setValues(newVals);
        onHandleChange(newVals);
        return true;
      }
      const arrayUniqueByTitle = [...new Map(newVals.map((item) => [item['title'], item])).values()];
      setValues(arrayUniqueByTitle);
      onHandleChange(arrayUniqueByTitle);
      return true;
    }
    return false;
  };

  const getAccumulatedUniqueValues = (newValue) => {
    const vals = [...newValue, ...values];
    return [...new Set(vals)];
  };

  const handleIsCreatable = (details, entryValue, reason, target) => {
    let newValue = [];
    newValue = getAccumulatedUniqueValues(entryValue);
    const isSvgDeleteIcon = target instanceof SVGElement;
    if (reason === 'removeOption' && isSvgDeleteIcon) {
      newValue = entryValue;
    }
    if (!!!details.option === true || onlyContainSpaces(details.option)) return;
    const titleValue = removeOuterBlank(details.option.title) ?? removeOuterBlank(details.option);
    // for multiple and creatable
    const { option: singleValue } = details;
    let newVals = [];

    if (singleValue.id && newValue.length > 0) {
      newValue.map((val) => newVals.push({ id: val.id, title: val.title }));
      const arrayUniqueByTitle = [...new Map(newVals.map((item) => [item['title'], item])).values()];
      setValues(arrayUniqueByTitle);
      onHandleChange(arrayUniqueByTitle);
      return;
    }
    if (handleNoInputVal(singleValue, newVals, newValue, titleValue)) return;
    const optInArr = newValue.find((val) => val.id === singleValue.inputValue);
    if (optInArr) {
      // removing created on the fly
      newValue.splice(newValue.indexOf(singleValue), 1);
      newValue.map((val) => newVals.push({ id: val.id, title: val.title }));
      setValues(newVals);
      onHandleChange(newVals);
      return;
    }
    // add new on the fly
    newValue.push({ id: singleValue.inputValue.toLowerCase(), title: capitalizeString(singleValue.inputValue) });
    newValue.splice(newValue.indexOf(singleValue), 1);

    newValue.map((val) => newVals.push({ id: val.id, title: val.title }));
    const arrayUniqueByTitle = [...new Map(newVals.map((item) => [item['title'], item])).values()];
    setValues(arrayUniqueByTitle);
    onHandleChange(arrayUniqueByTitle);
  };

  const handleAutoCompleteChange = (_event, newValue, _reason, details) => {
    if (!!!newValue) return;
    if (disabled === true) return;
    switch (true) {
      case typeof newValue === 'string':
        setSearchInputValue(newValue);
        setValues({ title: newValue });
        onHandleChange(newValue);
        break;
      case !!isCreatable:
        handleIsCreatable(details, newValue, _reason, _event.target);
        break;
      case !!isMultiple:
        setValues(newValue);
        onHandleChange(newValue);
        break;
      default:
        setValues(newValue);
        setSearchInputValue(newValue.title);
        onHandleChange(newValue);
    }
    //single
  };

  return (
    <FormControl fullWidth sx={{ textAlign: 'left', ...parentSx }}>
      {label && label !== '' && (
        <FormLabel error={!!error && error !== ''} htmlFor={id} sx={styleLabel}>
          {label}
        </FormLabel>
      )}
      <Autocomplete
        id={id}
        multiple={isMultiple || false}
        size="normal"
        sx={{ bgcolor: 'white', ...sx, marginRight: 0 }}
        open={searchOpen}
        onOpen={() => setSearchOpen(true)}
        onClose={() => setSearchOpen(false)}
        inputValue={isSkillsInput ? inputValue : searchInputValue}
        disabled={disabled}
        onInputChange={(_event, newInputValue, reason) => {
          setSearchInputValue(newInputValue);
          if (newInputValue === '' && reason === 'clear') {
            setValues([]);
            setInputValue('');
            onHandleChange({ id: ' ', title: '' });
          }
        }}
        value={values}
        freeSolo={handleFreeSolo}
        onChange={handleAutoCompleteChange}
        isOptionEqualToValue={(option, value) => option?.id === value?.id}
        getOptionLabel={(option) => {
          return option[labelField] || '';
        }}
        getOptionDisabled={getOptionDisabled}
        options={opts}
        loading={loading}
        data-testid={`${id}-test`}
        ListboxProps={{ 'data-testid': `${id}-list-box-test` }}
        clearOnBlur={handleClearOnBlur ?? (isMultiple || false)}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          const { inputValue } = params;
          // Suggest the creation of a new value
          const isExisting = options.some((option) => inputValue === option.title);
          if (inputValue !== '' && !isExisting && isCreatable && !onlyContainSpaces(inputValue)) {
            filtered.push({
              inputValue: inputValue.trimStart(),
              title: `Add "${inputValue}"`,
            });
          }
          return filtered;
        }}
        renderInput={(params) => (
          <TextField
            multiline={renderInputMultiline}
            rows={renderInputMultiline ? 5 : 0}
            sx={{}}
            {...params}
            label={''}
            InputLabelProps={InputLabelProps || { shrink: true }}
            error={!!error}
            variant="outlined"
            placeholder={placeholder || ''}
            InputProps={{
              ...params.InputProps,
              ...props.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
        renderTags={(_values, getTagProps) => {
          return _values.map((option, index) => (
            <Chip
              label={option.title}
              {...getTagProps({ index })}
              variant="select-tag"
              deleteIcon={<ClearIcon />}
              disabled={minReqOpts && _values.length <= minReqOpts ? true : false}
            />
          ));
        }}
      />
      {error && (
        <Typography
          variant="caption"
          sx={{ color: 'error.main', textAlign: 'left', display: 'block', marginTop: '3px', ...styleErrorControl }}
          id={`${id}-error`}
        >
          {error}
        </Typography>
      )}
    </FormControl>
  );
};

InputAutocomplete.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  labelField: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.number, PropTypes.string]),
  error: PropTypes.string,
  sx: PropTypes.object,
  renderInputMultiline: PropTypes.bool,
  onHandleChange: PropTypes.func.isRequired,
  onSearch: PropTypes.func,
  minReqOpts: PropTypes.number,
  isSkillsInput: PropTypes.bool,
  handleFreeSolo: PropTypes.bool,
  reset: PropTypes.bool,
  isMultiple: PropTypes.bool,
  disabled: PropTypes.bool,
  styleErrorControl: PropTypes.object,
  defaultOpts: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.bool]),
};

export default InputAutocomplete;
