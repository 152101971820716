import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';

const SkillPills = ({ rate, onHandleRate, disabled, stylePill, labelPill }) => {
  const pills = [];

  const pillStyles = makeStyles({
    pill: {
      height: 15,
      width: 30,
      backgroundColor: '#ccc',
      marginLeft: 5,
      ...stylePill,
      cursor: disabled ? 'normal' : 'pointer',
    },
    colorLow: { backgroundColor: '#e84741' },
    colorMid: { backgroundColor: '#ef9148' },
    colorHigh: { backgroundColor: '#7df48b' },
  });
  const classes = pillStyles();

  for (let i = 0; i < 5; i++) {
    pills.push(
      <Box key={i}>
        <div
          className={clsx(classes.pill, {
            [classes.colorLow]: rate === 1 && i < 1,
            [classes.colorMid]: i < rate && rate > 1 && rate < 4,
            [classes.colorHigh]: i + 1 <= rate && rate >= 4,
          })}
          onClick={(e) => {
            if (!disabled) onHandleRate(i + 1);
          }}
        ></div>
        {labelPill && rate === i + 1 ? (
          <Typography
            sx={{
              fontSize: '12px',
              textAlign: 'center',
              marginBottom: 2,
            }}
          >
            {rate === 1 && i < 1 && 'BAD'}
            {i < rate && rate > 1 && rate < 4 && 'GOOD'}
            {i + 1 <= rate && rate >= 4 && 'EXCELLENT'}
          </Typography>
        ) : null}
      </Box>
    );
  }
  return pills;
};

SkillPills.propTypes = {
  rate: PropTypes.number.isRequired,
  onHandleRate: PropTypes.func,
  disabled: PropTypes.bool.isRequired,
  stylePill: PropTypes.object,
  labelPill: PropTypes.bool,
};

export default SkillPills;
