import { useCallback, useEffect, useState } from 'react';

import httpService from '../../services/httpService';
import useIsMounted from '../useIsMounted';

export default function useCustomers(params = {}) {
  const [customers, setCustomers] = useState([]);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const isMounted = useIsMounted();

  const fetchCustomers = useCallback(async () => {
    try {
      setIsLoading(true);
      const { data } = await httpService.get(process.env.REACT_APP_API_BACKEND + '/v1/clients', { params: params });
      if (!isMounted.current) return;
      setCustomers(data);
      setIsLoading(false);
    } catch (error) {
      setError(true);
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  const refetch = useCallback(() => {
    setCustomers([]);
    fetchCustomers();
  }, [fetchCustomers]);

  useEffect(() => {
    fetchCustomers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { customers, isLoading, error, refetch };
}
