import { useEffect, useState } from 'react';

import ClientForm from './ClientForm';

import { Accordion, AccordionDetails, AccordionSummary, Box, Container, Typography } from '@mui/material';

import Button from '@mui/material/Button';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ROLE_SUPERTAS } from 'constants/users';
import { connect } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import clientService from '../../../services/clientService';
import ActiveProjectList from './ActiveProjectList';

const ClientFormLayout = (props) => {
  const [counters, setCounters] = useState({});
  const [editing, setEditing] = useState(false);
  const [projects, setProjects] = useState([]);
  const { CustomerId, modeCustomer } = useParams();

  let navigate = useNavigate();

  useEffect(() => {
    (async () => {
      if (CustomerId) {
        const { data } = await clientService.getClientProjects(CustomerId, 1);
        if (modeCustomer === 'view') setEditing(true);
        else setEditing(false);
        setProjects(data);
      }
    })();
  }, [CustomerId, modeCustomer]); // eslint-disable-line

  const [expanded, setExpanded] = useState(true);

  const boxStyle = {
    borderRadius: '4px',
    height: '100%',
  };
  const maxWidth = props.maxWidth || 'xl';

  const handleSubmit = async (newId) => {
    toast.success('Customer created!');
    return navigate(`/tas/customer/${newId}/view`);
  };

  return (
    <Box style={boxStyle}>
      <Container maxWidth={maxWidth}>
        {/*  FORM SECTION  */}
        <ClientForm
          CustomerId={CustomerId}
          isDisabled={editing}
          mode={modeCustomer}
          onValidForm={handleSubmit}
          onResetForm={() => navigate(0)}
          onChangeMode={() => navigate(`/tas/customer/${CustomerId}/edit`)}
          onUpdateCounter={(counters) => setCounters({ ...counters })}
        />

        {/*  PROJECTS SECTION  */}
        {CustomerId && (
          <Accordion
            elevation={0}
            onChange={(e, expanded) => {
              setExpanded(expanded);
            }}
            expanded={expanded}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                id="panel1a-header"
                sx={{
                  border: 'none',
                  padding: '2px',
                  margin: '0px',
                  marginY: 0,
                }}
              >
                <Box sx={{ display: 'flex', fontWeight: 'bold', fontSize: '16px', color: 'green' }}>
                  {counters.activeProjectCount}
                  <Typography sx={{ fontWeight: 'bold', fontSize: '16px', color: 'black', marginLeft: 1 }}>
                    &nbsp;ACTIVE PROJECTS
                  </Typography>
                </Box>
              </AccordionSummary>
              {[ROLE_SUPERTAS].includes(props.auth.user.role) && (
                <Button component={Link} to={'/tas/projects/create'} variant="text" sx={{ marginLeft: 5 }}>
                  <Typography display="flex" sx={{ fontWeight: 'bold', fontSize: '16px', color: 'green' }}>
                    + ADD A NEW PROJECT
                  </Typography>
                </Button>
              )}
            </Box>
            <AccordionDetails
              sx={{
                padding: '8px',
                margin: '8px 0',
                background: 'white',
                width: '100%',
                borderColor: 'nexxusGrayMid.main',
                borderStyle: 'solid',
                borderWidth: '1px',
              }}
            >
              <ActiveProjectList projects={projects} activeProjectCount />
            </AccordionDetails>
          </Accordion>
        )}
      </Container>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientFormLayout);
