import { CANDIDATEPROJECT_STATUS } from 'constants/candidate_project';
import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { DownloadIcon } from '../../assets/icons/index';
import projectService from '../../services/projectService';
import { availabilityOptions, currentTitle, seniority, timezoneOpts } from '../../utils/staticData';

const headers = [
  { label: 'Project Description', key: 'description' },
  { label: 'Project Position', key: 'position' },
  { label: 'Project Seniority', key: 'seniority' },
  { label: 'Project Skills', key: 'projectSkills' },
  { label: 'Candidate FullName', key: 'candFullname' },
  { label: 'Candidate Project - Status', key: 'candStatus' },
  { label: 'Candidate Skills', key: 'candSkills' },
  { label: 'Candidate Email', key: 'candEmail' },
  { label: 'Candidate Location', key: 'candLocation' },
  { label: 'Candidate Availability', key: 'candAvailability' },
  { label: 'Candidate Linkedin', key: 'candLinkedin' },
  { label: 'Candidate Seniority', key: 'candSeniority' },
  { label: 'Candidate Current Title', key: 'candTitle' },
  { label: 'Candidate Salary expectation', key: 'candSalaryExp' },
  { label: 'Candidate NCS Score', key: 'candNcs' },
  { label: 'Candidate Timezone', key: 'candTimezone' },
];

const ProjectReportLink = ({ project, color }) => {
  const [candidates, setCandidates] = useState([]);
  let csvLinkEl = useRef();

  useEffect(() => {
    if (candidates.length > 0) {
      csvLinkEl.link.click();
    }
  }, [candidates]);

  const getCandidates = async (projectId) => {
    const { data } = await projectService.getProjectCandidates(projectId);
    // description: data.description.replace(/(\r\n|\n|\r)/gm, "").trim(),
    const position = currentTitle.find((pos) => pos._id === data.position);
    const senior = seniority.find((pos) => pos._id === data.seniority);
    const pjSkills = data.Skills.map((skill) => skill.name);

    const dataPj = {
      description: data.description.replace('"', '').trim(),
      position: position?.label || '',
      seniority: senior?.label || '',
      projectSkills: pjSkills.join(','),
    };
    let rows;
    if (data.Candidates.length > 0) {
      rows = data.Candidates.map((cand) => {
        const status = _.findKey(CANDIDATEPROJECT_STATUS, function (el) {
          return el === cand.Project_Candidate.status;
        });
        const timezone = timezoneOpts.find((time) => time._id === cand.timezone);
        const senior = seniority.find((sen) => sen._id === cand.developer_seniority);
        const title = currentTitle.find((title) => title._id === cand.developer_title);
        const availability = availabilityOptions.find((av) => av._id === cand.availability);
        const candSkills = cand.Skills.map((skill) => skill.name);
        return {
          ...dataPj,
          candFullname: cand.full_name,
          candStatus: status || ' ',
          candSkills: candSkills.join(','),
          candEmail: cand.email,
          candLocation: cand.location,
          candAvailability: availability?.label || ' ',
          candLinkedin: cand.linkedin,
          candSeniority: senior?.label || ' ',
          candTitle: title?.label || ' ',
          candSalaryExp: cand.salary_expectation,
          candNcs: cand.candidate_ncs,
          candTimezone: timezone?.label || ' ',
        };
      });
    } else {
      rows = [{ ...dataPj }];
    }

    setCandidates(rows);
  };

  return (
    <>
      <DownloadIcon color={color} onClick={() => getCandidates(project.id)}></DownloadIcon>
      <CSVLink
        headers={headers}
        uFEFF={true}
        filename={`${project.Client.companyName.replaceAll(' ', '')}-${project.position.replaceAll(' ', '')}`}
        data={candidates}
        ref={(ref) => (csvLinkEl = ref)}
      />
    </>
  );
};

export default ProjectReportLink;
