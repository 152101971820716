import axios from 'axios';
import auth from './authService';

axios.interceptors.request.use(
  (config) => {
    const tokens = JSON.parse(auth.getAllTokens());
    if (!tokens) return Promise.reject();

    config.headers['Authorization'] = `Bearer ${tokens.access.token}`;
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.defaults.headers.common['Content-Type'] = 'application/json';
const apiAllTokens = 'tokens';

export function setTokens(tokens) {
  return localStorage.setItem('tokens', JSON.stringify(tokens));
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  patch: axios.patch,
  delete: axios.delete,
  create: axios.create,
  setTokens,
  apiAllTokens,
};
