import { useCallback, useEffect, useState } from 'react';
import http from 'services/httpService';

export default function useFetchLogs({ params, runAtStart = false, runOnChange = false }) {
  const [logs, setLogs] = useState([]);
  const [error, setError] = useState(false);
  const [hasFetched, setHasFetched] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const fetchLogs = useCallback(async () => {
    try {
      setIsLoading(true);
      const { data } = await http.get(`${process.env.REACT_APP_API_BACKEND}/v1/logs`, { params });
      setLogs(data);
      setIsLoading(false);
    } catch (error) {
      setError(true);
      setIsLoading(false);
    }
  }, [params]);

  const refetch = useCallback(() => {
    setLogs([]);
    fetchLogs();
  }, [fetchLogs]);

  useEffect(() => {
    const asyncFetch = async () => {
      if ((runAtStart && !hasFetched) || runOnChange) {
        await fetchLogs();
        setHasFetched(true);
      }
    };
    asyncFetch();
  }, [fetchLogs, hasFetched, runAtStart, runOnChange]);

  return { logs, isLoading, error, refetch };
}
