import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Box, Paper, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/system';
import InputSelectV2 from 'components/FormElement/InputSelectV2';
import TabsCandidateOverview from 'components/TabsCandidateOverview/TabsCandidateOverview';
import { useCustomers } from 'hooks/request';
import useCandidateProjectStatus from 'hooks/useCandidateProjectStatus';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { capitalizeString } from 'utils/utils';
import MainLayout from '../../components/MainLayout/MainLayout';
import projectService from '../../services/projectService';
import { loginExpire } from '../../store/auth';

const Candidates = (props) => {
  const [projects, setProjects] = useState([]);
  const [customerId, setCustomerId] = useState('');
  const theme = useTheme();
  const [client, setClient] = useState('');
  let { ProjectId: projID } = useParams();
  const { customers } = useCustomers();
  const { candidates, projectId, setStatusSelected, setProjectId, setCandidates } = useCandidateProjectStatus();

  //init Hook
  useEffect(() => {
    (async () => {
      if (customerId !== '') {
        const { data } = await projectService.getProjectsByClient(customerId);
        const projs = data.map((pj) => ({
          ...pj,
          _id: pj.id,
          label: `${pj.Client.companyName} - ${capitalizeString(pj.position)} - ${capitalizeString(pj.seniority)}`,
        }));
        setClient(projs[0].Client.companyName);
        setProjectId('');
        setProjects(projs);
        setCandidates([]);
        setStatusSelected('');
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId]);

  useEffect(() => {
    if (projID) {
      setProjectId(projID);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ExclamationIcon = styled(ErrorOutlineIcon)(({ theme }) => ({
    transform: 'rotate(180deg)',
    mt: 0.5,
  }));

  return (
    <>
      <MainLayout {...props} subtitle="Candidate" subtitleFocus="Overview" current="projects">
        <Box sx={{ paddingX: 0 }}>
          <Box
            sx={{
              height: '180px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              background: theme.palette.gradients.dark.lr,
              color: 'white',
            }}
          >
            <div style={{}}>
              <Typography variant="h5" align="center" pt={1} pb={1} sx={{ textTransform: 'uppercase' }}>
                <strong>{client}</strong>
              </Typography>

              <Typography variant="h4" align="center" pt={0} pb={1} sx={{ textTransform: 'uppercase' }}>
                <strong style={{ letterSpacing: '.2em' }}>Candidate</strong>{' '}
                <span style={{ fontWeight: '300' }}>Overview</span>
              </Typography>
            </div>
          </Box>
          <Stack
            direction="row"
            spacing={1}
            sx={{
              mt: 4,
              mx: 2,
              [theme.breakpoints.down('md')]: {
                flexWrap: 'wrap',
              },
            }}
          >
            <Box
              sx={(theme) => ({
                width: '50%',
                [theme.breakpoints.down('md')]: {
                  width: '100%',
                },
              })}
            >
              <Box
                sx={{
                  textAlign: 'left',
                  marginTop: 1,
                  marginRight: 2,
                  marginBottom: 2,
                }}
              >
                <Typography
                  sx={{
                    color: 'nexxusGrayMid.main',
                    fontSize: 12,
                    textAlign: 'left',
                    marginTop: 3,
                    fontWeight: 'bold',
                  }}
                >
                  SELECT A CUSTOMER
                </Typography>
                <InputSelectV2
                  id={'projectId'}
                  size={'normal'}
                  opts={customers.map((customer) => ({ _id: customer.id, label: customer.companyName }))}
                  initialValue={customerId}
                  onChangeInput={(e) => setCustomerId(e.target.value)}
                />
              </Box>
            </Box>
            <Box
              sx={(theme) => ({
                width: '50%',
                [theme.breakpoints.down('md')]: {
                  width: '100%',
                },
              })}
            >
              <Box
                sx={{
                  textAlign: 'left',
                  marginTop: 1,
                  marginRight: 2,
                  marginBottom: 2,
                }}
              >
                <Typography
                  sx={{
                    color: 'nexxusGrayMid.main',
                    fontSize: 12,
                    textAlign: 'left',
                    marginTop: 3,
                    fontWeight: 'bold',
                  }}
                >
                  SELECT A PROJECT FROM CUSTOMER
                </Typography>
                <InputSelectV2
                  id={'projectId'}
                  size={'normal'}
                  opts={projects}
                  initialValue={projectId}
                  onChangeInput={(e) => setProjectId(e.target.value)}
                />
              </Box>
            </Box>
            <Box
              sx={{
                flexShrink: 1,
                width: '57%',
                [theme.breakpoints.down('md')]: {
                  width: '100%',
                },
              }}
            >
              <Stack direction="row" spacing={1} sx={{ mt: 4 }}>
                <Box>
                  <ExclamationIcon />
                </Box>
                <Box>
                  <Typography variant="h6" sx={{ fontWeight: 900, fontSize: 14 }}>
                    WHAT'S NCS ?
                  </Typography>
                  <Typography variant="body1" sx={{ mt: 0, fontSize: 10 }}>
                    The Nexxus Compatibility Score (NCS) formula is based off of data collected during our interview and code
                    testing process. This evaluation combined with the project specifics calculates the NCS score out of 100.
                  </Typography>
                </Box>
              </Stack>
            </Box>
          </Stack>
          <Paper elevation={0}></Paper>
          <TabsCandidateOverview candidates={candidates} projectId={projectId} setStatusSelected={setStatusSelected} />
        </Box>
      </MainLayout>
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return {
    loginExpire: (user) => {
      dispatch(loginExpire(user));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Candidates);
