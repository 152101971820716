import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import MainLayout from 'components/MainLayout/MainLayout';
import { ROLE_SUPERCUSTOMER, ROLE_SUPERTAS } from 'constants/users';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { loginExpire } from 'store/auth';

const Page404 = (props) => {
  const {
    auth: { user },
  } = props;

  let pathname = user.role;
  if (pathname === ROLE_SUPERTAS) pathname = 'tas';
  if (pathname === ROLE_SUPERCUSTOMER) pathname = 'customer';

  return (
    <MainLayout {...props} current="dashboard">
      <Stack direction="column" justifyContent="space-between" sx={{ my: 8, textAlign: 'center' }}>
        <Typography variant="h1" sx={{ fontWeight: 900, fontSize: '3em', color: '#b7b7b7' }}>
          OOPS!
        </Typography>
        <Typography variant="h1" sx={{ fontWeight: 900, fontSize: '16em', color: '#b7b7b7' }}>
          404
        </Typography>
        <Typography variant="h1" sx={{ fontWeight: 900, fontSize: '3em', color: '#b7b7b7' }}>
          THIS PAGE DOES NOT EXIST
        </Typography>
        <Button
          component={Link}
          to={typeof user !== 'undefined' ? `/${pathname}/dashboard` : '/login'}
          variant="text"
          sx={{ mt: 4, '&:hover': { background: 'none', textDecoration: 'underline' } }}
        >
          <Typography display="flex" sx={{ fontWeight: 'bold', fontSize: '2em', color: '#009eff' }}>
            RETURN TO HOME
          </Typography>
        </Button>
      </Stack>
    </MainLayout>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return {
    loginExpire: (user) => {
      dispatch(loginExpire(user));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Page404);
