import moment from 'moment';
import http from './httpService';

export async function login(user) {
  const body = { ...user };
  const instance = http.create();

  instance.defaults.headers.common['Content-Type'] = 'application/json';
  instance.interceptors.request.use(null, (error) => {
    Promise.reject(error);
  });
  instance.interceptors.response.use((response) => {
    return response;
  });

  const { data } = await instance.post(process.env.REACT_APP_API_BACKEND + '/v1/auth/login', {
    email: body.email,
    password: body.password,
  });

  http.setTokens(data.tokens);
  localStorage.setItem('currentUser', JSON.stringify(data.user));
  localStorage.setItem('lastLogin', moment(new Date()).format('DD/MM/yyyy h:mm:ss a'));

  if (localStorage.getItem('remember') && localStorage.getItem('remember') === body.email) {
    if (body.remember === true) localStorage.setItem('remember', body.email);
    else localStorage.removeItem('remember');
  } else {
    if (body.remember === true) localStorage.setItem('remember', body.email);
  }

  return data;
}

export async function logout() {
  const tokens = JSON.parse(localStorage.getItem(http.apiAllTokens));
  if (tokens) {
    const body = { refreshToken: tokens.refresh.token };
    await http.post(`${process.env.REACT_APP_API_BACKEND}/v1/auth/logout`, body).catch((error) => {
      console.log(error);
    });
  }
  localStorage.removeItem(http.apiAllTokens);
  localStorage.removeItem('currentUser');
  localStorage.removeItem('lastLogin');
}

export async function getCurrentUser() {
  return http.get(`${process.env.REACT_APP_API_BACKEND}/v1/users/me`);
}

export function getAllTokens() {
  return localStorage.getItem(http.apiAllTokens);
}

export async function getRememberUser() {
  return localStorage.getItem('remember');
}

export async function forgotPassword(email) {
  const instance = http.create();
  instance.defaults.headers.common['Content-Type'] = 'application/json';

  const { data } = await instance.post(process.env.REACT_APP_API_BACKEND + '/v1/auth/forgot-password', {
    email: email,
  });

  return data;
}

export async function resetPassword(password, token) {
  const instance = http.create();
  instance.defaults.headers.common['Content-Type'] = 'application/json';

  const { data } = await instance.post(
    process.env.REACT_APP_API_BACKEND + '/v1/auth/reset-password',
    { password: password },
    {
      params: {
        token: token,
      },
    }
  );

  return data;
}

export async function checkExpToken(token) {
  const instance = http.create();
  instance.defaults.headers.common['Content-Type'] = 'application/json';
  const { data } = await instance.post(process.env.REACT_APP_API_BACKEND + '/v1/auth/check-token', {
    token: token,
  });

  return data;
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  login,
  logout,
  getCurrentUser,
  getAllTokens,
  forgotPassword,
  resetPassword,
  checkExpToken,
  getRememberUser,
};
