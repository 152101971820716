import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';

import UploadFileIcon from '@mui/icons-material/UploadFile';
import { styled, useTheme } from '@mui/styles';

const InputFileWithText = ({
  id,
  accept,
  error,
  styleLabel,
  styleError,
  label,
  placeholder,
  styleControl,
  onFileChange,
  reset,
  defaultFileName,
  isDisabled,
}) => {
  const [file, setFile] = useState('');
  const theme = useTheme();

  useEffect(() => {
    (async () => {
      if (reset && reset === true) {
        setFile('');
      }
    })();
  }, [reset]);

  useEffect(() => {
    (async () => {
      if (defaultFileName && defaultFileName !== '') {
        setFile(defaultFileName);
      }
    })();
  }, [defaultFileName]);

  let inputFileRef = useRef();

  const Input = styled('input')({
    display: 'none',
  });

  return (
    <FormControl fullWidth sx={{ textAlign: 'left', ...styleControl }}>
      {label && label !== '' && (
        <FormLabel disabled={isDisabled} error={!!error && error !== ''} htmlFor={id} sx={styleLabel}>
          {label}
        </FormLabel>
      )}

      <Paper
        sx={{
          minHeight: 56,
          borderWidth: theme.variables.inputs.borderWidth,
          borderStyle: 'solid',
          borderColor: theme.variables.inputs.borderColor,
          boxShadow: 0,
          borderRadius: theme.variables.inputs.borderRadius,
          p: '2px 4px',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <InputBase
          sx={{ ml: 1, flex: 1, fontSize: 12 }}
          placeholder={placeholder || ''}
          disabled={isDisabled}
          inputProps={{ 'aria-label': 'search google maps' }}
          readOnly={true}
          value={file}
        />

        <Input
          ref={(ref) => (inputFileRef = ref)}
          accept={accept.join(',')}
          id={id}
          type="file"
          disabled={isDisabled}
          onChange={(e) => {
            if (e.target.files[0]) {
              const fileName = e.target.files[0].name;
              if (accept.includes(e.target.files[0].type)) {
                setFile(fileName);
                onFileChange(e);
              }
            }
          }}
        />
        <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
        <IconButton
          type="button"
          disabled={isDisabled}
          sx={{ p: '10px', '&.Mui-disabled': { opacity: '.5' } }}
          onClick={() => {
            inputFileRef.click();
          }}
        >
          <UploadFileIcon color="primary" />
        </IconButton>
      </Paper>
      {error && (
        <FormHelperText id={id} sx={{ styleError }}>
          error
        </FormHelperText>
      )}
    </FormControl>
  );
};

InputFileWithText.propTypes = {
  id: PropTypes.string.isRequired,
  accept: PropTypes.arrayOf(PropTypes.string),
  error: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  reset: PropTypes.bool,
  defaultFileName: PropTypes.string,
  onFileChange: PropTypes.func,
  styleControl: PropTypes.object,
  styleLabel: PropTypes.object,
  styleError: PropTypes.object,
};

export default InputFileWithText;
