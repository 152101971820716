import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { Box, Button, Container, Grid } from '@mui/material';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';
import InputProfileImage from 'components/FormElement/InputProfileImage';
import InputTextOutlinedV2 from 'components/FormElement/InputTextOutlinedV2';
import MainLayout from 'components/MainLayout/MainLayout';
import { useCurrentUser } from 'hooks/useCurrentUser';
import MeEmailForm from 'pages/me/meEmailForm';
import MePasswordForm from 'pages/me/mePasswordForm';
import ProfileFields, { profileInitialState } from 'pages/me/profileFields';
import { updateFileWithFilesValue } from 'pages/me/profileUploads';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import userService from 'services/userService';
import { loginExpire, loginSuccess } from 'store/auth';

const Profile = (props) => {
  const [files, setFiles] = useState('');
  const [validating, setValidating] = useState(false);
  const { currentUser } = useCurrentUser();

  const { fields, handleSave, errors, handleSubmit, validSubmit, initialState, setForm, setErrors } =
    ProfileFields(profileInitialState);

  useEffect(() => {
    (async () => {
      await currentUser;
      if (currentUser) {
        setForm({
          ...initialState,
          first_name: currentUser.first_name,
          last_name: currentUser.last_name,
          phone: currentUser?.phone || '',
          email: currentUser.email,
          avatar: currentUser?.avatar || '',
        });
        setErrors({ ...initialState });
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  const maxWidth = props.maxWidth || 'xl';

  useEffect(() => {
    if (files.length === 0) setForm({ ...fields, avatar: '' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  useEffect(() => {
    (async () => {
      if (validSubmit) {
        const newData = { ...fields };
        if (files.length > 0) {
          const url = await updateFileWithFilesValue(files, currentUser.id);
          newData['avatar'] = url[0];
        }
        delete newData['email'];
        const { data } = await userService.updateMeInfo(newData);
        props.loginSuccess({ ...props.auth, user: data });
        localStorage.setItem('currentUser', JSON.stringify(data));
        toast.success('Update profile success!');
        setValidating(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validSubmit]);

  return (
    <MainLayout {...props} current="reports">
      <Box>
        <Container maxWidth={maxWidth}>
          <AvatarProfile currentUser={props.auth.user} fields={fields} marginBottom="4em" onHandleFileChange={setFiles} />
          <InformationProfile
            validating={validating}
            setValidating={setValidating}
            fields={fields}
            errors={errors}
            onChange={handleSave}
            trySubmit={() => handleSubmit(fields)}
          />
        </Container>
      </Box>
    </MainLayout>
  );
};

const AvatarProfile = ({ currentUser, fields, onHandleFileChange, marginTop = '', marginBottom = '' }) => {
  const [logoUrlPreview, setLogoUrlPreview] = useState('');
  const [logoUrl, setLogoUrl] = useState('');

  const handleDrop = (files) => {
    if (files.length > 1 || !files[0].name) return;
    setLogoUrlPreview(URL.createObjectURL(files[0]));
    onHandleFileChange(files);
  };

  useEffect(() => {
    if (fields.avatar && fields.avatar !== '') setLogoUrl(fields.avatar);
  }, [fields.avatar]);

  const setAvatar = () => logoUrlPreview || logoUrl || '';

  return (
    <Grid
      container
      spacing={2}
      sx={{
        justifyContent: 'start',
        alignItems: 'center',
        marginTop: marginTop ?? '0px',
        marginBottom: marginBottom ?? '0px',
      }}
    >
      <Grid
        item
        xs={12}
        md={'auto'}
        sx={(theme) => ({
          [theme.breakpoints.down('md')]: {
            textAlign: 'center',
            marginBottom: '1em',
          },
        })}
      >
        <Typography
          variant="h4"
          align="left"
          pt={1}
          pb={1}
          sx={(theme) => ({
            [theme.breakpoints.down('md')]: {
              textAlign: 'center',
            },
            textTransform: 'uppercase',
          })}
        >
          Welcome <strong>{currentUser?.first_name}!</strong>
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        md={'auto'}
        sx={(theme) => ({
          [theme.breakpoints.down('md')]: {
            marginLeft: 'auto',
            marginRight: 'auto',
          },
          marginLeft: 'auto',
        })}
      >
        <Stack spacing={2} sx={{ display: 'inline-flex', alignItems: 'center', width: '100%' }}>
          <InputProfileImage
            onHandleDrop={handleDrop}
            isDisabled={false}
            isCompany={false}
            name={' '}
            avatar={setAvatar()}
            accept={['image/jpeg', 'image/png', 'image/gif']}
            onHandleDelete={(e) => {
              setLogoUrl('');
              setLogoUrlPreview('');
              onHandleFileChange([]);
            }}
          />
        </Stack>
      </Grid>
    </Grid>
  );
};

const CustomButton = styled(Button)({
  padding: '10px 30px',
});

const InformationProfile = ({ fields, errors, onChange, trySubmit, validating, setValidating }) => {
  const handleSave = () => {
    setValidating(true);
    trySubmit();
  };

  return (
    <>
      <Grid
        container
        spacing={2}
        sx={{
          justifyContent: 'start',
          alignItems: 'center',
        }}
      >
        <Grid item xs={12}>
          <Typography variant="h6" sx={{ margin: '6px' }} gutterBottom>
            Personal Information
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider light sx={{ marginBottom: '8px' }} />
        </Grid>
        <Grid item xs={12}>
          <InputTextOutlinedV2
            id="first_name"
            label="First Name"
            placeholder=""
            onChangeInput={onChange}
            initialValue={fields?.first_name || ''}
            error={errors.first_name?.txt}
          />
        </Grid>
        <Grid item xs={12}>
          <InputTextOutlinedV2
            id="last_name"
            label="Last Name"
            placeholder=""
            onChangeInput={onChange}
            initialValue={fields?.last_name || ''}
            error={errors.last_name?.txt}
          />
        </Grid>
        <Grid item xs={12}>
          <InputTextOutlinedV2
            id="phone"
            label="Phone"
            placeholder=""
            onChangeInput={onChange}
            initialValue={fields?.phone || ''}
            error={errors.phone?.txt}
          />
        </Grid>
        <Grid item xs={12}>
          <div style={{ display: 'flex', gap: '1em', justifyContent: 'end', marginTop: '2em' }}>
            <CustomButton
              startIcon={<SaveAltIcon sx={{ fontSize: '2em' }} />}
              color="success"
              variant="contained"
              onClick={!validating ? handleSave : null}
            >
              {!validating ? 'Save' : 'Validating...'}
            </CustomButton>
          </div>
        </Grid>

        <Grid item xs={12} sx={{ marginTop: '2em' }}>
          <Typography variant="h6" sx={{ margin: '6px' }} gutterBottom>
            Your Email Address
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider light sx={{ marginTop: '0px', marginBottom: '2em' }} />
        </Grid>

        <Grid item xs={12} md={8} lg={6}>
          <Box
            sx={(theme) => ({
              [theme.breakpoints.down('md')]: {
                flexDirection: 'column',
              },
              display: 'flex',
              gap: '1em',
            })}
          >
            <MeEmailForm></MeEmailForm>
            <MePasswordForm />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return {
    loginExpire: (user) => {
      dispatch(loginExpire(user));
    },
    loginSuccess: (user) => {
      dispatch(loginSuccess(user));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
