import MainLayout from 'components/MainLayout/MainLayout';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { loginExpire } from 'store/auth';

import Container from '@mui/material/Container';
import { useNavigate, useParams } from 'react-router-dom';
import authService from 'services/authService';
import projectService from 'services/projectService';
import OverviewSummary from './projectOverview/OverviewSummary';

const ProjectOverview = (props) => {
  const maxWidth = props.maxWidth || 'xl';
  const [clientId, setClientId] = useState(false);
  const [project, setProject] = useState('');
  const { ProjectId } = useParams();
  const history = useNavigate();
  const user = props.auth.user;

  //init Hook
  useEffect(() => {
    (async () => {
      try {
        const { data: user } = await authService.getCurrentUser();
        setClientId(user.Client.id);
        if (!!ProjectId) {
          const { data } = await projectService.getProject(ProjectId);
          setProject(data);
        }
      } catch (e) {
        if (e.response.data.code === 422) history('/404');
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MainLayout {...props} subtitle="Project" subtitleFocus="Overview" current="projects">
      <Container maxWidth={maxWidth}>
        <OverviewSummary user={user} clientId={clientId} projectDB={project} />
      </Container>
    </MainLayout>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return {
    loginExpire: (user) => {
      dispatch(loginExpire(user));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectOverview);
