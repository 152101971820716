import { Stack } from '@mui/material';
import { useTheme } from '@mui/system';
import InputTextOutlinedV2 from 'components/FormElement/InputTextOutlinedV2';
import ModalFeedback from 'components/Generic/Modal/ModalFeedback';
import { CANDIDATEPROJECT_STATUS } from 'constants/candidate_project';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import customerService from 'services/customerService';
import { capitalizeString } from 'utils/utils';

const ModalOverview = (props) => {
  const [feedback, setFeedback] = useState('');
  const [statusToFeedback, setStatusToFeedback] = useState('');
  const [projSelected, setProjSelected] = useState({ name: '', feedback: '' });
  const { candidate, project, open, handleCloseModal, onCloseModalFeedUpdated } = props;
  const theme = useTheme();

  useEffect(() => {
    (async () => {
      if (candidate) {
        const { data } = await customerService.getCustomerFeedback(project, candidate.id);

        const actualStatus = Object.keys(CANDIDATEPROJECT_STATUS).find(
          (key) => CANDIDATEPROJECT_STATUS[key] === data.Project_Candidates[0].status
        );

        const feed = _.has(data.Project_Candidates[0], `customer_feedback.${actualStatus}`)
          ? data.Project_Candidates[0].customer_feedback[actualStatus].feedback
          : '';
        if (data.Project_Candidates && data.Project_Candidates.length > 0) {
          Promise.all([
            setStatusToFeedback(actualStatus),
            setProjSelected({
              name: capitalizeString(data.Project_Candidates[0].Project.position),
              feedback: feed,
            }),
            setFeedback(feed),
          ]);
        } else {
          setStatusToFeedback(actualStatus);
        }
      }
    })();
  }, [candidate, project]);

  const handleFeedback = async (e) => {
    await customerService.saveCustomerFeedback({
      feedback: feedback,
      statusFeed: statusToFeedback,
      candidateId: candidate.id,
      projectId: project,
    });
    toast.success('Feedback saved!');
    onCloseModalFeedUpdated(candidate.id);
  };

  const onCloseModal = () => {
    setFeedback('');
    handleCloseModal();
  };

  return (
    <ModalFeedback
      open={open}
      onClose={onCloseModal}
      onCancel={onCloseModal}
      onAccept={handleFeedback}
      variant="general"
      headerText="CANDIDATE FEEDBACK"
      headerBarColor={theme.palette.info.main}
      btnConfirmText="SUBMIT"
    >
      <Stack spacing={2}>
        <InputTextOutlinedV2
          id={'candidate_name'}
          type="text"
          placeholder="NAME"
          size={'normal'}
          label="CANDIDATE NAME"
          isDisabled={true}
          initialValue={candidate?.full_name || ''}
        />
        <InputTextOutlinedV2
          id={'current_project'}
          type="text"
          placeholder="PROJECT"
          size={'normal'}
          label="CURRENT PROJECT"
          isDisabled={true}
          initialValue={projSelected.name}
        />
        <InputTextOutlinedV2
          id={'customer_feedback'}
          size={'normal'}
          label="CUSTOMER FEEDBACK"
          multiline
          rowsMultiline={4}
          initialValue={feedback}
          onChangeInput={(e) => setFeedback(e.target.value)}
        />
      </Stack>
    </ModalFeedback>
  );
};

export default ModalOverview;
