import { Box, FormLabel, IconButton } from '@mui/material';
import { styled } from '@mui/system';
import SvgItem from 'components/Media/SvgItem';

const SvgButton = ({ iconName, label, fontSize, color, isDisabled, onClick = () => {} }) => {
  const Container = styled(Box)(({ theme }) => ({
    display: 'inline-flex',
    flexDirection: 'column',
    alignItems: 'center',
  }));

  return (
    <Container>
      {label !== '' && <FormLabel>{label}</FormLabel>}
      <IconButton color={color} disabled={isDisabled} sx={{ '&.Mui-disabled': { opacity: '.5' } }} onClick={onClick}>
        <SvgItem name={iconName} width="1em" height="1em" style={{ fontSize: fontSize, padding: 2 }} />
      </IconButton>
    </Container>
  );
};

export default SvgButton;
