import Joi from 'joi';
import candidateService from 'services/candidateService';
import { setValidationMessages } from '../utils/validationMessages';

const methodEmail = async (value, helpers) => {
  try {
    if (helpers.prefs.initialStateRequest.email !== value) await candidateService.getEmailValid(value);
  } catch (e) {
    throw Object.assign(new Error('Email is already taken!'), {
      details: [
        {
          context: {
            label: 'email',
            key: 'email',
          },
          code: 422,
          type: 'any.invalid',
          path: ['email'],
          message: 'Email is already taken!',
        },
      ],
    });
  }
  return true;
};

export const schemaTAS = {
  candidate_id: Joi.string()
    .optional()
    .allow('')
    .messages(setValidationMessages(['string', 'required'])),
  first_name: Joi.string()
    .min(3)
    .max(255)
    .required()
    .messages(setValidationMessages(['string', 'required'])),
  last_name: Joi.string()
    .min(3)
    .max(255)
    .required()
    .messages(setValidationMessages(['string', 'required'])),
  email: Joi.string()
    .required()
    .email({ minDomainSegments: 2, tlds: { allow: false } })
    .external(methodEmail, 'custom validation')
    .messages(setValidationMessages(['string', 'required'])),
  status: Joi.number()
    .integer()
    .valid(5, 10)
    .messages(setValidationMessages(['number', 'required'])),
  blacklisted: Joi.boolean(),
  staff: Joi.boolean(),
  availability: Joi.string()
    .min(3)
    .max(255)
    .required()
    .messages(setValidationMessages(['string', 'required'])),
  availability_interview: Joi.string()
    .min(3)
    .required()
    .messages(setValidationMessages(['string', 'required'])),
  location: Joi.string()
    .min(3)
    .max(255)
    .required()
    .messages(setValidationMessages(['string', 'required'])),
  developer_title: Joi.string()
    .min(3)
    .max(255)
    .required()
    .messages(setValidationMessages(['string', 'required'])),
  developer_seniority: Joi.string()
    .min(3)
    .max(255)
    .required()
    .messages(setValidationMessages(['string', 'required'])),
  employment_type: Joi.string()
    .min(3)
    .max(255)
    .required()
    .messages(setValidationMessages(['string', 'required'])),
  salary_currency: Joi.string()
    .min(3)
    .max(255)
    .required()
    .messages(setValidationMessages(['string', 'required'])),
  salary_expectation: Joi.number()
    .integer()
    .positive()
    .required()
    .messages(setValidationMessages(['number', 'required'])),
  // rate: Joi.number()
  //   .integer()
  //   .min(1)
  //   .required()
  //   .messages(setValidationMessages(['number', 'required'])),
  timezone: Joi.string()
    .min(3)
    .max(255)
    .required()
    .messages(setValidationMessages(['string', 'required'])),
  linkedin: Joi.string()
    .uri()
    .optional()
    .allow('')
    .messages(setValidationMessages(['string', 'required', 'uri'])),
  github_profile: Joi.string()
    .uri()
    .required()
    .allow('')
    .messages(setValidationMessages(['string', 'required', 'uri'])),
  candidate_code_score: Joi.number()
    .integer()
    .positive()
    .optional()
    .allow('', 0)
    .messages(setValidationMessages(['number'])),
  candidate_result_link: Joi.string()
    .uri()
    .optional()
    .allow('')
    .messages(setValidationMessages(['string', 'required', 'uri'])),
  portfolio_link: Joi.string()
    .uri()
    .required()
    .allow('')
    .messages(setValidationMessages(['string', 'required', 'uri'])),
  info_additional: Joi.string()
    .optional()
    .allow('', ' ')
    .messages(setValidationMessages(['string'])),
  tags: Joi.array()
    .min(1)
    .messages(setValidationMessages(['array'])),
  rate_customer: Joi.number()
    .integer()
    .positive()
    .optional()
    .allow('', 0)
    .messages(setValidationMessages(['number'])),
  rate_per_hour: Joi.number()
    .integer()
    .positive()
    .optional()
    .allow('', 0)
    .messages(setValidationMessages(['number'])),
};
