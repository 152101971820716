import { useState } from 'react';

import { Box, Divider, Stack, Typography } from '@mui/material';

import { connect } from 'react-redux';
import { loginExpire } from 'store/auth';
import useProjects from '../../../hooks/request/useProjects';
import FiltersDashboard from './filtersDashboard';
import ProjectsListDashboard from './projectsListDashboard';

const DashboardSummary = ({ customers, recruiters, skills, auth: { user } }) => {
  const [filters, setFilters] = useState({
    client: '',
    recruiters: [],
    position: '',
    technology: '',
    status: [],
    excludeStatus: '',
    page: '',
    limit: '',
  });
  const [_filterst, _setFilters] = useState(filters);

  const projects = useProjects({ filters: _filterst });

  const handleFilterProjects = () => {
    _setFilters(filters);
  };

  return (
    <Box>
      <Stack direction="row" alignItems="center" spacing={2}>
        <Typography variant="h4" align="left" pt={1} pb={1} sx={{ textTransform: 'uppercase' }}>
          Welcome <strong>{user?.first_name}!</strong>
        </Typography>
      </Stack>
      <Divider sx={{ mt: 4, mb: 4, borderBottomWidth: 2 }} />
      <FiltersDashboard
        {...{
          filters,
          setFilters,
          handleFilterProjects,
          recruiters,
          skills,
          customers,
        }}
      />
      <ProjectsListDashboard {...{ projects }} />
    </Box>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return {
    loginExpire: (user) => {
      dispatch(loginExpire(user));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardSummary);
