import { useEffect, useState } from 'react';

import httpService from '../../services/httpService';
import useIsMounted from '../useIsMounted';

export default function useSkills() {
  const [state, setState] = useState({
    rows: [],
    total: 0,
  });
  const isMountedRef = useIsMounted();

  useEffect(() => {
    const req = httpService.get(process.env.REACT_APP_API_BACKEND + '/v1/skills/all');
    req.then(({ data }) => {
      // 👇️ only update state if component is mounted
      if (isMountedRef.current) {
        setState({
          rows: data,
          total: data.length,
        });
      }
    });
  }, [isMountedRef]);

  return state;
}
