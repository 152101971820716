import { useCandidates } from 'hooks/request';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { usePrevious } from 'utils/utils';

const useCandidateFilter = (only_blacklisted = false, only_staff = false) => {
  const [filters, setFilters] = useState({ page: 0, limit: 10, orderBy: ['updatedAt'], order: 'DESC' });
  const [pagination, setPagination] = useState({});
  const [featuredCandidates, setFeaturedCandidates] = useState([]);
  const prevFilters = usePrevious(filters);
  const {
    candidates,
    isLoading: candidatesIsLoading,
    error: candidatesError,
    refetch,
  } = useCandidates({ filters, pagination, runAtStart: false, only_blacklisted, only_staff });

  const handleFilterCandidates = (handle_filters) => {
    setFilters(handle_filters);
  };

  const handleFilterPagination = (handle_pagination) => {
    setPagination({ ...handle_pagination });
  };

  useEffect(() => {
    if (candidates.length && featuredCandidates.length === 0) {
      setFeaturedCandidates(candidates.slice(0, 4));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [candidates, filters]);

  useEffect(() => {
    if (prevFilters !== filters) {
      setFeaturedCandidates([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  useEffect(() => {
    if (!_.isEmpty(pagination)) refetch();
  }, [pagination, refetch]);

  return {
    filters,
    handleFilterCandidates,
    handleFilterPagination,
    candidatesIsLoading,
    featuredCandidates,
    candidatesError,
    candidates,
  };
};

export default useCandidateFilter;
