export const ROLE_CUSTOMER = 'customer';
export const ROLE_ADMIN = 'admin';
export const ROLE_TAS = 'tas';
export const ROLE_SUPERTAS = 'superTas';
export const ROLE_SUPERCUSTOMER = 'superCustomer';
export const ROLE_ACCOUNT_MANAGER = 'accountManager';
export const ROLE_CANDIDATE = 'candidate';

export const ROLE_LABELS = {
  customer: 'Customer',
  admin: 'Administrator',
  tas: 'Talent Acquisition',
  superTas: 'Admin Talent Acquisition',
  accountManager: 'Account Manager',
  candidate: 'Candidate',
  superCustomer: 'Admin Customer',
};
