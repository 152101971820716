import { joiResolver } from '@hookform/resolvers/joi';
import {
  Typography,
  Avatar,
  FormLabel,
  Select,
  MenuItem,
  FormControl,
  Stack,
  Box,
  FormHelperText,
  Button,
} from '@mui/material';
import useCandidateActiveProjects from 'hooks/useCandidateActiveProjects';
import Joi from 'joi';
import { useMemo, useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import ReactQuill from 'react-quill';
import { toast } from 'react-toastify';
import customerService from 'services/customerService';
import { capitalizeString } from 'utils/utils';

const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
    ['link'],
    ['clean'],
  ],
};

const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
];

const FeedbackForm = (props) => {
  const candidateId = useMemo(() => props.candidateId, [props.candidateId]);
  const [customerLogo, setCustomerLogo] = useState('');
  const [projectOptions, setProjectOptions] = useState([]);

  const { candidateActiveProjects } = useCandidateActiveProjects(candidateId);

  const schema = Joi.object({
    feedback: Joi.string().not('<p><br></p>').required().messages({
      'string.empty': 'Feedback message is required',
      'any.invalid': 'Feedback message is required',
    }),
    projectId: Joi.number().required().messages({
      'number.base': 'Project is required',
    }),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({
    resolver: joiResolver(schema),
    defaultValues: {
      feedback: '',
      projectId: '',
    },
  });

  const onSubmit = async (data) => {
    customerService
      .saveCustomerFeedback({
        feedback: data.feedback,
        statusFeed: 'SUBMITTED_CUSTOMER',
        candidateId: props.candidateId,
        projectId: data.projectId,
      })
      .then((res) => {
        toast.success('Feedback added successfully');
        reset();
        setCustomerLogo('');
        props.onSuccess();
      })
      .catch((err) => {
        toast.error('Error adding feedback');
      });
  };

  useEffect(() => {
    const options = candidateActiveProjects.map((project) => ({
      id: project.id,
      label:
        project.Client.companyName +
        ' - ' +
        capitalizeString(project.position) +
        ' - ' +
        capitalizeString(project.seniority),
      logo: project.Client.logoUrl,
    }));

    setProjectOptions(options);
  }, [candidateActiveProjects]);

  useEffect(() => {
    console.log(errors);
  }, [errors]);

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={2}>
        <Typography variant="subtitle" textAlign="center">
          Add Feedback
        </Typography>
        <Stack direction="row" spacing={2}>
          <Avatar sx={{ width: 80, height: 80 }} src={customerLogo} />
          <FormControl fullWidth>
            <FormLabel>Select Project</FormLabel>
            <Controller
              control={control}
              name="projectId"
              render={({ field: { onChange, onBlur, name, value } }) => (
                <Select
                  id="project"
                  label="projectId"
                  notched={false}
                  name={name}
                  value={value}
                  onChange={(e) => {
                    const logo = projectOptions.find((project) => project.id === e.target.value).logo;
                    setCustomerLogo(logo);
                    onChange(e);
                  }}
                  onBlur={onBlur}
                >
                  {projectOptions.map((project) => (
                    <MenuItem key={project.id} value={project.id}>
                      {project.label}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />

            <FormHelperText error={true}>{errors.projectId && errors.projectId.message}</FormHelperText>
          </FormControl>
        </Stack>
        <Box
          sx={{
            '> .quill > .ql-container': { height: '300px', display: 'flex', flex: 1, flexDirection: 'column' },
            '> .quill > .ql-container > ql-editor': { flex: 1 },
          }}
        >
          <Controller
            name="feedback"
            control={control}
            render={({ field }) => (
              <ReactQuill
                theme="snow"
                modules={modules}
                formats={formats}
                placeholder={`Type your feedback here...`}
                {...field}
              />
            )}
          />
        </Box>
        <FormHelperText error={true} sx={{ marginTop: '1px !important;' }}>
          {errors.feedback && errors.feedback.message}
        </FormHelperText>
        <FormControl fullWidth>
          <Button type="submit" variant="contained" color="primary">
            Add Feedback
          </Button>
        </FormControl>
      </Stack>
    </Box>
  );
};

export default FeedbackForm;
