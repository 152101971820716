import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { IconButton, Tooltip, Typography } from '@mui/material';
import { styled } from '@mui/system';

const TooTipIcon = styled(IconButton)(({ theme }) => ({
  position: 'relative',
  top: '0',
  padding: '0',
  marginTop: '0',
  marginLeft: '.4em',
  '& .MuiSvgIcon-root': {
    position: 'absolute',
    left: 0,
    fontSize: '.75em!important',
    fill: theme.palette.info.main,
  },
}));

const CustomToolTip = ({ title, message, buttonSx }) => {
  return (
    <Tooltip
      enterTouchDelay={300}
      title={
        <>
          <Typography color="inherit">{title}</Typography>
          <p>{message}</p>
        </>
      }
    >
      <TooTipIcon variant="action-icon" aria-label="help" sx={buttonSx}>
        <HelpOutlineIcon />
      </TooTipIcon>
    </Tooltip>
  );
};

export default CustomToolTip;
