import { Avatar, Box, FormControl, Grid, Link, Stack, Typography, useTheme } from '@mui/material';
import InputSelectV2 from 'components/FormElement/InputSelectV2';
import { FormHook } from 'hooks/form/Form';
import { useCustomers } from 'hooks/request';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CLIENTSTATUS, PROJECT_STATUS } from 'utils/staticData';
import { usePrevious } from 'utils/utils';
import { schemaProjectHeader } from 'validations/projects/projectRegister';

const CustomerForm = ({ project, validateForm, onValidForm }) => {
  const { ProjectId, ClientId } = useParams();
  let elementRef = useRef();
  const [clientSelected, setClientSelected] = useState({});
  const theme = useTheme();

  const { fields, handleSave, errors, handleSubmit, validSubmit, setErrors, initialState, setValues } = FormHook(
    { ClientId: '', status: '' },
    schemaProjectHeader
  );

  const prevProjectId = usePrevious(ProjectId);
  const { customers, refetch: refetCustomers } = useCustomers(
    !!ProjectId ? { id: ProjectId } : { status: CLIENTSTATUS.ACTIVE }
  );

  useEffect(() => {
    if (ProjectId !== prevProjectId) {
      refetCustomers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ProjectId, refetCustomers]);

  useEffect(() => {
    if (customers && customers.length > 0 && project.ClientId && project.ClientId !== '') {
      const client = customers.find((client) => client.id === project.ClientId);
      const statusDB = Object.keys(PROJECT_STATUS).find((key) => PROJECT_STATUS[key] === project.status);

      setClientSelected(client);
      setValues((prev) => ({ ...prev, ClientId: project.ClientId, status: statusDB }));
    }
  }, [customers, project, setValues]);

  useEffect(() => {
    if (validateForm === true) {
      elementRef.dispatchEvent(new Event('submit', { bubbles: true, cancelable: true }));
    }
  }, [validateForm]);

  useEffect(() => {
    (async () => {
      if (validSubmit === null) return;
      else if (validSubmit === false) {
        onValidForm(false);
        return;
      }
      if (validSubmit) {
        try {
          setErrors({ ...initialState });
          onValidForm(fields);
        } catch (error) {
          if (error.response && error.response.data) {
            return toast.error(error.response.data.message);
          }
        }
      }
    })();
  }, [validSubmit]); // eslint-disable-line

  const handleChangeLogo = (idClient) => {
    const client = customers.find((client) => client.id === idClient);
    if (clientSelected !== client) {
      setClientSelected(client);
    }
  };

  useEffect(() => {
    if (ClientId && customers.length) {
      setClientSelected(customers.find((client) => client.id === ClientId));
      setValues((prev) => ({ ...prev, ClientId }));
    }
  }, [ClientId, customers, setValues]);

  return (
    <Grid container sx={{ marginBottom: 2, [theme.breakpoints.down('sm')]: { flexDirection: 'column-reverse' } }}>
      <Grid
        item
        xs={12}
        sm={6}
        ref={(ref) => (elementRef = ref)}
        component="form"
        onSubmit={(e) => handleSubmit(e, schemaProjectHeader)}
      >
        <Typography variant="h4" align="left" pb={1} sx={{ textTransform: 'uppercase' }}>
          {!!ProjectId ? 'EDIT' : 'CREATE'} PROJECT
        </Typography>
        <Box sx={{ display: 'flex' }}>
          {/* Client  */}
          <FormControl fullWidth sx={{ paddingRight: 1 }}>
            {customers && (
              <InputSelectV2
                placeholder={'Customer'}
                id={'ClientId'}
                opts={customers.map((client) => ({ _id: client.id, label: client.companyName }))}
                error={errors.ClientId && errors.ClientId.txt}
                initialValue={fields.ClientId}
                onChangeInput={(e) => {
                  handleSave(e);
                  handleChangeLogo(e.target.value);
                }}
                size={'normal'}
                variant="standard"
                backgroundColor="nexxusGrayExtraLight.main"
                isDisabled={!!ProjectId ?? !!ClientId}
                defaultOption={true}
              />
            )}
          </FormControl>
          {/* Status Project */}
          <FormControl fullWidth sx={{ paddingLeft: 1 }}>
            <InputSelectV2
              placeholder={'Status'}
              id="status"
              opts={Object.keys(PROJECT_STATUS).map((key, value) => {
                return { _id: key, label: key.replace('_', '') };
              })}
              error={errors.status && errors.status.txt}
              initialValue={fields.status}
              onChangeInput={(e) => handleSave(e)}
              size={'normal'}
              variant="standard"
              backgroundColor="nexxusGrayExtraLight.main"
            />
          </FormControl>
        </Box>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Stack direction="row" spacing={4} alignItems="center" justifyContent="flex-end">
          <Box sx={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column' }}>
            <Typography
              sx={{
                fontSize: '16px',
                fontWeight: 'bold',
                textAlign: 'left',
              }}
            >
              {clientSelected && clientSelected.label}
            </Typography>
            {clientSelected && clientSelected.label && (
              <>
                <Typography
                  sx={{
                    fontSize: '14px',
                    textAlign: 'left',
                  }}
                >
                  {clientSelected.address}
                </Typography>
                <Typography
                  sx={{
                    fontSize: '14px',
                    textAlign: 'left',
                  }}
                >
                  {clientSelected.phone}
                </Typography>
              </>
            )}

            {clientSelected && clientSelected.websiteUrl && (
              <Link
                component="a"
                href={clientSelected.websiteUrl}
                target="_blank"
                sx={{
                  color: 'nexxusBlueExtraLight.main',
                  fontSize: 14,
                  fontWeight: 'bold',
                  textAlign: 'left',
                  textDecoration: 'none',
                }}
              >
                VISIT WEBSITE
              </Link>
            )}
          </Box>
          <Box
            sx={{
              width: 86,
              height: 86,
              border: 1,
              borderColor: 'grey.light',
              overflow: 'hidden',
              borderRadius: '100%',
            }}
          >
            <Avatar
              sx={{
                width: '100%',
                height: '100%',
                fontSize: '1.3rem',
                fontWeight: 'bold',
                textTransform: 'uppercase',
              }}
              src={clientSelected?.logoUrl}
            />
          </Box>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default CustomerForm;
