import useUsers from 'hooks/request/useUsers';
import { useEffect, useState } from 'react';

const useUsersFilter = () => {
  const [filters, setFilters] = useState({});
  const { users, isLoading: usersIsLoading, error: usersError, refetch } = useUsers({ filters, runAtStart: true });

  const handleFilterUsers = (filters) => {
    setFilters(filters);
  };

  useEffect(() => {
    refetch();
  }, [filters, refetch]);

  return {
    filters,
    handleFilterUsers,
    refetch,
    usersIsLoading,
    usersError,
    users,
  };
};

export default useUsersFilter;
