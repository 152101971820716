import { Box, Typography } from '@mui/material';

import { useTheme } from '@mui/system';
import { Navigation, Pagination, Virtual } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import CandidateInfo from './views/CandidateInfo';
import CandidateLog from './views/CandidateLog';
import CandidateSkills from './views/CandidateSkills';
import CandidateUploads from './views/CandidateUploads';

import { CANDIDATE_CARD_SUMMARY_HEIGHT } from '../../../constants/candidate_card';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './swiper.css';

export default function CandidateCardSummary(props) {
  const swiperModules = [Navigation, Pagination, Virtual];
  const height = CANDIDATE_CARD_SUMMARY_HEIGHT;
  const theme = useTheme();

  const Item = ({ title, children, sx, overflowY = 'auto' }) => {
    return (
      <Box sx={{ height, overflowY }}>
        <Typography
          sx={{
            color: theme.palette.grey.light,
            fontWeight: 700,
            mb: 2.5,
            fontSize: 14,
            textTransform: 'uppercase',
            textAlign: 'center',
          }}
        >
          {title}
        </Typography>
        <Box sx={{ maxHeight: height - 45, overflowY, mx: 4.5, ...sx }}>{children}</Box>
      </Box>
    );
  };

  return (
    <Box>
      <Swiper
        modules={swiperModules}
        navigation
        pagination={{
          clickable: true,
        }}
        loop={true}
        slidesPerView={1}
        style={{ height }}
      >
        <SwiperSlide>
          <Item title={props.candidate.developer_title}>
            <CandidateInfo {...props} />
          </Item>
        </SwiperSlide>
        <SwiperSlide>
          <Item title="Skills &amp; Disciplines">
            <CandidateSkills {...props} />
          </Item>
        </SwiperSlide>
        {/* <SwiperSlide>
          <Item title="Code Tests & Results">
            <CandidateCodeTest {...props} />
          </Item>
        </SwiperSlide> */}
        <SwiperSlide>
          <Item title="Interview Uploads" overflowY="visible">
            <CandidateUploads {...props} />
          </Item>
        </SwiperSlide>
        <SwiperSlide>
          <Item
            title="History Log"
            sx={{ borderStyle: 'solid', borderColor: 'nexxusGrayLight.main', borderWidth: '2px', p: 1 }}
          >
            <CandidateLog {...props} />
          </Item>
        </SwiperSlide>
      </Swiper>
    </Box>
  );
}
