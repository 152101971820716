import { CANDIDATE_STATUS } from 'constants/candidate';
import { FormHook } from '../../../hooks/form/Form';
import { schemaTAS } from '../../../validations/candidateRegister';
export const candidateInitialState = {
  first_name: '',
  last_name: '',
  availability: '',
  availability_interview: '',
  email: '',
  status: CANDIDATE_STATUS.ACTIVE,
  blacklisted: false,
  location: '',
  developer_title: '',
  developer_seniority: '',
  employment_type: '',
  salary_currency: '',
  salary_expectation: '',
  candidate_result_link: '',
  candidate_code_score: '',
  timezone: '',
  linkedin: '',
  github_profile: '',
  portfolio_link: '',
  info_additional: '',
  tags: [],
  rate_customer: '',
  rate_per_hour: '',
};

export default function CandidateFields(data = candidateInitialState) {
  return FormHook(data, schemaTAS);
}
