import { Avatar, IconButton, Stack } from '@mui/material';
import { useState, useRef } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  PopoverNcs,
  NcsPopover,
  CustomSelect,
  SearchInput,
  CustomPopover,
  CustomButton,
  LifeTimeProjects,
} from '@nexxus/components';

const CandidateHeader = ({
  logoUrl,
  positions,
  onHandlePosition,
  lifetimeTotals,
  candidatesNames,
  handleCandidateSelected,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElNCS, setAnchorElNCS] = useState(null);
  const [anchorElLifetimeModal, setAnchorElLifetimeModal] = useState(null);
  const myRef = useRef(null);

  const handleSearch = (searchTerm, value, _reason) => {
    handleCandidateSelected(_reason === 'clear' ? '' : value.key);
  };
  const handleLearnMoreBtn = () => {
    setAnchorElNCS(myRef.current);
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{ padding: '16px', background: 'white', borderRadius: '5px' }}
    >
      <Stack direction="row" alignItems="center" gap={1}>
        <CustomSelect
          options={positions ?? []}
          onChangeInput={onHandlePosition}
          selectText="Select a position"
          variant={'variant-1'}
          defaultOption={false}
        />

        <SearchInput options={candidatesNames} onSearch={handleSearch} label="Search Candidate" autoWidth />
        {/* <SearchInput
          options={[
            { label: 'example', key: 0 },
            { label: 'example2', key: 1 },
          ]}
          onSearch={handleSearch}
          label="Filter By:"
          width="174px"
          multiple={true}
        /> */}
      </Stack>
      <Stack direction="row" gap={1}>
        <CustomButton
          onClick={(e) => setAnchorEl(e.currentTarget)}
          sx={{ paddingRight: '8px', paddingLeft: '8px', width: '132px', height: '36px' }}
        >
          What is NCS?{' '}
        </CustomButton>
        <Avatar src={`${logoUrl}`} sx={{ width: 36, height: 36 }} />

        <IconButton
          color="primary"
          component="label"
          sx={{ height: '33px' }}
          onClick={(e) => setAnchorElLifetimeModal(e.currentTarget)}
        >
          <ExpandMoreIcon />
        </IconButton>
        <CustomPopover
          sx={{ boxShadow: '0px 4px 4px 2px rgba(0, 0, 0, 0.2)', border: 'none', borderRadius: '5px' }}
          backdropShadow={false}
          anchorEl={anchorElLifetimeModal}
          setAnchorEl={setAnchorElLifetimeModal}
        >
          <LifeTimeProjects
            candidateSubmissions={lifetimeTotals?.SUBMITTED_CUSTOMER.count || 0}
            codeTesting={lifetimeTotals?.SKILLS_ASSESSMENT.count || 0}
            panelInterview={lifetimeTotals?.PANEL_INTERVIEW.count || 0}
            customerInterview={lifetimeTotals?.CUSTOMER_INTERVIEW.count || 0}
            hired={lifetimeTotals?.HIRED.count || 0}
          />
        </CustomPopover>
      </Stack>
      <NcsPopover onClickLearnMore={handleLearnMoreBtn} buttonRef={myRef} anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
      <PopoverNcs
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        anchorEl={anchorElNCS}
        setAnchorEl={setAnchorElNCS}
      />
    </Stack>
  );
};

export default CandidateHeader;
