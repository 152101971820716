import { useTheme } from '@mui/material/styles';
import { DataGrid } from '@mui/x-data-grid';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, IconButton, Stack, Typography } from '@mui/material';

import { FiberManualRecord } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import { connect } from 'react-redux';
import { currentTitle } from 'utils/staticData';
import { loginExpire } from '../../../store/auth';

const NoDataOverlay = () => {
  return (
    <Stack height="100%" alignItems="center" justifyContent="center">
      <Typography variant="h3" color="nexxusGrayLight.main" sx={{ fontWeight: 'bold' }}>
        NO DATA
      </Typography>
      <Typography variant="h6" color="nexxusGrayLight.main">
        Please Select Candidate Fields
      </Typography>
    </Stack>
  );
};

const RenderActions = (props) => {
  const { row } = props;
  const navigate = useNavigate();

  const handleViewProject = (ev) => {
    ev.preventDefault();
    navigate(`/accountmanager/customer/intake/${row.id}`);
  };

  return (
    <Stack direction="row" spacing={1}>
      <IconButton size="small" onClick={handleViewProject}>
        <EditIcon />
      </IconButton>
    </Stack>
  );
};

const setColumns = ({ user }) => {
  return [
    {
      field: 'status',
      headerName: '',
      width: 50,
      sortable: false,
      renderCell: () => {
        return <FiberManualRecord color="onhold" sx={{ mb: -0.7 }} />;
      },
    },
    {
      field: 'Client.companyName',
      headerName: 'CUSTOMER',
      width: 150,
      sortable: false,
      valueGetter: ({ row }) => row.Client?.companyName || '',
    },
    {
      field: 'name',
      headerName: 'PROJECT NAME',
      flex: 1,
      sortable: false,
      valueGetter: ({ row }) => row.name,
    },
    {
      field: 'position',
      headerName: 'PROJECT TITLE',
      flex: 1,
      sortable: false,
      valueGetter: ({ row }) => {
        const title = currentTitle.find((pos) => pos._id === row.position);
        return title ? title.label : '';
      },
    },
    {
      field: 'technology',
      headerName: 'TECHNOLOGY',
      align: 'center',
      headerAlign: 'center',
      flex: 1,
      sortable: false,
      valueGetter: ({ row }) => row?.Skills[0]?.name || '',
    },
    {
      field: 'teamsize',
      headerName: 'HEADCOUNT',
      width: 100,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      renderCell: ({ row }) => {
        const totalCandidates = row.Candidates?.length || 0;
        const color = totalCandidates > 0 ? 'success.main' : 'error.main';

        return (
          <Typography variant="cell" color={color}>
            {totalCandidates}/{row.teamsize}
          </Typography>
        );
      },
    },
    {
      field: 'Recruiter.full_name',
      headerName: 'RECRUITER',
      width: 200,
      sortable: false,
      valueGetter: ({ row }) => row.Recruiter?.full_name || '',
    },
    {
      field: 'action',
      headerName: 'Actions',
      width: 100,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
      renderCell: ({ row }) => {
        return <RenderActions row={row} userRole={user.role} />;
      },
    },
  ];
};

const ProjectsListDashboard = (props) => {
  const theme = useTheme();
  const [pageSize, setPageSize] = React.useState(10);
  return (
    <>
      <Box sx={{ width: '100%', mt: 4 }}>
        <DataGrid
          rows={props.projects.rows}
          columns={setColumns(props.auth)}
          autoHeight
          rowsPerPageOptions={[5, 10, 20, 50, 100]}
          disableSelectionOnClick
          pageSize={pageSize}
          onPageSizeChange={(newPage) => setPageSize(newPage)}
          pagination
          disableColumnMenu
          components={{
            ...theme.components.MuiDataGrid.defaultProps.components,
            ...{
              NoRowsOverlay: () => <NoDataOverlay />,
              NoResultsOverlay: () => <NoDataOverlay />,
            },
          }}
        />
      </Box>
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return {
    loginExpire: (user) => {
      dispatch(loginExpire(user));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectsListDashboard);
