import userService from 'services/userService';

const updateFileWithFilesValue = async (files, userId) => {
  let arrUrlFiles = [];
  if (files.length > 0) {
    for (const [, value] of Object.entries(files)) {
      const url = await uploadAvatar(value);
      arrUrlFiles.push(url);
    }
  }
  return arrUrlFiles;
};

const uploadAvatar = async (files, userId) => {
  const formData = new FormData();
  if (files) {
    formData.append('id', 'avatar_url');
    formData.append('dest', 'user-attachments');
    formData.append('attachment', files, files.name);

    const { data } = await userService.uploadUserFile(formData);
    return data;
  }
};

export { updateFileWithFilesValue };
