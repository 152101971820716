import Joi from 'joi';
import _ from 'lodash';
import { useState } from 'react';

export function FormHook(initialState, schema) {
  const [fields, setValues] = useState({ ...initialState });
  const [initialStateRequest, setInitialStateRequest] = useState({ ...initialState });
  const [files, setFiles] = useState({});

  const [errors, setErrors] = useState({});
  const [validSubmit, setValidSubmit] = useState(null);
  const [customSchema, setCustomSchema] = useState({});

  const resetForm = () => {
    setValidSubmit(false);
    setValues({ ...initialState });
    setFiles([]);
  };

  const setForm = (body) => {
    setValues({ ...body });
  };

  const handleSave = async (event, initialValue = '') => {
    const data = { ...fields };
    let { target } = event;
    const error = { ...errors };
    let errorMessage;

    data[target.name] = target.value;
    if (target.type === 'checkbox') {
      data[target.name] = target.checked ? target.checked : false;
    }
    const schemaa = _.isEmpty(customSchema) ? { ...schema } : { ...customSchema };
    if (target.name.search(/confirm/i) !== -1) {
      const ref = target.name.split('_');
      target = {
        input: { name: `${ref[0]}`, value: data[ref[0]] },
        input_confirm: { name: `${ref[0]}_confirm`, value: data[`${ref[0]}_confirm`] },
      };
    } else {
      target = { input: { name: target.name, value: target.value } };
    }

    errorMessage = await validateProperty(target, { ...schemaa });
    const prop = Object.keys(target).length > 1 ? target.input_confirm.name : target.input.name;
    if (errorMessage) error[prop] = { response: true, txt: errorMessage[prop] };
    else delete error[prop];

    setValues(data);
    setErrors(error);
  };

  const handleSaveRepeaterProp = async (event, schemaProp) => {
    let data = _.cloneDeep(fields);
    let errorState = _.cloneDeep(errors);

    const { target } = event;
    const arr = target.name.split('_');

    if (typeof data[arr[0]][arr[1]] === 'undefined') data[arr[0]][arr[1]] = { [arr[2]]: {} };
    data[arr[0]][arr[1]][arr[2]] = target.value;
    const errorMessage = await validateProperty({ input: { name: arr[2], value: target.value } }, { ...schemaProp });

    if (errorMessage) {
      if (typeof errorState[arr[0]] === 'undefined') errorState[arr[0]] = [];
      if (typeof errorState[arr[0]][arr[1]] === 'undefined') errorState[arr[0]][arr[1]] = { [arr[2]]: {} };
      errorState[arr[0]][arr[1]][arr[2]] = { response: true, txt: errorMessage[arr[2]] };
      setErrors(errorState);
      setValues(data);
      return;
    }
    if (errorState[arr[0]] && errorState[arr[0]][arr[1]] && errorState[arr[0]][arr[1]][arr[2]]) {
      delete errorState[arr[0]][arr[1]][arr[2]];
    }
    setErrors(errorState);
    setValues(data);
  };

  const validateProperty = async (target, origSchema) => {
    let obj;
    let sliceSchema;
    if (Object.keys(target).length > 1) {
      //confirm validation
      const { input, input_confirm } = target;
      obj = { [input.name]: input.value, [input_confirm.name]: input_confirm.value };
      sliceSchema = { [input.name]: origSchema[input.name], [input_confirm.name]: origSchema[input_confirm.name] };
    } else {
      // single validation
      const { input } = target;
      obj = { [input.name]: input.value };
      sliceSchema = { [input.name]: origSchema[input.name] };
    }

    const schema = Joi.object(sliceSchema);
    try {
      await schema.validateAsync(obj, {
        initialStateRequest: initialStateRequest,
      });
      return null;
    } catch (error) {
      const errors = {};
      const prop = Object.keys(target).length > 1 ? target.input_confirm.name : target.input.name;
      errors[prop] = error.details[0]['message'];
      return errors;
    }
  };

  const validate = async (origSchema) => {
    const schema = Joi.object(origSchema);
    try {
      await schema.validateAsync(
        { ...fields },
        {
          abortEarly: false,
          allowUnknown: true,
          initialStateRequest: initialStateRequest,
        }
      );
      return null;
    } catch (error) {
      return setErrorDetails(error.details);
    }
  };

  const setErrorDetails = (details) => {
    let errors = {};
    for (let item of details) {
      if (item.path.length <= 1) {
        errors[item.context.label] = item.message;
      } else {
        if (!errors[item.path[0]]) errors[item.path[0]] = [];
        if (!errors[item.path[0]][item.path[1]]) errors[item.path[0]][item.path[1]] = { [item.path[2]]: item.message };
        else errors[item.path[0]][item.path[1]][item.path[2]] = item.message;
      }
    }
    return errors;
  };

  const handleSubmit = async (e) => {
    if (e && typeof e.preventDefault !== 'undefined') e.preventDefault();
    let valid = await validate({ ...schema });
    setValidSubmit(false);

    if (!!!valid) {
      setErrors({});
      setValidSubmit(true);
      return;
    }
    const errors = {};
    Object.entries(valid).forEach(([key, value]) => {
      if (typeof value === 'string') {
        errors[key] = { response: true, txt: value };
        return errors[key];
      }
      value.forEach((obj, index) => {
        Object.keys(obj).forEach((prop, i) => {
          if (!errors[key]) errors[key] = [];
          if (!errors[key][index]) errors[key][index] = { [prop]: { response: true, txt: obj[prop] } };
          else errors[key][index][prop] = { response: true, txt: obj[prop] };
        });
      });
    });
    setValidSubmit(false);
    return setErrors(errors || {});
  };

  return {
    initialStateRequest,
    fields,
    files,
    errors,
    setErrors,
    handleSave,
    validateProperty,
    handleSubmit,
    validSubmit,
    initialState,
    setForm,
    setInitialStateRequest,
    resetForm,
    setCustomSchema,
    handleSaveRepeaterProp,
    setValues,
    setValidSubmit,
  };
}
