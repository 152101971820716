import http from './httpService';

function getAllClients() {
  return http.get(process.env.REACT_APP_API_BACKEND + '/v1/clients');
}

function getClient(ClientId) {
  return http.get(`${process.env.REACT_APP_API_BACKEND}/v1/clients/${ClientId}`);
}

function createClient(body) {
  return http.post(`${process.env.REACT_APP_API_BACKEND}/v1/clients`, body);
}

function updateClient(ClientId, body) {
  return http.patch(`${process.env.REACT_APP_API_BACKEND}/v1/clients/${ClientId}`, body);
}

function uploadClientLogoImage(formData, key) {
  return http.post(process.env.REACT_APP_API_BACKEND + '/v1/clients/profile-upload', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

function getClientProjects(ClientId, status) {
  return http.get(`${process.env.REACT_APP_API_BACKEND}/v1/projects/client/${ClientId}`, { status });
}

const ClientService = {
  getAllClients,
  getClient,
  createClient,
  uploadClientLogoImage,
  updateClient,
  getClientProjects,
};

export default ClientService;
