import MainLayout from 'components/MainLayout/MainLayout';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { loginExpire } from 'store/auth';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box, Button, CircularProgress, Divider, Typography } from '@mui/material';
import ModalFeedback from 'components/Generic/Modal/ModalFeedback';
import useBreadcrumbs from 'hooks/useBreadcrumbs';
import CustomerForm from 'pages/tas/projects/EditProject/CustomerForm';
import ProjectForm from 'pages/tas/projects/EditProject/ProjectForm';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import projectService from 'services/projectService';
import ProjectPipelineCounters from 'components/Projects/ProjectPipelineCounters';

const EditProject = (props) => {
  const history = useNavigate();
  const [trySubmit, setTrySubmit] = useState(false);
  const [formClient, setFormClient] = useState(false);
  const [formProject, setFormProject] = useState(false);
  const [dataProject, setDataProject] = useState({});
  const [open, setOpen] = useState(false);

  const { ProjectId, ClientId } = useParams();

  const onCloseModal = (event, reason) => {
    event?.preventDefault();
    setOpen(false);
    setFormClient(false);
    setFormProject(false);
    setTrySubmit(false);
  };

  const onAccept = async () => {
    try {
      if (!!ProjectId) {
        await projectService.patchProject({ ...formClient, ...formProject }, ProjectId);
        setOpen(false);
        toast.success('Update project success!');
        history(`/tas/projects/${ProjectId}/view`, { replace: true });
      } else {
        const { data: project } = await projectService.registerProject({ ...formClient, ...formProject });
        setOpen(false);
        toast.success('Create project success!');
        history(`/tas/projects/${project.id}/view`);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        return toast.error(error.response.data.message);
      }
    }
  };

  //  hook saving forms
  useEffect(() => {
    (async () => {
      if (formClient !== false && formProject !== false) {
        setOpen(true);
      }
    })();
  }, [formClient, formProject]); // eslint-disable-line

  useEffect(() => {
    (async () => {
      if (!!ProjectId) {
        const { data } = await projectService.getProject(ProjectId);
        if (!data) return toast.error('Project not found!');
        setDataProject(data);
      } else if (!!ClientId) {
        console.log('!!ClientId');
      }
    })();
  }, [ProjectId]); // eslint-disable-line

  const { setBreadcrumbs } = useBreadcrumbs();

  useEffect(() => {
    setBreadcrumbs([
      {
        name: 'Dashboard',
        url: '/tas/dashboard',
      },
      {
        name: 'Projects',
        url: '/tas/projects',
      },
      {
        name: (!!ProjectId ? 'EDIT' : 'CREATE') + ' PROJECT',
      },
    ]);
  }, [ProjectId, setBreadcrumbs]);

  return (
    <MainLayout {...props} current="projects">
      <ModalFeedback
        variant="info"
        headerText="Project"
        titleText={!!ProjectId ? 'Do you want to edit this project?' : 'Do you want to add a new project?'}
        open={open}
        onCancel={onCloseModal}
        onClose={onCloseModal}
        onAccept={onAccept}
      />
      <Box>
        {/* HEADER FORM*/}
        <CustomerForm
          project={dataProject}
          validateForm={trySubmit}
          onValidForm={(fieldsCustomer) => {
            if (fieldsCustomer === false) {
              setTimeout(() => {
                setTrySubmit(false);
              }, 200);
            } else {
              setFormClient(fieldsCustomer);
            }
          }}
        ></CustomerForm>
        {/* CARDS STATUS */}
        {/* <CardStatusForm ProjectId={ProjectId}></CardStatusForm> */}
        <Box sx={{ marginTop: 4, marginBottom: 3 }}>
          <ProjectPipelineCounters ProjectId={ProjectId}></ProjectPipelineCounters>
        </Box>
        <Divider sx={{ marginBottom: 1 }} />
        {/* PROJECT FORM */}
        <ProjectForm
          submitForm={trySubmit}
          reset={false}
          project={dataProject}
          onValidForm={(fields) => {
            if (fields === false) {
              setTimeout(() => {
                setTrySubmit(false);
              }, 200);
            } else {
              setFormProject(fields);
            }
          }}
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 6,
          }}
        >
          <Typography
            sx={{
              fontSize: '13px',
              fontWeight: 'bold',
              textAlign: 'left',
              color: 'nexxusGray.main',
              marginRight: 2,
            }}
          >
            DON'T FORGET TO SAVE YOUR CHANGES
          </Typography>
          {trySubmit === true && (
            <Button
              variant="contained"
              sx={{ minWidth: '180px', height: '30px', borderRadius: 0, paddingX: 1 }}
              color="nexxusBlue"
              onClick={() => {}}
            >
              <Typography
                sx={{
                  fontSize: '15px',
                  fontWeight: 'bold',
                  paddingRight: 1,
                  color: 'white',
                  marginTop: '4px',
                }}
              >
                <CircularProgress style={{ color: 'white', marginRight: 16 }} size="0.8rem" thickness={8} />
                Validating
              </Typography>
            </Button>
          )}
          {trySubmit === false && (
            <Button
              variant="contained"
              sx={{ minWidth: '180px', height: '30px', borderRadius: 0, paddingX: 1 }}
              color="nexxusBlue"
              startIcon={<CheckCircleIcon sx={{ color: 'white', marginLeft: 2 }} />}
              onClick={() => {
                setTrySubmit(true);
              }}
            >
              <Typography
                sx={{
                  fontSize: '15px',
                  fontWeight: 'bold',
                  paddingRight: 1,
                  color: 'white',
                  marginTop: '4px',
                }}
              >
                save project
              </Typography>
            </Button>
          )}
        </Box>
      </Box>
    </MainLayout>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return {
    loginExpire: (user) => {
      dispatch(loginExpire(user));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditProject);
