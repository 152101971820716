import { useCallback, useEffect, useState } from 'react';
import UsersService from 'services/UsersService';

export default function useUsers({ filters, runAtStart = false, runOnChange = false }) {
  const [users, setUsers] = useState([]);
  const [error, setError] = useState(false);
  const [hasFetched, setHasFetched] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const fetchUsers = useCallback(async () => {
    try {
      setIsLoading(true);
      const { data } = await UsersService.getUsers(filters);
      setUsers(data);
      setIsLoading(false);
    } catch (error) {
      setError(true);
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const refetch = useCallback(() => {
    setUsers([]);
    fetchUsers();
  }, [fetchUsers]);

  useEffect(() => {
    if ((runAtStart && !hasFetched) || runOnChange) {
      fetchUsers();
      setHasFetched(true);
    }
  }, [fetchUsers, hasFetched, runAtStart, runOnChange]);

  return { users, isLoading, error, refetch };
}
