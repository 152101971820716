import { ROLE_ADMIN, ROLE_SUPERTAS, ROLE_TAS } from 'constants/users';
import Candidate from 'pages/tas/candidates/Candidate';
import CreateReport from 'pages/tas/reports/create/CreateReport';
import HistoricalView from 'pages/tas/reports/historicalView/HistoricalView';
import ListReports from 'pages/tas/reports/list/ListReports';
import ViewReport from 'pages/tas/reports/view/ViewReport';
import TagsPage from 'pages/tas/tags/TagsPage';
import UserAccessControl from 'pages/userAccessControl/UserAccessControl';
import { Navigate, Route } from 'react-router-dom';
import Candidates from '../pages/tas/Candidates';
import CandidateSearch from '../pages/tas/candidateSearch/CandidateSearch';
import CreateClient from '../pages/tas/clients/CreateClient';
import ManageClients from '../pages/tas/clients/ManageClients';
import CustomerIntake from '../pages/tas/CustomerIntake';
import Dashboard from '../pages/tas/dashboard/Dashboard';
import EditProject from '../pages/tas/projects/EditProject/EditProject';
import ManageProjects from '../pages/tas/projects/ManageProjects/ManageProjects';
import ViewProject from '../pages/tas/projects/ViewProject/ViewProject';
import PrivateRoute from './privateRouter';

const tasRoutes = [
  {
    name: 'Dashboard',
    path: [
      '/tas/dashboard',
      '/tas/dashboard/active_projects/:customerId',
      '/tas/dashboard/active_projects/:customerId/:projectId',
    ],
    element: <Dashboard />,
    authorization: [ROLE_TAS, ROLE_SUPERTAS],
  },
  {
    name: 'Candidates',
    path: '/tas/candidates',
    element: <Candidates />,
    authorization: [ROLE_TAS, ROLE_SUPERTAS],
  },
  {
    name: 'Candidate Search',
    path: '/tas/candidates/search',
    element: (
      <CandidateSearch
        key="view:SearchCandidate"
        title={
          <>
            Find The Perfect <strong>Candidate!</strong>
          </>
        }
      />
    ),
    authorization: [ROLE_TAS, ROLE_SUPERTAS],
  },
  {
    name: 'Blacklisted Candidates',
    path: '/tas/candidates/blacklisted',
    element: (
      <CandidateSearch
        key="view:Blacklisted"
        title={
          <>
            Find a blacklisted <strong>Candidate</strong>
          </>
        }
        only_blacklisted={true}
        showIncludeBox={false}
      />
    ),
    authorization: [ROLE_SUPERTAS],
  },
  {
    name: 'Blacklisted Candidates',
    path: '/tas/candidates/staff',
    element: (
      <CandidateSearch
        key="view:Staff"
        title={
          <>
            Sonatafy <strong>Engineers</strong>
          </>
        }
        only_staff={true}
        showIncludeBox={false}
      />
    ),
    authorization: [ROLE_SUPERTAS],
  },
  {
    name: 'New Candidate V2',
    path: '/tas/candidates/new',
    element: <Candidate editing={false} key={'create-new'} />,
    authorization: [ROLE_SUPERTAS, ROLE_TAS],
  },
  {
    name: 'New Candidate V2',
    path: ['/tas/candidates/:CandidateId', '/tas/candidates/:CandidateId/view'],
    element: <Candidate editing={true} key={`view-:CandidateId`} />,
    authorization: [ROLE_TAS, ROLE_SUPERTAS],
  },
  {
    name: 'Edit Candidate',
    path: '/tas/candidates/:CandidateId/edit',
    element: <Candidate editing={false} key={`edit-:CandidateId`} />,
    authorization: [ROLE_TAS, ROLE_SUPERTAS],
  },
  {
    name: 'Manage Customers',
    path: '/tas/customers',
    element: <ManageClients />,
    authorization: [ROLE_TAS, ROLE_SUPERTAS],
  },
  {
    name: 'Create Client',
    path: '/tas/customer/create',
    element: <CreateClient key={'create-new'} />,
    authorization: [ROLE_SUPERTAS],
  },
  {
    name: 'View/Edit Customer',
    path: '/tas/customer/:CustomerId/:modeCustomer',
    element: <CreateClient key={`view-:CustomerId-:modeCustomer`} />,
    authorization: [ROLE_TAS, ROLE_SUPERTAS],
  },
  {
    name: 'Customer Intake Form',
    path: '/tas/customer/intake',
    element: <CustomerIntake />,
    authorization: [ROLE_TAS, ROLE_SUPERTAS],
  },
  {
    name: 'View Projects',
    path: '/tas/projects',
    element: <ManageProjects />,
    authorization: [ROLE_TAS, ROLE_SUPERTAS],
  },
  {
    name: 'Create Project',
    path: '/tas/projects/create/based_on_customer/:ClientId',
    element: <EditProject key={'project-create-bycustomer'} />,
    authorization: [ROLE_SUPERTAS],
  },
  {
    name: 'Create Project',
    path: '/tas/projects/create',
    element: <EditProject key={'project-create'} />,
    authorization: [ROLE_SUPERTAS],
  },
  {
    name: 'Edit Project',
    path: '/tas/projects/:ProjectId/edit',
    element: <EditProject key={'project-edit-:ProjectId'} />,
    authorization: [ROLE_SUPERTAS],
  },
  {
    name: 'Edit Project View',
    path: '/tas/projects/:ProjectId/view',
    element: <ViewProject key={'project-view-:ProjectId'} />,
    authorization: [ROLE_TAS, ROLE_SUPERTAS],
  },
  {
    name: 'Reports List',
    path: '/tas/reports',
    element: <ListReports />,
    authorization: [ROLE_TAS, ROLE_SUPERTAS],
  },
  {
    name: 'Find Reports',
    path: '/tas/reports/search',
    element: <HistoricalView />,
    authorization: [ROLE_SUPERTAS, ROLE_ADMIN],
  },
  {
    name: 'Create Report',
    path: '/tas/reports/create/:ProjectId',
    element: <CreateReport />,
    authorization: [ROLE_TAS, ROLE_SUPERTAS],
  },
  {
    name: 'View Report',
    path: '/tas/reports/:ReportId/view',
    element: <ViewReport />,
    authorization: [ROLE_TAS, ROLE_SUPERTAS],
  },
  {
    name: 'Tags',
    path: '/tas/tags',
    element: <TagsPage />,
    authorization: [ROLE_SUPERTAS],
  },
  {
    name: 'User Access Control',
    path: '/tas/users',
    element: <UserAccessControl />,
    authorization: [ROLE_SUPERTAS],
  },
];

export const TasRoutes = (props) => {
  return tasRoutes.map((route, index) => {
    if (Array.isArray(route.path)) {
      return route.path.map((path, key) => {
        return (
          <Route
            key={`tas-${index}-${key}`}
            path={path}
            element={
              <PrivateRoute>
                {route.authorization.includes(props.user?.role) ? route.element : <Navigate to="/403" replace />}
              </PrivateRoute>
            }
          />
        );
      });
    }

    return (
      <Route
        key={`tas-${index}`}
        path={route.path}
        element={
          <PrivateRoute>
            {route.authorization.includes(props.user?.role) ? route.element : <Navigate to="/403" replace />}
          </PrivateRoute>
        }
      />
    );
  });
};
