import Grid from '@mui/material/Grid';
import { useEffect, useRef, useState } from 'react';

import ProjectFields, { projectInitialState } from './projectFields';

import InputSelectV2 from 'components/FormElement/InputSelectV2';
import InputTextOutlinedV2 from 'components/FormElement/InputTextOutlinedV2';
import CustomToolTip from 'components/Generic/Tooltip/ToolTip';
import { holidaysOptions } from 'constants/project';
import { ROLE_SUPERTAS } from 'constants/users';
import _ from 'lodash';
import moment from 'moment';
import FieldsNewAdittionalSkills from 'pages/common/FieldsNewAdittionalSkills';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { loginExpire } from 'store/auth';
import InputFileWithText from '../../../../components/FormElement/InputFileWithText';
import { useRecruiters } from '../../../../hooks/request';
import projectService from '../../../../services/projectService';
import { currentTitle, hardwareOpts, seniority, timezoneOpts } from '../../../../utils/staticData';
import ManagerInput from './managerInput';

const ProjectForm = ({
  auth: { user },
  numberProject,
  trySubmit,
  riseHandleError,
  riseHandleValidation,
  reset,
  projectDB,
}) => {
  const { ProjectId } = useParams();
  const [files, setFile] = useState('');
  const [skillsSearch, setSkillsSearch] = useState([]);
  const [workHours, setWorkHours] = useState([]);
  let secondFormRef = useRef();
  const firstValid = useRef(false);

  const {
    fields,
    handleSave,
    setValues,
    handleSubmit,
    errors,
    validSubmit,
    resetForm,
    handleSaveRepeaterProp,
    setForm,
    setErrors,
    setValidSubmit,
  } = ProjectFields({ ...projectInitialState, status: 'ACTIVE', monthly_mxn: '0' });

  const recruiters = useRecruiters();

  useEffect(() => {
    (async () => {
      if (trySubmit && trySubmit === true) {
        Promise.all([setValidSubmit(null), handleSubmit(secondFormRef)]);
      }
    })();
  }, [trySubmit]); // eslint-disable-line

  useEffect(() => {
    (async () => {
      if (projectDB && !_.isEmpty(projectDB)) {
        const { data } = await projectService.getProject(ProjectId);

        const skill = await projectDB.SkillsDB.filter((sk) => sk.skill_type === 1 || sk.skill_type === 3);
        const aditionalSkill = await projectDB.SkillsDB.filter((sk) => sk.skill_type === 2);
        const newSkill = skill.map((sk) => {
          return { SkillId: sk.SkillId.toString(), skill_type: sk.skill_type, yearExperience: sk.year_experience };
        });
        const newAdittionalSkill = aditionalSkill.map((sk) => {
          return { SkillId: sk.SkillId.toString(), skill_type: 2, yearExperience: sk.year_experience };
        });

        const hardware = Object.values(data.hardware_needs);
        const hardware_label = hardwareOpts.filter((h) => hardware.includes(h._id)).map((obj) => obj._id);

        const newData = {
          ClientId: data.ClientId,
          description: data.description,
          position: data.position,
          seniority: data.seniority,
          teamsize: data.teamsize,
          RecruiterId: data.RecruiterId,
          managers: data.managers,
          timezone: data.timezone,
          status: 'DRAFT',
          customer_job_link: data.customer_job_link || '',
          codetest_link: data.codetest_link || '',
          maxrate: data.maxrate,
          maxrate_pesos: data.maxrate_pesos,
          holidays: data.holidays,
          hardware_needs: hardware_label,
          hardware_notes: data.hardware_notes,
          interview_process: data.interview_process,
          newSkills: newSkill, //array single
          adittionalSkills: newAdittionalSkill, // array single
          working_hours_start: data.working_hours.monday.start,
          working_hours_end: data.working_hours.monday.end,
          working_hours: {
            monday: {
              active: data.working_hours.monday.active,
              start: data.working_hours.monday.start,
              end: data.working_hours.monday.end,
            },
          },
        };

        await Promise.all([
          setForm({
            ...projectInitialState,
            ...newData,
          }),
          setErrors({ ...projectInitialState }),
        ]);
      }
    })();
  }, [projectDB]); // eslint-disable-line

  useEffect(() => {
    const validSubmitAndFirstValidCurrentTrue = async () => {
      try {
        const newData = { ...fields };
        if (!newData.adittionalSkills[0].SkillId || newData.adittionalSkills[0].SkillId === '')
          newData.adittionalSkills = [];
        if (files !== '') {
          const formData = new FormData();
          formData.append('id', 'job_description');
          formData.append('job_description', files, files.name);
          const {
            data: { file },
          } = await projectService.uploadProjectAttachments(formData, 'job_description');
          newData['customer_job_link'] = file;
        }
        return riseHandleValidation(numberProject, newData);
      } catch (error) {
        if (error.response && error.response.data) {
          return toast.error(error.response.data.message);
        }
      }
    };
    if (validSubmit && firstValid.current === true) {
      validSubmitAndFirstValidCurrentTrue();
    } else if (validSubmit === false && firstValid.current === true) {
      return riseHandleValidation(numberProject, false);
    }
    firstValid.current = true;
  }, [validSubmit]); // eslint-disable-line

  useEffect(() => {
    (async () => {
      if (reset && reset === true) {
        resetForm();
        setFile('');
      }
    })();
  }, [reset]); // eslint-disable-line

  useEffect(() => {
    (async () => {
      const arrHours = [];
      for (let i = 0; i <= 14; i++) {
        const time = moment('06:00', 'hh').add(i, 'hours');
        arrHours.push({ _id: time.format('HH'), label: time.format('LT') });
      }
      const skillOpts = [];
      const { data: skillsDB } = await projectService.getAllSkills();
      skillsDB.forEach((el) => skillOpts.push({ id: el.id, title: el.name }));
      setSkillsSearch(skillOpts);
      setWorkHours(arrHours);
    })();
  }, []); // eslint-disable-line

  const handleFileChange = ({ target }) => setFile(target.files[0]);

  return (
    <>
      <Grid
        container
        spacing={2}
        component="form"
        ref={(ref) => (secondFormRef = ref)}
        sx={{ paddingTop: 2 }}
        alignItems="flex-start"
      >
        <Grid item xs={12} sm={6}>
          <InputSelectV2
            id="position"
            size={'small'}
            opts={currentTitle}
            error={errors.position?.txt}
            initialValue={fields.position}
            placeholder={'Position Title'}
            label="POSITION TITLE"
            onChangeInput={(e) => {
              handleSave(e);
              riseHandleError();
            }}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <InputSelectV2
            id="seniority"
            size={'normal'}
            label={'Desired Seniority Level'}
            opts={seniority}
            error={errors.seniority?.txt}
            initialValue={fields.seniority}
            placeholder={'Project Seniority'}
            onChangeInput={(e) => {
              handleSave(e);
              riseHandleError();
            }}
            variant="standard"
            backgroundColor="nexxusGrayExtraLight.main"
          />
        </Grid>
        {user.role === ROLE_SUPERTAS && (
          <Grid item xs={12} sm={3}>
            <InputSelectV2
              id="RecruiterId"
              size={'normal'}
              opts={recruiters.options}
              error={errors.RecruiterId?.txt}
              initialValue={fields.RecruiterId}
              placeholder={'Recruiter'}
              label="LEAD RECRUITER"
              onChangeInput={(e) => {
                handleSave(e);
                riseHandleError();
              }}
              variant="standard"
              backgroundColor="nexxusGrayExtraLight.main"
              isDisabled={user.role !== ROLE_SUPERTAS}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={6}>
          <InputTextOutlinedV2
            id={'description'}
            size={'normal'}
            label={'Project description'}
            multiline
            rowsMultiline={4}
            error={errors.description?.txt}
            initialValue={fields.description}
            onChangeInput={(e) => {
              handleSave(e);
              riseHandleError();
            }}
            variant="standard"
            backgroundColor="nexxusGrayExtraLight.main"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputTextOutlinedV2
            id={'notes'}
            size={'normal'}
            label="PROJECT NOTES"
            multiline
            rowsMultiline={4}
            error={errors.notes?.txt}
            initialValue={fields.notes}
            onChangeInput={(e) => {
              handleSave(e);
            }}
            variant="standard"
            backgroundColor="nexxusGrayExtraLight.main"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputTextOutlinedV2
            id={'job_description_link'}
            error={errors.job_description_link?.txt}
            initialValue={fields.job_description_link}
            size={'normal'}
            label="Job Description Info"
            multiline
            rowsMultiline={4}
            onChangeInput={(e) => {
              handleSave(e);
            }}
            variant="standard"
            backgroundColor="nexxusGrayExtraLight.main"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputTextOutlinedV2
            id={'interview_process'}
            size={'normal'}
            label="INTERVIEW PROCESS AND ADDITIONAL REQUIREMENTS"
            tooltip={
              <CustomToolTip
                title={'Important Client Resource availability times'}
                message={
                  'Examples: Daily scrum meeting times, other important meeting times, overlap with existing resources etc.'
                }
                buttonSx={{}}
              />
            }
            multiline
            rowsMultiline={4}
            error={errors.interview_process?.txt}
            initialValue={fields.interview_process}
            onChangeInput={(e) => {
              handleSave(e);
              riseHandleError();
            }}
            variant="standard"
            backgroundColor="nexxusGrayExtraLight.main"
          />
        </Grid>

        <Grid item xs={12} sm={3}>
          <InputTextOutlinedV2
            id={'customer_job_description_link'}
            error={errors.customer_job_description_link?.txt}
            initialValue={fields.customer_job_description_link}
            size={'normal'}
            label="JOB DESCRIPTION LINK"
            onChangeInput={(e) => {
              handleSave(e);
            }}
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <InputFileWithText
            accept={['application/pdf']}
            id={'job_description'}
            label={'Job description file'}
            onFileChange={handleFileChange}
            reset={reset}
            defaultFileName={fields.customer_job_link}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <InputSelectV2
            id="hardware_needs"
            size={'normal'}
            label="HARDWARE NEEDS"
            initialValue={fields.hardware_needs}
            error={errors.hardware_needs && errors.hardware_needs.txt}
            opts={hardwareOpts}
            onChangeInput={(e) => {
              handleSave(e);
              riseHandleError();
            }}
            multiple={true}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <InputTextOutlinedV2
            id={'hardware_notes'}
            error={errors.hardware_notes?.txt}
            initialValue={fields.hardware_notes}
            size={'normal'}
            label="CUSTOM HARDWARE NOTES"
            onChangeInput={(e) => {
              handleSave(e);
              riseHandleError();
            }}
            variant="standard"
            backgroundColor="nexxusGrayExtraLight.main"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} xl={1}>
          <InputTextOutlinedV2
            id={'teamsize'}
            type="number"
            size={'normal'}
            label="HEADCOUNT"
            error={errors.teamsize?.txt}
            initialValue={fields.teamsize}
            onChangeInput={(e) => {
              handleSave(e);
              riseHandleError();
            }}
            variant="standard"
            backgroundColor="nexxusGrayExtraLight.main"
            styleControl={{ height: '100%', justifyContent: 'space-between' }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} xl={2}>
          <InputSelectV2
            id="holidays"
            size={'small'}
            opts={holidaysOptions}
            error={errors.holidays?.txt}
            initialValue={fields.holidays}
            placeholder={''}
            label="HOLIDAYS"
            tooltip={
              <CustomToolTip
                title="Tip"
                message="We like to establish up front if the Resources will follow the US client Holiday Schedule or Mexico Holiday schedule"
                buttonSx={{}}
              />
            }
            onChangeInput={(e) => {
              handleSave(e);
              riseHandleError();
            }}
            sxContainer={{ height: '100%', justifyContent: 'space-between' }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <InputSelectV2
            id="timezone"
            size={'normal'}
            opts={timezoneOpts}
            error={errors.timezone?.txt}
            initialValue={fields.timezone}
            placeholder={'Timezone'}
            label="TIMEZONE"
            onChangeInput={(e) => {
              handleSave(e);
              riseHandleError();
            }}
            sxContainer={{ height: '100%', justifyContent: 'space-between' }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} xl={2}>
          <InputSelectV2
            id={`working_hours_start`}
            opts={workHours}
            size={'normal'}
            initialValue={fields.working_hours_start}
            error={errors.working_hours_start?.txt}
            placeholder={'Start'}
            label="WORKING HOURS FROM"
            onChangeInput={(e) => {
              handleSave(e);
              riseHandleError();
            }}
            sxContainer={{ height: '100%', justifyContent: 'space-between' }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} xl={2}>
          <InputSelectV2
            label="WORKING HOURS TO"
            id="working_hours_end"
            size={'normal'}
            opts={workHours}
            initialValue={fields.working_hours_end}
            error={errors.working_hours_end && errors.working_hours_end.txt}
            placeholder={'End'}
            onChangeInput={(e) => {
              handleSave(e);
              riseHandleError();
            }}
            sxContainer={{ height: '100%', justifyContent: 'space-between' }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} xl={2}>
          <InputTextOutlinedV2
            id={'maxrate'}
            type="number"
            placeholder="USD"
            size={'normal'}
            label="USD RATE"
            error={errors.maxrate?.txt}
            initialValue={fields.maxrate}
            onChangeInput={(e) => {
              handleSave(e);
              riseHandleError();
            }}
            variant="standard"
            backgroundColor="nexxusGrayExtraLight.main"
            styleControl={{ height: '100%', justifyContent: 'space-between' }}
          />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        component="form"
        ref={(ref) => (secondFormRef = ref)}
        sx={{ paddingTop: 2 }}
        alignItems="flex-start"
      >
        <Grid item container xs={12} md={6} alignItems="flex-start">
          <FieldsNewAdittionalSkills
            keyField="newSkills"
            fields={fields.newSkills}
            errors={errors.newSkills}
            skillsSearch={skillsSearch}
            reset={reset}
            setValues={setValues}
            handleSaveRepeaterProp={handleSaveRepeaterProp}
          />
        </Grid>

        <Grid item container xs={12} md={6} alignItems="flex-start">
          <FieldsNewAdittionalSkills
            keyField="adittionalSkills"
            fields={fields.adittionalSkills}
            errors={errors.adittionalSkills}
            skillsSearch={skillsSearch}
            reset={reset}
            setValues={setValues}
            handleSaveRepeaterProp={handleSaveRepeaterProp}
          />
        </Grid>

        <Grid item xs={12} md={6} sx={{ mb: 4 }}>
          <ManagerInput
            managers={fields.managers}
            errors={errors}
            addMoreManager={() => {
              const newFields = { ...fields };
              newFields.managers.push({ name: '', email: '' });
              setValues(newFields);
              riseHandleError();
            }}
            removeManager={() => {
              const newFields = { ...fields };
              setValues((prev) => ({ ...prev, managers: newFields.managers.slice(0, -1) }));
              riseHandleError();
            }}
            onHandleChange={(e, schema) => {
              handleSaveRepeaterProp(e, schema);
              riseHandleError();
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return {
    loginExpire: (user) => {
      dispatch(loginExpire(user));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectForm);
