import { CANDIDATEPROJECT_COUNTERS, CANDIDATEPROJECT_STATUS } from 'constants/candidate_project';
import { excludeForTotalCounters, GROUP_PROJECT_STATUS_REJECTED } from 'constants/customer';
import customerService from 'services/customerService';
import reportService from 'services/reportService';

const { useEffect, useState } = require('react');
const { default: candidateService } = require('services/candidateService');

const useCustomerProjectCandidates = (type) => {
  const initialCounters = {
    PANEL_INTERVIEW: {
      orderTab: 1,
      count: 0,
      key: 'PANEL_INTERVIEW',
      label: 'qualification interview',
      cssClass: '',
      projectStatus: 'PANEL_INTERVIEW',
      in: ['tas', 'customer'],
    },
    SKILLS_ASSESSMENT: {
      orderTab: 2,
      count: 0,
      key: 'SKILLS_ASSESSMENT',
      label: 'skills assessment',
      cssClass: '',
      projectStatus: 'SKILLS_ASSESSMENT',
    },
    SUBMITTED_CUSTOMER: {
      orderTab: 3,
      count: 0,
      key: 'SUBMITTED_CUSTOMER',
      label: 'submitted',
      cssClass: '',
      projectStatus: 'SUBMITTED_CUSTOMER',
    },
    CUSTOMER_INTERVIEW: {
      orderTab: 4,
      count: 0,
      key: 'CUSTOMER_INTERVIEW',
      label: 'interview',
      cssClass: '',
      projectStatus: 'CUSTOMER_INTERVIEW',
    },
    HIRED: {
      orderTab: 5,
      count: 0,
      key: 'HIRED',
      label: 'hired',
      cssClass: 'hired',
      projectStatus: 'HIRED',
      in: ['tas', 'customer'],
    },
    REJECTED: {
      orderTab: 6,
      count: 0,
      key: 'REJECTED',
      label: 'rejected',
      cssClass: 'rejected',
      projectStatus: 'REJECTED',
      in: ['tas', 'customer'],
    },
  };
  const source = Object.assign({}, ...Object.entries({ ...CANDIDATEPROJECT_COUNTERS }).map(([k, v]) => ({ [k]: [] })));
  const [projectId, setProjectId] = useState('');
  const [customer, setCustomer] = useState({});
  const [statusSelected, setStatusSelected] = useState('');
  const [candidates, setCandidates] = useState([]);
  const [candidateSelected, setCandidateSelected] = useState('');
  const [totalCandidates, setTotalCandidates] = useState(0);
  const [loading, setLoading] = useState(false);

  const [candidatesCountStatus, setCandidatesCountStatus] = useState(initialCounters);
  const [dataSource, setDataSource] = useState({ candidates: source });
  const sortBy = 'id';

  const setCountersReport = (candidates) => {
    let newCounters = { ...initialCounters };
    let total = 0;
    if (candidates)
      Object.keys(candidates)
        .filter((status) => status !== 'ARCHIVED')
        .forEach((key) => {
          if (
            [
              'PANEL_INTERVIEW_REJECTED',
              'SKILLS_ASSESSMENT_REJECTED',
              'SUBMITTED_CUSTOMER_REJECTED',
              'CUSTOMER_INTERVIEW_REJECTED',
              'OFFER_REJECTION',
              'DROPPED_OUT',
            ].includes(key)
          )
            newCounters.REJECTED.count += candidates[key].length;
          else if (['CUSTOMER_INTERVIEW', 'SUBMITTED_CUSTOMER'].includes(key))
            newCounters.CUSTOMER_STAGE.count += candidates[key].length;
          else newCounters[key].count += candidates[key].length;
          total += candidates[key].length;
        });
    return { counters: newCounters, total: total };
  };

  const getStatusArr = () => {
    let status = [];
    let statusProject;
    if (statusSelected === 'REJECTED') {
      status = [...GROUP_PROJECT_STATUS_REJECTED];
      statusProject = 'REJECTED';
    } else {
      status.push(CANDIDATEPROJECT_STATUS[statusSelected]);
      statusProject = statusSelected;
    }

    return { statusArr: status, statusTab: statusProject };
  };

  const fetchCandidates = async (projectId) => {
    const tab = getStatusArr();
    let params = {
      status: tab.statusArr,
      sortBy: sortBy,
    };
    if (candidateSelected !== '') params['candidateId'] = candidateSelected;

    let src;
    if (projectId && projectId !== '') {
      const { data } = await candidateService.getCandidatesByProjectStatus(projectId, params);
      src = data;
    } else {
      let allStatus = [];
      if (statusSelected !== 'REJECTED') {
        const status = Object.entries(CANDIDATEPROJECT_STATUS)
          .filter(([k, v]) => k === statusSelected)
          .reduce((accum, [k, v]) => {
            accum[k] = v;
            return accum;
          }, {});
        allStatus.push(status[statusSelected]);
      } else {
        allStatus = [...GROUP_PROJECT_STATUS_REJECTED];
      }
      let params = {
        ProjectId: projectId !== '' ? projectId : null,
        includes: true,
        status: allStatus,
      };
      if (candidateSelected !== '') params['candidateId'] = candidateSelected;
      params['ProjectId'] = projectId !== '' && projectId !== 0 ? projectId : null;
      params['includes'] = true;
      const { data } = await customerService.getCustomerCandidates(customer.id, params);
      src = data;
    }

    return src ?? [];
  };

  useEffect(() => {
    (async () => {
      if (statusSelected !== '') Promise.all([setLoading(true), setCandidates(await fetchCandidates(projectId))]);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusSelected, dataSource]);

  useEffect(() => {
    (async () => {
      if (candidateSelected === '' && statusSelected !== '') {
        Promise.all([setLoading(true), setCandidates(await fetchCandidates(projectId))]);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [candidateSelected]);

  const setSourceAndCounters = async (clientId, projectId = null) => {
    const { data: countsStatus } = await customerService.getCustomerCounters(clientId, projectId, {
      excludeForTotal: excludeForTotalCounters,
    });
    Promise.all([
      setCandidatesCountStatus((prev) => ({
        ...prev,
        PANEL_INTERVIEW: { ...prev['PANEL_INTERVIEW'], count: countsStatus.COUNTER_PANEL_INTERVIEW },
        SKILLS_ASSESSMENT: { ...prev['SKILLS_ASSESSMENT'], count: countsStatus.COUNTER_SKILLS_ASSESSMENT },
        CUSTOMER_INTERVIEW: { ...prev['CUSTOMER_INTERVIEW'], count: countsStatus.COUNTER_CUSTOMER_INTERVIEW },
        SUBMITTED_CUSTOMER: { ...prev['SUBMITTED_CUSTOMER'], count: countsStatus.COUNTER_SUBMITTED_CUSTOMER },
        HIRED: { ...prev['HIRED'], count: countsStatus.COUNTER_HIRED },
        REJECTED: {
          ...prev['REJECTED'],
          count:
            Number(countsStatus.COUNTER_PANEL_INTERVIEW_REJECTED) +
            Number(countsStatus.COUNTER_SKILLS_ASSESSMENT_REJECTED) +
            Number(countsStatus.COUNTER_CUSTOMER_INTERVIEW_REJECTED) +
            Number(countsStatus.COUNTER_SUBMITTED_CUSTOMER_REJECTED) +
            Number(countsStatus.COUNTER_OFFER_REJECTION) +
            Number(countsStatus.COUNTER_DROPPED_OUT),
        },
      })),
    ]);
  };

  useEffect(() => {
    (async () => {
      if (projectId !== '') {
        // counters
        if (!type) {
          await setSourceAndCounters(customer.id, projectId === 0 ? null : projectId);
        } else if (type && type === 'report') {
          const { data } = await reportService.getReportOfWeek(projectId);
          const { counters, total } = setCountersReport(data?.candidates || []);
          setDataSource(data ?? source);
          setTotalCandidates(total);
          setCandidatesCountStatus(counters);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, customer]);

  return {
    initialCounters,
    candidates,
    projectId,
    candidatesCountStatus,
    totalCandidates,
    statusSelected,
    dataSource,
    customer,
    candidateSelected,
    loading,
    setCandidateSelected,
    setStatusSelected,
    setProjectId,
    setCandidates,
    setCustomer,
    setLoading,
  };
};

export default useCustomerProjectCandidates;
