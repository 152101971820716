import { Box } from '@mui/material';
import { styled } from '@mui/system';
import InputSelectV2 from 'components/FormElement/InputSelectV2';
import { useTabInterviewState } from 'contexts/TabInterviewContext';
import { useEffect } from 'react';
import candidateService from 'services/candidateService';
import { CANDIDATE_NOTE_SCOPE } from 'utils/staticData';
import ManageCandidateNotes from './Elements/ManageCandidateNotes';
import Title from './Elements/Title';

const GridContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit,minmax(min(200px,50%),1fr))',
  gridGap: `${theme.spacing(2)} ${theme.spacing(1)}`,
}));

const LeftInterviewTab = () => {
  const {
    currentCandidateId,
    currentIsDisabled,
    globalDataUpdateDone,
    setGlobalDataUpdateDone,
    gradeOptions,
    fields,
    setValues,
    handleSave,
    errors,
    validSubmit,
  } = useTabInterviewState();

  // Get All Candidate Code Tests
  useEffect(() => {
    (async () => {
      if (currentCandidateId) {
        const res = await candidateService.getCandidateGrade(currentCandidateId);
        const { data } = res;
        if (res.status === 200) {
          setValues({
            ...fields,
            professionalism: data.professionalism || 0,
            english_proficiency: data.english_proficiency || 0,
            articulation: data.articulation || 0,
            experience: data.experience || 0,
            cultural_match: data.cultural_match || 0,
            engineering_interview: data?.engineering_interview || 0,
          });
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCandidateId]);

  // Update Candidate Grade
  useEffect(() => {
    (async () => {
      setGlobalDataUpdateDone(false);
      if (currentCandidateId && validSubmit && !globalDataUpdateDone) {
        await candidateService.updateCandidateActivity(currentCandidateId);
        await candidateService.updateCandidateGrade(currentCandidateId, fields);
        setGlobalDataUpdateDone(true);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validSubmit]);

  return (
    <Box sx={{ mb: 5 }}>
      <Title>GRADE BASED ON TAS PANEL INTERVIEW</Title>

      <GridContainer sx={{ marginTop: 3, marginBottom: 3 }}>
        <InputSelectV2
          id={'professionalism'}
          label="PROFESSIONALISM"
          opts={gradeOptions}
          placeholder="Select Grade"
          initialValue={fields.professionalism}
          error={errors.professionalism && errors.professionalism.txt}
          isDisabled={currentIsDisabled}
          onChangeInput={(e) => handleSave(e)}
        />
        <InputSelectV2
          id={'english_proficiency'}
          label="ENGLISH PROFICIENCY"
          opts={gradeOptions}
          placeholder="Select Grade"
          initialValue={fields.english_proficiency}
          error={errors.english_proficiency && errors.english_proficiency.txt}
          isDisabled={currentIsDisabled}
          onChangeInput={(e) => handleSave(e)}
        />
        <InputSelectV2
          id={'articulation'}
          label="ARTICULATION CLARITY"
          opts={gradeOptions}
          placeholder="Select Grade"
          initialValue={fields.articulation}
          error={errors.articulation && errors.articulation.txt}
          isDisabled={currentIsDisabled}
          onChangeInput={(e) => handleSave(e)}
        />
        <InputSelectV2
          id={'cultural_match'}
          label="CULTURAL MATCH"
          opts={gradeOptions}
          placeholder="Select Grade"
          initialValue={fields.cultural_match}
          error={errors.cultural_match && errors.cultural_match.txt}
          isDisabled={currentIsDisabled}
          onChangeInput={(e) => handleSave(e)}
        />
        <InputSelectV2
          id={'experience'}
          label="EXPERIENCE/JOB HISTORY"
          opts={gradeOptions}
          placeholder="Select Grade"
          initialValue={fields.experience}
          error={errors.experience && errors.experience.txt}
          isDisabled={currentIsDisabled}
          onChangeInput={(e) => handleSave(e)}
        />
      </GridContainer>

      {currentCandidateId && (
        <ManageCandidateNotes
          isDisabled={currentIsDisabled}
          scope={CANDIDATE_NOTE_SCOPE.GLOBAL}
          projectId={null}
          candidateId={currentCandidateId}
          height={280}
          label="INTERVIEW NOTES"
        />
      )}
    </Box>
  );
};

export default LeftInterviewTab;
