import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import InputProfileImage from '../../../../components/FormElement/InputProfileImage';

import ClientService from '../../../../services/clientService';
import { CLIENTSTATUS, timezoneOpts } from '../../../../utils/staticData';
import { schemaCustomerManager, schemaCustomerStakeholder } from '../../../../validations/clients/clientRegister';
import CustomerFields from './customerFields';
import InputTextOutlinedV2 from 'components/FormElement/InputTextOutlinedV2';
import InputSelectV2 from 'components/FormElement/InputSelectV2';

const CustomerSection = ({ customer, trySubmit, onHandleError, onHandleValidation, reset, isDisabled }) => {
  let firstFormRef = useRef();
  // eslint-disable-next-line no-unused-vars

  const [logoUrlPreview, setLogoUrlPreview] = useState('');
  const [files, setFiles] = useState([]);

  const {
    fields,
    handleSave,
    setValues,
    handleSubmit,
    errors,
    validSubmit,
    resetForm,
    setErrors,
    setForm,
    initialState,
    handleSaveRepeaterProp,
  } = CustomerFields();

  const handleDrop = (files) => {
    if (files.length > 1 || !files[0].name) return;
    setFiles([{ file: files[0], name: [files[0].name] }]);
    setLogoUrlPreview(URL.createObjectURL(files[0]));
  };

  useEffect(() => {
    (async () => {
      if (reset && reset === true) {
        resetForm();
        setFiles([]);
        setLogoUrlPreview('');
      }
    })();
  }, [reset]); // eslint-disable-line

  useEffect(() => {
    (async () => {
      if (customer) {
        let fields = {
          companyName: customer.companyName,
          websiteUrl: customer.websiteUrl,
          logoUrl: customer.logoUrl,
          timezone: customer.timezone,
          status: CLIENTSTATUS.ACTIVE.toString(),
          notes: customer.notes,
          managers: customer.managers,
        };
        setErrors({});
        setForm({ ...initialState, ...fields });
      }
    })();
  }, [customer]); // eslint-disable-line

  useEffect(() => {
    (async () => {
      if (trySubmit && trySubmit === true && !customer) {
        handleSubmit(firstFormRef);
      } else {
        onHandleValidation({ ...fields });
      }
    })();
  }, [trySubmit]); // eslint-disable-line

  useEffect(() => {
    const uploadLogo = async (value, formData, newData) => {
      if (value.file) {
        formData.append('id', 'avatar_url');
        formData.append('avatar_url', value.file, value.file.name);
        const {
          data: { file },
        } = await ClientService.uploadClientLogoImage(formData, 'avatar_url');
        newData['logoUrl'] = file;
      }
    };
    (async () => {
      if (!validSubmit) {
        onHandleError();
        return;
      }
      setErrors({});
      const newData = { ...fields };
      try {
        if (files.length <= 0) {
          onHandleValidation({ ...newData });
          return;
        }
        for (const [, value] of Object.entries(files)) {
          const formData = new FormData();
          await uploadLogo(value, formData, newData);
        }
        onHandleValidation({ ...newData });
      } catch (error) {
        if (error.response && error.response.data) {
          return toast.error(error.response.data.message);
        }
      }
    })();
  }, [validSubmit]); // eslint-disable-line

  return (
    <Grid container spacing={2} ref={(ref) => (firstFormRef = ref)} component="form" onSubmit={handleSubmit}>
      <Grid item xs={12}>
        <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'end' }}>
          <Typography sx={{ fontWeight: 900, fontSize: 18 }}>COMPANY INFORMATION</Typography>
          <Box sx={{ marginLeft: 'auto' }}>
            <InputProfileImage
              onHandleDrop={handleDrop}
              avatar={logoUrlPreview || fields.logoUrl}
              name={`${fields?.companyName}`}
              isCompany
              styleAvatar={{ width: 100, height: 100, fontSize: '2.5rem' }}
              onHandleDelete={(e) => {
                setLogoUrlPreview('');
                setValues({ ...fields, logoUrl: '' });
              }}
              isDisabled={isDisabled}
            />
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} sm={6} lg={4}>
        <InputTextOutlinedV2
          id={'companyName'}
          size={'normal'}
          label="COMPANY NAME"
          error={errors.companyName && errors.companyName.txt}
          initialValue={fields.companyName}
          onChangeInput={(e) => {
            handleSave(e);
            onHandleError();
          }}
          isDisabled={isDisabled}
          styleInput={{ marginBottom: '3px' }}
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={4}>
        <InputTextOutlinedV2
          id={'websiteUrl'}
          size={'normal'}
          label="COMPANY WEBSITE"
          error={errors.websiteUrl && errors.websiteUrl.txt}
          initialValue={fields.websiteUrl}
          onChangeInput={(e) => {
            handleSave(e);
            onHandleError();
          }}
          type="url"
          isDisabled={isDisabled}
          styleInput={{ marginBottom: '3px' }}
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={4}>
        <InputSelectV2
          id={'timezone'}
          size={'normal'}
          label="TIMEZONE"
          opts={timezoneOpts}
          error={errors.timezone && errors.timezone.txt}
          initialValue={fields.timezone}
          onChangeInput={(e) => {
            handleSave(e);
            onHandleError();
          }}
          isDisabled={isDisabled}
          sx={{ marginBottom: '3px' }}
        />
      </Grid>
      <Grid item xs={12} md={8}>
        <Grid container spacing={2} columnSpacing={2}>
          <Grid item xs={6}>
            <FormControl fullWidth sx={{ textAlign: 'left' }}>
              <FormLabel>EXECUTIVE STAKEHOLDERS</FormLabel>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth sx={{ textAlign: 'left' }}>
              <FormLabel>EXECUTIVE STAKEHOLDERS EMAIL</FormLabel>
            </FormControl>
          </Grid>
        </Grid>
        {fields.managers.length > 0 &&
          fields.managers.map((manager, index) => (
            <Grid container spacing={0} sx={{ marginBottom: 1 }} key={index} columnSpacing={2}>
              <Grid item xs={6} sx={{ pt: 0 }}>
                <InputTextOutlinedV2
                  id={`managers_${index}_stakeholder`}
                  size={'normal'}
                  error={errors.managers && errors.managers[index]?.stakeholder && errors.managers[index].stakeholder.txt}
                  initialValue={manager.stakeholder}
                  onChangeInput={(e) => {
                    handleSaveRepeaterProp(e, schemaCustomerStakeholder);
                    onHandleError();
                  }}
                  isDisabled={isDisabled}
                  styleControl={{ pt: 0 }}
                  styleInput={{ marginBottom: '3px' }}
                />
              </Grid>
              <Grid item xs={6} sx={{ pt: 0 }}>
                <InputTextOutlinedV2
                  id={`managers_${index}_email`}
                  size={'normal'}
                  error={errors.managers && errors.managers[index]?.email && errors.managers[index].email.txt}
                  initialValue={manager.email}
                  onChangeInput={(e) => {
                    handleSaveRepeaterProp(e, schemaCustomerManager);
                    onHandleError();
                  }}
                  isDisabled={isDisabled}
                  type="email"
                  styleInput={{ marginBottom: '3px' }}
                />
              </Grid>
            </Grid>
          ))}
        {fields.managers.length < 3 && (
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', my: 1, mx: 0 }}>
            <Typography
              sx={{
                fontSize: '12px',
                fontWeight: 'bold',
                textAlign: 'right',
                color: 'nexxusGrayMid.main',
                lineHeight: 1,
                marginRight: 1,
              }}
            >
              ADD ANOTHER
            </Typography>
            <Button
              variant="outlined"
              color="nexxusGrayMid"
              sx={{ padding: 0, minWidth: '10px', paddingX: '5px', paddingY: '3px', borderRadius: '50%' }}
              onClick={() => {
                const newFields = { ...fields };
                newFields.managers.push({ stakeholder: '', email: '' });
                setValues((prev) => ({ ...prev, managers: newFields.managers }));
              }}
            >
              <Typography
                sx={{
                  fontSize: '12px',
                  fontWeight: 'bold',
                  textAlign: 'right',
                  color: 'nexxusGrayMid.main',
                  lineHeight: 1,
                }}
              >
                +
              </Typography>
            </Button>
          </Box>
        )}
      </Grid>
      <Grid item xs={12} md={4}>
        <InputTextOutlinedV2
          id={'notes'}
          size={'normal'}
          label="ADDITIONAL NOTES OR COMMENTS"
          multiline
          rowsMultiline={7}
          error={errors.notes && errors.notes.txt}
          initialValue={fields.notes}
          onChangeInput={(e) => {
            handleSave(e);
            onHandleError();
          }}
          styleInput={{ marginBottom: '3px' }}
          styleControl={{
            '& fieldset': {
              maxHeight: '190px',
            },
            '& .MuiInputBase-root': {
              padding: '11.5px 14px!important',
            },
          }}
          isDisabled={isDisabled}
        />
      </Grid>
    </Grid>
  );
};

export default CustomerSection;
