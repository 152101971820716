import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';
import useFetchLogs from 'hooks/request/useFetchLogs';
import _ from 'lodash';
import moment from 'moment';

const TabHistory = ({ candidateId }) => {
  const { logs } = useFetchLogs({
    params: {
      type: 'project_candidate',
      candidateId,
    },
    runAtStart: true,
  });

  const LogContainer = styled(Box)(({ theme }) => ({
    padding: '15px',
    overflowY: 'auto',
    height: '350px',
    borderRadius: theme.variables.inputs.borderRadius,
    borderColor: theme.variables.inputs.borderColor,
    borderWidth: theme.variables.inputs.borderWidth,
    borderStyle: 'solid',
  }));

  const LogEntry = styled(Typography)(({ theme }) => ({
    margin: '5px 0',
    padding: '2px 5px',
  }));

  const formattedDate = (date) => moment(date).format('MM/DD/YYYY');

  return (
    <Box sx={{}}>
      <Typography variant="label">CANDIDATE HISTORY LOG</Typography>
      <LogContainer>
        {logs &&
          logs.map((log, index) => {
            return (
              <LogEntry key={index}>
                <em>{formattedDate(log.createdAt)}</em> - {_.replace(log.meta.message, '_', ' ')}
              </LogEntry>
            );
          })}
      </LogContainer>
    </Box>
  );
};

export default TabHistory;
