import {
  Table,
  TableCell as MuiTableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Box,
  TableBody,
  Divider,
} from '@mui/material';
import { withStyles } from '@mui/styles';

const TableCell = withStyles({
  root: {
    borderBottom: 'none',
  },
})(MuiTableCell);

const ManageCustomerTable = ({ children, user }) => {
  return (
    <TableContainer component={Box}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography sx={{ color: 'nexxusGrayMid.main', fontWeight: 'bold', fontSize: '12px', textAlign: 'center' }}>
                COMPANY
              </Typography>
            </TableCell>
            <TableCell>
              <Typography sx={{ color: 'nexxusGrayMid.main', fontWeight: 'bold', fontSize: '12px', textAlign: 'center' }}>
                WEBSITE
              </Typography>
            </TableCell>
            {/* <TableCell>
              <Typography sx={{ color: 'nexxusGrayMid.main', fontWeight: 'bold', fontSize: '12px', textAlign: 'center' }}>
                MAX RATE
              </Typography>
            </TableCell> */}
            <TableCell sx={{ paddingX: 1 }}>
              <Typography sx={{ color: 'nexxusGrayMid.main', fontWeight: 'bold', fontSize: '12px', textAlign: 'center' }}>
                OFFERED
              </Typography>
            </TableCell>
            <TableCell sx={{ paddingX: 1 }}>
              <Typography sx={{ color: 'nexxusGrayMid.main', fontWeight: 'bold', fontSize: '12px', textAlign: 'center' }}>
                ACCEPTED
              </Typography>
            </TableCell>
            <TableCell sx={{ paddingX: 1 }}>
              <Typography sx={{ color: 'nexxusGrayMid.main', fontWeight: 'bold', fontSize: '12px', textAlign: 'center' }}>
                DENIED
              </Typography>
            </TableCell>
            <TableCell sx={{ paddingX: 1, width: '80px' }}>
              <Typography sx={{ color: 'nexxusGrayMid.main', fontWeight: 'bold', fontSize: '12px', textAlign: 'center' }}>
                {user.role !== 'tas' ? 'EDIT' : ''}
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell sx={{ padding: 0 }} colSpan={7}>
              <Divider sx={{ flexGrow: 1, borderBottomWidth: '2px', marginBottom: 3 }} />
            </TableCell>
          </TableRow>
          {children && children.map((child) => child)}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default ManageCustomerTable;
