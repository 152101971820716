import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import InputSelectV2 from 'components/FormElement/InputSelectV2';
import TabsCandidateOverview from 'components/TabsCandidateOverview/TabsCandidateOverview';
import useCandidateProjectStatus from 'hooks/useCandidateProjectStatus';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { capitalizeString } from 'utils/utils';
import MainLayout from '../../components/MainLayout/MainLayout';
import projectService from '../../services/projectService';
import { loginExpire } from '../../store/auth';

const Candidates = (props) => {
  const [searchParams] = useSearchParams();
  const [projects, setProjects] = useState([]);
  const { initialCounters, candidates, projectId, setStatusSelected, setProjectId, statusSelected, candidatesCountStatus } =
    useCandidateProjectStatus();
  const [selectedProject, setSelectedProject] = useState('');
  const tabSelected = searchParams.get('Tab');
  const pjId = searchParams.get('ProjectId');

  //init Hook
  useEffect(() => {
    (async () => {
      const { data } = await projectService.getAllProjects();
      const projs = data.map((pj) => ({
        _id: pj.id,
        label: `${pj.Client.companyName} - ${capitalizeString(pj.position)} - ${capitalizeString(pj.seniority)}`,
      }));
      setProjects(projs);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (projectId !== '') {
        let status = 'PANEL_INTERVIEW';
        if (tabSelected) status = pjId === projectId ? tabSelected : 'PANEL_INTERVIEW';
        setStatusSelected(status);
      }
    })();
  }, [projectId, pjId, tabSelected, setStatusSelected]);

  return (
    <>
      <MainLayout {...props} subtitle="Candidate" subtitleFocus="Overview" current="projects">
        <Box sx={{ paddingX: 0 }}>
          <Grid container spacing={1}>
            <Grid item xs={12} lg={7}>
              <Box
                sx={{
                  minWidth: 120,
                  textAlign: 'left',
                  marginTop: 1,
                  marginRight: 2,
                  marginBottom: 2,
                }}
              >
                <Typography variant="h4" align="left" pt={3} pb={1} sx={{ textTransform: 'uppercase' }}>
                  Candidate <strong>Overview</strong>
                </Typography>

                <Typography
                  sx={{
                    color: 'nexxusGrayMid.main',
                    fontSize: 12,
                    textAlign: 'left',
                    marginTop: 3,
                    fontWeight: 'bold',
                  }}
                >
                  SELECT A PROJECT TO VIEW THE STATUS OF ALL ASSIGNED CANDIDATES
                </Typography>
                <InputSelectV2
                  id={'projectId'}
                  size={'normal'}
                  opts={projects}
                  initialValue={projectId}
                  onChangeInput={(e) => {
                    setSelectedProject(projects.filter((p) => p._id === e.target.value)[0]);
                    setProjectId(e.target.value);
                  }}
                />
              </Box>
            </Grid>
          </Grid>
          <Paper elevation={0}></Paper>
          <TabsCandidateOverview
            initialCounters={initialCounters}
            candidates={candidates}
            projectId={projectId}
            setStatusSelected={setStatusSelected}
            candidatesCountStatus={candidatesCountStatus}
            statusSelected={statusSelected}
            selectedProject={selectedProject}
          ></TabsCandidateOverview>
        </Box>
      </MainLayout>
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return {
    loginExpire: (user) => {
      dispatch(loginExpire(user));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Candidates);
