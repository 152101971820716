import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { capitalizeString } from 'utils/utils';
import tagService from '../services/tagService';
import useIsMounted from './useIsMounted';

const useTags = () => {
  const [tags, setTags] = useState([]);
  const [error, setError] = useState(false);
  const isMountedRef = useIsMounted();

  const fetchTags = useCallback(async () => {
    try {
      const { data } = await tagService.getTagsWithCount();
      const capitalizedTags = data.map((tag) => {
        return { ...tag, name: capitalizeString(tag.name) };
      });

      if (isMountedRef.current) {
        setTags(capitalizedTags);
      }
    } catch (error) {
      setError(true);
    }
  }, [isMountedRef]);

  const refetch = async () => {
    fetchTags();
  };

  useEffect(() => {
    if (isMountedRef.current) {
      fetchTags();
    }
  }, [fetchTags, isMountedRef]);

  return { tags, error, refetch };
};

const formattedTagsSelection = (items) => {
  const arrItems = _.map(items, (item) => item.title.toString().toLowerCase());
  const objInput = { target: { name: 'tags', value: arrItems } };
  return objInput;
};

export { useTags, formattedTagsSelection };
