import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { useMediaQuery } from '@mui/material';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import { styled, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import ModalFeedback from 'components/Generic/Modal/ModalFeedback';
import { Fragment, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import ProjectForm from './projectForm';

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
  border: `none`,
  // border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary expandIcon={<ArrowRightIcon sx={{ fontSize: '2rem', color: '#b2b2b2' }} />} {...props} />
))(({ theme }) => ({
  backgroundColor: 'rgba(255, 255, 255, .05)',
  flexDirection: 'row',
  padding: 0,
  justifyContent: 'flex-start',
  borderBottom: ' 1px solid rgba(0, 0, 0, .125)',
  // marginBottom: 12,
  // borderBottom: 'none',
  '& .MuiAccordionSummary-expandIconWrapper': {
    marginLeft: theme.spacing(1.5),
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
    marginLeft: theme.spacing(1.5),
  },
  '& .MuiAccordionSummary-content': {
    // marginLeft: theme.spacing(1),
    borderTop: 'none',
    flexGrow: 0,
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  // padding: theme.spacing(2),
  padding: 0,
}));

const ProjectSection = ({ trySubmit, projects, onHandleError, onHandleValidation, reset, projectDB }) => {
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [isCustomerIntake, setIsCustomerIntake] = useState(false);
  const [modalWarningOpen, setModalWarningOpen] = useState(false);

  useMemo(() => {
    if (location.pathname.includes('customer/projects/intake')) {
      setIsCustomerIntake(true);
      isMobile && setModalWarningOpen(true);
    } else {
      setIsCustomerIntake(false);
    }
  }, [location, isMobile]);

  const getProjectLabel = (index) => {
    if (isCustomerIntake) {
      return index === 0
        ? `Project #${index + 1} Specific criteria`
        : `Project #${index + 1} Specific criteria (if applicable)`;
    } else {
      return `PROJECT #${index + 1} INFORMATION`;
    }
  };
  return (
    <Box>
      <ModalFeedback
        open={modalWarningOpen}
        onClose={() => setModalWarningOpen(false)}
        onAccept={() => setModalWarningOpen(false)}
        disableBtnCancel={true}
        variant={'info'}
        headerText={'Advice'}
        titleText={'Recommendation'}
        bodyText={' We highly recommend you to fill this form on a laptop or desktop for better experience'}
        modalWidth={350}
      />
      {projects.length > 0 &&
        projects.map((proj, index) => (
          <Fragment key={index}>
            <Accordion defaultExpanded={index === 0 ? true : false}>
              <AccordionSummary aria-controls={`panel${index}d-content`} id={`panel${index}d-header`}>
                <Typography sx={{ fontWeight: 900, fontSize: 18 }}>{getProjectLabel(index)}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <ProjectForm
                  numberProject={index + 1}
                  trySubmit={trySubmit}
                  riseHandleError={onHandleError}
                  riseHandleValidation={onHandleValidation}
                  reset={reset}
                  projectDB={projectDB}
                />
              </AccordionDetails>
            </Accordion>
          </Fragment>
        ))}
    </Box>
  );
};

export default ProjectSection;
