import { Grid } from '@mui/material';
import InputAutocomplete from 'components/FormElement/InputAutoComplete';
import InputSelectV2 from 'components/FormElement/InputSelectV2';
import { useCallback } from 'react';

const RowSkill = ({ id, index, skills, schemas, onUpdateSkill, errors, skillsSearch, reset, skillRow }) => {
  const schema = id === 'newSkills' ? schemas.newSkill : schemas.adittionalSkill;
  const labelSkillField = id === 'newSkills' ? 'SKILLS (MUST HAVE)' : 'SKILLS (NICE TO HAVE)';
  const getMarginTop = (index) => 0;

  const handleChange = useCallback(
    (items) => {
      const obj = { target: { name: `${id}_${index}_SkillId`, value: items.id } };
      onUpdateSkill(obj, schema.skill);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [schema]
  );

  const handleOnChangeInput = useCallback(
    (e) => {
      onUpdateSkill(e, schema.year);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [schema]
  );

  const handleSearchSkills = useCallback(() => {
    return skillsSearch;
  }, [skillsSearch]);

  return (
    <>
      <Grid item xs={8} sx={{ marginTop: getMarginTop(index) }}>
        <InputAutocomplete
          id={`${id}_${index}_SkillId`}
          labelField="title"
          label={labelSkillField}
          placeholder={'Skills'}
          onHandleChange={handleChange}
          error={errors && errors[index] ? errors[index].SkillId?.txt : ''}
          onSearch={handleSearchSkills}
          sx={{ mr: 1, marginBottom: '3px' }}
          isSkillsInput={true}
          handleFreeSolo={false}
          defaultOpts={skillsSearch}
          reset={reset}
          isMultiple={false}
          value={skillRow ? parseInt(skillRow.SkillId) : ''}
          styleErrorControl={{ marginTop: '3px' }}
        />
      </Grid>
      <Grid item xs={4} sx={{ marginTop: getMarginTop(index) }}>
        <InputSelectV2
          id={`${id}_${index}_yearExperience`}
          size={'small'}
          opts={[
            { _id: 1, label: '< 3' },
            { _id: 2, label: '3 - 5' },
            { _id: 3, label: '5 - 8' },
            { _id: 4, label: '8 +' },
          ]}
          error={errors && errors[index] ? errors[index].yearExperience?.txt : ''}
          initialValue={skillRow?.yearExperience ?? ''}
          placeholder={'0'}
          label="YEARS"
          styleError={{ marginTop: '3px' }}
          onChangeInput={handleOnChangeInput}
        />
      </Grid>
    </>
  );
};

export default RowSkill;
