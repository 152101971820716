import jwtDecode from 'jwt-decode';
import moment from 'moment';
import { toast } from 'react-toastify';
import authService from 'services/authService';
import { logout } from 'store/auth';

export default function loggingMiddleware({ dispatch, getState }) {
  return (next) => async (action) => {
    switch (action.type) {
      case 'auth/loginExpire':
        if (getState().auth && getState().auth.tokens) {
          const tokenExpiration = jwtDecode(getState().auth.tokens.access.token).exp;
          const tokenExpirationTimeInSeconds = tokenExpiration - moment(Math.floor(Date.now() / 1000));
          if (tokenExpiration && tokenExpirationTimeInSeconds < 30) {
            return Promise.all([
              toast.error('Sesion expired!', { autoClose: 2000 }),
              authService.logout(),
              dispatch(logout()),
            ]);
          }
        }
        break;
      case 'auth/resetExpire':
        break;
      default:
        break;
    }
    return next(action);
  };
}
