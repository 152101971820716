import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Stack,
  Typography,
  styled,
  Link as LinkMaterial,
} from '@mui/material';
import { useTheme } from '@mui/system';
import { PdfIcon } from 'assets/icons';
import MoreUploadsTab from 'components/Candidates/UploadsCandidateTab/MoreUploadsTab';
import ModalFeedback from 'components/Generic/Modal/ModalFeedback';
import { useEffect, useRef, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack5';
import { toast } from 'react-toastify';
import candidateService from '../services/candidateService';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
const url = `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
pdfjs.GlobalWorkerOptions.workerSrc = url;

const UploadPdf = ({ label, filename, handleChange, handleDelete, filetype, uploading, fileurl, inputRef, isDisabled }) => {
  const theme = useTheme();
  const [fileViewer, setFileViewer] = useState('');
  const [open, setOpen] = useState(false);

  const onCloseModal = (event, reason) => {
    event?.preventDefault();
    Promise.all([setFileViewer(''), setOpen(false), setPageNumber(1)]);
  };

  useEffect(() => {
    if (fileViewer !== '') setOpen(true);
  }, [fileViewer]);

  const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setPageNumber(1);
  };

  const changePage = (offset) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  };

  const PdfViewerControls = styled(Box)({
    display: numPages ? 'flex' : 'none',
    position: 'relative',
    minWidth: 'min-content',
    minHeight: 'min-content',
    margin: '10px 0',
    justifyContent: 'center',
  });

  const PdfControl = styled(Button)({
    width: '34px',
    height: '34px',
    background: `white`,
    border: '0',
    font: 'inherit',
    fontSize: '2em',
  });

  return (
    <>
      <ModalFeedback
        variant="general"
        headerText="PDF Viewer"
        titleText=""
        open={open}
        onCancel={onCloseModal}
        onClose={onCloseModal}
        modalWidth={1200}
        disableBtnConfirm={true}
        btnCancelText="Close"
      >
        <Box sx={{ p: '0 0 20px' }}>
          <LinkMaterial
            component="a"
            href={fileViewer}
            sx={{
              color: 'nexxusBlueExtraLight.main',
              fontWeight: 'bold',
            }}
            target="_blank"
          >
            DOWNLOAD
          </LinkMaterial>
        </Box>

        <Box
          sx={{
            position: 'relative',
            textAlign: 'center',
            '& canvas': {
              margin: '0 auto',
            },
            display: 'block',
          }}
        >
          <Document file={fileViewer} renderMode="canvas" renderTextLayer={false} onLoadSuccess={onDocumentLoadSuccess}>
            <Page pageNumber={pageNumber} />
          </Document>
          <PdfViewerControls>
            <Stack direction="row" spacing={2} justifyContent="center" alignItems="center">
              <PdfControl
                sx={{
                  borderTopLeftRadius: 5,
                  borderBottomLeftRadius: 5,
                }}
                type="button"
                disabled={pageNumber <= 1}
                onClick={() => changePage(-1)}
              >
                ‹
              </PdfControl>
              <Typography variant="h6" sx={{ px: 2 }}>
                {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
              </Typography>
              <PdfControl
                sx={{
                  borderTopRightRadius: 5,
                  borderBottomRightRadius: 5,
                }}
                type="button"
                disabled={pageNumber >= numPages}
                onClick={() => changePage(1)}
              >
                ›
              </PdfControl>
            </Stack>
          </PdfViewerControls>
        </Box>
      </ModalFeedback>

      <Stack alignItems="center">
        <Typography variant="subtitle" marginBottom={2}>
          {label}
        </Typography>
        <IconButton disableRipple disabled={!fileurl} onClick={() => setFileViewer(fileurl)}>
          <PdfIcon
            sx={{
              fontSize: 64,
              color: fileurl ? theme.palette.primary.lighter : theme.palette.grey.light,
              '&:hover': { color: theme.palette.primary.light },
            }}
          />
        </IconButton>
        <Typography variant="body2" marginTop={2} width={140} height={24} overflow="hidden" textOverflow="ellipsis">
          {filename ? filename : 'No File is Selected'}
        </Typography>
        <div className="file-input">
          <input
            type="file"
            id={`file-${filetype}`}
            name={`file-${filetype}`}
            accept="application/pdf"
            ref={inputRef}
            className="file"
            onChange={handleChange}
            disabled={isDisabled}
          />
          {!uploading && (
            <label
              className={isDisabled ? 'disabled' : ''}
              style={{ opacity: isDisabled ? '.5' : '1' }}
              htmlFor={`file-${filetype}`}
            >
              {fileurl ? 'UPDATE PDF' : 'UPLOAD PDF'}
            </label>
          )}
          {uploading && <CircularProgress size={20} />}
        </div>
        {fileurl && (
          <Button variant="text" color="error" disabled={isDisabled} onClick={handleDelete}>
            Delete File
          </Button>
        )}
      </Stack>
    </>
  );
};

const InterviewUploads = ({ candidateId, isDisabled }) => {
  const theme = useTheme();
  const [audioList, setAudioList] = useState([
    { id: -1, audio_file_url: 'none', ref: useRef() },
    { id: -2, audio_file_url: 'none', ref: useRef() },
    { id: -3, audio_file_url: 'none', ref: useRef() },
  ]);
  const [candidate, setCandidate] = useState({});

  const [formFile, setFormFile] = useState('');
  const [cvFile, setCvFile] = useState('');
  const [interviewURL, setInterviewURL] = useState('');
  const [cvURL, setCvURL] = useState('');
  const [formFileUploading, setFormFileUploading] = useState(false);
  const [cvFileUploading, setCvFileUploading] = useState(false);
  const intertviewRef = useRef();
  const cvRef = useRef();

  const [selectedAudioFile, setSelectedAudioFile] = useState([null, null, null]);
  const [isAudioFilePicked, setIsAudioFilePicked] = useState([false, false, false]);

  const [audiosKey, setAudiosKey] = useState(1);
  const [audioUploading, setAudioUploading] = useState([false, false, false]);

  const [open, setOpen] = useState(false);
  const [audioID, setAudioID] = useState('');
  const [audioIndex, setAudioIndex] = useState('');
  const [deleteAudio, setDeleteAudio] = useState(false);

  const handleOpenDeleteModal = (audioID, index, target) => {
    setOpen(true);
    setAudioID(audioID);
    setAudioIndex(index);
  };

  const onCloseModal = (event, reason) => {
    event?.preventDefault();
    setOpen(false);
  };

  const onAccept = (e, reason) => {
    setDeleteAudio(true);
    setOpen(false);
  };

  useEffect(() => {
    deleteAudio && handleDeleteAudio(audioID, audioIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteAudio]);

  const cvChangeHandler = async (event) => {
    //validate file is pdf format
    if (event.target.files[0] && event.target.files[0].type !== 'application/pdf') {
      toast.error('Please upload a valid PDF file');
      return false;
    }
    setCvFileUploading(true);
    try {
      const { data } = await handleSubmission(event.target.files[0], 'cv');
      setCvFile(data?.cv_file_name);
      setCvURL(data?.cv_file_url);
      setCvFileUploading(false);
    } catch (error) {
      setCvFileUploading(false);
      setCvFile('');
      setCvURL('');
      toast.error('Something went wrong');
      cvRef.current.value = '';
    }
  };

  const formChangeHandler = async (event) => {
    //validate file is pdf format
    if (event.target.files[0] && event.target.files[0].type !== 'application/pdf') {
      toast.error('Please upload a valid PDF file');
      return false;
    }
    setFormFileUploading(true);
    try {
      const { data } = await handleSubmission(event.target.files[0], 'interview');
      setFormFile(data?.interview_file_name);
      setInterviewURL(data?.interview_file_url);
      setFormFileUploading(false);
    } catch (error) {
      setFormFileUploading(false);
      toast.error('Something went wrong');
      intertviewRef.current.value = '';
    }
  };

  const audioChangeHandler = async (event, index) => {
    if (event.target.files[0] && event.target.files[0].type !== 'audio/mpeg') {
      toast.error('Please upload a valid MP3 file');
      return false;
    }

    const newAudioFiles = [...selectedAudioFile];
    const newIsAudioFilePicked = [...isAudioFilePicked];
    newAudioFiles[index] = event.target.files[0];
    newIsAudioFilePicked[index] = true;
    setSelectedAudioFile(newAudioFiles);
    setIsAudioFilePicked(newIsAudioFilePicked);
    const question_text = `Interview Question #${index + 1} - Audio`;
    const question_id = index + 1;
    const beforeAudioLoading = [...audioUploading];
    beforeAudioLoading[index] = true;
    setAudioUploading(beforeAudioLoading);
    try {
      const res = await handleSubmission(event.target.files[0], 'audios', {
        question_text,
        question_id,
      });
      const newAudioLoading = [...audioUploading];
      newAudioLoading[index] = false;
      setAudioUploading(newAudioLoading);
      let newAudioList = [...audioList];
      res.data.CandidateAudios.forEach((audio) => {
        newAudioList[audio.question_id - 1] = audio;
      });
      setAudioList(newAudioList);
      setAudiosKey(audiosKey + 1);
    } catch (error) {
      toast.error('Something went wrong');
      let newAudioList = [...audioList];
      newAudioList[index].ref.current.value = '';
      setAudioList(newAudioList);
    }
  };

  const handleSubmission = async (file, fileType, body = {}) => {
    const params = {
      [fileType]: file,
      ...body,
    };
    try {
      const res = await candidateService.updateCandidateFile(candidate.id, fileType, params);
      return res;
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = async (fileType) => {
    try {
      await candidateService.deleteCandidateFile(candidate.id, fileType);
      switch (fileType) {
        case 'cv':
          candidate.cv_file_name = null;
          setCvFile('');
          setCvURL('');
          cvRef.current.value = '';
          break;
        case 'interview':
          candidate.interview_file_name = null;
          setFormFile('');
          setInterviewURL('');
          intertviewRef.current.value = '';
          break;

        default:
          break;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteAudio = async (audioId, audioIndex) => {
    try {
      let newAudioList = [...audioList];
      newAudioList[audioIndex] = { id: -1, audio_file_url: 'none' };
      setAudioList(newAudioList);
      await candidateService.deleteCandidateAudio(audioId);
      setDeleteAudio(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await candidateService.getCandidate(candidateId);
      setCandidate(data);
      let newAudioList = audioList;
      data.CandidateAudios.forEach((audio) => {
        newAudioList[audio.question_id - 1] = audio;
      });
      setAudioList(newAudioList);
      setAudiosKey(audiosKey + 1);
      setFormFile(data.interview_file_name);
      setCvFile(data.cv_file_name);
      setInterviewURL(data.interview_file_url);
      setCvURL(data.cv_file_url);
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container spacing={2}>
      <ModalFeedback
        variant="warning"
        headerText="Delete Audio"
        titleText="Delete audio permanently?"
        open={open}
        onCancel={onCloseModal}
        onClose={onCloseModal}
        onAccept={onAccept}
      />
      <Grid item container xs={12} md={6} display="flex" direction="column" justifyContent="center">
        <Typography variant="subtitle" fontSize={18} align="center" marginBottom={2}>
          DOCUMENTATION
        </Typography>
        <Stack direction="row" spacing={2} justifyContent="center" alignItems="start">
          <UploadPdf
            label="ENGLISH CERT"
            filetype="interview"
            filename={formFile}
            fileurl={interviewURL}
            handleChange={formChangeHandler}
            handleDelete={() => handleDelete('interview')}
            uploading={formFileUploading}
            inputRef={intertviewRef}
            isDisabled={isDisabled}
          />

          <UploadPdf
            label="CV/RESUME"
            filetype="cv"
            filename={cvFile}
            fileurl={cvURL}
            handleChange={cvChangeHandler}
            handleDelete={() => handleDelete('cv')}
            uploading={cvFileUploading}
            inputRef={cvRef}
            isDisabled={isDisabled}
          />
        </Stack>
      </Grid>
      <Grid item xs={12} md={6} key={audiosKey}>
        {audioList.map((audio, index) => (
          <Box sx={{ marginTop: 5 }} key={audio.id}>
            <Typography sx={{ fontSize: 16, fontWeight: 'bold' }}>
              {audio ? audio.question_text : `Interview Question #${index + 1} - Audio`}
            </Typography>
            <Paper
              elevation={0}
              sx={{
                borderColor: theme.variables.inputs.borderColor,
                borderWidth: theme.variables.inputs.borderWidth,
                borderStyle: 'solid',
                display: 'flex',
                padding: '0.1rem 0',
                borderRadius: theme.variables.inputs.borderRadius,
              }}
            >
              <audio controls>
                <source src={audio ? audio.audio_file_url : ''} type="audio/mp3" />
                <source src={audio ? audio.audio_file_url : ''} type="audio/wav" />
                Your browser does not support the audio element.
              </audio>
            </Paper>
            <Box sx={{ display: 'flex', marginTop: 1 }}>
              <Typography sx={{ fontSize: 13, marginTop: '5px', marginRight: '5px' }}>
                {audio ? audio.audio_file_name : 'No File is Selected '}
              </Typography>
              <div className="file-input-small">
                <input
                  type="file"
                  name={`audio-${index}`}
                  id={`file-${index}`}
                  ref={audioList[index].ref}
                  className="file"
                  accept="audio/mpeg"
                  onChange={(e) => audioChangeHandler(e, index)}
                  disabled={isDisabled}
                />
                {!audioUploading[index] && (
                  <label
                    className={isDisabled ? 'disabled' : ''}
                    style={{ opacity: isDisabled ? '0.5' : '1', cursor: 'text!important' }}
                    htmlFor={`file-${index}`}
                  >
                    UPDATE AUDIO
                  </label>
                )}
                {audioUploading[index] && <CircularProgress size={20} />}
              </div>
              {audio.id > 0 && (
                <Button
                  variant="text"
                  onClick={(e) => handleOpenDeleteModal(audio.id, index, e.target)}
                  sx={{ textTransform: 'none' }}
                >
                  <Typography sx={{ fontSize: 13, color: 'red', fontWeight: 'bold' }}>DELETE AUDIO</Typography>
                </Button>
              )}
            </Box>
          </Box>
        ))}
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle" fontSize={18} align="left" marginBottom={0} sx={{ py: 1 }}>
          NEW ATTACHMENT TO CANDIDATE
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <MoreUploadsTab isDisabled={isDisabled}></MoreUploadsTab>
      </Grid>
    </Grid>
  );
};

export default InterviewUploads;
