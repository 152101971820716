import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Button, Divider, FormControlLabel, FormLabel, Grid, Stack, SvgIcon, Switch, Typography } from '@mui/material';
import InputSelectV2 from 'components/FormElement/InputSelectV2';
import InputTextOutlinedV2 from 'components/FormElement/InputTextOutlinedV2';
import ModalFeedback from 'components/Generic/Modal/ModalFeedback';
import { CANDIDATE_STATUS } from 'constants/candidate';
import { ROLE_SUPERTAS } from 'constants/users';
import _ from 'lodash';
import CandidateHeaderForm from 'pages/tas/candidateForm/candidateHeaderForm';
import ModalTags from 'pages/tas/candidates/modalTags';
import { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import InputProfileImage from 'components/FormElement/InputProfileImage';
import { ROLE_TAS } from 'constants/users';
import candidateService from 'services/candidateService';
import { loginExpire } from 'store/auth';

import {
  availabilityOptions,
  currentTitle,
  employmentType,
  locationOptions,
  salaryCurrency,
  seniority,
  timezoneOptsCand,
} from 'utils/staticData';
import { capitalizeString, currencyFormat } from 'utils/utils';
import { schemaTAS } from 'validations/candidateRegister';
import CandidateFields, { candidateInitialState } from './candidateFields';

const CandidateForm = ({ editing, setEditing, auth, setIsCandidateActive }) => {
  const { CandidateId } = useParams();
  const [notFound, setNotFound] = useState(false);
  const [files, setFiles] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [logoUrlPreview, setLogoUrlPreview] = useState('');
  const [logoUrl, setLogoUrl] = useState('');
  const [open, setOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const [tags, setTags] = useState([]); // options array objects
  const [initialTags, setInitialTags] = useState([]);
  const [resetTags, setResetTags] = useState(false);
  const location = useLocation();
  const [isEditPage, setIsEditPage] = useState(false);
  const [headerText, setHeaderText] = useState('');
  const [titleText, setTitleText] = useState('');
  const [showEditBottomBtn, setShowEditBottomBtn] = useState(false);
  const [interviewInputError, setInterviewInputError] = useState(false);

  useMemo(() => {
    setHeaderText('Confirm New Candidate');
    setTitleText('Do you want to add a new candidate?');

    if (isEditPage) {
      setHeaderText('Edit Candidate');
      setTitleText('Do you want to edit this candidate?');
    }
  }, [isEditPage]);

  useMemo(() => {
    const pathname = location.pathname;
    const urlContainsEditText = pathname.includes('edit');
    if (urlContainsEditText || editMode) {
      setIsEditPage(true);
    }
  }, [editMode, location]);

  const onCloseModal = (event, reason) => {
    event?.preventDefault();
    setOpen(false);
  };

  const onAccept = (e, reason) => {
    setSubmitting(true);
    setOpen(false);
  };

  let navigate = useNavigate();

  const {
    initialStateRequest,
    fields,
    handleSave,
    errors,
    handleSubmit,
    validSubmit,
    initialState,
    setValues,
    resetForm,
    setForm,
    setErrors,
    setInitialStateRequest,
  } = CandidateFields(candidateInitialState);

  const onTagsChangeInput = (items, fieldName) => {
    const arrItems = _.map(items, (item) => item.id);
    const objInput = { target: { name: 'tags', value: arrItems } };
    handleSave(objInput);
  };

  //init Hook
  useEffect(() => {
    (async () => {
      await getTagsOptions();
      if (!!CandidateId && CandidateId !== 'create') {
        const { data } = await candidateService.getCandidate(CandidateId);
        if (!data) return setNotFound(true);
        const getDataValue = (dataReference) => data[dataReference] ?? '';
        const newData = {
          first_name: data.first_name,
          last_name: data.last_name,
          email: data.email,
          status: data.status,
          blacklisted: data.blacklisted,
          linkedin: getDataValue('linkedin'),
          availability: getDataValue('availability'),
          availability_interview: getDataValue('availability_interview'),
          developer_seniority: getDataValue('developer_seniority'),
          developer_title: getDataValue('developer_title'),
          salary_currency: getDataValue('salary_currency'),
          salary_expectation: data.salary_expectation,
          employment_type: data.employment_type || '',
          github_profile: getDataValue('github_profile'),
          portfolio_link: getDataValue('portfolio_link'),
          candidate_result_link: getDataValue('candidate_result_link'),
          candidate_code_score: getDataValue('candidate_code_score'),
          location: getDataValue('location'),
          timezone: getDataValue('timezone'),
          english_proficiency: getDataValue('english_proficiency'),
          info_additional: getDataValue('info_additional'),
          tags: data.Tags.map((tag) => tag?.id || ''),
          avatar_url: getDataValue('avatar_url'),
          rate_per_hour: data.rate_per_hour || '',
          rate_customer: data.rate_customer || '',
          staff: data.staff,
        };
        await Promise.all([
          setInitialTags(data.Tags),
          setLogoUrl(data.avatar_url),
          setInitialStateRequest({ ...initialState, ...newData }),
          setForm({ ...initialState, ...newData }),
          setErrors({ ...initialState }),
        ]);
        return 0;
      }
      if (![ROLE_SUPERTAS, ROLE_TAS].includes(auth.user.role) === true) navigate('/403');
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [CandidateId]);

  useEffect(() => {
    const updateAvatarWithFilesValue = async (files, newData) => {
      if (files.length <= 0) return;
      for (const [, value] of Object.entries(files)) {
        value.file && (await updateAvatar(value, newData));
      }
    };
    const updateAvatar = async (value, newData) => {
      const formData = new FormData();

      formData.append('id', 'avatar_url');
      formData.append('avatar_url', value.file, value.file.name);
      const {
        data: { file },
      } = await candidateService.uploadCandidateImage(formData, 'avatar_url');
      newData['avatar_url'] = file;
      setValues({ ...fields, avatar_url: file });
    };
    (async () => {
      const newData = { ...fields };
      if (!!!submitting) return;
      try {
        await updateAvatarWithFilesValue(files, newData);
        if (newData.candidate_code_score === '') newData.candidate_code_score = 0;
        if (!!CandidateId && CandidateId !== 'create') {
          const { data: candidateUpdated } = await candidateService.patchCandidate(newData, CandidateId);
          await Promise.all([
            getTagsOptions(),
            setInitialTags(candidateUpdated.Tags),
            toast.success('Candidate saved!'),
            setIsCandidateActive(!newData.blacklisted),
            setSubmitting(false),
            setInitialStateRequest({ ...initialState, ...newData }),
            setFiles([]),
            setEditing(true),
          ]);
          return;
        }
        const { data: candidateSaved } = await candidateService.registerCandidate(newData);
        toast.success('Candidate saved!');
        resetForm();
        return navigate(`/tas/candidates/${candidateSaved.id}`);
      } catch (error) {
        setSubmitting(false);
        let err = '';
        if (typeof error?.response?.data?.message === 'string') {
          err = error.response.data.message;
          return toast.error(err);
        }
        const objError = JSON.parse(error.response.data.message);
        err = objError.message;
        const formErrors = { ...errors };
        formErrors[objError.field] = { response: true, txt: err };
        setErrors(formErrors);
        return toast.error(err);
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitting]);

  useEffect(() => {
    (async () => {
      if (validSubmit) {
        setOpen(true);
      } else {
        setOpen(false);
      }
    })();
  }, [validSubmit]);

  useEffect(() => {
    if (errors?.availability_interview?.response) {
      setInterviewInputError(true);
    }
    setInterviewInputError(false);
  }, [errors]);

  const getTagsOptions = async () => {
    const { data } = await candidateService.getTags();
    const tags = [];
    data.map((tag) => tags.push({ id: tag.id, title: capitalizeString(tag.name) }));
    setTags(tags);
  };

  const handleDrop = (files) => {
    if (files.length > 1 || !files[0].name) return;
    setFiles([{ file: files[0], name: [files[0].name] }]);
    setLogoUrlPreview(URL.createObjectURL(files[0]));
  };

  const discardChanges = async () => {
    const initial = { ...initialStateRequest };
    await Promise.all([
      setErrors({}),
      setLogoUrl(initial['avatar_url']),
      setLogoUrlPreview(initial['avatar_url']),
      setFiles([]),
      setResetTags(true),
      setValues(!!CandidateId ? initial : initialState),
    ]);
  };

  const setAvatar = () => {
    return logoUrlPreview || logoUrl || '';
  };

  const NoDataOverlay = () => {
    return (
      <Stack height="100%" alignItems="center" justifyContent="center">
        <Typography variant="h3" color="nexxusGrayLight.main" sx={{ fontWeight: 'bold' }}>
          NOT FOUND
        </Typography>
        <Typography variant="h6" color="nexxusGrayLight.main">
          Please go to create new candidate
        </Typography>
      </Stack>
    );
  };

  const setNumberFormatted = (field) => {
    if (editing) {
      return currencyFormat.format(field);
    } else {
      if (field !== '') return field;
      else return '0';
    }
  };

  return (
    <>
      {notFound && (
        <Box sx={{ marginY: 20 }}>
          <NoDataOverlay />
        </Box>
      )}
      {!notFound && (
        <>
          <Stack
            direction="row"
            justifyContent="space-between"
            sx={{
              '@media(max-width:560px)': {
                flexDirection: 'column',
              },
            }}
          >
            <CandidateHeaderForm
              onSetEditMode={setEditMode}
              showEditBottomBtn={setShowEditBottomBtn}
              editing={editing}
              first_name={fields.first_name}
              last_name={fields.last_name}
              onSetEditing={setEditing}
            >
              <InputProfileImage
                onHandleDrop={handleDrop}
                avatar={setAvatar()}
                name={`${fields.first_name} ${fields.last_name}`}
                accept={['image/jpeg', 'image/png', 'image/gif']}
                onHandleDelete={(e) => {
                  setLogoUrl('');
                  setLogoUrlPreview('');
                  setFiles([]);
                  setValues({ ...fields, avatar_url: '' });
                }}
                isDisabled={editing}
              />
            </CandidateHeaderForm>

            <Stack alignItems="flex-end">
              <ModalTags
                editing={editing}
                tags={tags}
                initialValue={initialTags}
                onHandleTags={(items) => {
                  setResetTags(false);
                  onTagsChangeInput(items);
                }}
                error={errors.tags}
                reset={resetTags}
                setResetTags={setResetTags}
              ></ModalTags>

              <FormControlLabel
                control={
                  <Switch
                    onChange={(e) => {
                      const obj = { target: { name: 'blacklisted', value: e.target.checked } };
                      handleSave(obj);
                    }}
                    checked={fields.blacklisted}
                    disabled={editing}
                  />
                }
                label="Blacklisted"
                labelPlacement="start"
              />

              <FormControlLabel
                control={
                  <Switch
                    onChange={(e) => {
                      const obj = { target: { name: 'staff', value: e.target.checked } };
                      handleSave(obj);
                    }}
                    checked={fields.staff}
                    disabled={editing}
                  />
                }
                label="Staff"
                labelPlacement="start"
              />
            </Stack>
          </Stack>
          <Divider sx={{ marginY: 2 }} />
          {/* Candidate Info Form */}
          <Grid container spacing={2} component="form" onSubmit={(e) => handleSubmit(e, schemaTAS)}>
            <ModalFeedback
              variant="info"
              headerText={headerText}
              titleText={titleText}
              open={open}
              onCancel={onCloseModal}
              onClose={onCloseModal}
              onAccept={onAccept}
            />
            {CandidateId && (
              <>
                <Grid item md={12}>
                  <Stack direction="row" spacing={2} justifyContent="center" alignItems="center" maxWidth={400}>
                    <FormLabel sx={{ minWidth: 130 }}>Candidate Status:</FormLabel>
                    <InputSelectV2
                      id={'status'}
                      size={'normal'}
                      label=""
                      opts={[
                        { _id: CANDIDATE_STATUS.ACTIVE, label: 'ACTIVE' },
                        { _id: CANDIDATE_STATUS.ARCHIVED, label: 'ARCHIVED' },
                      ]}
                      error={errors.status?.txt}
                      initialValue={fields.status}
                      onChangeInput={(e) => handleSave(e)}
                      variant="standard"
                      backgroundColor="nexxusGrayExtraLight.main"
                      isDisabled={editing}
                      inputProps={{ tabIndex: 1 }}
                    />
                  </Stack>
                </Grid>
              </>
            )}

            <Grid item xs={12}>
              <Grid container mt={2}>
                <Typography variant="h7" sx={{ fontWeight: '700' }}>
                  BASIC CANDIDATE INFO
                </Typography>
              </Grid>
              <Grid container spacing={2} mt={0.5}>
                {/* first name  */}
                <Grid item xs={12} md={6} lg={4} lg3={2}>
                  <InputTextOutlinedV2
                    id={'first_name'}
                    size={'normal'}
                    label="FIRST NAME"
                    error={errors.first_name?.txt}
                    initialValue={fields.first_name}
                    onChangeInput={(e) => {
                      handleSave(e);
                    }}
                    variant="standard"
                    backgroundColor="nexxusGrayExtraLight.main"
                    isDisabled={editing}
                    inputProps={{ tabIndex: 2 }}
                  />
                </Grid>
                {/* last name  */}
                <Grid item xs={12} md={6} lg={4} lg3={2}>
                  <InputTextOutlinedV2
                    id={'last_name'}
                    size={'normal'}
                    label="LAST NAME"
                    error={errors.last_name?.txt}
                    initialValue={fields.last_name}
                    onChangeInput={(e) => handleSave(e)}
                    variant="standard"
                    backgroundColor="nexxusGrayExtraLight.main"
                    isDisabled={editing}
                    inputProps={{ tabIndex: 3 }}
                  />
                </Grid>
                {/* email */}
                <Grid item xs={12} md={6} lg={4} lg3={2}>
                  <InputTextOutlinedV2
                    id={'email'}
                    size={'normal'}
                    type="email"
                    label="EMAIL"
                    error={errors.email?.txt}
                    initialValue={fields.email}
                    onChangeInput={(e) => {
                      handleSave(e, initialStateRequest?.email);
                    }}
                    variant="standard"
                    backgroundColor="nexxusGrayExtraLight.main"
                    isDisabled={editing}
                    inputProps={{ tabIndex: 4 }}
                  />
                </Grid>

                {/* location */}
                <Grid item xs={12} md={6} lg={6} lg3={3}>
                  <InputSelectV2
                    id="location"
                    size={'normal'}
                    label="LOCATION"
                    initialValue={fields.location}
                    error={errors.location?.txt}
                    opts={locationOptions}
                    onChangeInput={(e) => handleSave(e)}
                    isDisabled={editing}
                    inputProps={{ tabIndex: 5 }}
                  />
                </Grid>

                {/* timezone */}
                <Grid item xs={12} md={6} lg={6} lg3={3}>
                  <InputSelectV2
                    id="timezone"
                    size={'normal'}
                    label="TIMEZONE"
                    initialValue={fields.timezone}
                    error={errors.timezone?.txt}
                    opts={_.orderBy(timezoneOptsCand, [(timezone) => timezone._id.toLowerCase()], ['asc'])}
                    onChangeInput={(e) => handleSave(e)}
                    isDisabled={editing}
                    inputProps={{ tabIndex: 6 }}
                  />
                </Grid>
              </Grid>
              <Grid container mt={6}>
                <Typography variant="h7" sx={{ fontWeight: '700' }}>
                  CUSTOMER INFO
                </Typography>
              </Grid>
              <Grid container spacing={2} mt={0.5}>
                {/* rate for customer  */}
                <Grid item xs={12} md={6} lg={3}>
                  <InputTextOutlinedV2
                    id={'rate_customer'}
                    size={'normal'}
                    label="RATE FOR CUSTOMER"
                    error={errors.rate_customer?.txt}
                    initialValue={setNumberFormatted(fields.rate_customer)}
                    onChangeInput={(e) => (editing ? '' : handleSave(e))}
                    variant="standard"
                    backgroundColor="nexxusGrayExtraLight.main"
                    isDisabled={editing}
                    // inputProps={{ tabIndex: 3 }}
                  />
                </Grid>
                {/* rate for customer  */}
                <Grid item xs={12} md={6} lg={3}>
                  <InputTextOutlinedV2
                    id={'rate_per_hour'}
                    size={'normal'}
                    label={`RATE PER HOUR`}
                    error={errors.rate_per_hour?.txt}
                    // initialValue={fields.rate_per_hour}
                    initialValue={setNumberFormatted(fields.rate_per_hour)}
                    onChangeInput={(e) => (editing ? '' : handleSave(e))}
                    variant="standard"
                    backgroundColor="nexxusGrayExtraLight.main"
                    isDisabled={editing}
                    // inputProps={{ tabIndex: 3 }}
                  />
                </Grid>
              </Grid>
              <Grid container mt={6}>
                <Typography variant="h7" sx={{ fontWeight: '700' }}>
                  JOB CANDIDATE INFO
                </Typography>
              </Grid>
              <Grid container spacing={2} mt={0.5}>
                {/* availability to interview  */}
                <Grid item xs={12} md={6} lg={3}>
                  <InputSelectV2
                    id={'availability'}
                    size={'normal'}
                    label="AVAILABILITY TO JOIN"
                    opts={availabilityOptions}
                    error={errors.availability?.txt}
                    initialValue={fields.availability}
                    onChangeInput={(e) => handleSave(e)}
                    variant="standard"
                    backgroundColor="nexxusGrayExtraLight.main"
                    isDisabled={editing}
                    inputProps={{ tabIndex: 7 }}
                  />
                </Grid>
                {/* seniority */}
                <Grid item xs={12} md={6} lg={3}>
                  <InputSelectV2
                    id={'developer_seniority'}
                    size={'normal'}
                    label="SENIORITY"
                    opts={seniority}
                    error={errors.developer_seniority?.txt}
                    initialValue={fields.developer_seniority}
                    onChangeInput={(e) => handleSave(e)}
                    variant="standard"
                    backgroundColor="nexxusGrayExtraLight.main"
                    isDisabled={editing}
                    inputProps={{ tabIndex: 8 }}
                  />
                </Grid>
                {/* developer title */}
                <Grid item xs={12} md={6} lg={6}>
                  <InputSelectV2
                    id={'developer_title'}
                    size={'normal'}
                    label="POSITION"
                    error={errors.developer_title?.txt}
                    initialValue={fields.developer_title}
                    opts={currentTitle}
                    onChangeInput={(e) => handleSave(e)}
                    variant="standard"
                    backgroundColor="nexxusGrayExtraLight.main"
                    isDisabled={editing}
                    inputProps={{ tabIndex: 9 }}
                  />
                </Grid>
                {/* salary expectation */}
                <Grid item xs={12} md={6} lg={3}>
                  <InputTextOutlinedV2
                    id={'salary_expectation'}
                    size={'normal'}
                    type={editing ? 'text' : 'number'}
                    label="Expected Monthly Salary"
                    error={errors.salary_expectation?.txt}
                    initialValue={editing ? currencyFormat.format(fields?.salary_expectation) : fields.salary_expectation}
                    onChangeInput={(e) => (editing ? '' : handleSave(e))}
                    variant="standard"
                    backgroundColor="nexxusGrayExtraLight.main"
                    isDisabled={editing}
                    inputProps={{ tabIndex: 10 }}
                  />
                </Grid>
                {/* currency */}
                <Grid item xs={12} md={6} lg={3}>
                  <InputSelectV2
                    id={'salary_currency'}
                    size={'normal'}
                    label="CURRENCY"
                    error={errors.salary_currency?.txt}
                    initialValue={fields.salary_currency}
                    opts={_.orderBy(salaryCurrency, [(currency) => currency.label.toLowerCase()], ['asc'])}
                    onChangeInput={(e) => handleSave(e)}
                    variant="standard"
                    backgroundColor="nexxusGrayExtraLight.main"
                    isDisabled={editing}
                    inputProps={{ tabIndex: 11 }}
                  />
                </Grid>
                {/* employment type */}
                <Grid item xs={12} md={6} lg={3}>
                  <InputSelectV2
                    id={'employment_type'}
                    size={'normal'}
                    label="EMPLOYMENT/SCHEMA TYPE"
                    error={errors.employment_type?.txt}
                    initialValue={fields.employment_type}
                    opts={employmentType}
                    onChangeInput={(e) => handleSave(e)}
                    variant="standard"
                    backgroundColor="nexxusGrayExtraLight.main"
                    isDisabled={editing}
                    inputProps={{ tabIndex: 12 }}
                  />
                </Grid>
                {/* linkedin */}
                <Grid item xs={12} md={6} lg={3}>
                  <InputTextOutlinedV2
                    id={'linkedin'}
                    size={'normal'}
                    type="url"
                    label="LINKEDIN PROFILE - INSERT LINK"
                    error={errors.linkedin?.txt}
                    initialValue={fields.linkedin}
                    onChangeInput={(e) => handleSave(e)}
                    variant="standard"
                    backgroundColor="nexxusGrayExtraLight.main"
                    isDisabled={editing}
                    inputProps={{ tabIndex: 13 }}
                    styleControl={{ height: '100%', justifyContent: 'space-between' }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} mt={0.5}>
                <Grid item xs={12} lg={6}>
                  <Grid item container xs={12} spacing={2}>
                    {/* portfolio link */}
                    <Grid item xs={6}>
                      <InputTextOutlinedV2
                        id={'portfolio_link'}
                        size={'normal'}
                        type={'url'}
                        label="PORTFOLIO - INSERT LINK"
                        error={errors.portfolio_link?.txt}
                        initialValue={fields.portfolio_link}
                        onChangeInput={(e) => handleSave(e)}
                        variant="standard"
                        backgroundColor="nexxusGrayExtraLight.main"
                        isDisabled={editing}
                        inputProps={{ tabIndex: 14 }}
                      />
                    </Grid>
                    {/* github profile */}
                    <Grid item xs={6}>
                      <InputTextOutlinedV2
                        id={'github_profile'}
                        size={'normal'}
                        type="url"
                        label="GITHUB PROFILE - INSERT LINK"
                        error={errors.github_profile?.txt}
                        initialValue={fields.github_profile}
                        onChangeInput={(e) => handleSave(e)}
                        variant="standard"
                        backgroundColor="nexxusGrayExtraLight.main"
                        isDisabled={editing}
                        inputProps={{ tabIndex: 15 }}
                        styleControl={{ height: '100%', justifyContent: 'space-between' }}
                      />
                    </Grid>
                    {/* candidate_result_link */}
                    <Grid item xs={6}>
                      <InputTextOutlinedV2
                        id={'candidate_result_link'}
                        size={'normal'}
                        type="url"
                        label="CANDIDATE RESULT - INSERT LINK"
                        error={errors.candidate_result_link?.txt}
                        initialValue={fields.candidate_result_link}
                        onChangeInput={(e) => handleSave(e)}
                        variant="standard"
                        backgroundColor="nexxusGrayExtraLight.main"
                        isDisabled={editing}
                        inputProps={{ tabIndex: 16 }}
                        styleControl={{ height: '100%', justifyContent: 'space-between' }}
                      />
                    </Grid>
                    {/* candidate_code_score */}
                    <Grid item xs={6}>
                      <InputTextOutlinedV2
                        id={'candidate_code_score'}
                        size={'normal'}
                        type={editing ? 'text' : 'number'}
                        label="CANDIDATE CODE TEST SCORE"
                        error={errors.candidate_code_score?.txt}
                        initialValue={editing ? `${fields.candidate_code_score}%` : fields.candidate_code_score}
                        onChangeInput={(e) => (editing ? '' : handleSave(e))}
                        variant="standard"
                        backgroundColor="nexxusGrayExtraLight.main"
                        isDisabled={editing}
                        inputProps={{ tabIndex: 17 }}
                        styleControl={{ height: '100%', justifyContent: 'space-between' }}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} lg={6} sx={{ display: 'flex' }}>
                  <Grid item container xs={12} spacing={2} alignItems={'flex-start'}>
                    {/* availability to interview */}
                    <Grid item xs={6}>
                      <InputTextOutlinedV2
                        id={'availability_interview'}
                        size={'normal'}
                        label="AVAILABILITY TO INTERVIEW"
                        error={errors.availability_interview?.txt}
                        initialValue={fields.availability_interview}
                        onChangeInput={(e) => handleSave(e)}
                        variant="standard"
                        backgroundColor="nexxusGrayExtraLight.main"
                        styleInput={{ mr: 1, minHeight: '152px' }}
                        styleControl={{ display: 'flex', justifyContent: 'space-between', height: '100%' }}
                        multiline
                        rowsMultiline={5}
                        isDisabled={editing}
                        inputProps={{ tabIndex: 18 }}
                      />
                    </Grid>
                    {/* info */}
                    <Grid item xs={6}>
                      <InputTextOutlinedV2
                        id={'info_additional'}
                        size={'normal'}
                        label="ADDITIONAL INFORMATION"
                        error={errors.info_additional?.txt}
                        initialValue={fields.info_additional}
                        onChangeInput={(e) => handleSave(e)}
                        variant="standard"
                        backgroundColor="nexxusGrayExtraLight.main"
                        styleInput={{
                          mr: 1,
                          minHeight: '152px',
                        }}
                        styleControl={{
                          display: 'flex',
                          justifyContent: !interviewInputError ? 'flex-start' : 'space-between',
                          height: '100%',
                          marginTop: '0!important',
                        }}
                        styleLabel={{ marginTop: '0!important' }}
                        multiline
                        rowsMultiline={5}
                        isDisabled={editing}
                        inputProps={{ tabIndex: 19 }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {/* save button form */}

            <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
              {editing && !showEditBottomBtn && (
                <Button
                  variant="contained"
                  sx={{ mt: '8px' }}
                  color="error"
                  startIcon={<SvgIcon style={{ color: '#fff' }} component={EditIcon} inheritViewBox />}
                  onClick={() => {
                    setEditMode(true);
                    setEditing(false);
                  }}
                >
                  edit candidate
                </Button>
              )}
            </Grid>

            <Grid item xs={12} sm={9} sx={{ margin: 'auto' }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'start',
                  alignItems: 'center',
                  marginTop: 1,
                  '@media(max-width:600px)': {
                    flexDirection: 'column',
                  },
                }}
              >
                <Typography
                  sx={{
                    fontSize: '13px',
                    fontWeight: 'bold',
                    textAlign: 'left',
                    color: 'nexxusGray.main',
                    marginRight: 2,
                  }}
                >
                  DON'T FORGET TO SAVE YOUR CHANGES
                </Typography>
                <Button
                  variant="contained"
                  type="submit"
                  startIcon={<CheckCircleIcon />}
                  disabled={CandidateId !== 'create' && editing}
                  tabIndex={20}
                >
                  Save Candidate
                </Button>
                <Typography
                  sx={{
                    fontSize: '9px',
                    fontWeight: 'bold',
                    textAlign: 'left',
                    color: 'nexxusGray.main',
                    mx: 2,
                  }}
                >
                  OR
                </Typography>
                <Button
                  variant="action"
                  color="warning"
                  disabled={editing}
                  onClick={async () => {
                    setErrors({});
                    discardChanges();
                  }}
                  tabIndex={21}
                >
                  <Typography display="flex" sx={{ fontWeight: 'bold', fontSize: 12 }}>
                    DISCARD CHANGES
                  </Typography>
                </Button>
              </Box>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return {
    loginExpire: (user) => {
      dispatch(loginExpire(user));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CandidateForm);
