import PropTypes from 'prop-types';
// @mui
import {  StyledEngineProvider} from '@mui/material/styles';
import shadows from '@mui/material/styles/shadows';
import {ThemeProvider as NexxusThemeProvider} from '../theme-returner/ThemeProvider'

import '../App.css';
import { CssBaseline } from '@mui/material';

// ----------------------------------------------------------------------

ThemeProvider.propTypes = {
  children: PropTypes.node,
};

const customizedShadows = shadows;
customizedShadows[1] = '0px 0px 3px 0px #e4e4e4';

export default function ThemeProvider({ children }) {

  return (
    <StyledEngineProvider injectFirst>
      <NexxusThemeProvider>
        <CssBaseline />
        {children}
      </NexxusThemeProvider>
    </StyledEngineProvider>
  );
}
