import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { loginExpire } from 'store/auth';
import { BreadcrumbsProvider } from './contexts/BreadcrumbsContext';
import AppRouter from './routers/appRouter';
import ThemeProvider from 'theme';

const App = (props) => {
  return (
    <BreadcrumbsProvider>
      <ThemeProvider>
        <div className="App">
          <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnVisibilityChange={false}
            draggable={false}
            pauseOnHover={false}
            theme="colored"
          />
          <AppRouter />
        </div>
      </ThemeProvider>
    </BreadcrumbsProvider>
  );
};
const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
  return {
    loginExpire: (user) => {
      dispatch(loginExpire(user));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
