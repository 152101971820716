import PropTypes from 'prop-types';
import { createContext, useState } from 'react';

//-----------------------------------------------------------------------------

const BreadcrumbsContext = createContext({
  breadcrumbs: [],
  setBreadcrumbs: () => {},
});

//-----------------------------------------------------------------------------

const BreadcrumbsProvider = ({ children }) => {
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  return <BreadcrumbsContext.Provider value={{ breadcrumbs, setBreadcrumbs }}>{children}</BreadcrumbsContext.Provider>;
};

BreadcrumbsProvider.propTypes = {
  children: PropTypes.node,
};

export { BreadcrumbsContext, BreadcrumbsProvider };
